import React, { useState, useRef, useEffect, } from "react";
import JoditEditor from 'jodit-react';

const RichTextEditor =({setValue,config,initalvalue})=>{
const editor= useRef(null);
return(
    <JoditEditor
    ref={editor}
    onBlur ={(content)=>setValue(content)}
    config={config}
    value={initalvalue}
 
    />
);


};

export default RichTextEditor;