import React from "react";
import WestIcon from "@mui/icons-material/West";
import { useNavigate, useLocation } from "react-router-dom";

function ParamBackButton() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    const segments = location.pathname.split('/').filter(Boolean);
  
    if (location.state && location.state.fromHome) {
      // If coming from home, navigate back to home
      navigate('/home');
    }
   
    else if (location.state && location.state.fromComp) {
      // If coming from home, navigate back to home
      navigate('/competitions');
    }
     else if (segments.length > 1) {
      segments.pop();
      const newPath = '/' + segments.join('/');
  
      const searchParams = new URLSearchParams(location.search);
      const queryString = searchParams.toString();
  
      const newUrl = queryString ? `${newPath}?${queryString}` : newPath;
  
      navigate(newUrl);
    } else {
      navigate('/');
    }
  };
  

  return (
    <div className="flex ml-10 md:ml-24 cursor-pointer transition-opacity hover:opacity-70" onClick={handleBackClick}>
      <WestIcon className="text-clue-yellow" />
      <span className="text-clue-yellow ml-2 hidden md:inline ">Back</span>
    </div>
  );
}

export default ParamBackButton;
