import "./App.css";
import React, { useState, useEffect } from "react";
import {
  useLocation,
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import Sidebar from "./components/sidebar/sidebar";
import Collection from "./pages/admin_pages/collections";
import Users from "./pages/admin_pages/users";
import Accounts from "./pages/admin_pages/accounts";
import Competitions from "./pages/admin_pages/competitions";
import Home from "./pages/main_pages/home";
import CompetitionsByAuthor from "./pages/main_pages/comp_by_author";

import Reset_mail from "./components/reset_pw/reset_mail";
import Handle_Error from "./components/Error/handle_error";
import Profile from "./components/profile/user_profile";
import Verify_Mail from "./components/verify/verify_mail";
import Entry from "./pages/admin_pages/entrypage";
import UserAccounts from "./pages/main_pages/user_accounts";
import SignInPage from "./components/sign_in/sign_in_page";
import Sign_up from "./components/sign_up/sign_up";
import Archives from "./pages/admin_pages/archives";
import InfoPage from "./components/infopage/infopage";
import PaymentModule from "./components/payment/paymentmodule";
import Dashboard from "./components/dashboard/dashboardpage";
import AuthorDashboard from "./components/dashboard/authordashboardpage";
import ContributionDetails from "./components/dashboard/contributioninfo";
import ReadBook from "./components/book/readbook";
import CompAdded from "./pages/admin_pages/compadded";
import PreviewComp from "./pages/admin_pages/previewcomp";
import CollectionInfo from "./pages/admin_pages/collectioninfo";
import CollectionCompEdit from "./pages/admin_pages/collectionedit";
import ArchiveDetail from "./pages/admin_pages/archive_detail";
import UserDetails from "./pages/admin_pages/user_details";
import ViewEntries from "./pages/admin_pages/view_entries";
import SubmissionPage from "./components/submission/submission_page";
import SuccessfulSubmit from "./components/submission/successful_submit_page";
import Case_Closed from "./components/submission/case_closed";
import Case_Solved from "./components/submission/case_solved";
import ViewSales from "./pages/admin_pages/view_sales";
import SplashScreen from "./components/splashscreen/splashscreen";
import "@fontsource/inter";
import EditPreviewComp from "./pages/admin_pages/collectioneditpreview";
import CompUpdated from "./pages/admin_pages/compupdated";
import ViewArchiveEntries from "./pages/admin_pages/view_archive_entries";
import Miscellaneous from "./pages/admin_pages/misc";
import PrivacyPolicy from "./components/misc/privacypolicy";
import TermsAndConditions from "./components/misc/termsconditions";
import ContactInformation from "./components/misc/contactinformation";
import CookiesPolicy from "./components/misc/cookiespolicy";
import EmailSubs from "./components/misc/emailsubs";
import ComposeEmail from "./components/misc/composeemail";
import ArchivedCollectionCompEdit from "./pages/admin_pages/archivecollectionedit";
import ArchiveEditPreviewComp from "./pages/admin_pages/archivecollectioneditpreview";
import ArchiveUpdated from "./pages/admin_pages/archiveupdated";
import BookOwned from "./components/payment/bookowned";
import BookOver from "./components/payment/bookover";
import ViewContSales from "./components/dashboard/view_cont_sales";
import ViewContEntries from "./components/dashboard/view_cont_entries";

function Layout() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const forScroll = [
    "/adboard/dashboard/collection/info",
    "/adboard/dashboard/collection/info/edit",
    "/adboard/dashboard/new-competition/success",
    "/adboard/dashboard/new-competition/previewcomp",
    "/account/verify-mail",
    "/competitions/product",
    "/competitions/payment",
    "/adboard/dashboard/users/user",
    "/adboard/dashboard/archive/details",
    "/adboard/dashboard/collection/view-entries",
    "/adboard/dashboard/collection/view-sales",
    "/adboard/dashboard/miscellaneous",
  ].includes(location.pathname);

  const showSidebar = [
    "/adboard/dashboard",
    "/adboard",
    "/adboard/dashboard/",
    "/adboard/",
    "/adboard/dashboard/collection",
    "/adboard/dashboard/archive",
    "/adboard/dashboard/users",
    "/adboard/dashboard/accounts",
    "/adboard/dashboard/new-competition",
    "/adboard/dashboard/miscellaneous",
    "/adboard/dashboard/collection/",
    "/adboard/dashboard/archive/",
    "/adboard/dashboard/users/",
    "/adboard/dashboard/accounts/",
    "/adboard/dashboard/new-competition/",
    "/adboard/dashboard/miscellaneous/",
  ].includes(location.pathname);

  const showNavbar = [
    "/",
    "/home",
    "/competitions",
    "/account",
    "/account/signin",
    "/account/signup",
    "/home/product",
    "/home/payment",
    "/competitions/product",
    "/competitions/payment",
    "/account/dashboard",
    "/account/dashboard/author-dashboard/competition",
    "/account/dashboard/author-dashboard",
    "/account/dashboard/book",
    "/account/dashboard/my-profile",
    "/beyond-the-yellow-tape",
    "/account/reset-password",
    "/account/verify-mail",
    "/account/dashboard/book/submission",
    "/account/dashboard/book/submission/success",
    "/account/dashboard/book/submission/closed",
    "/account/dashboard/book/submission/solved",
    "/competitions/product/payment",
    "/competitions/product/owned",
    "/competitions/product/over",
    "/home/",
    "/competitions/",
    "/account/",
    "/account/signin/",
    "/account/signup/",
    "/home/product/",
    "/home/payment/",
    "/competitions/product/",
    "/competitions/payment/",
    "/account/dashboard/",
    "/account/dashboard/author-dashboard/competitio/",
    "/account/dashboard/author-dashboard/",
    "/account/dashboard/book/",
    "/account/dashboard/my-profile/",
    "/beyond-the-yellow-tape/",
    "/account/reset-password/",
    "/account/verify-mail/",
    "/account/dashboard/book/submission/",
    "/account/dashboard/book/submission/success/",
    "/account/dashboard/book/submission/closed/",
    "/account/dashboard/book/submission/solved/",
    "/competitions/product/payment/",
    "/competitions/product/owned/",
    "/competitions/product/over/",
  ].includes(location.pathname);

  const intro = ["/", "/home"].includes(location.pathname);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('websiteToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  return (
    <div
      className={`App ${showSidebar ? "h-screen" : ""}`}
      style={{ fontFamily: "Inter" }}
    >
      {showSidebar && <Sidebar />}
      {intro && isLoading ? (
        <SplashScreen />
      ) : (
        <div className="App-content">
          {showNavbar && <Navbar />}
          <Routes>

            <Route path="/adboard/signin" element={<Entry />} />
            <Route
              path="/adboard/dashboard"
              element={<Collection />}
            />
            <Route
              path="/adboard"
              element={<Collection />}
            />
            <Route
              path="/adboard/dashboard/collection"
              element={<Collection />}
            />
            <Route path="/adboard/dashboard/archive" element={<Archives />} />
            <Route path="/adboard/dashboard/users" element={<Users />} />
            <Route path="/adboard/dashboard/accounts" element={<Accounts />} />
            <Route
              path="/adboard/dashboard/new-competition"
              element={<Competitions />}
            />
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/"
              element={isAuthenticated ? <Navigate to="/account/dashboard" replace /> : <Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/competitions" element={<CompetitionsByAuthor />} />
            <Route path="/account" element={<SignInPage />} />
            <Route path="/account/signin" element={<SignInPage />} />
            <Route path="/account/signup" element={<Sign_up />} />
            <Route path="/home/product" element={<InfoPage />} />
            <Route path="/home/payment" element={<PaymentModule />} />
            <Route path="/account/dashboard" element={<Dashboard />} />
            <Route
              path="/account/dashboard/author-dashboard/competition"
              element={<ContributionDetails />}
            />
            <Route
              path="/account/dashboard/author-dashboard"
              element={<AuthorDashboard />}
            />
            <Route path="/account/dashboard/book" element={<ReadBook />} />
            <Route path="/account/dashboard/my-profile" element={<Profile />} />
            <Route path="/beyond-the-yellow-tape" element={<Handle_Error />} />
            <Route path="/account/reset-password" element={<Reset_mail />} />
            <Route
              path="*"
              element={<Navigate to="/beyond-the-yellow-tape" />}
            />
            <Route
              path="/adboard/dashboard/new-competition/success"
              element={<CompAdded />}
            />
            <Route
              path="/adboard/dashboard/new-competition/previewcomp"
              element={<PreviewComp />}
            />
            <Route
              path="/adboard/dashboard/collection/info"
              element={<CollectionInfo />}
            />
            <Route
              path="/adboard/dashboard/collection/info/edit"
              element={<CollectionCompEdit />}
            />
            <Route
              path="/adboard/dashboard/archive/details/edit"
              element={<ArchivedCollectionCompEdit />}
            />
            <Route path="/account/verify-mail" element={<Verify_Mail />} />
            <Route path="/competitions/product" element={<InfoPage />} />
            <Route
              path="/competitions/product/payment"
              element={<PaymentModule />}
            />
            <Route
              path="/competitions/product/owned"
              element={<BookOwned />}
            />
            <Route
              path="/competitions/product/over"
              element={<BookOver />}
            />
            <Route
              path="/adboard/dashboard/archive/details"
              element={<ArchiveDetail />}
            />
            <Route
              path="/adboard/dashboard/users/user"
              element={<UserDetails />}
            />
            <Route
              path="/adboard/dashboard/collection/info/view-entries"
              element={<ViewEntries />}
            />
            <Route
              path="/account/dashboard/book/submission"
              element={<SubmissionPage />}
            />
            <Route
              path="/account/dashboard/book/submission/success"
              element={<SuccessfulSubmit />}
            />
            <Route
              path="/account/dashboard/book/submission/closed"
              element={<Case_Closed />}
            />
            <Route
              path="/account/dashboard/book/submission/solved"
              element={<Case_Solved />}
            />
            <Route
              path="/adboard/dashboard/collection/view-sales"
              element={<ViewSales />}
            />
            <Route
              path="/adboard/dashboard/collection/info/edit/preview"
              element={<EditPreviewComp />}
            />
            <Route
              path="/adboard/dashboard/collection/archive/edit/preview"
              element={<ArchiveEditPreviewComp />}
            />
            <Route
              path="/adboard/dashboard/collection/info/edit/success"
              element={<CompUpdated />}
            />
            <Route
              path="/adboard/dashboard/collection/archive/edit/success"
              element={<ArchiveUpdated />}
            />
            <Route
              path="/adboard/dashboard/miscellaneous"
              element={<Miscellaneous />}
            />
            <Route
              path="/adboard/dashboard/miscellaneous/privacypolicy"
              element={<PrivacyPolicy />}
            />
            <Route
              path="/adboard/dashboard/miscellaneous/termsandconditions"
              element={<TermsAndConditions />}
            />
            <Route
              path="/adboard/dashboard/miscellaneous/contactinformation"
              element={<ContactInformation />}
            />
            <Route
              path="/adboard/dashboard/miscellaneous/cookiespolicy"
              element={<CookiesPolicy />}
            />
            <Route
              path="/adboard/dashboard/miscellaneous/emailsubs"
              element={<EmailSubs />}
            />
            <Route
              path="/adboard/dashboard/miscellaneous/emailsubs/compose"
              element={<ComposeEmail />}
            />
            <Route
              path="/adboard/dashboard/miscellaneous/emailsubs/compose"
              element={<ComposeEmail />}
            />
            <Route
              path="/account/dashboard/author-dashboard/competition/view-sales"
              element={<ViewContSales />}
            />
            <Route
              path="/account/dashboard/author-dashboard/competition/view-entries"
              element={<ViewContEntries />}
            />
          </Routes>
        </div>
      )}
    </div>
  );
}

export default Layout;
