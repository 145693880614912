import Toast from "../toasts/toast";
import BackButton from "../backbutton/backbutton.js";
import { useState, useEffect, useRef } from "react";
import RichTextEditor from "../RichTextEditor/RichTextEditor.js";
import { useLocation,useNavigate } from "react-router-dom";
import { INSERT_AS_HTML } from "jodit";
import EmailManager from "../../models/admin/misc/http/emailsubshttp.js";

function ComposeEmail() {
  const emailManager = new EmailManager();
  const [toastMessages, setToastMessages] = useState([]);
  const [isSusOpen, setIsSusOpen] = useState(false);
  const [suspectName, setSuspectName] = useState("");
  const [forSubject, setForSubject] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const isMobile = window.innerWidth < 768;
  const [selectedEmail, setSelectedEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showLoading2, setShowLoading2] = useState(false);
  const [showLoading3, setShowLoading3] = useState(false);
  const [showLoading4, setShowLoading4] = useState(false);
  const config = {
    // all options from https://xdsoft.net/jodit/docs/
    placeholder: "Start typing....",
    theme: "dark", // Add this line for dark mode

    removeButtons: [
      "undo",
      "redo",
      "superscript",
      "subscript",
      "search",
      "source",
      "find",
      "fullsize",
      "preview",
      "print",
      "about",
      "image",
      "imageGallery",
      "file",
      "video",
      "lineBreak",
    ],
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: INSERT_AS_HTML,
    processPasteHTML: true,
    direction: "ltr",
    style: {
      height: isMobile ? "245px" : "350px",
      //background: '#000000',
      textAlign: "left",
    },
    tabIndex: 1,
    defaultTimeout: 0,
    addNewLine: false,
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };





  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const forId = searchParams.get("bookID");

  const openSus = async () => {
    // Fetch the list of emails when the dropdown is opened
    setShowLoading2(true);
    try {
      if (!forSubject.trim() || !editorContent.trim()) {
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Invalid Input",
            body: "Subject and body cannot be empty.",
          },
        ]);
        return;
      }
      const response = await emailManager.get(forId);
      if (response?.success) {
        setEmailList(response?.data || []);}
      else{
        //setShowLoading(true);
      }
    } catch (error) {
      console.error("Error fetching email subscribers:", error);
    }
    finally {
      // Set loading to false whether the request is successful or not
      setShowLoading2(false);
    }
  

    setIsSusOpen(true);
  };

  const closeSus = () => {
    setSelectedEmail("");
    setIsSusOpen(false);
  };

  const handleAllSendEmail = async () => {
    setShowLoading4(true);
    try {
      if (!forSubject.trim() || !editorContent.trim()) {
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Invalid Input",
            body: "Subject and body cannot be empty.",
          },
        ]);
        return;
      }
      // Call the post method from EmailManager to send the email
        // Include forID in the post request
        let response;
        if (location.state && location.state.fromSales) {
   response = await emailManager.post("all", forSubject, editorContent, forId);
} else {
  // If fromSales is not true, omit forID from the post request
   response = await emailManager.post("all", forSubject, editorContent);
}
      if (response?.success) {
        setForSubject("");
        setEditorContent("");
        setFile(null);
        setToastMessages([
          ...toastMessages,
          {
            type: "success",
            title: "Email Sent",
            body: "Email has been sent successfully.",
          },
        ]);
      }
      else{
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Email Sent",
          body: response.message,
        },
      ]);
    }
  } catch (error) {

      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Error",
          body: "Some error occured.",
        },
      ]);
    }
    finally {
      // Set loading to false whether the request is successful or not
      setShowLoading4(false);
    }
  };
  const handleSendToUser = async () => {
    setShowLoading3(true);
    try {
      // Call the post method from EmailManager to send the email
      const response = await emailManager.post(selectedEmail, forSubject, editorContent);
      if (response?.success) {
        setForSubject("");
        setEditorContent("");
        setFile(null);
        setToastMessages([
          ...toastMessages,
          {
            type: "success",
            title: "Email Sent",
            body: "Email has been sent successfully.",
          },
        ]);
      }
      else{
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Email Sent",
          body: "Email has not been sent.",
        },
      ]);
    }
  } catch (error) {

      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Error",
          body: "Some error occured.",
        },
      ]);
    }
    finally{
      setShowLoading3(false);
    }
    setSelectedEmail("");
    closeSus();
   
  };
  const [file, setFile] = useState(null);

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return; // User canceled the file selection
    }
    // Ensure that a file is selected and it has a valid extension
    if (selectedFile && (selectedFile.name.endsWith(".html") || selectedFile.name.endsWith(".htm"))) {
      setFile(selectedFile);

      // Read the content of the selected file
      const reader = new FileReader();
      reader.onload = (e) => {
        setEditorContent(e.target.result);
      };
      reader.readAsText(selectedFile);
    } else {
      // Reset file and editor content if the selected file is not valid
      setFile(null);
      setEditorContent("");
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Error",
          body: "Only HTML or .HTM file can be uploaded",
        },
      ]);
    }
  };

  const handleButtonClick = () => {
    // Trigger a click on the hidden file input
    fileInputRef.current.click();
  };
  const handleDoubleClick = (event) => {
    // Prevent the default behavior of the double-click event
    event.preventDefault();
  
    // Select the content of the input field
    event.target.select();
  };
  return (
    <div className="w-full" style={{ fontFamily: "Inter" }}>
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div></div></div>
      ) : (
        <>
          {toastMessages.map((toast, index) => (
            <Toast
              className="mb-0"
              key={index}
              toasts={[toast]}
              onClose={() => {
                // Remove the toast message when it's closed
                const updatedToasts = [...toastMessages];
                updatedToasts.splice(index, 1);
                setToastMessages(updatedToasts);
              }}
            />
          ))}

          <div className="mt-14 flex justify-between">
            <div>
              <BackButton />
            </div>
            <div className="px-10 md:px-32">
              <input
                type="file"
                accept=".html, .htm"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
                onDoubleClick={(e) => handleDoubleClick(e)}
              />

              {/* Button or link to trigger file input */}
              <span
                className="text-clue-yellow md:ml-10 underline cursor-pointer"
                onClick={handleButtonClick}
              >
                <span className="ml-2 md:ml-2 underline inline-block">Upload HTML instead</span>
              </span>
            </div>
          </div>
          <div className="flex ml-10 md:ml-[10%] mt-10">
            <span className="text-clue-book-title text-2xl">Compose Email</span>
          </div>
          <div className="flex items-center justify-center mt-10">
            <input
              type="text"
              id="cardNumber1"
              placeholder="Subject"
              value={forSubject}
              onChange={(e) => setForSubject(e.target.value)}
              className="w-[80%] h-12 text-clue-gray bg-clue-black px-5 p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
            />
          </div>
          <>
  {file ? (
    // Render iframe when a file is uploaded
    <div className="mt-10 w-[80%] ml-[10%]">
      <iframe
        title="HTML Viewer"
        srcDoc={editorContent}
        style={{ minHeight: "500px", maxHeight: "50vh", width: "100%" }}
        sandbox="allow-same-origin allow-scripts"
      />
    </div>
  ) : (
    // Render RichTextEditor when no file is uploaded
    <div className="mt-10 w-[80%] ml-[10%]">
      <RichTextEditor
        setValue={setEditorContent}
        initalvalue={editorContent}
        config={config}
        style={{ minHeight: "500px", maxHeight: "50vh" }}
      />
    </div>
  )}
</>
          <div className="flex md:flex-row flex-col w-[90%] justify-end mb-10">
            <button
              className={`transition-opacity hover:opacity-70 text-white px-20 py-2 bg-onclickfield mt-8 font-bold text-lg rounded-lg ml-10 md:ml-0 md:mr-10`}
              onClick={openSus}  >
              {showLoading2 ? (
                <span className="flex w-full items-center justify-center">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline  w-32 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </span>
              ) : (
                <span>
                {forId === null ? "Send to a User" : "Send to an Entrant"}
              </span>
              )}
          
          
            </button>
            <button
              className={`transition-opacity hover:opacity-70 text-white px-24 py-2 bg-onclickfield mt-8 font-bold text-lg rounded-lg ml-10 md:ml-0 `}
              onClick={handleAllSendEmail}
            >
              {showLoading4 ? (
                <span className="flex w-full items-center justify-center">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline  w-24 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </span>
              ) : (
                <span>
                {forId === null ? "Send to All" : "Send to All Entrants"}
              </span>
              )}
              
            </button>
          </div>
          {isSusOpen && (
            <div
              className="fixed rounded-xl inset-0 flex items-center justify-center z-50"
              onClick={closeSus}
            >
              <div className="bg-black opacity-50 absolute inset-0"></div>
              <div
                className="bg-clue-black p-5 px-10 md:w-[480px] w-80  rounded-3xl relative z-10"
                onClick={(e) => e.stopPropagation()}
              >
                <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-3">
                {forId === null ? "Select User" : "Select Entrant"}
                </h3>
                <p className="text-clue-gray text-left text-sm mb-4 ml-[-10px] md:ml-[-20px]">
                  You are about to send an email to one of your website users with your official account.
                </p>
                <select
                  value={selectedEmail}
                  onChange={(e) => setSelectedEmail(e.target.value)}
                  className="mb-4 px-5 w-60 md:w-80 bg-clue-black text-white p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                >
                  <option value="" disabled>Select Email</option>
                  {emailList.map((email) => (
                    <option key={email.id} value={email.email}>
                      {email.email}
                    </option>
                  ))}
                </select>
                <div className="flex justify-end mt-2">
                  <button
                    onClick={closeSus}
                    className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-1 px-4"
                  >
                    Cancel
                  </button>
                  <button  className={`bg-clue-purchase text-white px-4 py-1 rounded ${
    selectedEmail ? "" : "opacity-50 cursor-not-allowed"
  }`}
  onClick={handleSendToUser}
  disabled={!selectedEmail || showLoading3}
>
                {showLoading3 ? (
                <span className="flex w-full items-center justify-center"
              >
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline  w-10 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </span>
              ) : (
                <span>Send</span>
              )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>)}
    </div>
  );
}

export default ComposeEmail;
