import React, { useState, useEffect } from "react";
import "@fontsource/inter";
import { useNavigate, useLocation } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import HttpManager from "../../models/admin/auth/https/signinhttp.js";
import SignInManager from "../../models/website/auth/https/websigninhttp.js";
import Toast from "../toasts/toast.js";

function Sign_In({ isAdmin }) {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [toastMessages, setToastMessages] = useState([]);
  const navigate = useNavigate();
  const httpManager = new HttpManager();
  const signInManager = new SignInManager();

  const [isFocused, setIsFocused] = useState(false);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  // const navigateToAdminDashboard = () => {
  //   setShowLoading(true);
  //   httpManager.login(email, password).then((value) => {
  //     handleSignInResponse(value);
  //   });
  // };
  useEffect(() => {
    if (isAdmin === false) {
      const websiteToken = localStorage.getItem('websiteToken');

      if (websiteToken) {
        // Use React Router's useHistory hook to navigate
        navigate('/account/dashboard');
      }
    }

  },);
  const navigateToDashboard = async () => {
    setShowLoading(true);
    try {
      if (!email || !password) {
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Missing Information",
            body: "All fields are required.",
          },
        ]);
        setShowLoading(false);
        return; // Do not proceed with sign-up
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Invalid Email",
            body: "Please enter a valid email address.",
          },
        ]);
        setShowLoading(false);
        return; // Do not proceed with sign-in
      }

      const response = await (isAdmin
        ? httpManager.login(email, password)
        : signInManager.login(email, password));

      if (response.message === "Your email is not verified") {
        // Get the navigate function using useNavigate hook

        const is_from_signup2 = true;
        const unauthorized = true;
        // Redirect to "/account/verify-mail" with state
        navigate("/account/verify-mail", { state: { email, unauthorized, is_from_signup2 } });

        // Stop further execution (optional, depending on your use case)
        return;
      }
      handleSignInResponse(response);
    } catch (error) {
      handleSignInError("Enter valid email and password");
    }
  };

  const handleSignInResponse = (response) => {
    setShowLoading(false);

    if (!response.error) {
      const baseResponse = response.success;

      if (baseResponse === true) {
        const userToken = response.data.token;
        const email = response.data.email;
        const username = response.data.username;
        //const isEmailSubscribed = response.data.is_email_subscribed;

        if (userToken != null) {
          if (isAdmin) {
            localStorage.setItem('userToken', `Bearer ${userToken}`);
            localStorage.setItem('email', email);
            localStorage.setItem('username', username);
          }
          else {
            localStorage.setItem('websiteToken', `Bearer ${userToken}`);
            localStorage.setItem('websiteEmail', email);
            localStorage.setItem('websiteUsername', username);
            //localStorage.setItem('WebisEmailSubscribed', isEmailSubscribed);
          }
          if (location.state && location.state.frompayment) {
            // Redirect back to the original page with dynamic book_id
            const redirectPath = `/competitions/product/payment?book_id=${location.state.id}`;
            navigate(redirectPath);
          } else {
            navigate(isAdmin ? "/adboard/dashboard/collection" : "/account/dashboard");
          }
        } else {
          handleSignInError(response.message);
        }
      } else {
        handleSignInError(response.message);
      }
    } else {
      handleSignInError(response.error);
    }
  };

  const handleSignInError = (errorMessage) => {
    setShowLoading(false);
    setToastMessages([
      ...toastMessages,
      {
        type: "invalid",
        title: "Error",
        body: errorMessage,
      },
    ]);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <div className="flex justify-center items-center ">
        <div
          className={`  mx-2 md:w-[490px] w-[320px] md:mt-[15vh] md:mb-[15vh] mt-12 block rounded-[30px] text-neutral-600 bg-clue-black ${isAdmin ? "md:h-[420px] h-[350px]" : "md:h-[480px] h-[415px] "
            }`}
        >
          <p className=" md:ml-1 md:mr-[58%] md:text-[24px] text-[22px] mr-[155px]  md:mt-12 mt-10 mb-7 md:mb-10 font-[700] leading-tight border-neutral-600 text-clue-yellow">
            Sign in
          </p>

          <div className={"relative mb-4 "}>
            <input
              type="email"
              class="focus:outline-none focus:ring-0 focus:border-onclickfield peer m-0 block h-[45px] md:h-[56px] md:mx-14 md:w-[77%] mx-10 rounded-[10px] w-[245px]  border-[1px] border-solid border-clue-gray bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray "
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Email"
            />
          </div>

          <div class="relative mb-4 ">
            <input
              type={showPassword ? "text" : "password"}
              class="focus:outline-none focus:ring-0 focus:border-clue-purchase peer m-0 block h-[45px] md:h-[56px]   md:mx-14 md:w-[77%]  mx-10 w-[245px]  rounded-[10px] border-[1px] border-solid border-clue-gray  bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
            />
            {showPassword ? (
              <VisibilityOffOutlinedIcon
                className="absolute md:top-[18px] top-[12px] right-[50px] md:right-[70px] cursor-pointer text-gray-300"
                onClick={toggleShowPassword}
              />
            ) : (
              <VisibilityOutlinedIcon
                className="absolute md:top-[18px] top-[12px] right-[50px] md:right-[70px] cursor-pointer text-gray-300"
                onClick={toggleShowPassword}
              />
            )}
          </div>
          {!isAdmin && (
            <p className="text-base md:text-[18px] md:ml-[44%] w-60 md:w-72 ml-[98px] font-[500] text-clue-yellow  cursor-pointer">
              <a href="/account/reset-password" className="underline">
                Forgot Password
              </a>
            </p>
          )}
          <button
            type="button"
            onClick={navigateToDashboard}
            className={`mb-2 block md:mx-14 md:w-[77%]  mx-10 h-[45px] md:h-[56px] w-[245px] bg-onclickfield rounded-[10px] md:pb-1 md:pl-1 text-center text-[19px] md:text-[22px] font-[700] leading-normal text-white transition-opacity hover:opacity-70 
                    ${isAdmin
                ? "md:mt-11 mt-8 md:pb-[1] pb-[6px] pl-[1.5px] md:pl-1"
                : "md:mt-[25px] mt-[25px] md:pb-0 md:pl-0 pl-[-0.5px] pb-[3px]"
              }`}
          >
            {showLoading ? (
              <span className="flex w-full items-center justify-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </span>
            ) : (
              <span>Sign In</span>
            )}
          </button>
          {!isAdmin && (
            <p className=" md:mt-9 mt-5 w-52 md:w-72 text-[17px] md:text-[18px] md:ml-[22%]  text-center ml-[60px] font-[400] text-clue-gray leading-[1.30] text-lg ">
              Don't have an account?
              <a
                href="/account/signup"
                className="font-[700] text-clue-yellow pl-2 md:text-center underline text-base md:text-[19px]"
              >
                Sign up
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
export default Sign_In;
