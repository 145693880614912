import EndofPage from "../../components/endofpage/endline";
import MainTile from "../../components/tiles/main_tile";
import SubTile from "../../components/tiles/sub_tile";
import Footer from "../../components/footer/footer.js";
import React, { useState, useRef, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AllCompList from "../../models/website/public/https/comphttp.js";

function CompetitionsByAuthor() {
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const filterRef = useRef(null);
  const buttonRef = useRef(null);
  const [allComps, setAllComps] = useState({ data: [] });
  const [showLoading, setShowLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      setShowLoading(true);
      try {
        
        const allCompsResponse = await new AllCompList().get();
        if (allCompsResponse?.success) {
          setAllComps(allCompsResponse);
        } else {
          setShowLoading(true);
        }
        
      } catch (error) {
        console.error("Error fetching competition data:", error);
      }finally {
        setShowLoading(false);
      }
    }

    fetchData();
  }, [ setAllComps, setShowLoading]);
  
  // const navigateToPreview = () => {
  //   navigate("/competitions/product", {
  //     state: {
  //      all
  //     },
  //   });
  // };
  const [filters, setFilters] = useState({
    ageUnder18: false,
    filterBy: "",
    sortBy: "",
    orderBy: "",
  });

  function handleFilterChange(event) {
    const { name, value, type, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

 

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowFilters(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const filteredBooks = allComps.data.filter((book) => {
    if (
      searchTerm &&
      !book.title.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    if (filters.ageUnder18 && !book.under_18_appropriate) return false;

    if (filters.filterBy === "Active" && book.status !== "Active") return false;
    if (filters.filterBy === "Ended" && book.status !== "Ended") return false;

    return true;
  });

  let sortedBooks = [...filteredBooks];
  if (filters.sortBy) {
    sortedBooks.sort((a, b) => {
      if (filters.sortBy === "Prize") {
        const prizeA = Number(a.prize_amount);
        const prizeB = Number(b.prize_amount);
        return filters.orderBy === "Ascending"
          ? prizeA - prizeB
          : prizeB - prizeA;
      } else if (filters.sortBy === "DrawDate") {
        const dateA = new Date(a.end_date);
        const dateB = new Date(b.end_date);
        return filters.orderBy === "Ascending" ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
  }

  function resetFilters() {
    setFilters({
      ageUnder18: false,
      filterBy: "",
      sortBy: "",
      orderBy: "",
    });
    setSearchTerm(""); // Reset the search term as well
  }

  
  return (
    <div className="flex flex-col min-h-[95vh] text-white" style={{ fontFamily: "Inter" }}>
       {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div></div></div>
      ) : (
      <>
      <div className="flex-grow">
      <div
        className="md:max-w-[73%] mt-10 mx-5 md:mx-auto flex flex-col items-start space-x-4 relative"
        style={{ fontFamily: "Inter" }}
      >
        <div className="flex w-full items-center space-x-4">
          <div className="flex-grow flex items-center bg-clue-black rounded-xl p-2">
            <SearchIcon className="text-clue-yellow m-2" />
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Here"
              className="w-full bg-clue-black text-white placeholder-white border-none outline-none focus:ring-0"
            />
          </div>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="flex items-center h-14 bg-clue-black text-white ml-5 px-5 p-2 rounded-xl"
            ref={buttonRef}
          >
            <FilterListIcon className="text-clue-yellow" />
            <span className="ml-4 hidden md:block">Filters</span>
          </button>
        </div>
        {showFilters && (
          <>
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-40"
              onClick={() => setShowFilters(false)}
            ></div>
            <div
              className="bg-clue-black md:h-[480px] mt-4 p-4 rounded-xl absolute top-12 right-0 z-50"
              style={{ minWidth: "290px" }}
              ref={filterRef}
            >
            <div className="w-full text-right mt-4 mb-4">
              <span
                className="text-clue-purchase mr-5 underline cursor-pointer"
                onClick={resetFilters}
              >
                Reset Filters
              </span>

            </div>
            <div className="grid grid-rows-1 md:grid-cols-1 gap-x-4 text-clue-book-author mb-5">
              <div className="text-left pb-2 border-b border-sidebar-hover-color ">
                <p className="font-bold mb-2 text-filter-heading md:mx-5">Age</p>
                <label className="block ml-5 md:ml-0 ">
                  <input
                    type="checkbox"
                    name="ageUnder18"
                    checked={filters.ageUnder18}
                    onChange={handleFilterChange}
                    className="form-checkbox text-clue-purchase h-3 w-3 md:ml-10"
                  />
                  <span className="ml-2 ">Suitable for under 18</span>

                </label>
              </div>
              
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 text-clue-book-author">
              <div className="text-left  md:col-span-4">
                <div className="mb-4 pb-2 border-b border-sidebar-hover-color">
                  <p className="font-bold mb-2 text-filter-heading md:mx-5">Status</p>
                  <label className="block ml-5 md:ml-0">
                    <input
                      type="radio"
                      name="filterBy"
                      value="Active"
                      checked={filters.filterBy === "Active"}
                      onChange={handleFilterChange}
                      className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                    />
                    <span className="ml-2">Active</span>
                  </label>
                  <label className="block ml-5 md:ml-0">
                    <input
                      type="radio"
                      name="filterBy"
                      value="Ended"
                      checked={filters.filterBy === "Ended"}
                      onChange={handleFilterChange}
                      className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                    />
                    <span className="ml-2">Ended</span>
                  </label>
                </div>
                <div className="mb-4 pb-2 border-b border-sidebar-hover-color">
                  <p className="font-bold mb-2 text-filter-heading md:mx-5">Sort By</p>
                  <label className="block ml-5 md:ml-0">
                    <input
                      type="radio"
                      name="sortBy"
                      value="Prize"
                      checked={filters.sortBy === "Prize"}
                      onChange={handleFilterChange}
                      className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                    />
                    <span className="ml-2">Prize</span>
                  </label>
                  <label className="block ml-5 md:ml-0">
                    <input
                      type="radio"
                      name="sortBy"
                      value="DrawDate"
                      checked={filters.sortBy === "DrawDate"}
                      onChange={handleFilterChange}
                      className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                    />
                    <span className="ml-2">Draw Date</span>
                  </label>
                </div>
                <div className="">
                  <p className="font-bold mb-2 text-filter-heading md:mx-5">Order By</p>
                  <label className="block ml-5 md:ml-0">
                    <input
                      type="radio"
                      name="orderBy"
                      value="Ascending"
                      checked={filters.orderBy === "Ascending"}
                      onChange={handleFilterChange}
                      className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                    />
                    <span className="ml-2">Ascending</span>
                  </label>
                  <label className="block ml-5 md:ml-0">
                    <input
                      type="radio"
                      name="orderBy"
                      value="Descending"
                      checked={filters.orderBy === "Descending"}
                      onChange={handleFilterChange}
                      className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                    />
                    <span className="ml-2">Descending</span>
                  </label>
                </div>
              </div>
            </div>


          </div>
       </>
        )}
      </div>
      {sortedBooks.map((book, index) => (
  <div key={index} className="mx-5 md:w-[80%] md:mx-[10%]">
    <div onClick={() => navigate(`/competitions/product?book_id=${book.id}`)}>
      {book.status === "Active" ? (
        // Render MainTile if status is "active"
        <MainTile bookDetails={book} fromComp={true} />
      ) : (
        // Render SubTile for other cases
        <SubTile bookDetails={book} />
      )}
    </div>
  </div>
))}



     {/* {sortedBooks.map((book, index) => (
        <div key={index} className="mx-5 md:w-[80%] md:mx-[10%]">
          {book.ended === false ? (
            <Link to="/competitions/product">
              <MainTile bookDetails={book} />
            </Link>
          ) : (
            <div onClick={() =>navigate("/competitions/product")}>
              <SubTile bookDetails={book} />
            </div>
          )}
        </div>
      ))} */}

      <div className="my-24">
        <EndofPage />
      </div>
      </div>
      <div className="mt-10">
        <Footer />
      </div></>
      )}
    </div>
  );
}

export default CompetitionsByAuthor;