import React, { Suspense, useState, useEffect } from "react";
import myImage from "../../assets/images/cover.png";
import BackButton from "../../components/backbutton/backbutton.js";
import BookPage from "../../components/book/bookpage";
import { useNavigate, useLocation } from "react-router-dom";
import AddCompManager from "../../models/admin/competitions/https/createcomphttp.js";
import { Visibility } from "@mui/icons-material";
import Toast from "../../components/toasts/toast.js";
import AddContributionManager from "../../models/admin/contributions/https/createconthttp.js";
import AllUsersManager from "../../models/admin/users/https/getallusershttp.js";
import { format } from "date-fns";
import PromoCode from "../../models/admin/promocode/http/promohttp.js";
import WestIcon from "@mui/icons-material/West";

function PreviewComp() {
  const allusersManager = new AllUsersManager();
  const [users, setUsers] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setShowLoading(true);
    allusersManager.get().then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setUsers(value.data);
          setShowLoading(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  const location = useLocation();
  const {
    fullFile,
    title,
    description,
    startDate,
    endDate,
    prizeAmount,
    prizeAmount2,
    prizeAmount3,
    culprit,
    isVisible,
    under18,
    suspects,
    fullImage,
    author,
    amount,
    linkToAuthor,
    is1Winner,
    contributions,
    codeName,
    discount,
    promotions,
    isFile,
    isImage,
    fileSize,
    imageSize,
    data,
  } = location.state;
  const navigate = useNavigate();
  const [toastMessages, setToastMessages] = useState([]);
  const compManager = new AddCompManager();
  const contManager = new AddContributionManager();
  const navigateToSuccess = () => {
    addCompetition();
  };
  const bookDetails = {
    title: title,
    author: author,
    description: description,
    price: prizeAmount,
    startDate: startDate,
    drawDate: endDate,
    endDate: "22/02/2023",
    prizeAmount: "Appropriate for ages of 14 and above",
    ages: under18,
    winner: "N/A",
    visibility: isVisible,
  };

  const dateFormatter = (date) => {
    return format(new Date(date), "MMM dd, yyyy");
  };

  const addCompetition = async () => {
    setShowLoading(true);

    try {
      const compValue = await compManager.add(
        fullFile,
        title,
        description,
        startDate,
        endDate,
        prizeAmount,
        prizeAmount2,
        prizeAmount3,
        culprit,
        isVisible,
        under18,
        suspects,
        fullImage,
        author,
        amount,
        linkToAuthor,
        is1Winner
      );
      console.log(prizeAmount, prizeAmount2, prizeAmount3);

      if (!compValue || compValue.error) {
        handleErrorResponse(compValue);
        return;
      }

      const compId = compValue.data.comp_id;

      const updatedContributions = contributions.map((contribution) => ({
        ...contribution,
        book_id: compId,
      }));

      const contResponses = await Promise.all(
        updatedContributions.map((contribution) =>
          contManager.add(
            contribution.user_id,
            contribution.percentage,
            contribution.is_owner,
            contribution.book_id
          )
        )
      );

      if (contResponses.some((contResponse) => contResponse.error)) {
        handleErrorResponse(contResponses.find((response) => response.error));
        return;
      }

      const promoCode = new PromoCode();
      const updatedPromo = promotions.map((promotion) => ({
        book_id: compId,
        ...promotion,
      }));

      const promoResponses = await Promise.all(
        updatedPromo.map((promotion) =>
          promoCode.add(
            promotion.book_id,
            promotion.codeName,
            promotion.discount
          )
        )
      );

      if (promoResponses.some((promoResponse) => promoResponse.error)) {
        handleErrorResponse(promoResponses.find((response) => response.error));
        return;
      }

      setShowLoading(false);
      navigate("/adboard/dashboard/new-competition/success");
    } catch (error) {
      setToastMessages([
        ...toastMessages,
        {
          type: "error",
          title: "Error",
          body: error.message || "An error occurred.",
        },
      ]);
      setShowLoading(false);
    }
  };

  const handleErrorResponse = (response) => {
    setToastMessages([
      ...toastMessages,
      {
        type: response.error ? "error" : "invalid",
        title: "Error",
        body: response.error || response.message || "An error occurred.",
      },
    ]);
  };

  // const addCompetition = () => {
  //   compManager
  //     .add(
  //       fullFile,
  //       title,
  //       description,
  //       startDate,
  //       endDate,
  //       prizeAmount,
  //       culprit,
  //       isVisible,
  //       under18,
  //       suspects,
  //       fullImage,
  //       author,
  //       amount,
  //       linkToAuthor,
  //       is1Winner
  //     )
  //     .then((value) => {
  //       if (!value.error) {
  //         const baseResponse = value.success;
  //         if (baseResponse == true) {
  //           const updatedContributions = contributions.map((contribution) => ({
  //             ...contribution,
  //             book_id: value.data.comp_id,
  //           }));
  //           for (const contribution of updatedContributions) {
  //             contManager
  //               .add(
  //                 contribution.user_id,
  //                 contribution.percentage,
  //                 contribution.is_owner,
  //                 contribution.book_id
  //               )
  //               .then((value) => {
  //                 if (!value.error) {
  //                   const baseResponse = value.success;
  //                   if (baseResponse == true) {
  //                   } else {
  //                     setToastMessages([
  //                       ...toastMessages,
  //                       {
  //                         type: "invalid",
  //                         title: "Error",
  //                         body: value.message,
  //                       },
  //                     ]);
  //                   }
  //                 } else {
  //                   setToastMessages([
  //                     ...toastMessages,
  //                     {
  //                       type: "error",
  //                       title: "Error",
  //                       body: value.error,
  //                     },
  //                   ]);
  //                 }
  //               });
  //           }

  //           navigate("/adboard/dashboard/new-competition/success");
  //         } else {
  //           setToastMessages([
  //             ...toastMessages,
  //             {
  //               type: "invalid",
  //               title: "Error",
  //               body: value.message,
  //             },
  //           ]);
  //         }
  //       } else {
  //         setToastMessages([
  //           ...toastMessages,
  //           {
  //             type: "error",
  //             title: "Error",
  //             body: value.error,
  //           },
  //         ]);
  //       }
  //     });
  // };

  const [imageData, setImageData] = useState(null);

  const readImageFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const encodedImageData = e.target.result;
        setImageData(encodedImageData);
      };
      reader.readAsDataURL(file);
    }
  };

  const navigateToAuthorLink = () => {
    window.open(linkToAuthor, "_blank");
  };

  useEffect(() => {
    readImageFile(fullImage);
  }, [fullImage]);

  const handleBackClick = () => {
    navigate("/adboard/dashboard/new-competition", {
      state: {
        fullFile1: fullFile,
        title1: title,
        description1: description,
        startDate1: startDate,
        endDate1: endDate,
        prizeAmount1: prizeAmount,
        prizeAmount12: prizeAmount2,
        prizeAmount13: prizeAmount3,
        culprit1: culprit,
        isVisible1: isVisible,
        under181: under18,
        suspects1: suspects,
        fullImage1: fullImage,
        author1: author,
        amount1: amount,
        linkToAuthor1: linkToAuthor,
        is1Winner1: is1Winner,
        contributions1: contributions,
        codeName1: codeName,
        discount1: discount,
        promotions1: promotions,
        isFile1: isFile,
        isImage1: isImage,
        fileSize1: fileSize,
        imageSize1: imageSize,
        data1: data,
      },
    });
  };

  return (
    <div className="w-full" style={{ fontFamily: "Inter" }}>
      {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <div className="mt-10 bg-clue-purchase mx-4 md:mx-24 italic flex flex-col md:flex-row rounded-xl py-3 px-5">
        <span className="flex-1 text-left text-white text-sm">
          If something doesn’t look good you can go back and edit it. If
          everything looks good to go add the product to your collection, this
          action is irreversible, however you can delete the product from the
          collection later.
        </span>
        <button
          className="bg-clue-black font-semibold text-white rounded-xl mx-11 md:mt-0 mt-4 md:py-0 py-2 w-52 md:w-80 text-center"
          onClick={navigateToSuccess}
        >
          {showLoading ? (
            <span className="flex w-full items-center justify-center">
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </span>
          ) : (
            <span>Add to Collection</span>
          )}
        </button>
      </div>
      <div className="mt-10">
        <div
          className="flex ml-10 md:ml-24 cursor-pointer transition-opacity hover:opacity-70"
          onClick={handleBackClick}
        >
          <WestIcon className="text-clue-yellow" />
          <span className="text-clue-yellow ml-2 hidden md:inline ">Back</span>
        </div>
      </div>
      <div className="md:mx-24 mx-10 mt-10">
        <div className="border-b-2 border-filter-heading">
          <div className="flex justify-center md:justify-start my-8">
            <p className="text-clue-yellow font-bold text-xl">
              Product Preview
            </p>
          </div>
          <div className="flex flex-col md:flex-row w-full">
            <div className="w-full md:w-1/4">
              <img
                src={imageData}
                alt="Preview Image"
                className="w-3/4 md:w-full h-full object-cover mx-auto"
              />
            </div>
            <div className="ml-0 md:ml-20 flex flex-col w-full mt-10 md:mt-0">
              <div className="md:text-left w-full">
                <h2 className="text-clue-book-title font-semibold text-2xl mb-2">
                  {bookDetails.title}
                </h2>
                <p
                  className="text-clue-book-author text-base font-medium md:mb-10 cursor-pointer"
                  onClick={navigateToAuthorLink}
                >
                  {bookDetails.author}
                </p>
              </div>
              <div className="flex w-full flex-col md:flex-row mt-5">
                <div className="flex-col w-full mt-5 md:mt-0 md:text-left ">
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Start Date
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {dateFormatter(bookDetails.startDate)}
                  </p>
                </div>

                <div className="flex-col w-full md:text-left mt-5 md:mt-0">
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Draw Date
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {dateFormatter(bookDetails.drawDate)}
                  </p>
                </div>
                <div className="flex-col w-full mt-5 md:mt-0 md:text-left ">
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Prize Amount
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {prizeAmount && prizeAmount.trim() !== "" ? `$${prizeAmount}` : ""}
                    {prizeAmount2 && prizeAmount2.trim() !== "" ? (prizeAmount ? `, $${prizeAmount2}` : `$${prizeAmount2}`) : ""}
                    {prizeAmount3 && prizeAmount3.trim() !== "" ? (prizeAmount || prizeAmount2 ? `, $${prizeAmount3}` : `$${prizeAmount3}`) : ""}
                  </p>

                </div>
              </div>
              <div className="flex flex-col w-full md:flex-row mt-5 md:mt-10">
                <div className="flex-col md:text-left  w-full">
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Ages
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {under18 ? (
                      <span>Appropriate for ages of 14 and above</span>
                    ) : (
                      <span>Not suitable for readers under the age of 18</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="py-10">
            <p className="text-clue-draw-amount font-medium md:text-left ">
              Description
            </p>
            <p
              className="text-clue-book-author md:text-left  mt-3"
              dangerouslySetInnerHTML={{ __html: bookDetails.description }}
            ></p>
          </div>
        </div>
        <div className="border-b-2 border-filter-heading">
          <div className="flex justify-center md:justify-start my-8">
            <p className="text-clue-yellow font-bold text-xl">
              Additional Information
            </p>
          </div>
          <div className="flex flex-col w-full mt-5 md:mt-10">
            <div className="flex-col md:text-left  w-full">
              <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                Winner
              </p>
              <p className="text-clue-book-author font-bold mb-2 text-base">
                {bookDetails.winner}
              </p>
            </div>
            <div className="flex-col md:text-left mt-5 w-full">
              <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                Visible on Product Page
              </p>
              <p className="text-clue-book-author font-bold mb-2 text-base">
                {isVisible ? <span>Yes</span> : <span>No</span>}
              </p>
            </div>
            <div className="flex-col md:text-left mt-5 w-full">
              <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                Contributors
              </p>
              <p className="text-clue-book-author font-bold mb-2 text-base">
                {contributions.map((contribution, index) => {
                  const user = users.find(
                    (u) => u.user_id === contribution.user_id
                  );
                  return user ? (
                    <p
                      key={index}
                      className="text-clue-book-author font-bold mb-2 text-base"
                    >
                      {user.username}{" "}
                      {contribution.is_owner
                        ? String.fromCharCode(160) +
                        " (Company) " +
                        String.fromCharCode(160)
                        : "" + String.fromCharCode(160)}{" "}
                      {contribution.percentage}%
                    </p>
                  ) : null;
                })}
              </p>
            </div>
            <div className="flex-col md:text-left mt-5 mb-10 w-full">
              <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                Suspects
              </p>
              <p className="text-clue-book-author font-bold mb-2 text-base">
                {suspects.map((suspect, index) => (
                  <p key={index} className="text-suspect">
                    {suspect}
                  </p>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:mx-24 mt-10">
        <div className="flex justify-center md:justify-start my-8">
          <p className="text-clue-yellow font-bold text-xl">Book Contents</p>
        </div>
        <div>
          <BookPage bookUrl={fullFile} px="2260px" />
        </div>
      </div>
    </div>
  );
}
export default PreviewComp;
