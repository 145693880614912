import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Vector from "../../assets/images/Vector.png";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import GradientIcon from "@mui/icons-material/Gradient";
import CloseIcon from "@mui/icons-material/Close";
import Toast from "../../components/toasts/toast";
import AllUsersManager from "../../models/admin/users/https/getallusershttp";
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor.js";
import { INSERT_AS_HTML } from "jodit";
import { parse, set } from "date-fns";
function Competitions() {
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPromotionsOpen, setIsPromotionsOpen] = useState(false);
  const [isSusOpen, setIsSusOpen] = useState(false);
  const [isDeleteCont, setIsDeleteCont] = useState(false);
  const [isDeletePromo, setIsDeletePromo] = useState(false);
  const [isDeleteSus, setIsDeleteSus] = useState(false);
  const [isFile, setIsFile] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [imageSize, setImageSize] = useState("");
  const [isImage, setIsImage] = useState("");
  const [isPromo, setIsPromo] = useState(false);
  const [is3Winner, setIs3Winner] = useState(false);
  const [is1Winner, setIs1Winner] = useState(!is3Winner);
  const [suspectName, setSuspectName] = useState("");
  const [forIdx, setIdx] = useState(0);
  const [ContIdx, setContIdx] = useState(0);
  const [PromoIdx, setPromoIdx] = useState(0);
  const [toastMessages, setToastMessages] = useState([]);
  const [fullFile, setFullFile] = useState("");
  const [fullImage, setFullImage] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  //FORM VALUES

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [prizeAmount, setPrizeAmount] = useState("");
  const [prizeAmount2, setPrizeAmount2] = useState("");
  const [prizeAmount3, setPrizeAmount3] = useState("");
  const [culprit, setCulprit] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [under18, setUnder18] = useState(false);
  const [suspects, setSuspects] = useState([]);
  const [author, setAuthor] = useState("");
  const [amount, setAmount] = useState("");
  const [linkToAuthor, setLinkToAuthor] = useState("");
  const [contributions, setContributions] = useState([
    {
      user_id: "65c31ce0b268efa34d5005ea",
      percentage: "100",
      is_owner: true,
      book_id: "",
    },
  ]);
  const [promotions, setPromotions] = useState([]);

  //CONTRIBUTIONS

  const [userid, setUserId] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const [percentage, setPercentage] = useState("");
  const [bookId, setBookId] = useState("");

  const [susData, setSusData] = useState([]);
  const [data, setData] = useState(susData);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditPromotionOpen, setIsEditPromotionOpen] = useState(false);
  const [EditContIdx, setEditContIdx] = useState(0);
  const [EditPromoIdx, setEditPromoIdx] = useState(0);
  const isMobile = window.innerWidth < 768;

  const location = useLocation();

  const config = {
    // all options from https://xdsoft.net/jodit/docs/
    placeholder: "Start typing....",
    theme: "dark", // Add this line for dark mode

    removeButtons: [
      "undo",
      "redo",
      "superscript",
      "subscript",
      "search",
      "source",
      "find",
      "fullsize",
      "preview",
      "print",
      "about",
      "image",
      "imageGallery",
      "file",
      "video",
      "lineBreak",
    ],
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: INSERT_AS_HTML,
    processPasteHTML: true,
    direction: "ltr",
    style: {
      height: isMobile ? "245px" : "350px",
      //background: '#000000',
      textAlign: "left",
    },
    tabIndex: 1,
    defaultTimeout: 0,
    addNewLine: false,
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };
  const handleAddContribution = () => {
    const newContribution = {
      user_id: userid,
      percentage: percentage,
      is_owner: isOwner,
      book_id: bookId,
    };

    setContributions((prevContributions) => [
      ...prevContributions,
      newContribution,
    ]);

    setUserId("");
    setIsOwner(false);
    setPercentage("");
    setBookId("");
  };

  const handleAddPromotions = () => {
    const newPromotions = {
      codeName: codeName,
      discount: discount,
    };

    setPromotions((prevPromotions) => [...prevPromotions, newPromotions]);

    setCodeName("");
    setDiscount("");
  };

  useEffect(() => {
    if (location.state) {
      const {
        fullFile1,
        title1,
        description1,
        startDate1,
        endDate1,
        prizeAmount1,
        prizeAmount2,
        prizeAmount3,
        culprit1,
        isVisible1,
        under181,
        suspects1,
        fullImage1,
        author1,
        amount1,
        linkToAuthor1,
        is1Winner1,
        contributions1,
        codeName1,
        discount1,
        promotions1,
        isFile1,
        isImage1,
        fileSize1,
        imageSize1,
        data1,
      } = location.state;

      setTitle(title1);
      setDescription(description1);
      setStartDate(startDate1);
      setEndDate(endDate1);
      setPrizeAmount(prizeAmount1);
      setPrizeAmount2(prizeAmount2);
      setPrizeAmount3(prizeAmount3);
      setCulprit(culprit1);
      setIsVisible(isVisible1);
      setUnder18(under181);
      setSuspects(suspects1);
      setAuthor(author1);
      setAmount(amount1);
      setLinkToAuthor(linkToAuthor1);
      setIs1Winner(is1Winner1);
      setContributions(contributions1);
      setCodeName(codeName1);
      setDiscount(discount1);
      setPromotions(promotions1);
      setFullFile(fullFile1);
      setFullImage(fullImage1);
      setIsFile(isFile1);
      setIsImage(isImage1);
      setFileSize(fileSize1);
      setImageSize(imageSize1);
      setData(data1); // You need to define this function or remove this line if not needed
    }
  }, [location.state]);

  const onUserIdChange = (e) => {
    setUserId(e.target.value);
  };

  const onContributorChange = (e) => {
    setIsOwner(e.target.value === "true");
  };

  const handlePercentageChange = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      setPercentage(inputValue);
    }
  };

  const addContributor = () => {
    handleAddContribution();
    closeModal();
  };

  const addPromotions = () => {
    handleAddPromotions();
    closePromotionsModal();
  };

  useEffect(() => {
    setIs1Winner(!is3Winner);
  }, [is3Winner]);

  const [title1Empty, settitle1Empty] = useState(false);
  const [description1Empty, setdescription1Empty] = useState(false);
  const [startDate1Empty, setstartDate1Empty] = useState(false);
  const [endDate1Empty, setendDate1Empty] = useState(false);
  const [prizeAmount1Empty, setprizeAmount1Empty] = useState(false);
  const [prizeAmount1Empty2, setprizeAmount1Empty2] = useState(false);
  const [prizeAmount1Empty3, setprizeAmount1Empty3] = useState(false);
  const [culprit1Empty, setculprit1Empty] = useState(false);
  const [suspects1Empty, setsuspects1Empty] = useState(false);
  const [author1Empty, setauthor1Empty] = useState(false);
  const [amount1Empty, setamount1Empty] = useState(false);
  const [linkToAuthor1Empty, setlinkToAuthor1Empty] = useState(false);
  const [fullFile1Empty, setfullFile1Empty] = useState(false);
  const [fullImage1Empty, setfullImage1Empty] = useState(false);

  const validateInfo = () => {
    let isValid = true;

    if (title === "") {
      settitle1Empty(true);
      isValid = false;
    } else {
      settitle1Empty(false);
    }

    if (description === "") {
      setdescription1Empty(true);
      isValid = false;
    } else {
      setdescription1Empty(false);
    }

    if (startDate === null) {
      setstartDate1Empty(true);
      isValid = false;
    } else {
      setstartDate1Empty(false);
    }

    if (endDate === null) {
      setendDate1Empty(true);
      isValid = false;
    } else {
      setendDate1Empty(false);
    }

    if (prizeAmount === "") {
      setprizeAmount1Empty(true);
      isValid = false;
    } else {
      setprizeAmount1Empty(false);
    }

    if (is3Winner) {
      if (prizeAmount2 === "") {
        setprizeAmount1Empty2(true);
        isValid = false;
      } else {
        setprizeAmount1Empty2(false);
      }

      if (prizeAmount3 === "") {
        setprizeAmount1Empty3(true);
        isValid = false;
      } else {
        setprizeAmount1Empty3(false);
      }
    }

    if (culprit === "") {
      setculprit1Empty(true);
      isValid = false;
    } else {
      setculprit1Empty(false);
    }

    if (suspects === "") {
      setsuspects1Empty(true);
      isValid = false;
    } else {
      setsuspects1Empty(false);
    }

    if (author === "") {
      setauthor1Empty(true);
      isValid = false;
    } else {
      setauthor1Empty(false);
    }

    if (amount === "") {
      setamount1Empty(true);
      isValid = false;
    } else {
      setamount1Empty(false);
    }

    // if (linkToAuthor === "") {
    //   setlinkToAuthor1Empty(true);
    //   isValid = false;
    // } else {
    //   setlinkToAuthor1Empty(false);
    // }

    if (fullFile === "") {
      setfullFile1Empty(true);
      isValid = false;
    } else {
      setfullFile1Empty(false);
    }

    if (fullImage === "") {
      setfullImage1Empty(true);
      isValid = false;
    } else {
      setfullImage1Empty(false);
    }

    return isValid;
  };

  const [codeName, setCodeName] = useState("");
  const [discount, setDiscount] = useState("");
  const handleCodeNameChange = (e) => {
    setCodeName(e.target.value);
  };
  const [discountError, setDiscountError] = useState(false);
  const handleDiscountChange = (event) => {
    const newDiscount = event.target.value;

    if (!isNaN(newDiscount) && newDiscount >= 0 && newDiscount <= 100) {
      setDiscount(newDiscount);
    } else {
    }
  };
  const navigate = useNavigate();
  const allusersManager = new AllUsersManager();
  const [users, setUsers] = useState([]);
  const navigateToPreview = () => {
    if (validateInfo() == true) {
      navigate("/adboard/dashboard/new-competition/previewcomp", {
        state: {
          fullFile,
          title,
          description,
          startDate,
          endDate,
          prizeAmount,
          prizeAmount2,
          prizeAmount3,
          culprit,
          isVisible,
          under18,
          suspects,
          fullImage,
          author,
          amount,
          linkToAuthor,
          is1Winner,
          contributions,
          codeName,
          discount,
          promotions,
          isFile,
          isImage,
          fileSize,
          imageSize,
          data,
        },
      });
      console.log(prizeAmount, prizeAmount2, prizeAmount3);
    } else {
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Missing Values",
          body: "Please enter all the values",
        },
      ]);
    }
  };

  useEffect(() => {
    setShowLoading(true);
    allusersManager.get(true).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setUsers(value.data);
          setShowLoading(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  const handleContDelete = () => {
    contributions.splice(ContIdx, 1);
    closeDeleteCont();
  };

  const handlePromoDelete = () => {
    promotions.splice(PromoIdx, 1);
    closeDeletePromo();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openPromotionsModal = () => {
    setIsPromotionsOpen(true);
  };

  const closePromotionsModal = () => {
    setIsPromotionsOpen(false);
  };

  const openDeleteCont = (index) => {
    setContIdx(index);
    setIsDeleteCont(true);
  };

  const openEditModal = (idx) => {
    setEditContIdx(idx);
    setPercentage(contributions[idx].percentage);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openEditPromotion = (idx) => {
    setEditPromoIdx(idx);
    setCodeName(promotions[idx].codeName);
    setDiscount(promotions[idx].discount);
    setIsEditPromotionOpen(true);
  };

  const closeEditPromotion = () => {
    setIsEditPromotionOpen(false);
  };

  const closeDeleteCont = () => {
    setIsDeleteCont(false);
  };

  const openDeletePromo = (index) => {
    setPromoIdx(index);
    setIsDeletePromo(true);
  };

  const closeDeletePromo = () => {
    setIsDeletePromo(false);
  };

  const openDeleteSus = (idx) => {
    setIdx(idx);
    setIsDeleteSus(true);
  };

  const closeDeletesus = () => {
    setIsDeleteSus(false);
  };
  const openSus = () => {
    setIsSusOpen(true);
  };

  const closeSus = () => {
    setIsSusOpen(false);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleStartChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndChange = (e) => {
    setEndDate(e.target.value);
  };

  // const handlePrizeChange = (e) => {
  //   const inputValue = e.target.value;
  //   if (!isNaN(inputValue)) {
  //     setPrizeAmount(inputValue);
  //   }
  // };

  // const handlePrizeChange2 = (e) => {
  //   const inputValue = e.target.value;
  //   if (!isNaN(inputValue)) {
  //     setPrizeAmount2(inputValue);
  //   }
  // };

  // const handlePrizeChange3 = (e) => {
  //   const inputValue = e.target.value;
  //   if (!isNaN(inputValue)) {
  //     setPrizeAmount3(inputValue);
  //   }
  // };
  const handlePrizeChange = (setter) => (e) => {
    const inputValue = e.target.value;
    // Regular expression to match numbers with up to two decimal places
    const regex = /^\d+(\.\d{0,2})?$/;

    // Check if the input matches the regex
    if (regex.test(inputValue)) {
      setter(inputValue);
    }
  };

  // Usage with different state setters
  const handlePrizeChange1 = handlePrizeChange(setPrizeAmount);
  const handlePrizeChange2 = handlePrizeChange(setPrizeAmount2);
  const handlePrizeChange3 = handlePrizeChange(setPrizeAmount3);

  const handleCulpritChange = (e) => {
    setCulprit(e.target.value);
  };

  const handleVisibleChange = (e) => {
    setIsVisible(!isVisible);
  };

  const handleUnder18Change = (e) => {
    setUnder18(!under18);
  };

  const handleSuspectChange = (e) => {
    setSuspects(e.target.value);
  };

  const handleAuthorChange = (e) => {
    setAuthor(e.target.value);
  };

  // const handleAmountChange = (e) => {
  //   const inputValue = e.target.value;
  //   if (!isNaN(inputValue)) {
  //     setAmount(inputValue);
  //   }
  // };
  const handleAmountChange = (e) => {
    const inputValue = e.target.value;

    // Allow empty input
    if (inputValue === "") {
      setAmount(inputValue);
      return;
    }

    // Regular expression to allow up to two decimal places
    const regex = /^\d*\.?\d{0,2}$/;

    // Check if the input matches the regex
    if (regex.test(inputValue)) {
      setAmount(inputValue);
    }
  };
  const handleAuthorLink = (e) => {
    setLinkToAuthor(e.target.value);
  };

  const handleFileChange = (event) => {
    const maxFileNameLength = 20;
    const file = event.target.files[0];
    setFullFile(file);
    if (file) {
      const fileName = file.name;

      if (fileName.length > maxFileNameLength) {
        const fileExtension = fileName.split(".").pop();
        const truncatedFileName = fileName.substring(
          0,
          maxFileNameLength - fileExtension.length - 1
        );
        const displayedFileName = truncatedFileName + "..." + fileExtension;

        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsFile(displayedFileName);
        setFileSize(fileSizeInMB);
      } else {
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsFile(fileName);
        setFileSize(fileSizeInMB);
      }
    }
  };

  const removeFileChange = (e) => {
    e.stopPropagation();
    setIsFile("");
    setFullFile("");
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const maxFileNameLength = 20;
    const file = event.dataTransfer.files[0];
    setFullFile(file);
    if (file) {
      const fileName = file.name;

      if (fileName.length > maxFileNameLength) {
        const fileExtension = fileName.split(".").pop();
        const truncatedFileName = fileName.substring(
          0,
          maxFileNameLength - fileExtension.length - 1
        );
        const displayedFileName = truncatedFileName + "..." + fileExtension;

        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsFile(displayedFileName);
        setFileSize(fileSizeInMB);
      } else {
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsFile(fileName);
        setFileSize(fileSizeInMB);
      }
    }
  };

  const handleImageChange = (event) => {
    const maxFileNameLength = 20;
    const file = event.target.files[0];
    setFullImage(file);
    if (file) {
      const fileName = file.name;

      if (fileName.length > maxFileNameLength) {
        const fileExtension = fileName.split(".").pop();
        const truncatedFileName = fileName.substring(
          0,
          maxFileNameLength - fileExtension.length - 1
        );
        const displayedFileName = truncatedFileName + "..." + fileExtension;

        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsImage(displayedFileName);
        setImageSize(fileSizeInMB);
      } else {
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsImage(fileName);
        setImageSize(fileSizeInMB);
      }
    }
  };

  const handleImageDragOver = (event) => {
    event.preventDefault();
  };

  const removeImageChange = (e) => {
    e.stopPropagation();
    setIsImage("");
    setFullImage("");
  };

  const handleImageDrop = (event) => {
    event.preventDefault();
    const maxFileNameLength = 20;
    const file = event.dataTransfer.files[0];
    setFullImage(file);
    if (file) {
      const fileName = file.name;

      if (fileName.length > maxFileNameLength) {
        const fileExtension = fileName.split(".").pop();
        const truncatedFileName = fileName.substring(
          0,
          maxFileNameLength - fileExtension.length - 1
        );
        const displayedFileName = truncatedFileName + "..." + fileExtension;

        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsImage(displayedFileName);
        setImageSize(fileSizeInMB);
      } else {
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsImage(fileName);
        setImageSize(fileSizeInMB);
      }
    }
  };

  const updateSuspects = () => {
    if (suspectName.trim() === "") {
      console.error("Invalid suspect name");
      return;
    }

    const newSuspect = {
      name: suspectName,
      culprit: false,
    };

    setSusData((prevSusData) => {
      const updatedSusData = [...prevSusData, newSuspect];
      setData(updatedSusData);
      return updatedSusData;
    });

    setSuspects((prevSuspects) => [...prevSuspects, suspectName]);

    closeSus();
    setSuspectName("");
  };

  const handleCulpritClick = (index) => {
    const newData = data.map((suspect, idx) => {
      if (idx === index) {
        return { ...suspect, culprit: true };
      } else {
        return { ...suspect, culprit: false };
      }
    });
    setData(newData);
  };

  useEffect(() => {
    const newCulprit = data.find((suspect) => suspect.culprit)?.name || "";
    setCulprit(newCulprit);
  }, [data]);

  const deleteSus = () => {
    if (forIdx >= 0 && forIdx <= susData.length) {
      const updatedSusData = susData.filter((_, idx) => idx !== forIdx);
      setSusData(updatedSusData);
      if (culprit && susData[forIdx].culprit) {
        setCulprit("");
      }
      setTimeout(() => {
        setData(updatedSusData);
      }, 10);
      closeDeletesus();
    } else {
      console.error("Invalid index provided for deletion");
    }
  };

  const updateContribution = () => {
    if (userid !== "") {
      contributions[EditContIdx].user_id = userid;
    }

    if (percentage !== "") {
      contributions[EditContIdx].percentage = percentage;
    }

    if (isOwner !== undefined) {
      contributions[EditContIdx].is_owner = isOwner;
    }

    setUserId("");
    setIsOwner(false);
    setPercentage("");

    closeEditModal();
  };

  const updatePromotion = () => {
    if (codeName !== "") {
      promotions[EditPromoIdx].codeName = codeName;
    }

    if (discount !== "") {
      promotions[EditPromoIdx].discount = discount;
    }

    setCodeName("");
    setDiscount("");

    closeEditPromotion();
  };

  return (
    <div className="w-full md:h-screen" style={{ fontFamily: "Inter" }}>
      {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <div className="ml-0 md:ml-10">
        <div className="flex justify-start md:justify-start mt-24 md:mt-16">
          <p className="text-clue-purchase font-bold md:ml-[0px] ml-[20px] text-[24px] md:text-2xl">
            New Competitions
          </p>
        </div>
        <div className="border-b-2 border-filter-heading md:mr-24 mx-10">
          <div>
            <div className="flex justify-center md:justify-start my-8">
              <p className="text-clue-yellow font-bold text-xl">EPUB Book</p>
            </div>
            <div
              className={`flex  ${isFile == ""
                ? fullFile1Empty
                  ? "border-red-500"
                  : "border-filter-heading"
                : fullFile1Empty
                  ? "border-red-500"
                  : "border-clue-purchase"
                } flex-col cursor-pointer justify-center items-center rounded-xl border-2 border-dashed h-40 w-full mb-10 transition-opacity hover:opacity-70`}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={() => fileInputRef.current.click()}
            >
              <div className="flex w-full justify-end">
                {isFile !== "" && (
                  <div
                    className="flex rounded-3xl border-2 bg-clue-ellipse justify-end cursor-pointer mr-3 md:mr-7"
                    onClick={removeFileChange}
                  >
                    <CloseIcon fontSize="small" />
                  </div>
                )}
              </div>
              <input
                ref={fileInputRef}
                type="file"
                accept=".epub"
                className="hidden"
                onChange={handleFileChange}
              />
              {isFile == "" ? (
                <img src={Vector} alt="Vector Image" className="mr-2.5" />
              ) : (
                <AutoStoriesOutlinedIcon
                  className="mr-2.5 text-clue-purchase"
                  fontSize="large"
                />
              )}
              {isFile == "" ? (
                <span className="text-clue-gray mt-5">
                  <span className="underline text-white">Choose a file</span> or
                  drag it here
                </span>
              ) : (
                <div className="flex flex-col">
                  <span className="text-clue-gray mt-2">{isFile}</span>
                  <span className="text-clue-gray mt-2">{fileSize}MB</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="border-b-2 border-filter-heading md:mr-24 mx-10">
          <div>
            <div className="flex justify-center md:justify-start my-8">
              <p className="text-clue-yellow font-bold text-xl">Information</p>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="flex flex-col order-2 md:order-1 md:mr-24 w-full">
                <div className="mb-4">
                  <label
                    className="block text-left mb-2 text-sm text-filter-heading"
                    htmlFor="cardNumber1"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={handleTitleChange}
                    className={`w-full bg-clue-black p-2 rounded-xl ${title1Empty ? "border-red-500" : "outline-none border-0"
                      } focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase text-clue-gray`}
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-left mb-2 text-sm text-filter-heading"
                    htmlFor="desc"
                  >
                    Author's Name
                  </label>
                  <input
                    id="name"
                    value={author}
                    onChange={handleAuthorChange}
                    rows="4"
                    className={`w-full text-clue-gray bg-clue-black p-2 rounded-xl focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase resize-none ${author1Empty ? "border-red-500" : "outline-none border-0"
                      }`}
                  />
                </div>
              </div>
              <div
                className={`order-1 md:order-2 ${isImage == ""
                  ? fullImage1Empty
                    ? "border-red-500"
                    : "border-filter-heading"
                  : fullImage1Empty
                    ? "border-red-500"
                    : "border-clue-purchase"
                  } flex flex-col cursor-pointer justify-center items-center rounded-xl border-2 border-dashed h-40 w-full mb-5 transition-opacity hover:opacity-70`}
                onDragOver={handleImageDragOver}
                onDrop={handleImageDrop}
                onClick={() => imageInputRef.current.click()}
              >
                <div className="flex w-full justify-end">
                  {isImage !== "" && (
                    <div
                      className="flex rounded-3xl border-2 bg-clue-ellipse justify-end cursor-pointer mr-3 md:mr-7"
                      onClick={removeImageChange}
                    >
                      <CloseIcon fontSize="small" />
                    </div>
                  )}
                </div>
                <input
                  ref={imageInputRef}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="hidden"
                  onChange={handleImageChange}
                />
                {isImage == "" ? (
                  <AddPhotoAlternateIcon
                    className="mr-2.5 text-clue-yellow"
                    fontSize="large"
                  />
                ) : (
                  <GradientIcon
                    className="mr-2.5 text-clue-purchase"
                    fontSize="large"
                  />
                )}
                {isImage == "" ? (
                  <span className="text-clue-gray mt-5">
                    <span className="underline text-white">Choose a file</span>{" "}
                    or drag it here
                  </span>
                ) : (
                  <div className="flex flex-col">
                    <span className="text-clue-gray mt-2">{isImage}</span>
                    <span className="text-clue-gray mt-2">{imageSize}MB</span>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-left text-sm mb-2 text-filter-heading"
                htmlFor="link"
              >
                Link to Author’s Site
              </label>
              <input
                type="text"
                id="cardNumber1"
                onChange={handleAuthorLink}
                value={linkToAuthor}
                className={`w-full text-clue-gray bg-clue-black p-2 rounded-xl ${linkToAuthor1Empty
                  ? "border-red-500"
                  : "outline-none border-0"
                  } focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase`}
              />
            </div>
            <div className="mb-10">
              <label
                className="block text-left mb-2 text-sm text-filter-heading"
                htmlFor="desc"
              >
                Description
              </label>
              <RichTextEditor
                setValue={setDescription}
                initalvalue={description}
                config={config}
              />
            </div>
          </div>
        </div>
        <div className="border-b-2 border-filter-heading md:mr-24 mx-10">
          <div>
            <div className="flex justify-center md:justify-start my-8">
              <p className="text-clue-yellow font-bold text-xl">
                Competition Details
              </p>
            </div>
            <div className="w-full flex flex-col lg:flex-row">
              <div className="w-full mb-4 lg:mr-[2%]">
                <label
                  className="block text-left mb-2 text-sm text-filter-heading"
                  htmlFor="cardNumber1"
                >
                  Start Date
                </label>
                <div className="flex">
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleStartChange}
                    id="startDate"
                    className={`w-full text-filter-heading bg-clue-black p-2 rounded-l-xl ${startDate1Empty
                      ? "border-red-500"
                      : "outline-none border-0"
                      } focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase`}
                  />
                </div>
              </div>
              <div className="w-full mb-4 lg:mx-[2%]">
                <label
                  className="block text-left mb-2 text-sm text-filter-heading"
                  htmlFor="cardNumber1"
                >
                  End Date
                </label>
                <div className="flex">
                  <input
                    type="date"
                    value={endDate}
                    onChange={handleEndChange}
                    id="endDate"
                    className={`w-full text-filter-heading bg-clue-black p-2 rounded-l-xl ${endDate1Empty ? "border-red-500" : "outline-none border-0"
                      } focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase`}
                  />
                </div>
              </div>
              <div className="w-full mb-4 lg:ml-[2%]">
                <label
                  className="block text-left mb-2 text-sm text-filter-heading"
                  htmlFor="cardNumber1"
                >
                  Purchase Amount
                </label>
                <input
                  type="text"
                  id="title"
                  value={amount}
                  onChange={handleAmountChange}
                  className={`w-full text-clue-gray bg-clue-black p-2 rounded-xl ${amount1Empty ? "border-red-500" : " outline-none border-0"
                    } focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase`}
                />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row mb-4">
              <div
                className={`${is3Winner == false ? "w-96" : "w-full"
                  } mb-4 lg:mr-[2%]`}
              >
                <label
                  className="block text-left mb-2 text-sm text-filter-heading"
                  htmlFor="cardNumber1"
                >
                  Prize Amount 1
                </label>
                <input
                  type="text"
                  id="title"
                  value={prizeAmount}
                  onChange={handlePrizeChange1}
                  className={`w-full text-clue-gray bg-clue-black p-2 rounded-xl ${prizeAmount1Empty
                    ? "border-red-500"
                    : "outline-none border-0"
                    } focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase`}
                />
              </div>
              {is3Winner && (
                <>
                  <div className="w-full mb-4 lg:mx-[2%]">
                    <label
                      className="block text-left mb-2 text-sm text-filter-heading"
                      htmlFor="cardNumber1"
                    >
                      Prize Amount 2
                    </label>
                    <input
                      type="text"
                      id="title"
                      value={prizeAmount2}
                      onChange={handlePrizeChange2}
                      className={`w-full text-clue-gray bg-clue-black p-2 rounded-xl ${prizeAmount1Empty2
                        ? "border-red-500"
                        : "outline-none border-0"
                        } focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase`}
                    />
                  </div>
                  <div className="w-full mb-4 lg:ml-[2%]">
                    <label
                      className="block text-left mb-2 text-sm text-filter-heading"
                      htmlFor="cardNumber1"
                    >
                      Prize Amount 3
                    </label>
                    <input
                      type="text"
                      id="title"
                      value={prizeAmount3}
                      onChange={handlePrizeChange3}
                      className={`w-full text-clue-gray bg-clue-black p-2 rounded-xl ${prizeAmount1Empty3
                        ? "border-red-500"
                        : "outline-none border-0"
                        } focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase`}
                    />
                  </div>
                </>
              )}
            </div>
            {/* <div className="flex mb-2 mt-5">
              <span className="text-clue-gray font-semibold">
                Include Promo Code
              </span>
              <div className="ml-5 mt-1">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    id="promo"
                    class="sr-only peer"
                    onClick={() => setIsPromo(!isPromo)}
                  />

                  <div class="w-11 h-6  peer-focus:outline-none peer-focus:ring-4  peer-focus:ring-clue-purchase rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-clue-purchase"></div>
                </label>
              </div>
            </div>
            {isPromo ? (
              <div className="md:w-72 mb-4">
                <label
                  className="block text-left mb-1 mt-3 text-sm text-filter-heading"
                  htmlFor="cardNumber1"
                >
                  Code Name
                </label>
                <input
                  type="text"
                  id="codename"
                  value={codeName}
                  onChange={handleCodeNameChange}
                  className="w-full text-clue-gray bg-clue-black p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
                />
                <label
                  className="block text-left mb-1 mt-3 text-sm text-filter-heading"
                  htmlFor="cardNumber1"
                >
                  Discount Percentage
                </label>
                <input
                  type="text"
                  id="discount"
                  value={discount}
                  onChange={handleDiscountChange}
                  className="w-full text-clue-gray bg-clue-black p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
                />
              </div>
            ) : (
              <div></div>
            )} */}
            <div className="flex mt-3 mb-5">
              <span className="text-clue-gray font-semibold">3 Winners</span>
              <div className="ml-5 mt-1">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    id="winners"
                    class="sr-only peer"
                    onClick={() => setIs3Winner(!is3Winner)}
                  />
                  <div class="w-11 h-6  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-clue-purchase  rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-clue-purchase"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-2 border-filter-heading lg:mr-24 mx-10">
          <div>
            <div className="flex flex-col md:flex-row justify-center md:justify-between my-8">
              <span className="text-clue-yellow font-bold text-xl">
                Promotions
              </span>
              <span
                className="text-clue-yellow underline md:mt-0 mt-5 cursor-pointer transition-opacity hover:opacity-70"
                onClick={openPromotionsModal}
              >
                Add Promotions
              </span>
            </div>
            <div className="w-full mb-10">
              {promotions.map((item, index) => (
                <div
                  key={index}
                  className="md:text-base rounded-xl text-sm flex justify-between bg-clue-black h-16 mb-5 w-full py-5 px-2 md:px-5"
                >
                  <span className="text-filter-heading">{item.codeName}</span>
                  <span className="text-filter-heading">{item.discount}%</span>
                  <div className="flex space-x-2 md:space-x-10">
                    <span
                      className="text-filter-heading cursor-pointer"
                      onClick={() => openEditPromotion(index)}
                    >
                      <CreateIcon
                        className="text-clue-purchase"
                        fontSize="small"
                      />
                    </span>
                    <div onClick={() => openDeletePromo(index)}>
                      <span className="text-filter-heading cursor-pointer">
                        <DeleteIcon
                          className="text-clue-logout"
                          fontSize="small"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="border-b-2 border-filter-heading md:mr-24 mx-10">
          <div>
            <div className="flex justify-center md:justify-start my-8">
              <p className="text-clue-yellow font-bold text-xl">Visibility</p>
            </div>
            <div className="flex mb-5">
              <span className="text-clue-gray font-bold">
                Visiblity on Product Page
              </span>
              <div className="ml-10">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isVisible}
                    onChange={handleVisibleChange}
                    id="visibility"
                    class="sr-only peer"
                  />
                  <div class="w-11 h-6  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-clue-purchase  rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-clue-purchase"></div>
                </label>
              </div>
            </div>
            <div className="mb-10">
              <p className={`italic text-sm text-filter-heading text-left `}>
                {isVisible
                  ? "Turn this toggle off if you don’t want the competition to be live yet"
                  : "Turn this toggle on when you are ready to launch the competition."}
              </p>
            </div>
          </div>
        </div>
        <div className="border-b-2 border-filter-heading md:mr-24 mx-10">
          <div>
            <div className="flex justify-center md:justify-start my-8">
              <p className="text-clue-yellow font-bold text-xl">Reader's Age</p>
            </div>
            <div className="flex mb-5">
              <span className="text-clue-gray font-bold">
                Under 18 appropriate
              </span>
              <div className="ml-16">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={under18}
                    onChange={handleUnder18Change}
                    id="under18"
                    class="sr-only peer"
                  />
                  <div class="w-11 h-6  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-clue-purchase  rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-clue-purchase"></div>
                </label>
              </div>
            </div>
            <div className="mb-10">
              <p className={`italic text-sm text-filter-heading text-left `}>
                {under18
                  ? "Turn this toggle off if your book contains content that is not suitable for people under the age of 18."
                  : "Turn this toggle on if your book contains content is suitable for people under the age of 18."}
              </p>
            </div>
          </div>
        </div>
        <div className="border-b-2 border-filter-heading lg:mr-24 mx-10">
          <div>
            <div className="flex flex-col md:flex-row justify-center md:justify-between my-8">
              <span className="text-clue-yellow font-bold text-xl">
                Company & Contributors
              </span>
              <span
                className="text-clue-yellow underline md:mt-0 mt-5 cursor-pointer transition-opacity hover:opacity-70"
                onClick={openModal}
              >
                Add Contributor
              </span>
            </div>
            <div className="w-full mb-10">
              {contributions.map((item, index) => (
                <div
                  key={index}
                  className="md:text-base rounded-xl text-sm flex justify-between bg-clue-black h-16 mb-5 w-full py-5 px-2 md:px-5"
                >
                  <span className="text-filter-heading">
                    {item.user_id ===
                      users.find((user) => user.user_id === item.user_id)?.user_id
                      ? users.find((user) => user.user_id === item.user_id)
                        ?.username
                      : ""}
                  </span>
                  <span className="text-filter-heading">
                    {item.is_owner ? "Company" : "Contributor"}
                  </span>
                  <span className="text-filter-heading">{item.percentage}</span>
                  <div className="flex space-x-2 md:space-x-10">
                    <span
                      className="text-filter-heading cursor-pointer"
                      onClick={() => openEditModal(index)}
                    >
                      <CreateIcon
                        className="text-clue-purchase"
                        fontSize="small"
                      />
                    </span>
                    <div onClick={() => openDeleteCont(index)}>
                      <span className="text-filter-heading cursor-pointer">
                        <DeleteIcon
                          className="text-clue-logout"
                          fontSize="small"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="lg:mr-24 mx-10">
          <div>
            <div className="flex flex-col md:flex-row justify-center md:justify-between my-8">
              <span className="text-clue-yellow font-bold text-xl">
                Suspects
              </span>
              <span
                className="text-clue-yellow underline md:mt-0 mt-5 cursor-pointer transition-opacity hover:opacity-70"
                onClick={openSus}
              >
                Add Suspect
              </span>
            </div>

            <div className="w-full mb-10">
              {data.map((item, idx) => (
                <div
                  key={idx}
                  className="md:text-base text-sm rounded-xl flex justify-between bg-clue-black h-16 mb-5 w-full py-5 px-2 md:px-5"
                >
                  <span className="text-filter-heading">{item.name}</span>
                  <div className="flex space-x-2 md:space-x-10">
                    <div
                      className={`h-7 rounded-lg cursor-pointer ${item.culprit
                        ? "bg-clue-purchase border-2 border-clue-purchase"
                        : "border-2 border-filter-heading"
                        }`}
                      onClick={() => handleCulpritClick(idx)}
                    >
                      <span
                        className={`m-3 ${item.culprit ? "text-white" : "text-filter-heading"
                          }`}
                      >
                        is the culprit
                      </span>
                    </div>
                    {/* <span className="text-filter-heading m-0.5 md:mt-0 cursor-pointer">
                      <CreateIcon
                        className="text-clue-purchase"
                        fontSize="small"
                      />
                    </span> */}
                    <div onClick={() => openDeleteSus(idx)}>
                      <span className="text-filter-heading m-0.5 md:mt-0 cursor-pointer">
                        <DeleteIcon
                          className="text-clue-logout"
                          fontSize="small"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {isDeleteCont && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeDeleteCont}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black rounded-3xl md:w-auto w-80 p-8 px-12 relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                    Delete Contributor
                  </h3>
                  <p className="text-filter-heading text-left">
                    Are you sure you want to delete this contributor from this
                    book?
                  </p>
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeDeleteCont}
                      className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-clue-logout text-white px-4 py-2 rounded"
                      onClick={handleContDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isModalOpen && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeModal}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black p-8 px-12 md:w-auto w-80  rounded-3xl relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                    Add Contributor
                  </h3>
                  <select
                    className="mb-4 w-60 md:w-80 bg-clue-black text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    onChange={onUserIdChange}
                  >
                    <option value="" disabled selected>
                      Username
                    </option>
                    {users.map((user) => (
                      <option key={user.user_id} value={user.user_id}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                  <br />
                  <input
                    type="text"
                    value={percentage}
                    onChange={handlePercentageChange}
                    className="mb-4 w-60 md:w-80 bg-clue-black placeholder:text-clue-gray text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    placeholder="Percentage"
                  />
                  <br />
                  <select
                    className="mb-4 w-60 md:w-80 bg-clue-black text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    onChange={onContributorChange}
                  >
                    <option value="" disabled selected>
                      Select Contributor
                    </option>
                    <option value="false">Contributor</option>
                    <option value="true">Company</option>
                  </select>

                  <br />
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeModal}
                      className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-clue-purchase text-white px-4 py-2 rounded"
                      onClick={addContributor}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isEditModalOpen && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeEditModal}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black p-8 px-12 md:w-auto w-80  rounded-3xl relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                    Update Contributor
                  </h3>
                  <select
                    className="mb-4 w-60 md:w-80 bg-clue-black text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    onChange={onUserIdChange}
                  >
                    <option
                      value={contributions[EditContIdx].user_id}
                      disabled
                      selected
                    >
                      {users.map((user) =>
                        contributions[EditContIdx].user_id == user.user_id
                          ? user.username
                          : ""
                      )}
                    </option>
                    {users.map((user) => (
                      <option key={user.user_id} value={user.user_id}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                  <br />
                  <input
                    type="text"
                    value={percentage}
                    onChange={handlePercentageChange}
                    className="mb-4 w-60 md:w-80 bg-clue-black placeholder:text-clue-gray text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    placeholder="Percentage"
                  />
                  <br />
                  <select
                    className="mb-4 w-60 md:w-80 bg-clue-black text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    onChange={onContributorChange}
                  >
                    <option value="" disabled selected>
                      {contributions[EditContIdx].is_owner
                        ? "Company"
                        : "Contributor"}
                    </option>
                    <option value="false">Contributor</option>
                    <option value="true">Company</option>
                  </select>

                  <br />
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeEditModal}
                      className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-clue-purchase text-white px-4 py-2 rounded"
                      onClick={updateContribution}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isSusOpen && (
              <div
                className="fixed rounded-xl inset-0 flex items-center justify-center z-50"
                onClick={closeSus}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black p-8 px-12 md:w-auto w-80  rounded-3xl relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                    Add Suspect
                  </h3>
                  <input
                    type="text"
                    className="mb-4 w-60 md:w-80 bg-clue-black text-white p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    placeholder="Name"
                    value={suspectName}
                    onChange={(e) => {
                      setSuspectName(e.target.value);
                    }}
                  />
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeSus}
                      className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-clue-purchase text-white px-4 py-2 rounded"
                      onClick={updateSuspects}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isDeleteSus && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeDeletesus}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black rounded-3xl md:w-auto w-80  p-8 px-12 relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                    Delete Suspect
                  </h3>
                  <p className="text-filter-heading text-left">
                    Are you sure you want to delete this suspect from this book?
                  </p>
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeDeletesus}
                      className="text-filter-heading mr-4 border-2 rounded-md border-filter-heading py-2 px-4"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-clue-logout text-white px-4 py-2 rounded"
                      onClick={deleteSus}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isPromotionsOpen && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closePromotionsModal}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black p-8 px-12 md:w-auto w-80  rounded-3xl relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                    Add Promotion
                  </h3>
                  <input
                    type="text"
                    value={codeName}
                    onChange={handleCodeNameChange}
                    className="mb-4 w-60 md:w-80 bg-clue-black placeholder:text-clue-gray text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    placeholder="Promo Code"
                  />
                  <br />
                  <input
                    type="text"
                    value={discount}
                    onChange={handleDiscountChange}
                    className="mb-4 w-60 md:w-80 bg-clue-black placeholder:text-clue-gray text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    placeholder="Percentage"
                  />
                  <br />
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closePromotionsModal}
                      className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-clue-purchase text-white px-4 py-2 rounded"
                      onClick={addPromotions}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isEditPromotionOpen && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeEditPromotion}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black p-8 px-12 md:w-auto w-80  rounded-3xl relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                    Update Promotions
                  </h3>
                  <input
                    type="text"
                    value={codeName}
                    onChange={handleCodeNameChange}
                    className="mb-4 w-60 md:w-80 bg-clue-black placeholder:text-clue-gray text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    placeholder="Promo Code"
                  />
                  <br />
                  <input
                    type="text"
                    value={discount}
                    onChange={handleDiscountChange}
                    className="mb-4 w-60 md:w-80 bg-clue-black placeholder:text-clue-gray text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    placeholder="Percentage"
                  />
                  <br />
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeEditPromotion}
                      className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-clue-purchase text-white px-4 py-2 rounded"
                      onClick={updatePromotion}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isDeletePromo && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeDeletePromo}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black rounded-3xl md:w-auto w-80 p-8 px-12 relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                    Delete Promo Code
                  </h3>
                  <p className="text-filter-heading text-left">
                    Are you sure you want to delete this promo code from this
                    book?
                  </p>
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeDeletePromo}
                      className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-clue-logout text-white px-4 py-2 rounded"
                      onClick={handlePromoDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex justify-center items-center">
          <button
            type="button"
            onClick={navigateToPreview}
            className="mb-24 mx-24 md:w-60 md:mx-96 md:mt-10 inline-block rounded-md bg-clue-purchase px-6 py-2 font-semibold text-white transition-opacity hover:opacity-70"
          >
            Go to Preview
          </button>
        </div>
      </div>
    </div>
  );
}
export default Competitions;
