import React, { useState, useEffect, useRef } from "react";
import myImage from "../../assets/images/cover.png";
import "@fontsource/inter";
import ParamBackButton from "../backbutton/parambackbutton.js";
import MasterCardImage from "../../assets/images/mastercard_icon.png";
import VisaImage from "../../assets/images/visa_icon.png";
import PayPalImage from "../../assets/images/paypal_icon.png";
import Footer from "../../components/footer/footer.js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useLocation, useNavigate } from "react-router-dom";
import GetConfigPayment from "../../models/website/sales/https/getconfigpaymenthttp.js";
import CreatePayment from "../../models/website/sales/https/createpaymenthttp.js";
import CreateSales from "../../models/website/sales/https/createsaleshttp.js";
import Toast from "../toasts/toast.js";
import Candle from "../../assets/images/candle_hero.png";
import Flare from "../../assets/images/flare_hero.png";
import AllCompList from "../../models/website/public/https/comphttp.js";
import CheckPromoValid from "../../models/website/sales/https/checkpromohttp.js";
import Countdown from "react-countdown";

function PaymentModule() {
  useEffect(() => {
    const promoValue = sessionStorage.getItem("promo");
    const promoId = sessionStorage.getItem("promoid");
    if (promoValue) {
      sessionStorage.removeItem("promo");
    }
    if (promoId) {
      sessionStorage.removeItem("promoid");
    }
  }, []);
  let isPromoError = false;
  const [promoCode, setPromoCode] = useState("");
  const [successfulPayment, setSuccessfulPayment] = useState(true);
  const handlePromoCode = (e) => {
    setPromoCode(e.target.value);
  };
  const allcompList = new AllCompList();
  const checkPromoList = new CheckPromoValid();
  const navigate = useNavigate();
  const [parallaxStyles, setParallaxStyles] = useState({
    candle: { transform: "translate(0, 0) scale(0.4)" },
    flare: {
      transform: "scale(0.4) translate(-50%, -50%) translate(0, 0)",
      opacity: 0,
    },
  });
  const [allComps, setAllComps] = useState({ data: [] });
  const [clientIdFetched, setClientIdFetched] = useState(false);
  const [initialOptions, setInitialOptions] = useState({
    "enable-funding": "card",
    "disable-funding": "paylater,venmo",
    "data-sdk-integration-source": "integrationbuilder_sc",
  });

  const animateRef = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);

  const [showLoading, setShowLoading] = useState(false);
  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const parallaxX = (clientX / window.innerWidth - 0.5) * 40 * 0;
    const parallaxY = (clientY / window.innerHeight - 0.5) * 40 * 0;
    const windowHeight = window.innerHeight;
    setParallaxStyles({
      candle: {
        transform: `translate(${-parallaxX}px, ${parallaxY}px) scale(0.4)`,
      },
      flare: {
        transform: `scale(0.4) translate(-50%, -50%) translate(${
          -parallaxX * 0.3
        }px, ${parallaxY * 0.3}px)`,
        opacity: 1,
      },
    });
  };

  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      if (entries[0].isIntersecting) {
        setIsAnimated(true);
        observer.unobserve(animateRef.current);
      }
    };

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (animateRef.current) {
      observer.observe(animateRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  const fadeInAnimation = {
    animationName: "fadeIn",
    animationTimingFunction: "ease-in",
    animationDuration: "1.5s",
    animationIterationCount: 1,
    animationFillMode: "forwards",
    opacity: 0,
  };

  const [paymentType, setPaymentType] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("book_id");

  const [message, setMessage] = useState("");
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [toastMessages, setToastMessages] = useState([]);
  const [promoResponse, setPromoResponse] = useState([]);
  const [clientId, setClientId] = useState("");
  const [orderId, setOrderId] = useState("");
  const getConfig = new GetConfigPayment();
  const createNewPayment = new CreatePayment();
  const createNewSales = new CreateSales();
  const isAuthenticated = localStorage.getItem("websiteToken");

  const handleVerify = () => {
    checkPromoValidity();
  };

  useEffect(() => {
    setShowLoading(true);
    try {
      if (isAuthenticated) {
        getConfig.get(id).then((value) => {
          if (value == null) {
          } else if (!value.error) {
            const baseResponse = value.success;
            if (baseResponse == true) {
              if (value.data.next_action == "over") {
                navigate("/competitions/product/over");
              } else if (value.data.next_action == "owned") {
                navigate("/competitions/product/owned");
              } else {
                setClientId(value.data.paypal_client_id);
                setInitialOptions((prevOptions) => ({
                  ...prevOptions,
                  "client-id": value.data.paypal_client_id,
                }));
                setClientIdFetched(true);
                setShowLoading(false);
              }
            } else {
              setShowLoading(false);
              setToastMessages([
                ...toastMessages,
                {
                  type: "invalid",
                  title: "Error",
                  body: value.message,
                },
              ]);
            }
          } else {
            setShowLoading(false);
            setToastMessages([
              ...toastMessages,
              {
                type: "error",
                title: "Error",
                body: value.error,
              },
            ]);
          }
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Set loading to false whether the request is successful or not
      setShowLoading(false);
    }
  }, []);
  //"AVvwqU1BaisBhyrG7OjE6IuR2X9L_iaHUU3pD9aRktpq3aQTnyhyOEc4yo61AAR6w6E6WroYKgyIJefO"

  // const initialOptions = {
  //   "client-id": clientId,
  //   "enable-funding": "card",
  //   "disable-funding": "paylater,venmo",
  //   "data-sdk-integration-source": "integrationbuilder_sc",
  // };

  let newOrderId = "";
  const handleSignUp = () => {
    // Perform any necessary actions before navigation if needed
    navigate("/account/signup");
  };
  const handleSignIn = () => {
    // Perform any necessary actions before navigation if needed
    const frompayment = true;
    navigate("/account/signin", { state: { frompayment, id } });
  };

  useEffect(() => {
    async function fetchData() {
      if (!id) {
        navigate("/beyond-the-yellow-tape");
        return;
      }
      setShowLoading(true);

      try {
        const allCompsResponse = await allcompList.getbyid(id);

        if (allCompsResponse.success) {
          setAllComps(allCompsResponse);
        } else {
          setShowLoading(true);
          navigate("/beyond-the-yellow-tape");
        }
      } catch (error) {
        navigate("/beyond-the-yellow-tape");
      } finally {
        setShowLoading(false);
      }
    }

    fetchData();
  }, []);

  const convertDateFormat = (startDate) => {
    const parsedDate = new Date(startDate);

    if (isNaN(parsedDate)) {
      console.error("Invalid date format");
      return "";
    }

    // Use toLocaleDateString to format the date as "Nov 05, 2023"
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedStartDate = parsedDate.toLocaleDateString("en-US", options);
    return formattedStartDate;
  };

  const checkPromoValidity = () => {
    setShowLoading(true);
    try {
      if (isAuthenticated) {
        if (!promoCode) {
          setShowLoading(false);
          setIsPromoValid(false);
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: "Promo Code cannot be empty",
            },
          ]);
          return;
        }
        checkPromoList.get(id, promoCode).then((value) => {
          if (value == null) {
            setIsPromoValid(false);
            setToastMessages([
              ...toastMessages,
              {
                type: "invalid",
                title: "Error",
                body: "Promo Code is not valid",
              },
            ]);
            setPromoCode("");
          } else if (!value.error) {
            const baseResponse = value.success;
            if (baseResponse == true) {
              setIsPromoValid(true);
              setPromoResponse(value);
              sessionStorage.setItem("promo", promoCode);
              sessionStorage.setItem("promoid", value.data._id);
              setToastMessages([
                ...toastMessages,
                {
                  type: "success",
                  title: "Success",
                  body: value.message,
                },
              ]);
            } else {
              setShowLoading(false);
              setIsPromoValid(false);
              setToastMessages([
                ...toastMessages,
                {
                  type: "invalid",
                  title: "Error",
                  body: "Promo Code is not valid",
                },
              ]);
              setPromoCode("");
            }
          } else {
            setShowLoading(false);
            setIsPromoValid(false);
            setToastMessages([
              ...toastMessages,
              {
                type: "invalid",
                title: "Error",
                body: "Promo Code is not valid",
              },
            ]);
          }
        });
      }
    } catch (error) {
      setIsPromoValid(false);
      setToastMessages([
        ...toastMessages,
        {
          type: "error",
          title: "Error",
          body: "Error Occured",
        },
      ]);
    } finally {
      // Set loading to false whether the request is successful or not
      setShowLoading(false);
    }
  };
  // const amountSubtracted =
  // promoResponse && promoResponse.amount_subtracted !== undefined
  //   ? promoResponse.amount_subtracted
  //   : 0;
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return "Timer Expired!";
    } else if (days > 0) {
      return days + " days";
    } else {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const FreeBook = () => {
    createNewSales
      .create(id, "N/A", "100% Promo", promoResponse.data._id)
      .then((value) => {
        if (value == null) {
        } else if (!value.error) {
          const baseResponse = value.success;
          if (baseResponse == true) {
            navigate("/account/dashboard", {
              state: {
                successfulPayment,
              },
            });
          } else {
            setToastMessages([
              ...toastMessages,
              {
                type: "invalid",
                title: "Error",
                body: value.message,
              },
            ]);
          }
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "error",
              title: "Error",
              body: value.error,
            },
          ]);
        }
      });
  };

  return (
    <div
      style={{
        fontFamily: "Inter",
      }}
      className="mt-8 flex flex-col min-h-[99vh] text-white"
    >
      {" "}
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="md:w-40 w-3">
            {" "}
            <ParamBackButton />
          </div>
          <div className="lg:ml-[10%] mt-10 ml-0 flex justify-between items-start flex-col lg:flex-row flex-grow">
            {toastMessages.map((toast, index) => (
              <Toast
                className="mb-0"
                key={index}
                toasts={[toast]}
                onClose={() => {
                  const updatedToasts = [...toastMessages];
                  updatedToasts.splice(index, 1);
                  setToastMessages(updatedToasts);
                }}
              />
            ))}
            <div className="flex-1 mt-10 lg:mt-0 lg:max-w-[45vw] p-4 lg:p-6 rounded-md relative order-2 lg:order-1">
              <div className="flex flex-wrap lg:flex-nowrap justify-center lg:justify-between">
                <div className="flex-none mb-4 md:mb-0 w-full lg:w-auto">
                  <img
                    src={allComps.data.cover}
                    alt="Cover"
                    className="w-3/4 lg:w-52 lg:h-72 mx-auto object-cover"
                  />
                </div>
                <div className="px-6 flex-1 text-center lg:text-left mt-4 md:mt-0">
                  <h2 className="text-clue-book-title font-semibold text-2xl mt-8 mb-2">
                    {allComps.data.title}
                  </h2>
                  <p className="text-clue-book-author text-base font-medium mb-10">
                    {allComps.data.author}
                  </p>
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Draw Date
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    <Countdown
                      date={new Date(allComps.data.end_date)}
                      renderer={renderer}
                    />
                  </p>
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Prize Amount
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    $
                    {allComps.data.prize_amount &&
                    Array.isArray(allComps.data.prize_amount) &&
                    allComps.data.prize_amount.length > 0
                      ? `${allComps.data.prize_amount[0].toLocaleString()}${
                          allComps.data.prize_amount.length > 1
                            ? `, $${allComps.data.prize_amount[1].toLocaleString()}`
                            : ""
                        }${
                          allComps.data.prize_amount.length > 2
                            ? `, $${allComps.data.prize_amount[2].toLocaleString()}`
                            : ""
                        }`
                      : "No prize amount available"}
                  </p>
                </div>
              </div>
              <div class="m-4 lg:mt-8 lg:mr-8 max-w-lg block rounded-xl sm:ml-[15%] lg:ml-0 bg-black text-center">
                <div class="flex justify-between mt-8 p-2 px-4">
                  <div>
                    <p class="text-neutral-50">
                      1x {allComps.data.title} by {allComps.data.author}
                    </p>
                  </div>
                  <div>
                    <p class="text-neutral-50">${allComps.data.amount}</p>
                  </div>
                </div>
                <div class="flex justify-between px-4 p-2">
                  <div>
                    <p class="text-neutral-50">Platform Fee</p>
                  </div>
                  <div>
                    <p class="text-neutral-50">$0.00</p>
                  </div>
                </div>
                <div class="flex justify-between p-2 px-4  border-neutral-600">
                  <div>
                    <p class="text-neutral-50">Tax</p>
                  </div>
                  <div>
                    <p class="text-neutral-50">$0.00</p>
                  </div>
                </div>
                {isPromoValid ? (
                  <div className="flex justify-between p-2 px-4  border-neutral-600">
                    <div>
                      <p className="text-neutral-50">Promotion</p>
                    </div>
                    <div>
                      <p className="text-neutral-50">
                        - ${promoResponse.data.amount_subtracted}
                      </p>
                    </div>
                  </div>
                ) : null}
                <div class="flex justify-between border-t-2 p-4">
                  <div>
                    <p class="text-neutral-50">TOTAL</p>
                  </div>
                  <div>
                    <p class="text-neutral-50">
                      {isPromoValid
                        ? `$${promoResponse.data.discounted_amount}`
                        : `$${allComps.data.amount}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`bg-clue-black lg:ml-0 mr-[5%] ml-[5%] w-[90%] overflow-y-auto lg:w-[35vw] flex justify-center items-center rounded-xl mt-4 md:mt-0 order-1 lg:order-2 ${
                isAuthenticated
                  ? " h-[30rem]"
                  : " overflow-x-hidden overflow-y-hidden md:w-[44%] md:h-[32rem] h-[33rem]"
              }`}
            >
              <div className="xl:w-10/12 lg:w-[96%] w-11/12 space-y-4">
                {isAuthenticated && (
                  <div className="px-2 mt-2">
                    <h2 className="text-white text-left text-xl font-semibold mb-4">
                      Payment Details
                    </h2>
                    <div className="flex">
                      <div className="w-full  pr-0 md:pr-3 relative">
                        <label
                          className="block text-left text-clue-book-title"
                          htmlFor="expiryDate"
                        >
                          Promo Code
                        </label>
                        <input
                          type="text"
                          id="expiryDate"
                          autoComplete="promotion"
                          value={promoCode}
                          onChange={handlePromoCode}
                          className={`w-full mb-6 mt-2 bg-clue-black h-[46px] text-white p-2 rounded-xl 
                          border focus:outline-none focus:ring-0 focus:border-clue-purchase`}
                        />
                        <button
                          className="absolute top-0 right-0 mt-10 md:mr-6 mr-3 px-5 md:px-12 py-0.5 bg-clue-purchase text-white rounded-[4px]"
                          onClick={handleVerify}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {!isAuthenticated && (
                  <>
                    <div>
                      <div
                        className="flex h-[330px] md:h-[280px] justify-center md:mt-[-10px] mt-[-15px] relative"
                        onMouseMove={handleMouseMove}
                      >
                        <img
                          src={Candle}
                          className="z-20 h-[58%] md:h-[55%] md:mt-14 mt-10"
                        />
                        <img
                          src={Flare}
                          style={{
                            ...fadeInAnimation,
                          }}
                          className="absolute h-[30%] md:h-[40%]  z-10"
                        />
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <p className="md:mt-[-40px] mt-[-70px]  md:mb-5 mb-0 w-52 md:w-[92%] lg:w-96 text-[17px] md:text-[20px]   text-center  font-[400] text-clue-gray leading-tight text-lg ">
                          You'll have to create a free account to purchase this
                          book
                        </p>
                        <button
                          type="button"
                          className="mb-2  flex items-center justify-center  md:w-[99%] lg:w-[22rem]  md:mt-0 mt-8 mx-10 md:h-[56px] h-[45px] w-[245px] pb-[3px] bg-onclickfield rounded-[10px] md:pb-1 md:pl-1 text-center text-[19px] md:text-[22px] font-[700] leading-normal text-white transition-opacity hover:opacity-70"
                          onClick={handleSignUp}
                        >
                          Create an account
                        </button>

                        <div className="md:mt-7 mt-5 w-52 mb-4 md:w-[95%] lg:w-80  text-[17px] md:text-[20px]   text-center  font-[400] text-clue-gray leading-tight text-lg ">
                          Already have an account?
                          <a
                            className="font-[700] cursor-pointer text-clue-yellow md:pr-[0px] md:pl-[5px] pl-[6px] pr-[1px] md:inline-block  md:text-center underline"
                            onClick={handleSignIn}
                          >
                            Sign In
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {isAuthenticated && (
                  <div className=" h-72 overflow-y-auto px-2">
                    <label
                      className="block text-left mb-5 text-clue-book-title"
                      htmlFor="expiryDate"
                    >
                      Payment Type
                    </label>
                    {promoResponse &&
                    promoResponse.data &&
                    promoResponse.data.discounted_amount !== undefined &&
                    promoResponse.data.discounted_amount === 0 ? (
                      <button
                        className="w-full py-4 bg-clue-purchase text-white font-bold rounded-lg text-xl"
                        onClick={FreeBook}
                      >
                        Get for Free
                      </button>
                    ) : clientIdFetched && showLoading === false ? (
                      <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons
                          style={{
                            shape: "rect",
                            layout: "vertical",
                            color: "silver",
                            tagline: false,
                          }}
                          createOrder={async () => {
                            try {
                              let order_id = "";
                              let promo = sessionStorage.getItem("promo");
                              await createNewPayment
                                .create(id, promo)
                                .then((value) => {
                                  if (value == null) {
                                  } else if (!value.error) {
                                    const baseResponse = value.success;
                                    if (baseResponse == true) {
                                      setOrderId(value.data.paypal_order_id);
                                      order_id = value.data.paypal_order_id;
                                      newOrderId = value.data.paypal_order_id;
                                      setShowLoading(false);
                                    } else {
                                      setToastMessages([
                                        ...toastMessages,
                                        {
                                          type: "invalid",
                                          title: "Error",
                                          body: value.message,
                                        },
                                      ]);
                                    }
                                  } else {
                                    setToastMessages([
                                      ...toastMessages,
                                      {
                                        type: "error",
                                        title: "Error",
                                        body: value.error,
                                      },
                                    ]);
                                  }
                                });

                              if (order_id != "") {
                                return order_id;
                              } else {
                              }
                            } catch (error) {
                              console.error(error);
                              setMessage(
                                `Could not initiate PayPal Checkout...${error}`
                              );
                            }
                          }}
                          onApprove={async (data, actions) => {
                            try {
                              await actions.order.capture();
                              let promoid = sessionStorage.getItem("promoid");
                              createNewSales
                                .create(
                                  id,
                                  newOrderId,
                                  data.paymentSource,
                                  promoid
                                )
                                .then((value) => {
                                  if (value == null) {
                                  } else if (!value.error) {
                                    const baseResponse = value.success;
                                    if (baseResponse == true) {
                                      sessionStorage.removeItem("promo");
                                      sessionStorage.removeItem("promoid");
                                      setShowLoading(false);
                                      navigate("/account/dashboard", {
                                        state: {
                                          successfulPayment,
                                        },
                                      });
                                    } else {
                                      sessionStorage.removeItem("promo");
                                      sessionStorage.removeItem("promoid");
                                      setToastMessages([
                                        ...toastMessages,
                                        {
                                          type: "invalid",
                                          title: "Error",
                                          body: value.message,
                                        },
                                      ]);
                                    }
                                  } else {
                                    sessionStorage.removeItem("promo");
                                    sessionStorage.removeItem("promoid");
                                    setToastMessages([
                                      ...toastMessages,
                                      {
                                        type: "error",
                                        title: "Error",
                                        body: value.error,
                                      },
                                    ]);
                                  }
                                });
                            } catch (error) {
                              console.error(error);
                              setMessage(
                                `Sorry, your transaction could not be processed...${error}`
                              );
                            }
                          }}
                          onCancel={(data) => {
                            setToastMessages([
                              ...toastMessages,
                              {
                                type: "invalid",
                                title: "Payment Cancelled",
                                body: "Payment has been cancelled.",
                              },
                            ]);
                          }}
                          onError={async (data) => {
                            if (isPromoError == true) {
                              setToastMessages([
                                ...toastMessages,
                                {
                                  type: "error",
                                  title: "Error",
                                  body: "Invalid Promo Code.",
                                },
                              ]);
                            } else {
                              setToastMessages([
                                ...toastMessages,
                                {
                                  type: "error",
                                  title: "Error",
                                  body: "An error has occured.",
                                },
                              ]);
                            }
                          }}
                        />
                      </PayPalScriptProvider>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-36">
            <Footer />
          </div>
          <style jsx>
            {`
              @keyframes fadeIn {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            `}
          </style>
        </>
      )}
    </div>
  );
}

export default PaymentModule;
