import React, { useState, useEffect } from "react";
import BackButton from "../backbutton/backbutton";
import DownloadIcon from "@mui/icons-material/Download";
import Footer from "../../components/footer/footer.js";
import { Link } from "react-router-dom";
import ContributionsList from "../../models/website/author dasboard/http/authordashhttp.js";
import Export from "../../models/website/export/webexporthttp/webexporthttp.js";

function AuthorDashboard() {
  const [contributions, setContributions] = useState([]);
  const contributionsList = new ContributionsList();
  const [showLoading, setShowLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  // const book = [
  //   {
  //     title: "Title 1",
  //     percentage: "23%",
  //     totalearnings: "200$",
  //     entrants: "5",
  //   },
  //   {
  //     title: "Title 2",
  //     percentage: "23%",
  //     totalearnings: "200$",
  //     entrants: "5",
  //   },
  //   {
  //     title: "Title 3",
  //     percentage: "23%",
  //     totalearnings: "200$",
  //     entrants: "5",
  //   },
  // ];
  useEffect(() => {
    const fetchContributions = async () => {
      setShowLoading(true);
      try {
        const contributionsData = await contributionsList.get();
        if (contributionsData.success) {
          setContributions(contributionsData.data);
        } else {
          setShowLoading(true);
        }
      } catch (error) {
        console.error("Error fetching contributions:", error);
        // Handle error, show an error message, etc.
      } finally {
        // Set loading to false whether the request is successful or not
        setShowLoading(false);
      }
    };

    fetchContributions();
  }, []);
  function navigateToURL(bookId) {
    window.location.href = `/account/dashboard/author-dashboard/competition?book_id=${bookId}`;
  }

  const handleDownload = async (bookId, index) => {
    setLoadingStates((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });

    try {
      const exportInstance = new Export();
      const response = await exportInstance.ExportContByBookID(bookId);

      if (response?.success) {
        // Assume the response contains the base64 data in a property called 'base64Data'
        const base64Data = response.data.replace(
          /^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/,
          ""
        );

        // Remove whitespaces or line breaks from the base64 data
        const cleanedBase64Data = base64Data.replace(/\s/g, "");

        // Convert base64 to binary
        const binaryData = atob(cleanedBase64Data);

        // Create ArrayBuffer and uint8 array
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create Blob
        const blob = new Blob([uint8Array], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Contributions_List.xlsx";

        // Append link to the document
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      } else {
        // Handle error case
        console.error(response.errorMessage);
      }
    } catch (error) {
      // Handle other errors
      console.error(error.toString());
    } finally {
      // Set loading to false whether the request is successful or not
      setLoadingStates((prevStates) => {
        const newLoadingStates = [...prevStates];
        newLoadingStates[index] = false;
        return newLoadingStates;
      });
    }
  };
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Inter" }}>
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-10 md:w-40 w-3">
            <BackButton />
          </div>
          <div className="flex flex-col md:flex-row md:mx-36 justify-between mt-10">
            <span className="text-clue-gray font-bold text-2xl md:text-3xl mb-4 md:mb-0">
              Your Contributions
            </span>
          </div>
          <div className="overflow-x-auto mb-20 mt-10 mx-10 md:mx-44">
            <table className="table-fixed min-w-full bg-clue-black w-[800px] md:w-[50vw] rounded text-clue-book-author">
              <thead>
                <tr className="border-b border-filter-heading">
                  <th className="p-2 py-5  border-r border-filter-heading">
                    #
                  </th>
                  <th className="p-2 py-5  border-r border-filter-heading">
                    Title
                  </th>
                  <th
                    style={{ wordWrap: "break-word" }}
                    className="p-2 py-5  border-r border-filter-heading"
                  >
                    Percentage
                  </th>
                  <th
                    style={{ wordWrap: "break-word" }}
                    className="p-2 py-5  border-r border-filter-heading"
                  >
                    Total Earnings
                  </th>
                  <th
                    style={{ wordWrap: "break-word" }}
                    className="p-2 border-r border-filter-heading"
                  >
                    Entrants
                  </th>
                  <th className="p-2 border-filter-heading">Sales</th>
                </tr>
              </thead>
              <tbody>
                {contributions.map((contribution, index) => (
                  <tr
                    key={index}
                    className="border-b border-filter-heading hover:bg-black"
                    onClick={() => navigateToURL(contribution.book_id)}
                  >
                    <td className="p-2 py-5 border-r cursor-pointer border-filter-heading">
                      <a className="block w-full h-full">{index + 1}</a>
                    </td>
                    <td className="p-2 py-5  border-r cursor-pointer border-filter-heading">
                      <a
                        style={{ wordWrap: "break-word" }}
                        className=" block w-full h-full overflow-hidden overflow-ellipsis"
                      >
                        {contribution.book_name}
                      </a>
                    </td>
                    <td className="p-2 py-5  border-r cursor-pointer border-filter-heading">
                      <a
                        style={{ wordWrap: "break-word" }}
                        className=" block w-full h-full overflow-hidden overflow-ellipsis"
                      >
                        {contribution.contributor_percentage}%
                      </a>
                    </td>
                    <td className="p-2 py-5  border-r cursor-pointer border-filter-heading">
                      <a
                        style={{ wordWrap: "break-word" }}
                        className=" block w-full h-full overflow-hidden overflow-ellipsis"
                      >
                        $
                        {contribution.contributor_earnings !== undefined &&
                        contribution.contributor_earnings !== null
                          ? contribution.contributor_earnings.toFixed(2)
                          : "N/A"}
                      </a>
                    </td>
                    <td className="p-2 py-5  border-r cursor-pointer border-filter-heading">
                      <a
                        style={{ wordWrap: "break-word" }}
                        className=" block w-full h-full overflow-hidden overflow-ellipsis"
                      >
                        {contribution.entrants}
                      </a>
                    </td>
                    <td
                      onClick={(e) => {
                        e.stopPropagation();

                        handleDownload(contribution.book_id, index);
                        // Assuming contribution has an index property
                        // Add your new function logic here
                      }}
                      className={`p-2 py-5 border-filter-heading cursor-pointer text-clue-yellow ${
                        loadingStates[index] ? "pointer-events-none" : ""
                      }`}
                    >
                      {loadingStates[index] ? (
                        <span className="flex w-full items-center justify-center">
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        </span>
                      ) : (
                        <>
                          <DownloadIcon />{" "}
                          <span className="underline">Export</span>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
                <tr className="border-b-0">
                  <td className="py-10 border-r border-filter-heading"></td>
                  <td className="py-10 border-r border-filter-heading"></td>
                  <td className="py-10 border-r border-filter-heading"></td>
                  <td className="py-10 border-r border-filter-heading"></td>
                  <td className="py-10 border-r border-filter-heading"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-auto">
            <Footer />
          </div>{" "}
        </>
      )}
    </div>
  );
}

export default AuthorDashboard;
