import Toast from "../../components/toasts/toast";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTipOutlined";
import GavelIcon from "@mui/icons-material/GavelOutlined";
import CallIcon from "@mui/icons-material/CallOutlined";
import CookieIcon from "@mui/icons-material/CookieOutlined";

function Miscellaneous() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [toastMessages, setToastMessages] = useState([]);
  const navigateToPrivacyPolicy = () => {
    navigate("/adboard/dashboard/miscellaneous/privacypolicy");
  };
  const navigateToTermsAndConditions = () => {
    navigate("/adboard/dashboard/miscellaneous/termsandconditions");
  };
  const navigateToContactInformation = () => {
    navigate("/adboard/dashboard/miscellaneous/contactinformation");
  };
  const navigateToCookiesPolicy = () => {
    navigate("/adboard/dashboard/miscellaneous/cookiespolicy");
  };
  const navigateToEmailSubs = () => {
    navigate("/adboard/dashboard/miscellaneous/emailsubs");
  };
  return (
    <div className="w-full" style={{ fontFamily: "Inter" }}>
      {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <div className="overflow-x-hidden mb-10">
        <div className="ml-0 md:ml-10 flex justify-start md:justify-start mt-24 md:mt-16">
          <p className="text-clue-purchase font-bold md:ml-[0px] ml-[20px] text-[24px] md:text-2xl">
            Miscellaneous
          </p>
        </div>
        <div className="flex md:flex-row flex-col w-full justify-center mt-20">
          <div
            className="cursor-pointer flex flex-col items-center justify-center md:w-[40%] bg-clue-draw-amount hover:bg-onclickfield md:duration-300 h-36 mx-5 md:mx-0 md:mr-10 rounded-2xl"
            onClick={navigateToEmailSubs}
          >
            <div className="mb-1">
              <MailOutlineIcon className="text-white" fontSize="large" />
            </div>
            <div className="mt-1">
              <span className="text-white font-bold text-lg">
                View Email Subscriber's List
              </span>
            </div>
          </div>
          <div
            className="cursor-pointer flex flex-col items-center justify-center md:w-[40%] bg-clue-draw-amount  hover:bg-onclickfield md:duration-300  h-36  mx-5 md:mx-0 mt-10 md:mt-0 md:ml-10 rounded-2xl"
            onClick={navigateToPrivacyPolicy}
          >
            <div className="mb-1">
              <PrivacyTipIcon className="text-white" fontSize="large" />
            </div>
            <div className="mt-1">
              <span className="text-white font-bold text-lg">
                Update Privacy Policy
              </span>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col w-full justify-center mt-10 md:mt-14">
          <div
            className="cursor-pointer flex flex-col items-center justify-center md:w-[40%] bg-clue-draw-amount hover:bg-onclickfield md:duration-300  h-36 mx-5 md:mx-0 md:mr-10 rounded-2xl"
            onClick={navigateToTermsAndConditions}
          >
            <div className="mb-1">
              <GavelIcon className="text-white" fontSize="large" />
            </div>
            <div className="mt-1">
              <span className="text-white font-bold text-lg">
                Update Terms and Conditions
              </span>
            </div>
          </div>
          <div
            className="cursor-pointer flex flex-col items-center justify-center md:w-[40%] bg-clue-draw-amount hover:bg-onclickfield md:duration-300  h-36  mx-5 md:mx-0 mt-10 md:mt-0 md:ml-10 rounded-2xl"
            onClick={navigateToContactInformation}
          >
            <div className="mb-1">
              <CallIcon className="text-white" fontSize="large" />
            </div>
            <div className="mt-1">
              <span className="text-white font-bold text-lg">
                Update Contact Information
              </span>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col w-full justify-center mt-10 md:mt-14">
          <div
            className="cursor-pointer flex flex-col items-center justify-center md:w-[40%] bg-clue-draw-amount hover:bg-onclickfield md:duration-300  h-36 mx-5 md:mx-0 md:mr-10 rounded-2xl"
            onClick={navigateToCookiesPolicy}
          >
            <div className="mb-1">
              <CookieIcon className="text-white" fontSize="large" />
            </div>
            <div className="mt-1">
              <span className="text-white font-bold text-lg">
                Update Cookies Policy
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Miscellaneous;
