import React, { useEffect, useState } from "react";
import Clue from "../../assets/images/cluebycandlelight.png";
function SplashScreen() {
  const [fadeOut, setFadeOut] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setFadeOut(true);
    }, 1500);
  }, []);

  const fadein = {
    animationName: `slideFadeInUp`,
    animationDuration: `1500ms`,
    animationTimingFunction: `ease-in-out`,
    animationFillMode: `forwards`,
  };

  const fadeout = {
    animationName: `slideFadeOut`,
    animationDuration: `1000ms`,
    animationTimingFunction: `ease-out`,
    animationFillMode: `forwards`,
  };

  return (
    <div className="w-full flex justify-center items-center h-screen">
      <div
        className="w-[60vw] md:w-[30vw]"
        style={fadeOut ? fadeout : fadein}
      >
        <img src="https://dq6y48nvbage3.cloudfront.net/assets/clue-by-candlelight.png" alt="Clue" />
      </div>
      <style>
        {`
        @keyframes slideFadeInUp {
          from {
            transform: translateY(10rem);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }

        @keyframes slideFadeOut {
            from {
              opacity: 1;
            }
            to {
              opacity: 0;
            }
          }
      `}
      </style>
    </div>
  );
}
export default SplashScreen;
