import React, { useState, useRef, useEffect } from "react";
import myImage from "../../assets/images/cover.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "@fontsource/inter";
import { isValidInputTimeValue } from "@testing-library/user-event/dist/utils";

function MainTile({ bookDetails, fromHome, fromComp }) {
  const [showReadMore, setShowReadMore] = useState(false);
  const descRef = useRef(null);
  const location = useLocation();
  const forHome = ["/home"].includes(location.pathname);
  const forCompetitions = ["/competitions"].includes(location.pathname);
  const convertDateFormat = (startDate) => {
    const parsedDate = new Date(startDate);

    if (isNaN(parsedDate)) {
      console.error("Invalid date format");
      return "";
    }

    // Use toLocaleDateString to format the date as "Nov 05, 2023"
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedStartDate = parsedDate.toLocaleDateString("en-US", options);
    return formattedStartDate;
  };
  useEffect(() => {
    const element = descRef.current;
    if (element.scrollHeight > element.clientHeight) {
      setShowReadMore(true);
    } else {
      setShowReadMore(false);
    }
  }, [bookDetails.description]);
  const navigate = useNavigate();
  const toggleDescription = () => {};

  const handlePurchaseButton = (e) => {
    e.stopPropagation();
    // Assuming bookDetails is an object with an 'id' property
    if (bookDetails && bookDetails.id) {
      // Navigate to the desired route with the 'bookid' query parameter

      // Navigate to the desired route with the 'bookid' query parameter and the state
      navigate(`/competitions/product/payment?book_id=${bookDetails.id}`, {
        state: { fromHome: fromHome, fromComp: fromComp },
      });
    } else {
      // Handle the case where bookDetails or its id is not available
      console.error("Invalid bookDetails or missing id.");
    }
  };
  const [isDivHovered, setDivHovered] = useState(false);
  const [isPurchaseButtonHovered, setPurchaseButtonHovered] = useState(false);

  return (
    <div style={{ fontFamily: "Inter" }} className="ml-0 md:ml-30 mt-10">
      <div
        className={`cursor-pointer mt-10 bg-${
          isDivHovered ? "clue-purchase" : "clue-black"
        }  w-[90%] mx-auto p-6 rounded-md relative group hover:bg-${
          isDivHovered ? "clue-purchase" : "clue-black"
        }`}
        onMouseEnter={() => setDivHovered(true)}
        onMouseLeave={() => setDivHovered(false)}
      >
        <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-between">
          <div className="flex-none mb-4 md:mb-0 w-full md:w-auto">
            <img
              src={bookDetails.cover}
              alt="Cover"
              className="h-72 w-52 object-cover mx-auto"
            />
          </div>
          <div className="px-6 flex-1 text-center md:text-left mt-4 md:mt-0">
            <h2 className="text-clue-book-title font-bold text-lg mt-8 mb-2">
              {bookDetails.title}
            </h2>
            <p className="text-clue-book-author mb-10">{bookDetails.author}</p>
            <p
              ref={descRef}
              className="text-clue-book-desc text-sm overflow-hidden line-clamp-3"
              dangerouslySetInnerHTML={{ __html: bookDetails.description }}
            ></p>
            {showReadMore && (
              <p className="text-clue-book-desc text-sm ">
                <Link to="/home/product">
                  <span
                    className="text-clue-yellow hover:opacity-60 transition-transform transform hover:scale-105 group-hover:text-white underline cursor-pointer inline-block"
                    onClick={toggleDescription}
                  >
                    Read more
                  </span>
                </Link>
              </p>
            )}
          </div>
          <div className="flex-none text-white  text-center md:text-right w-full md:w-auto mt-4 md:mt-0">
            <p className="text-clue-booktitle text-2xl mb-5">
              ${bookDetails.amount}
            </p>
            <div className="mt-4 md:mt-14">
              <p className="text-clue-draw-amount mb-2 group-hover:text-clue-hover-draw text-sm">
                Draw Date
              </p>
              <p className="text-clue-book-author mb-2 text-sm">
                {convertDateFormat(bookDetails.end_date)}
              </p>
              <p className="text-clue-draw-amount mb-2 group-hover:text-clue-hover-draw text-sm">
                Prize Amount
              </p>

              <p className="text-clue-booktitle text-2xl mb-2">
                {bookDetails.prize_amount && bookDetails.prize_amount.length > 0
                  ? bookDetails.prize_amount.map((amount, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && ", "}${amount.toLocaleString()}
                      </React.Fragment>
                    ))
                  : "TBD"}
              </p>

              {/* <p className="text-clue-booktitle text-2xl mb-2">
                {bookDetails.prize_amount && bookDetails.prize_amount.length > 0 ? (
                  bookDetails.prize_amount.map((amount, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && ', '}
                      ${parseFloat(amount)}
                    </React.Fragment>
                  ))
                ) : (
                  'TBD'
                )}
              </p> */}

              {!bookDetails.is_purchased ? (
                <button
                  onClick={handlePurchaseButton}
                  onMouseEnter={(e) => {
                    e.stopPropagation();
                    setDivHovered(false); // Set isDivHovered to false when hovering over the purchase button
                  }}
                  onMouseLeave={() => setDivHovered(true)}
                  className={`bg-${
                    isDivHovered ? "black" : "clue-purchase"
                  } font-semibold text-white w-44 px-4 py-2 mt-6 rounded-xl text-center  hover:opacity-80 transition-transform transform hover:scale-110 hover:bg-${
                    isDivHovered ? "black" : "clue-purchase"
                  }`}
                >
                  Purchase
                </button>
              ) : (
                <button
                  onMouseEnter={(e) => {
                    e.stopPropagation();
                    setDivHovered(false); // Set isDivHovered to false when hovering over the purchase button
                  }}
                  onMouseLeave={() => setDivHovered(true)}
                  className="bg-gray-500 font-semibold text-clue-yellow w-44 px-4 py-2 mt-6 rounded-xl text-center cursor-not-allowed hover:opacity-80 transition-transform transform hover:scale-110"
                  // Add any additional styles for the "Purchased" button
                >
                  Purchased
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainTile;
