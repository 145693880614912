import React, { useState} from 'react';
import '@fontsource/inter';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { useNavigate } from "react-router-dom";

function Reset_Success({ isPopupOpen, closePopup }) {
    
    const navigate = useNavigate();

    
  
    const SignIn = () => {
        navigate('/account/signin');
    };
    
    return (
        <div className="flex justify-center items-center ">
            <div
                className="mx-2 md:w-[460px] w-[320px] md:h-[430px] h-[375px] md:mb-[15vh] md:mt-[15vh] mt-8 block rounded-[30px] bg-clue-black '
              ">
                <p
                    className="md:mr-[140px] md:text-[24px] text-[22px] mr-[57px]  md:mt-12 mt-8 md:mb-8 mb-5 font-[700] leading-tight text-clue-yellow">
                    Reset Password
                </p>
                <CelebrationIcon style={{ fontSize: '88px', color: '#B2700D' }} className=" md:mt-3 md:ml-7 mt-5" />
                <p className=" md:mb-7 md:mt-4 mt-5 w-56 md:w-[66%] text-[18px] md:text-[22px] md:ml-[80px]  text-center ml-[45px] font-[400] text-clue-gray leading-[1.30] text-lg ">
                        Your password has been successfully reset
                        
                    </p>
                
                
                

                

              
                <button
                    type="button"
                    className="mb-2 block md:mx-14 md:w-[370px] md:mt-9 mt-6 mx-10 md:h-[56px] h-[50px] w-[245px] bg-onclickfield rounded-[10px] md:pb-1 md:pl-1 text-center text-[20px] md:text-[22px] font-[700] leading-normal text-white transition-opacity hover:opacity-70"
                    onClick={isPopupOpen ? closePopup : SignIn}>
                        {isPopupOpen ? "Close" : "Sign In"}
                    
                </button>

                

            </div></div>

    );
}
export default Reset_Success;