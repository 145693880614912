import React, { useState, useEffect } from "react";
import { ReactReader } from "react-reader";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

function BookPage({ bookUrl, px }) {
  const [location, setLocation] = useState(null);
  const [toc, setToc] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth <= 768) {
        setShowTOC(false);
      } else {
        setShowTOC(true);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const readerStyles = {
    tocBackground: {
      background: "#000",
      color: "#FFF",
    },
    tocArea: {
      float: isMobile ? "none" : "left",
      width: isMobile ? "100%" : "28%",
      height: isMobile ? "60%" : "90vh",
      overflowY: "auto",
      borderRight: isMobile ? "none" : "1px solid #C6C6C6",
      textAlign: "left",
      position: isMobile ? "absolute" : "relative",
      top: isMobile ? px : "",
      zIndex: "40",
      background: "#000",
    },
    tocAreaButton: {
      fontSize: "14px",
      color: "#E3E3E3",
      marginLeft: "20px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
    readerArea: {
      float: isMobile ? "none" : "right",
      width: isMobile ? "92%" : "67%",
      background: "#FFF",
      marginLeft: isMobile ? "10px" : "0",
      marginTop: isMobile ? "20px" : "0",
    },
  };
  const [showTOC, setShowTOC] = useState(isMobile ? false : true);

  return (
    <div className="mt-10 md:mr-10">
      <div className="flex justify-between mr-10 text-left font-semibold ml-5 mb-5 text-xl text-clue-book-author">
        <h2>Contents</h2>
        {isMobile && (
          <button className="z-50" onClick={() => setShowTOC(!showTOC)}>
            {showTOC ? <CloseIcon /> : <MenuIcon />}
          </button>
        )}
      </div>
      <div className="h-[100vh]">
        <ReactReader
          url={bookUrl}
          location={location}
          readerStyles={readerStyles}
          showToc={showTOC}
          onTocChange={(newToc) => setToc(newToc)}
          locationChanged={(newLocation) => {
            setLocation(newLocation);
            if (isMobile) {
              setShowTOC(false);
            }
          }}
          epubOptions={{
            method: "default",
            width: "100%",
            height: "85vh",
            flow: "scrolled-doc",
            spread: "none",
            manager: "continuous",
          }}
        />
      </div>
    </div>
  );
}

export default BookPage;
