import React, { useState } from "react";
import "@fontsource/inter";
import OtpInput from "react-otp-input";
import WestIcon from "@mui/icons-material/West";
import Reset_pw from "./reset_pw"; // Import the Reset_pw component
import Toast from "../../components/toasts/toast";
import ResetPass from "../../models/admin/auth/https/resetpasshttp";
import WebResetPass from "../../models/website/auth/https/webresetpasshttp";

function Verify_Code({ isPopupOpen, closePopup, email, onBack,type }) {
  const [step, setStep] = useState(2);
  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [toastMessages, setToastMessages] = useState([]);
  // const webresetPassInstance = new WebResetPass();
  // const resetManager = new ResetPass();
  const resetInstance = type === "user" ? new WebResetPass() : new ResetPass();

  const handleSendCode = async () => {
    try {
      const value = await resetInstance.reset2(otp, email);
  
      if (!value.error) {
        const baseResponse = value.success;
       
  
        if (value.success === true) {
          setIsOtpValid(true); 
          setStep(3);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "OTP Code",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "OTP Code",
            body: value.error,
          },
        ]);
      }
    } catch (error) {
      console.error("Error in handleSendCode:", error);
      // Handle other error cases if needed
    }
  };

  const handleReSendCode = () => {
    resetInstance.reset1(email).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setToastMessages([
            ...toastMessages,
            {
              type: "success",
              title: "OTP Resend",
              body: "OTP has been resend.",
            },
          ]);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  };

  const back = () => {
    // Call the onBack function to go back to the previous step (Reset_mail)
    onBack();
  };

  return (
    <div>
      {toastMessages.map((toast, index) => (
        <Toast
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <div className="flex  justify-center items-center ">
        {step === 2 && ( // Render this part only if step is 2
          <div className="mx-2 md:w-[460px] w-[320px] md:h-[450px] h-[395px] md:mt-[15vh] md:mb-[15vh] mt-8 block rounded-[30px] bg-clue-black">
            <p className="md:mr-[32%] md:text-[24px] text-[22px] mr-[11%]  md:mt-12 mt-8 md:mb-8 mb-5 font-[700] leading-tight text-clue-yellow">
              <WestIcon
                className="text-clue-yellow mr-3 mb-1 cursor-pointer transition-opacity hover:opacity-70"
                onClick={back}
              />
              Reset Password
            </p>
            <div>
              <p className="md:mb-9 md:mt-6 mt-7 w-[247px] md:w-96 text-[16px] md:text-[20px] md:ml-[55px] text-left ml-[47px] font-[400] text-field-clue-gray leading-[1.2] md:leading-[1.2]">
                A verification code has been sent 
                <span>{" "}
                  to <span className="text-clue-yellow"> {email}</span>
                </span>
              </p>
              <div
                className={"relative md:mb-7 md:mt-0 mb-5 mt-8 ml-12 md:ml-20"}
              >
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderInput={(props, index) => (
                    <input
                      {...props}
                      className="md:mr-5 focus:ring-0 w-12 h-12 mr-3 md:h-14 md:w-16 border border-field-clue-gray focus:outline-none focus:border-onclickfield rounded-[10px] text-center text-field-clue-gray text-xl md:text-xl"
                      style={{
                        background: "transparent",
                        fontSize: "1.5rem",
                      }}
                    />
                  )}
                />
              </div>
              <button
                type="button"
                className="mb-2 block md:mx-14 md:w-[370px] md:mt-9 mt-8 mx-10 md:h-[56px] h-[50px] w-[245px] bg-onclickfield rounded-[10px] md:pb-1 md:pl-1 text-center text-[19px] md:text-[22px] font-[700] leading-normal text-white transition-opacity hover:opacity-70"
                onClick={handleSendCode}
              >
                Verify Code
              </button>
            </div>
            {step === 2 && !isOtpValid && (
              <div className="md:mt-7 mt-5 w-52 md:w-80 text-[17px] md:text-[20px] md:ml-[80px] text-center ml-[60px] font-[400] text-clue-gray leading-tight">
                Did not receive a code?
                <a
                  href="#"
                  className="font-[700] text-clue-yellow pl-2 md:text-center underline transition-opacity hover:opacity-70"
                  onClick={handleReSendCode}
                >
                  Resend
                </a>
              </div>
            )}
          </div>
        )}
        {step === 3 && isOtpValid && (
          <Reset_pw isPopupOpen={isPopupOpen} email={email} type={type} closePopup={closePopup} />
        )}
      </div>
    </div>
  );
}

export default Verify_Code;
