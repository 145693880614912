import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import myImage from "../../assets/images/clue_sidebar.png";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ArchiveIcon from "@mui/icons-material/Archive";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import "@fontsource/inter";
import { useNavigate } from "react-router-dom";
import SignoutManager from "../../models/admin/auth/https/signouthttp";

function Sidebar({ onPageChange }) {
  const [open, setOpen] = useState(window.innerWidth >= 768);
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSignout, setIsSignout] = useState(false);
  const signoutManager = new SignoutManager();
  const navigate = useNavigate();

  const closeSignOut = () => {
    
   
    setIsSignout(false);
  };
  const openSignOut = () => {
    setIsSignout(true);
  
  };

  const closeSidebar = () => {
    if (window.innerWidth >= 768) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleSignout=()=>{
    signoutManager.signout().then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          navigate('/adboard/signin');
        } else {
          console.error("Invalid token", baseResponse);
        }
      } else {
        console.error("Error:", value.error);
      }
    });
  }
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (open && windowWidth < 768) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [open, windowWidth]);

  const handleOutsideClick = (e) => {
    if (e.target.className.includes("overlay") && windowWidth < 768) {
      setOpen(false);
    }
  };

  const Menus1 = [
    {
      title: "Bookshelf",
      icon: <EmojiEventsIcon />,
      link: "/adboard/dashboard/collection",
    },
    {
      title: "Archive",
      icon: <ArchiveIcon />,
      link: "/adboard/dashboard/archive",
    },
    {
      title: "Users",
      icon: <PeopleAltIcon />,
      link: "/adboard/dashboard/users",
    },
    {
      title: "New Competition",
      icon: <AddBoxSharpIcon />,
      link: "/adboard/dashboard/new-competition",
    },
    {
      title: "Miscellaneous",
      icon: <ListAltIcon/>,
      link: "/adboard/dashboard/miscellaneous",
    }
  ];

  return (
    <>
      {windowWidth < 768 && (
        <div
          className={`fixed inset-0 z-10 transition-opacity ${
            open ? "block" : "hidden"
          } overlay`}
          onClick={handleOutsideClick}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        ></div>
      )}
      <div
        className="flex md:bg-clue-black w-0 md:w-72 md:overflow-y-auto md:overflow-x-hidden"
        style={{ fontFamily: "Inter", maxHeight: "100vh" }}
      >
        <div
          className={`bg-clue-black h-screen p-5 pt-8 w-72 transition-transform duration-300 flex-shrink-0 z-10
  ${open ? "translate-x-0" : "-translate-x-full"}`}
          style={{ maxHeight: "100vh" }}
        >
          <div className="md:hidden">
            {open ? (
              <CloseIcon
                className={`text-white absolute right-8 top-9 ${
                  !open && "hidden"
                }`}
                onClick={() => setOpen(!open)}
              />
            ) : (
              <MenuIcon
                className={`text-white absolute -right-8 top-9 ${
                  !open && "hidden"
                }`}
                onClick={() => setOpen(!open)}
              />
            )}
          </div>
          <div className={`mx-auto w-40 m-6 ${!open && "hidden duration-300"}`}>
            <img src="https://dq6y48nvbage3.cloudfront.net/assets/clue-sidebar.png" />
          </div>
          <ul className={`pt-2 ${!open && "hidden duration-300"}`}>
            {Menus1.map((menu, index) => (
              <NavLink
                key={index}
                to={menu.link}
                onClick={closeSidebar}
                className={`text-clue-gray text-md flex items-center gap-x-4 cursor-pointer p-2 rounded-md my-2 ${
                  location.pathname === menu.link
                    ? "bg-sidebar-hover-color text-white"
                    : ""
                } hover:bg-sidebar-hover-color hover:text-white`}
              >
                {menu.icon}
                {menu.title}
              </NavLink>
            ))}
            <hr
              className="bg-sidebar-hover-color my-5"
              style={{ borderColor: "#474747", width: "100%" }}
            />
            <NavLink
              onClick={closeSidebar}
              to="/adboard/dashboard/accounts"
              className={`text-clue-gray text-md flex items-center gap-x-4 cursor-pointer p-2 rounded-md my-2 ${
                location.pathname === "/adboard/dashboard/accounts"
                  ? "bg-sidebar-hover-color text-white"
                  : ""
              } hover:bg-sidebar-hover-color hover:text-white`}
            >
              <AccountCircleSharpIcon />
              Account
            </NavLink>
            <div
              onClick={openSignOut}
              className={`text-md flex items-center gap-x-4 cursor-pointer p-2 rounded-md my-2 text-signout-color hover:bg-sidebar-hover-color hover:text-white`}
            >
              <LogoutSharpIcon />
              Sign Out
            </div>
          </ul>
        </div>
        {isSignout && (
          <div
            className="fixed inset-0 flex items-center justify-center z-50"
            onClick={closeSignOut}
          >
            <div className="bg-black opacity-50 absolute inset-0"></div>
            <div
              className="bg-clue-black rounded-3xl md:w-auto w-80  p-8 px-12 relative z-10"
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="text-clue-book-author md:w-auto w-60 text-left mb-4">
                Confirm
              </h3>
              <p className="text-filter-heading md:w-auto w-60 text-left">
                Are you sure you want to sign out of your account?
              </p>
              <div className="flex justify-end mt-6">
                <button
                  onClick={closeSignOut}
                  className="text-filter-heading transition-opacity hover:opacity-70 mr-4 border-2 rounded-[9px] border-filter-heading py-0.5 px-6"
                >
                  Cancel
                </button>
                <button className="bg-clue-logout transition-opacity hover:opacity-70 text-white px-7 rounded-[9px] py-0.5 "
                onClick={handleSignout}>
                
                Sign Out
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Sidebar;
