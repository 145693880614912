import React from "react";
import myImage from "../../assets/images/cover.png";
import "@fontsource/inter";
import Countdown from "react-countdown";

function truncateTitle(title) {
  const words = title.split(" ");
  if (words.length > 2) {
    return words.slice(0, 3).join(" ") + "...";
  }
  return title;
}

function truncateAuthor(author, maxLength) {
  if (author.length > maxLength) {
    return author.substring(0, maxLength) + "...";
  }
  return author;
}

const convertDateFormat = (startDate) => {
  const parsedDate = new Date(startDate);

  if (isNaN(parsedDate)) {
    console.error("Invalid date format");
    return "";
  }

  // Use toLocaleDateString to format the date as "Nov 05, 2023"
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedStartDate = parsedDate.toLocaleDateString("en-US", options);
  return formattedStartDate;
};

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return "Timer Expired!";
  } else if (days > 0) {
    return days + " days";
  } else {
    return (
      <span>
        {hours}:{minutes}
      </span>
    );
  }
};

function AdminTile({ book }) {
  return (
    <div style={{ fontFamily: "Inter" }}>
      <div className="group cursor-pointer bg-clue-black h-auto w-[85%] mx-auto p-6 rounded-md relative md:hover:bg-clue-purchase md:duration-300">
        <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-between">
          <div className="flex-none mb-4 md:mb-0 w-full h-full md:w-[45%]">
            <img
              src={book.cover}
              alt="Cover"
              className="h-72 w-60 object-cover mx-auto"
            />
          </div>
          <div className="md:pl-6 flex-1 text-center md:text-left mt-4 md:mt-0">
            <h2 className="text-clue-book-title font-semibold text-sm md:text-lg mt-4 mb-2">
              {truncateTitle(book.title)}
            </h2>

            <p className="text-clue-book-author text-base font-medium mb-16">
              {truncateAuthor(book.author, 20)}
            </p>
            <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
              Prize Amount
            </p>
            {console.log(console.log(book.prize_amount))}
            <p className="text-clue-book-author font-bold mb-2 text-base">
              $
              {`${parseFloat(book.prize_amount[0]).toLocaleString()}${
                book.prize_amount.length > 1
                  ? `, $${parseFloat(book.prize_amount[1]).toLocaleString()}`
                  : ""
              }${
                book.prize_amount.length > 2
                  ? `, $${parseFloat(book.prize_amount[2]).toLocaleString()}`
                  : ""
              }`}
            </p>

            <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
              Time Left
            </p>
            <p className="text-clue-book-author font-bold mb-2 text-base">
              {/* {convertDateFormat(book.end_date)} */}
              <Countdown date={new Date(book.end_date)} renderer={renderer} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminTile;
