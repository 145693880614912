import React, { useState, useRef, useEffect } from "react";
import myImage from "../../assets/images/cover.png";
import "@fontsource/inter";

function SubTile({ bookDetails }) {
  const [showReadMore, setShowReadMore] = useState(false);
  const descRef = useRef(null);

  const toggleDescription = () => {};
  const handleVisitAuthorClick = (e) => {
    e.stopPropagation();
    if (bookDetails && bookDetails.link_to_author) {
      // Navigate to the author details page
      window.location.href = bookDetails.link_to_author;
    }
  };
  useEffect(() => {
    const element = descRef.current;
    if (element.scrollHeight > element.clientHeight) {
      setShowReadMore(true);
    } else {
      setShowReadMore(false);
    }
  }, [bookDetails.description]);
  const [isDivHovered, setDivHovered] = useState(false);
  return (
    <div style={{ fontFamily: "Inter" }} className="ml-0 md:ml-30 mt-10">
      <div
        className={` cursor-pointer mt-10 ${
          isDivHovered ? "bg-clue-purchase" : "bg-clue-subtile"
        }  w-[90%] mx-auto p-6 rounded-md relative group hover:bg-${
          isDivHovered ? "clue-purchase" : "clue-black"
        }`}
        onMouseEnter={() => setDivHovered(true)}
        onMouseLeave={() => setDivHovered(false)}
      >
        <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-between">
          <div className="flex-none mb-4 md:mb-0 w-full md:w-auto">
            <img
              src={bookDetails.cover}
              alt="Cover"
              className="h-72 w-52 object-cover mx-auto"
            />
          </div>
          <div className="px-6 flex-1 text-center md:text-left mt-4 md:mt-0">
            <h2 className="text-clue-book-title font-bold text-lg mt-8 mb-2">
              {bookDetails.title}
            </h2>
            <p className="text-clue-book-author mb-10">{bookDetails.author}</p>
            <p
              ref={descRef}
              className="text-clue-book-desc text-sm overflow-hidden line-clamp-3"
              dangerouslySetInnerHTML={{ __html: bookDetails.description }}
            ></p>
            {showReadMore && (
              <p className="text-clue-book-desc text-sm ">
                <span
                  className="text-clue-yellow group-hover:text-white underline cursor-pointer inline-block"
                  onClick={toggleDescription}
                >
                  Read More
                </span>
              </p>
            )}
          </div>
          <div className="flex-none text-center md:text-right w-full md:w-auto mt-4 md:mt-0">
            <p className="text-clue-purchase group-hover:text-clue-book-author font-bold text-xl mb-5">
              Competition Ended
            </p>
            <p className="text-clue-purchase group-hover:text-black font-bold text-xl mb-5">
              {bookDetails.competition}
            </p>
            <div className="mt-4 md:mt-14">
              <p className="text-clue-draw-amount mb-2 group-hover:text-clue-hover-draw text-sm">
                Winners
              </p>
              {bookDetails.winners && bookDetails.winners.length > 0 ? (
                <p className="text-clue-book-author mb-2 text-sm">
                  {bookDetails.winners.map((winner, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && ", "}
                      {winner}
                    </React.Fragment>
                  ))}
                </p>
              ) : (
                <p className="text-clue-book-author mb-2 text-sm">Pending</p>
              )}
              <p className="text-clue-draw-amount mb-2 group-hover:text-clue-hover-draw text-sm">
                Prize Amount
              </p>
              <p className="text-clue-booktitle text-2xl mb-2 ">
                {bookDetails.prize_amount && bookDetails.prize_amount.length > 0
                  ? bookDetails.prize_amount.map((amount, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && ", "}${amount.toLocaleString()}
                      </React.Fragment>
                    ))
                  : "TBD"}
              </p>

              {/* {bookDetails.prize_amount && bookDetails.prize_amount.length > 0 ? (
                  bookDetails.prize_amount.map((amount, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && ', '}
                      ${parseFloat(amount)}
                    </React.Fragment>
                  ))
                ) : (
                  'TBD'
                )} */}

              {bookDetails.link_to_author && (
                <button
                  onMouseEnter={(e) => {
                    e.stopPropagation();
                    setDivHovered(false); // Set isDivHovered to false when hovering over the purchase button
                  }}
                  onMouseLeave={() => setDivHovered(true)}
                  className={`border-[2px] ${
                    isDivHovered ? "border-pure-black" : "border-clue-purchase"
                  } text-white font-semibold w-44 px-4 py-2 mt-6 rounded-xl text-center hover:opacity-80 transition-transform transform hover:scale-110`}
                  onClick={handleVisitAuthorClick}
                >
                  Visit The Author
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubTile;
