import React from 'react';
import Error_detective from '../../assets/images/detective.png';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';
import WestIcon from "@mui/icons-material/West";
import { Link,useNavigate,useLocation } from "react-router-dom";

function Case_Solved() {
  const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id")
    const navigate = useNavigate();
  return (
    <div className="h-screen bg-black text-white flex flex-col justify-between">

      <div className="flex items-center justify-center mt-14 lg:mt-10 xl:mt-10">
        <img
          src="https://dq6y48nvbage3.cloudfront.net/assets/detective.png"
          alt="Error Detective"
          className="w-40 h-40 md:w-60 md:h-60"
        />
      </div>

      <p className="mt-4 md:mt-[20px] mx-12 text-xl md:text-2xl text-center font-semibold text-clue-yellow leading-7">
      This case has already been solved
      </p>

      <div className="mt-4  xl:mx-[35%] md:mx-[20%] md:mt-6 mx-[15%] md:mb-8 mb-6 text-base md:text-xl text-center font-normal text-clue-gray leading-7">
        <p className='w-full'>
        This case has already been solved and the culprit was turned in at 12/02/2023. Sorry detective you got late on this one
        </p>
      </div>
      <div className="md:ml-0 md:mt-7 lg:mt-3 xl:mt-2 mt-3 text-center">
                  <Link to="/competitions">
                    <button className="bg-clue-purchase text-white font-[700] md:px-20 px-4 py-3 rounded-[10px] text-[16px] md:text-lg transition-opacity hover:opacity-70"  >Explore more competitions</button></Link>
                </div>
                
                <div className="md:ml-0 md:mt-7 lg:mt-10 mb-16 md:mb-[-35%] lg:mb-8 xl:mb-8= xl:mt-4 mt-8 text-center">
         
                    <button className="bg-transparent border-solid border-filter-heading border text-filter-heading  md:px-20 px-4 py-3 rounded-[10px] text-[16px] md:text-lg transition-opacity hover:opacity-70" 
                    onClick={() => navigate(`/account/dashboard/book?book_id=${id}`)} >
                      <WestIcon className="mr-3" />Back To Book</button>
           </div>
      <div className='lg:mt-[29px] md:mt-[55%] xl:mt-[29px] md:bottom-0'> <Footer /></div>

    </div>
  );
}

export default Case_Solved;
