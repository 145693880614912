import React, { useState, useEffect } from 'react';
import Sign_In from "./sign_in";
import Footer from "../footer/footer";
import { useLocation,useNavigate } from 'react-router-dom';
import Toast from '../toasts/toast';
function SignInPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [toastMessages, setToastMessages] = useState([]);
  const showtoast = location?.state?.showtoast || false;

  useEffect(() => {
   
    // Show the toast when the component mounts
    if (showtoast) {
      setToastMessages([
        ...toastMessages,
        {
          type: "success",
          title: "Success",
          body: "Account Created",
        },
      ]);
    }
  }, [showtoast]);

  let isAdmin = false;

  return (

    <div className="overflow-y-hidden">
      {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <Sign_In isAdmin={isAdmin} />
      <div className='md:mt-[0%] mt-[20%]  '>
        <Footer />
      </div></div>
  );
}
export default SignInPage;