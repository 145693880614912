import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ParamBackButton from "../backbutton/parambackbutton.js";
import Toast from "../toasts/toast.js";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import EntriesIdManager from "../../models/admin/entries/https/listentriesidhttp.js";
import ChangeWinner from "../../models/admin/winners/https/updatewinnerhttp.js";
import RandomWinnerManager from "../../models/admin/winners/https/randomwinnerhttp.js";
import { set } from "date-fns";
import { setId } from "@material-tailwind/react/components/Tabs/TabsContext.js";
import CulpritUpdateCompManager from "../../models/admin/competitions/https/culpritupdatehttp.js";
import DeleteWinner from "../../models/admin/winners/https/deletewinnerhttp.js";
import Export from "../../models/admin/export/http/exporthttp.js";
import ContributionsList from "../../models/website/author dasboard/http/authordashhttp.js";

function ViewContEntries() {
  const [searchTerm, setSearchTerm] = useState("");
  const [toastMessages, setToastMessages] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [entry, setEntry] = useState([]);
  const [isWinner, setIsWinner] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const forId = searchParams.get("book_id");
  const salesManager = new ContributionsList();

  useEffect(() => {
    setShowLoading(true);
    salesManager.getcontbyid(forId).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          if (value.data.winners.length > 0) {
            setIsWinner(true);
          }
          setEntry(value.data.entries);
          setShowLoading(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, [isWinner]);

  const convertDateFormat = (date) => {
    const parsedDate = new Date(date);

    if (isNaN(parsedDate)) {
      console.error("Invalid date format");
      return "";
    }

    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedStartDate = parsedDate.toLocaleDateString("en-US", options);
    return formattedStartDate;
  };

  // const [showSuccessToast, setShowSuccessToast] = useState(false);
  // const successToast = [
  //     {
  //         type: "success",
  //         title: "Selected",
  //         body: "Winner has been selected",
  //     },
  // ];

  const filteredBookData = entry.filter((book) => {
    // Filter by search term in username
    if (
      searchTerm &&
      !book.username.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  const handleDownload = async () => {
    try {
      const exportInstance = new Export();
      const response = await exportInstance.ExportWebsiteListEntryByID(forId);

      if (response?.success) {
        // Assume the response contains the base64 data in a property called 'base64Data'
        const base64Data = response.data.replace(
          /^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/,
          ""
        );

        // Remove whitespaces or line breaks from the base64 data
        const cleanedBase64Data = base64Data.replace(/\s/g, "");

        // Convert base64 to binary
        const binaryData = atob(cleanedBase64Data);

        // Create ArrayBuffer and uint8 array
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create Blob
        const blob = new Blob([uint8Array], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Entries_list.xlsx";

        // Append link to the document
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      } else {
        // Handle error case
        console.error(response.errorMessage);
      }
    } catch (error) {
      // Handle other errors
      console.error(error.toString());
    }
  };
  
  return (
    <div
      className="w-full  xl:overflow-y-hidden"
      style={{ fontFamily: "Inter" }}
    >
      <div className=" md:mt-14 mt-14">
        <ParamBackButton />
      </div>
      <div className="ml-0 md:ml-16 xl:ml-48">
        <div class="flex md:flex-row flex-col md:items-center justify-between mt-5 md:mt-10">
          <div className="flex">
            <p class="text-clue-purchase font-bold md:ml-0 ml-10 text-[24px] md:text-[28px] text-left">
              Entries For Title
            </p>
          </div>
          <div className="flex md:flex-row flex-col md:items-end md:mr-0 mr-10 md:ml-0 ml-10 md:mt-0 mt-10">
            <a
              onClick={handleDownload}
              class="md:text-[16px] cursor-pointer text-clue-yellow lg:mr-16 md:mr-8 xl:mr-48 md:pl-12 underline transition-opacity hover:opacity-70"
            >
              Export Entries
            </a>
          </div>
        </div>

        <div className="md:mt-14 mt-10 md:w-[90%] mx-9 md:mx-0 flex flex-col items-start relative">
          <div className="flex w-full md:w-[65%] items-center">
            <div className="flex-grow flex items-center bg-clue-black rounded-xl p-2">
              <SearchIcon className="text-clue-yellow m-2" />
              <input
                type="text"
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search Users"
                className="w-full bg-clue-black text-white placeholder-white border-none outline-none focus:ring-0"
              />
            </div>
          </div>
        </div>
      </div>
      {toastMessages.map((toast, index) => (
        <Toast
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-20 left-0">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="md:overflow-x-auto xl:overflow-x-hidden overflow-x-auto  mt-10 mx-10 md:ml-16 md:mx-10 lg:mr-16 xl:mx-48 lg:w-auto md:w-auto  xl:w-auto mb-10">
          <table className="table-fixed min-w-full bg-clue-black w-[800px] md:w-[50vw] md:mx-0   rounded text-clue-book-author">
            <thead>
              <tr className="border-b border-filter-heading">
                <th className="p-0 py-5  border-r border-filter-heading w-20">#</th>
                <th className="p-2 py-5  border-r border-filter-heading">
                  Bought On
                </th>
                <th className="p-2 py-5 border-r border-filter-heading">
                  Submitted On
                </th>
                <th className="p-2 py-5 border-filter-heading">
                  Guess
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredBookData.map((book, index) => (
                <tr
                  key={index}
                  className="border-b border-filter-heading hover:bg-black"
                >
                  <td className="p-0 py-5 border-r border-filter-heading">
                    {index + 1}
                  </td>

                  <td className="p-2 py-5  border-r border-filter-heading">
                    {convertDateFormat(book.bought_on)}
                  </td>
                  <td className="p-2 py-5  border-r border-filter-heading">
                    {convertDateFormat(book.submitted_on)}
                  </td>

                  <td className={`p-2 py-5 border-filter-heading`}>
                    {book.is_culprit_match == true ? (
                      <span className="font-[500px] text-[16px] text-clue-restore">
                        Correct
                      </span>
                    ) : (
                      <span className="font-[500px] text-[16px] text-clue-logout">
                        Wrong
                      </span>
                    )}
                  </td>
                </tr>
              ))}
              <tr className="border-b-0 ">
                <td className="md:py-24 py-10 border-r border-filter-heading"></td>
                <td className="md:py-24 py-10 border-r border-filter-heading"></td>
                <td className="md:py-24 py-10 border-r border-filter-heading"></td>
                <td className="md:py-24 py-10 border-filter-heading"></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div className="md:mt-0 mt-40"></div>
    </div>
  );
}
export default ViewContEntries;
