import BackButton from "./../../components/backbutton/backbutton";
import React, { useState, useRef, useEffect } from "react";
import AllUsersManager from "../../models/admin/users/https/getallusershttp";
import { useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Toast from "../../components/toasts/toast";
import RestoreIcon from "@mui/icons-material/Restore";
import UnsubscribeOutlinedIcon from '@mui/icons-material/UnsubscribeOutlined';
import EmailManager from "../../models/admin/misc/http/emailsubshttp.js";

function UserDetails() {
    const location = useLocation();
    const [toastMessages, setToastMessages] = useState([]);
    const searchParams = new URLSearchParams(location.search);
    const forId = searchParams.get("userId");
    const [showLoading, setShowLoading] = useState(false);
  const emailManager = new EmailManager();

    // const competition = [
    //     {
    //         title: "Competition 1",
    //         total_amount: "$ 42.00",
    //         bought_on: "12/01/2023",
    //         method: "Paypal",
    //     },
    //     {
    //         title: "Competition 2",
    //         total_amount: "$ 42.00",
    //         bought_on: "12/01/2023",
    //         method: "Card",
    //     },
    //     {
    //         title: "Competition 3",
    //         total_amount: "$ 42.00",
    //         bought_on: "12/01/2023",
    //         method: "Card",
    //     },
    // ];
    // const userDetails = {
    //     username: "Username",
    //     joinedOn: "22/02/2023",
    //     totalAmountSpent: "$30.00",
    //     totalWinnings: "$30.00",
    // };
    
    const convertDateFormat = (startDate) => {
        const parsedDate = new Date(startDate);

        if (isNaN(parsedDate)) {
          
            return '';
        }

        // Use toLocaleDateString to format the date as "Nov 05, 2023"
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedStartDate = parsedDate.toLocaleDateString('en-US', options);
        return formattedStartDate;
    };
    const [userActivity, setUserActivity] = useState(null);
    const usermanager = new AllUsersManager();
    const fetchData = async () => {
        setShowLoading(true);
        try {
            const response = await usermanager.getUsersActivity(forId);
            setUserActivity(response);
        } catch (error) {
            console.error("Error fetching user activity:", error);
        }
        finally {
            // Set loading to false whether the request is successful or not
            setShowLoading(false);
        }
    };
    useEffect(() => {
        

        fetchData();
    }, [forId]);
   
    const [isDeleteSus, setIsDeleteSus] = useState(false);
    const [isRestore, setIsRestore] = useState(false);
   
    const openDeleteSus = () => {
    
        setIsDeleteSus(true);
      };
    
      const closeDeletesus = () => {
        setIsDeleteSus(false);
      };

      const openRestoreSus = () => {
    
        setIsRestore(true);
      };
    
      const closeRestoreSus = () => {
        setIsRestore(false);
      }
      const handleDisableUser = async () => {
        try {
          if (userActivity && userActivity.data && userActivity.data.email) {
            const email = userActivity.data.email;
            const response = await usermanager.disableUser(email);
      
            if (response.success !== undefined) {
              // Check for a defined success property in the response
              if (response.success) {
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "success",
                    title: "Success",
                    body: response.message,
                  },
                ]);
              } else {
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "invalid",
                    title: "Error",
                    body: "Error Occurred 1",
                  },
                ]);
              }
            } else {
              console.error("Invalid response structure: missing 'success' property");
            }
          } else {
            console.error("Email not found in userActivity");
          }
        } catch (error) {
            console.error("Error in handleDisableUser:", error); // Log the specific error
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: "Error Occurred 2",
            },
          ]);
        } finally {
          // Set loading to false whether the request is successful or not
          fetchData();
          closeDeletesus();
          closeRestoreSus();
        }
      };
      const [isUnsub, setisUnsub] = useState(false);
      const [isUnsubscribed, setIsUnsubscribed] = useState(false);
      const closeUnSub = () => {
        setisUnsub(false);
      };
      const openUnSub = (email) => {
        setisUnsub(true);
      };

      const handleUnsubscribe = async () => {
        // if (!selectedEmail) {
        //   setToastMessages([
        //     ...toastMessages,
        //     {
        //       type: "invalid",
        //       title: "Error",
        //       body: "No Email selected",
        //     },
        //   ]);
        //   return;
        // }
    
        try {
          const response = await emailManager.unsubscribe(userActivity.data.email);
    
    
          if (response.success === true) {
            // const updatedData = emailData.filter((data) => data.email !== selectedEmail);
            // setEmailData(updatedData);
            setIsUnsubscribed(true);
            closeUnSub();
            setToastMessages([
              ...toastMessages,
              {
                type: "success",
                title: "Success",
                body: response.message,
              },
            ]); // Close the popup after successful unsubscribe
          } else {
            setToastMessages([
              ...toastMessages,
              {
                type: "invalid",
                title: "Error",
                body: "Error Occurred",
              },
            ]);
            // Display an error message or perform other actions as needed
          }
        } catch (error) {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: "Error Occurred",
            },
          ]);
        }
      };
    return (
        <div style={{ fontFamily: "Inter" }}>
            {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
            {showLoading ? (
                <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
                    <div className="flex items-center justify-center">
                        <div role="status">
                            <svg aria-hidden="true"
                                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div></div></div>
            ) : (
                <>
                <div className="flex justify-between md:mt-24 mt-14 w-[90%]">
                <div>
          <BackButton />
        </div>
        
       
        {userActivity && userActivity.data && (
  userActivity.data.is_deleted === false ? (
    <div className="">
      {!isUnsubscribed && userActivity.data.is_email_subscribed && (
        <span className="cursor-pointer transition-opacity hover:opacity-70 mr-6" onClick={openUnSub}>
          <UnsubscribeOutlinedIcon className="text-clue-logout" />
          <span className="text-clue-logout underline ml-3">Unsubscribe Email</span>
        </span>
      )}
   
      <span className="cursor-pointer transition-opacity hover:opacity-70" onClick={openDeleteSus}>
        <DeleteIcon className="text-clue-logout md:pb-0.5" />
        <span className="text-clue-logout underline ml-2">Delete User Account</span>
      </span>
    </div>
  ) : (
    <div className="cursor-pointer transition-opacity hover:opacity-70" onClick={openRestoreSus}>
      <RestoreIcon className="text-clue-restore md:pb-0.5" />
      <span className="text-clue-restore underline ml-2">Restore User Account</span>
    </div>
  )
)}

        </div>
                    <div className="flex flex-col md:flex-col md:mx-36 md:mt-20 justify-normal mt-10">
                        <span className="text-clue-book-title font-[600] md:ml-0 md:text-justify md:mx-0 mx-12 text-left text-[28px] md:text-[36px] mb-4 md:mb-5">
                        {userActivity && userActivity.data && userActivity.data.username !== undefined
                                ? userActivity.data.username
                                : "-"}
                        </span>
                        <div className="text-clue-book-title font-[600] md:ml-0 md:text-justify md:mx-0 mx-12 text-left text-[20px] md:text-[24px] mb-4 md:mb-2">
                        {userActivity && userActivity.data && userActivity.data.email !== undefined
                                ? userActivity.data.email
                                : "-"}</div>
                    </div>
                    
                    <div className="  md:mx-[0px] md:ml-32 lg:w-auto text-clue-gray flex flex-col lg:mx-36 justify-normal md:flex-row mt-4 md:mt-10">
                        <div className="md:text-left">
                            <p>Joined On</p>
                            <p className="text-clue-book-author md:text-left">
                                {convertDateFormat(userActivity && userActivity.data && userActivity.data.joined_on
                                    ? userActivity.data.joined_on
                                    : "-")}
                            </p>
                        </div>
                        <div className="lg:ml-[150px] xl:ml-[250px] md:ml-[110px] ml-3 md:mt-0 mt-4">
                            <p>Total Amount Spent</p>
                            <p className="text-clue-book-author md:text-left"> ${userActivity && userActivity.data && userActivity.data.total_amount_spent !== undefined
                                ? userActivity.data.total_amount_spent
                                : "-"}</p>
                        </div>
                        <div className="lg:ml-[150px] xl:ml-[250px] md:ml-[110px] ml-3 md:mt-0 mt-4">
                            <p>Total Winnings</p>
                            <p className="text-clue-book-author md:text-left">
                                ${userActivity && userActivity.data && userActivity.data.total_winnings !== undefined
                                    ? userActivity.data.total_winnings
                                    : "-"}
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col md:mt-20 md:flex-row md:mx-36 justify-normal mt-10">
                        <span className="text-clue-yellow font-bold text-2xl md:text[24px] md:text-justify md:ml-0 ml-12 text-left mb-1 md:mb-0">
                            Purchase History
                        </span></div>
                    <div className="overflow-x-auto mt-10 mb-14 mx-10 lg:w-auto xl:w-[auto] md:w-[620px]  md:mx-[100px] lg:mx-[160px] xl:mx-[150px]">
                        <table className="table-fixed min-w-full bg-clue-black w-[800px] md:w-[50vw] rounded text-clue-book-author">
                            <thead>
                                <tr className="border-b border-filter-heading">
                                    <th className="p-2 py-5  border-r border-filter-heading">#</th>
                                    <th className="p-2 py-5  border-r border-filter-heading">Competition</th>
                                    <th className="p-2 py-5  border-r border-filter-heading">Total Amount</th>
                                    <th className="p-2 py-5  border-r border-filter-heading">
                                        Bought On
                                    </th>
                                    <th className="p-2  border-filter-heading">Method</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userActivity &&
                                    userActivity.data &&
                                    userActivity.data.sales.map((sale, index) => (
                                        <tr
                                            key={index}
                                            className="border-b border-filter-heading hover:bg-black"
                                        >
                                            <td className="p-2 py-5 border-r border-filter-heading">
                                                {index + 1}
                                            </td>
                                            <td className="p-2 py-5  border-r border-filter-heading">
                                                {sale.book_name ? sale.book_name : "N/A"}
                                            </td>
                                            <td className="p-2 py-5  border-r border-filter-heading">
                                                {sale.total_amount}
                                            </td>
                                            <td className="p-2 py-5  border-r border-filter-heading">
                                                {convertDateFormat(sale.bought_on)}
                                            </td>
                                            <td className="p-2 py-5  border-filter-heading">
                                                {sale.payment_type}
                                            </td>
                                        </tr>
                                    ))}
                                <tr className="border-b-0">
                                    <td className="py-10 border-r border-filter-heading"></td>
                                    <td className="py-10 border-r border-filter-heading"></td>
                                    <td className="py-10 border-r border-filter-heading"></td>
                                    <td className="py-10 border-r border-filter-heading"></td>
                                    <td className="py-10  border-filter-heading"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )}
             {isUnsub && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={closeUnSub}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black rounded-3xl md:w-auto w-80  p-8 px-12 relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author md:w-auto w-60 text-left mb-4">
              Confirm
            </h3>
            <p className="text-filter-heading md:w-auto w-60 text-left">
              Are you sure you want to unsubscribe this email?
            </p>
            <div className="flex md:justify-end justify-center mt-6">
              <button
                onClick={closeUnSub}
                className="text-filter-heading transition-opacity hover:opacity-70 mr-4 border-2 rounded-[9px] border-filter-heading py-0.5 px-6"
              >
                Cancel
              </button>
              <button className="bg-clue-logout transition-opacity hover:opacity-70 text-white px-7 rounded-[9px] py-0.5 "
                onClick={handleUnsubscribe}>
                Unsubscribe
              </button>
            </div>
          </div>
        </div>
      )}
            {isDeleteSus && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeDeletesus}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black rounded-3xl md:w-auto w-80  p-8 px-12 relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author font-[600]  text-left mb-4">
                    Delete User
                  </h3>
                  <p className="text-filter-heading flex items-start justify-start text-left w-60  md:w-full">
                  This operation is reversible, however the user won’t be able to log in to their accounts and you won’t be able to mail them through this panel
                    <br></br>Are you sure you want to delete this User?
                  </p>
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeDeletesus}
                      className="text-filter-heading mr-4 transition-opacity hover:opacity-70 border-2 rounded-md border-filter-heading py-0.5 px-6"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleDisableUser}
                      className="bg-clue-logout transition-opacity hover:opacity-70 text-white px-7 py-0.5 rounded"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isRestore && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeRestoreSus}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black rounded-3xl md:w-auto w-80  p-8 px-12 relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author font-[600]  text-left mb-4">
                    Restore User
                  </h3>
                  <p className="text-filter-heading flex items-start justify-start text-left w-60  md:w-full">
               Are you sure you want to restore this User?
                  </p>
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeRestoreSus}
                      className="text-filter-heading mr-4 transition-opacity hover:opacity-70 border-2 rounded-md border-filter-heading py-0.5 px-6"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleDisableUser}
                      className="bg-clue-purchase transition-opacity hover:opacity-70 text-white px-7 py-0.5 rounded"
                    >
                      Restore
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
    );
}

export default UserDetails;
