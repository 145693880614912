import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import myImage from "../../assets/images/cover.png";
import BackButton from "../../components/backbutton/backbutton.js";
import BookPage from "../../components/book/bookpage";
import CreateIcon from "@mui/icons-material/Create";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArchiveIcon from "@mui/icons-material/Archive";
import CollectionIdManager from "../../models/admin/competitions/https/getcollectionidhttp.js";
import ArchiveManager from "../../models/admin/competitions/https/movetoarchivehttp.js";
import Toast from "../../components/toasts/toast";
import ContributionIdManager from "../../models/admin/contributions/https/getcontbyidhttp.js";
import AllUsersManager from "../../models/admin/users/https/getallusershttp.js";
import GetWinner from "../../models/admin/winners/https/getwinnerbyidhttp.js";

function CollectionInfo() {
  const collectionidManager = new CollectionIdManager();
  const archiveManager = new ArchiveManager();
  const contIdManager = new ContributionIdManager();
  const getWinner = new GetWinner();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const forId = searchParams.get("id");
  const [toastMessages, setToastMessages] = useState([]);
  const [SpecColl, setSpecColl] = useState([]);
  const [openArchive, setOpenArchive] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [fullFile, setFullFile] = useState("");
  const [fullImage, setFullImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [prizeAmount, setPrizeAmount] = useState("");
  const [culprit, setCulprit] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [under18, setUnder18] = useState(false);
  const [suspects, setSuspects] = useState([]);
  const [author, setAuthor] = useState("");
  const [amount, setAmount] = useState("1000");
  const [linkToAuthor, setLinkToAuthor] = useState("");
  const [contributionsEdit, setContributionsEdit] = useState([]);
  const [winnerData, setWinnerData] = useState([]);

  //Finances

  const [promoid, setPromoId] = useState("");
  const [promoText, setPromoText] = useState("");
  const [promoDiscount, setPromoDiscount] = useState("");
  const [totalEarnings, setTotalEarnings] = useState("");
  const [financesContributors, setFinancesContributors] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);

  useEffect(() => {
    setShowLoading(true);
    collectionidManager.get(forId).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setShowLoading(false);
          setSpecColl(value.data);
          setFinancesContributors(value.data.contributors);
          setPromoCodes(value.data.promo_codes);
          setPromoId(value.data.promoid);
          setPromoText(value.data.promo_text);
          setPromoDiscount(value.data.promo_discount);
          setTotalEarnings(value.data.total_earnings);
          setFullFile(value.data.book);
          setFullImage(value.data.cover);
          setTitle(value.data.title);
          setDescription(value.data.description);
          setStartDate(value.data.start_date);
          setEndDate(value.data.end_date);
          setPrizeAmount(value.data.prize_amount);
          setCulprit(value.data.culprit);
          setIsVisible(value.data.is_visible_on_product_page);
          setUnder18(value.data.under_18_appropriate);
          setSuspects(value.data.suspects);
          setAuthor(value.data.author);
          setAmount(value.data.amount);
          setLinkToAuthor(value.data.link_to_author);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    setShowLoading(true);
    getWinner.get(forId).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setWinnerData(value.data);
          setShowLoading(false);
        } else {
        }
      } else {
      }
    });
  }, []);

  const allusersManager = new AllUsersManager();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setShowLoading(true);
    allusersManager.get().then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setUsers(value.data);
          setShowLoading(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  const navigateToEdit = () => {
    navigate(`/adboard/dashboard/collection/info/edit?id=${forId}`, {
      state: {
        fullFile,
        title,
        description,
        startDate,
        endDate,
        prizeAmount,
        culprit,
        isVisible,
        under18,
        suspects,
        fullImage,
        author,
        amount,
        linkToAuthor,
      },
    });
  };

  const navigateToAuthorLink = () => {
    if (linkToAuthor) {
      window.open(linkToAuthor, "_blank");
    }
  };

  const navigateToView = () => {
    navigate(`/adboard/dashboard/collection/info/view-entries?id=${forId}`);
  };

  const navigateToSales = () => {
    navigate(`/adboard/dashboard/collection/view-sales?id=${forId}`);
  };

  const closeSetArchive = () => {
    setOpenArchive(false);
  };
  const openSetArchive = () => {
    setOpenArchive(true);
  };

  const MoveToArchive = () => {
    setShowLoading(true);
    archiveManager.movetoarchive(forId).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setShowLoading(false);
          navigate("/adboard/dashboard/collection");
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  };

  return (
    <div className="w-full" style={{ fontFamily: "Inter" }}>
      {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <div className="flex md:flex-row flex-col mt-10 justify-between">
        <div>
          <BackButton />
        </div>
        <div className="flex md:flex-row flex-col md:my-0 mt-5 md:py-0 md:space-x-10 mr-10 md:mr-24">
          <span
            className="underline text-clue-yellow transition-opacity hover:opacity-70 cursor-pointer md:my-0 my-2"
            onClick={navigateToEdit}
          >
            <CreateIcon className="mx-2" fontSize="small" />
            Edit Details
          </span>
          <span
            className="underline text-clue-yellow transition-opacity hover:opacity-70 cursor-pointer md:my-0 my-2"
            onClick={navigateToView}
          >
            <PeopleAltIcon className="mx-2" fontSize="small" />
            View Entries
          </span>
          <span
            className="underline transition-opacity hover:opacity-70 text-clue-yellow cursor-pointer md:my-0 my-2"
            onClick={navigateToSales}
          >
            <AttachMoneyIcon className="mx-2" fontSize="small" />
            View Sales
          </span>
          <span
            className="underline transition-opacity hover:opacity-70 text-clue-logout cursor-pointer md:my-0 my-2"
            onClick={openSetArchive}
          >
            <ArchiveIcon className="mx-2" fontSize="small" />
            Move To Archive
          </span>
        </div>
      </div>
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="md:mx-24 mx-10 mt-10">
            <div className="border-b-2 border-filter-heading">
              <div className="flex justify-center md:justify-start my-8">
                <p className="text-clue-yellow font-bold text-xl">
                  Product Preview
                </p>
              </div>
              <div className="flex flex-col md:flex-row w-full">
                <div className="w-full md:w-72">
                  <img
                    src={SpecColl.cover}
                    className="w-72 md:w-72 h-72 object-cover mx-auto"
                  />
                </div>
                <div className="ml-0 md:ml-10 flex flex-col w-full mt-10 md:mt-0">
                  <div className="md:text-left w-full">
                    <h2 className="text-clue-book-title font-semibold text-2xl mb-2">
                      {SpecColl.title}
                    </h2>
                    <p
                      className="text-clue-book-author text-base font-medium md:mb-10 cursor-pointer"
                      onClick={navigateToAuthorLink}
                    >
                      {SpecColl.author}
                    </p>
                  </div>
                  <div className="flex w-full flex-col md:flex-row mt-5">
                    <div className="flex-col w-full mt-5 md:mt-0 md:text-left ">
                      <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                        Start Date
                      </p>
                      <p className="text-clue-book-author font-bold mb-2 text-base">
                        {SpecColl.start_date}
                      </p>
                    </div>

                    <div className="flex-col w-full md:text-left mt-5 md:mt-0">
                      <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                        Draw Date
                      </p>
                      <p className="text-clue-book-author font-bold mb-2 text-base">
                        {SpecColl.end_date}
                      </p>
                    </div>
                    <div className="flex-col w-full mt-5 md:mt-0 md:text-left ">
                      <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                        Prize Amount
                      </p>
                      <p className="text-clue-book-author font-bold mb-2 text-base">
                        {/* {SpecColl.prize_amount &&
                        Array.isArray(SpecColl.prize_amount) &&
                        SpecColl.prize_amount.length > 0
                          ? `$${SpecColl.prize_amount[0]}${
                              SpecColl.prize_amount.length > 1
                                ? `, $${SpecColl.prize_amount[1]}`
                                : ""
                            }${
                              SpecColl.prize_amount.length > 2
                                ? `, $${SpecColl.prize_amount[2]}`
                                : ""
                            }`
                          : "No prize amount available"}
                           */}
                        {console.log(SpecColl.prize_amount)}
                        {SpecColl.prize_amount &&
                        Array.isArray(SpecColl.prize_amount) &&
                        SpecColl.prize_amount.length > 0
                          ? `$${parseFloat(
                              SpecColl.prize_amount[0]
                            ).toLocaleString()}${
                              SpecColl.prize_amount.length > 1
                                ? `, $${parseFloat(
                                    SpecColl.prize_amount[1]
                                  ).toLocaleString()}`
                                : ""
                            }${
                              SpecColl.prize_amount.length > 2
                                ? `, $${parseFloat(
                                    SpecColl.prize_amount[2]
                                  ).toLocaleString()}`
                                : ""
                            }`
                          : "No prize amount available"}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col w-full md:flex-row mt-5 md:mt-10">
                    <div className="flex-col md:text-left  w-full">
                      <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                        Ages
                      </p>
                      <p className="text-clue-book-author font-bold mb-2 text-base">
                        {SpecColl.under_18_appropriate ? (
                          <span>Appropriate for ages of 14 and above</span>
                        ) : (
                          <span>
                            Not suitable for readers under the age of 18
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-10">
                <p className="text-clue-draw-amount font-medium md:text-left ">
                  Description
                </p>
                <p
                  className="text-clue-book-author md:text-left  mt-3"
                  dangerouslySetInnerHTML={{ __html: SpecColl.description }}
                ></p>
              </div>
            </div>
            <div className="border-b-2 border-filter-heading">
              <div className="flex justify-center md:justify-start my-8">
                <p className="text-clue-yellow font-bold text-xl">
                  Additional Information
                </p>
              </div>
              <div className="flex flex-col w-full mt-5 md:mt-10">
                <div className="flex-col md:text-left  w-full">
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Winner(s)
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    <p>{winnerData.length == 0 ? "N/A" : ""}</p>
                    <p>
                      {winnerData.length > 0 &&
                        users
                          .filter(
                            (user) => user.user_id === winnerData[0].user_id
                          )
                          .map((user) => user.username)
                          .join("")}
                    </p>
                    <p>
                      {winnerData.length > 1 &&
                        users
                          .filter(
                            (user) => user.user_id === winnerData[1].user_id
                          )
                          .map((user) => user.username)
                          .join("")}
                    </p>
                    <p>
                      {winnerData.length > 2 &&
                        users
                          .filter(
                            (user) => user.user_id === winnerData[2].user_id
                          )
                          .map((user) => user.username)
                          .join("")}
                    </p>
                  </p>
                </div>
                <div className="flex-col md:text-left mt-5 w-full">
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Visible on Product Page
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {isVisible ? "Yes" : "No"}
                  </p>
                </div>
                <div className="flex-col md:text-left mt-5 w-full">
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Contributors
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {financesContributors.map((contribution, index) => {
                      return (
                        <p
                          key={index}
                          className="text-clue-book-author font-bold mb-2 text-base"
                        >
                          {contribution.username}{" "}
                          {contribution.is_owner
                            ? String.fromCharCode(160) +
                              " (Company) " +
                              String.fromCharCode(160)
                            : "" + String.fromCharCode(160)}{" "}
                          {contribution.percentage}%
                        </p>
                      );
                    })}
                  </p>
                </div>
                <div className="flex-col md:text-left mt-5 mb-10 w-full">
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Suspects
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {Array.isArray(SpecColl?.suspects) ? (
                      SpecColl.suspects.map((suspect, index) => (
                        <p key={index} className="text-suspect">
                          {suspect}
                        </p>
                      ))
                    ) : (
                      <span className="text-suspect">{SpecColl?.suspects}</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col border-b-2 border-filter-heading">
              <div className="flex justify-center md:justify-start mt-8">
                <p className="text-clue-yellow font-bold text-xl">Finances</p>
              </div>
              <div class="flex flex-col mt-10 mx-auto md:ml-[18%] w-full md:w-[60%] rounded-xl bg-black text-center">
                <div class="flex justify-between p-2 px-4 border-b-2 border-filter-heading">
                  <div>
                    <p class="text-account-color font-semibold">
                      Total Earnings
                    </p>
                  </div>
                  <div>
                    <p class="text-account-color font-semibold">
                      ${SpecColl.total_earnings && SpecColl.total_earnings}
                    </p>
                  </div>
                </div>
                <div
                  class={`flex justify-between px-4 p-2 border-filter-heading  ${
                    SpecColl.promo_text == null &&
                    financesContributors.length == 0
                      ? "mb-10"
                      : " border-b-2"
                  }`}
                >
                  <div>
                    <p class="text-account-color font-semibold">Prize Amount</p>
                  </div>
                  <div>
                    <p class="text-account-color font-semibold">
                      {SpecColl.prize_amount &&
                      Array.isArray(SpecColl.prize_amount) &&
                      SpecColl.prize_amount.length > 0
                        ? `$${SpecColl.prize_amount[0].toLocaleString()}${
                            SpecColl.prize_amount.length > 1
                              ? `, $${SpecColl.prize_amount[1].toLocaleString()}`
                              : ""
                          }${
                            SpecColl.prize_amount.length > 2
                              ? `, $${SpecColl.prize_amount[2].toLocaleString()}`
                              : ""
                          }`
                        : "No prize amount available"}
                    </p>
                  </div>
                </div>
                {financesContributors.map((contribution, index) => {
                  return (
                    <div
                      class={`flex justify-between p-2 px-4 border-filter-heading ${
                        promoCodes.length == 0 &&
                        financesContributors.length == index + 1
                          ? "mb-10"
                          : "border-b-2"
                      }`}
                    >
                      <div>
                        <p class="text-account-color font-semibold">
                          {contribution.is_owner ? "Company" : "Contributor"}{" "}
                          <span className="italic">
                            ({contribution.username})
                          </span>
                        </p>
                      </div>
                      <div>
                        <p class="text-account-color font-semibold">
                          $
                          {contribution.contributor_earnings &&
                            contribution.contributor_earnings.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {promoCodes.map((promoCode, index) => {
                  return (
                    <div
                      class={`flex justify-between p-2 px-4 border-filter-heading ${
                        promoCodes.length === index + 1 ? "mb-10" : "border-b-2"
                      }`}
                    >
                      <div>
                        <p class="text-account-color font-semibold">
                          Promo Code{" "}
                          <span className="italic">({promoCode.text})</span>
                        </p>
                      </div>
                      <div>
                        <p class="text-account-color font-semibold">
                          $
                          {(
                            SpecColl.amount *
                            (promoCode.discount / 100)
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="md:mx-24 mt-10">
            <div className="flex justify-center md:justify-start my-8">
              <p className="text-clue-yellow font-bold text-xl">
                Book Contents
              </p>
            </div>
            <div>
              <BookPage bookUrl={SpecColl.book} px="2570px" />
            </div>
          </div>
          {openArchive && (
            <div
              className="fixed inset-0 flex items-center justify-center z-50"
              onClick={closeSetArchive}
            >
              <div className="bg-black opacity-50 absolute inset-0"></div>
              <div
                className="bg-clue-black rounded-3xl md:w-auto w-80  p-8 px-12 relative z-10"
                onClick={(e) => e.stopPropagation()}
              >
                <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                  Archive Collection
                </h3>
                <p className="text-filter-heading text-left">
                  {showLoading ? (
                    <span className="flex w-full items-center justify-center">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </span>
                  ) : (
                    <span>
                      Moving this competition will also disable its visibility
                      from the product page. Do you wish to continue?
                    </span>
                  )}
                </p>
                <div className="flex justify-end mt-6">
                  <button
                    onClick={closeSetArchive}
                    className="text-filter-heading mr-4 border-2 rounded-md border-filter-heading py-2 px-4"
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-clue-logout text-white px-4 py-2 rounded"
                    onClick={MoveToArchive}
                  >
                    Archive
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default CollectionInfo;
