import React, { useState,useEffect } from 'react';
import '@fontsource/inter';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from "react-router-dom";
import Footer from '../footer/footer';
import AddUserManager from '../../models/website/auth/https/adduserhttp';
import Toast from '../toasts/toast';
import TermsOfServiceModal from '../footer/termsofservice';
import AllCompList from "../../models/website/public/https/comphttp";

function Sign_up() {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [toastMessages, setToastMessages] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    // New state variables for checkboxes
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [receiveUpdates, setReceiveUpdates] = useState(false);
    const handleAgreeTermsChange = () => setAgreeTerms(!agreeTerms);
    const handleReceiveUpdatesChange = () => setReceiveUpdates(!receiveUpdates);
    const [termsAndConditions, setTermsAndConditions] = useState("");
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

    const openTermsModal = () => {
        setIsTermsModalOpen(true);
      };
      
  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };
      const api = new AllCompList();
      useEffect(() => {
        const fetchData = async () => {
          try {
            const footerResponse = await api.getfooter();
            if(footerResponse.success){
            // Filter data based on type
            const termsData = footerResponse.data.find(
              (item) => item.type === "terms-and-conditions"
            );
            setTermsAndConditions(termsData?.text || "");
        }
        else{
            setToastMessages([
                ...toastMessages,
                {
                    type: "invalid",
                    title: "Error",
                    body: "Some Error Occurred",
                },
            ]);
        }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
      }, []);
    const handleEmailChange = (e) => {

        setEmail(e.target.value);
    };
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };
  const [showLoading, setShowLoading] = useState(false);
   
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const navigate = useNavigate();
    const handleSignIn = () => {
        // Implement your sign-in logic here
    };
    const handleSignUp = async () => {
        setShowLoading(true);
        try {
            if (!email || !username || !password || !confirmpassword) {
                setToastMessages([
                    ...toastMessages,
                    {
                        type: "invalid",
                        title: "Missing Information",
                        body: "All fields are required.",
                    },
                ]);
                setShowLoading(false);
                return; // Do not proceed with sign-up
            }
            if (username.length >= 50) {
                setToastMessages([
                    ...toastMessages,
                    {
                        type: "invalid",
                        title: "Invalid Username",
                        body: "Username should be less than 50 characters.",
                    },
                ]);
                setShowLoading(false);
                return; // Do not proceed with sign-up
            }
    

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setToastMessages([
                    ...toastMessages,
                    {
                        type: "invalid",
                        title: "Invalid Email",
                        body: "Please enter a valid email address.",
                    },
                ]);
                setShowLoading(false);
                return; // Do not proceed with sign-up
            }
            if (password.length < 8) {
                setToastMessages([
                    ...toastMessages,
                    {
                        type: "invalid",
                        title: "Password Length",
                        body: "Password should be at least 8 characters.",
                    },
                ]);
                setShowLoading(false);
                return; // Do not proceed with sign-up
            }
            // Check if password and confirm password match
            if (password !== confirmpassword) {
                setToastMessages([
                    ...toastMessages,
                    {
                        type: "invalid",
                        title: "Password Mismatch",
                        body: "Password and confirm password do not match.",
                    },
                ]);
                setShowLoading(false);
                return; // Do not proceed with sign-up
            }

            if (!agreeTerms) {
                setToastMessages([
                    ...toastMessages,
                    {
                        type: "invalid",
                        title: "Terms and Condition",
                        body: "Terms and Condition must be accepted",
                    },
                ]);
                setShowLoading(false);
                return; // Do not proceed with sign-up
            }

            // AddAdminManager API call
            const isEmailSubscribed = receiveUpdates;
            const addusermanager = new AddUserManager();

            const response = await addusermanager.add(email, password, username, isEmailSubscribed).then((value) => {
                if (!value.error) {
                    const baseResponse = value.success;
                    if (baseResponse === true) {
                        const is_from_signup=true;
                        navigate("/account/verify-mail", { state: { email,is_from_signup } });
                    } else {
                        setToastMessages([
                            ...toastMessages,
                            {
                                type: "invalid",
                                title: "Error",
                                body: value.message,
                            },
                        ]);
                        
                    }
                } else {
                    setToastMessages([
                        ...toastMessages,
                        {
                            type: "invalid",
                            title: "Error",
                            body: value.error,
                        },
                    ]);
                }
            });

            // Handle the response as needed

            // Redirect or show a success message
        } catch (error) {
            // Handle errors, show error message, etc.
            console.error("Error signing up:", error);
        }
        finally{
            setShowLoading(false);
        }
    };

    return (
        <div>
            {toastMessages.map((toast, index) => (
                <Toast
                    className="mb-0"
                    key={index}
                    toasts={[toast]}
                    onClose={() => {
                        // Remove the toast message when it's closed
                        const updatedToasts = [...toastMessages];
                        updatedToasts.splice(index, 1);
                        setToastMessages(updatedToasts);
                    }}
                />
            ))}
            <TermsOfServiceModal
        isOpen={isTermsModalOpen}
        onClose={closeTermsModal}
        text={termsAndConditions}/>
            <div className="flex justify-center items-center ">
                <div
                    className="mx-2 md:w-[490px] w-[320px] md:h-[720px] h-[640px] md:mt-24 mt-12 mb-20  block rounded-[30px] bg-clue-black '
              ">
                    <p
                        className="md:mr-[271px] text-[24px] md:text-[26px] mr-[47%]  md:mt-10 mt-8 md:mb-8 mb-5 font-[700] leading-tight text-clue-yellow">
                        Sign up
                    </p>
                    <div className={'relative md:mb-7 mb-5 '} >
                        <input
                            type="text"
                            class="focus:outline-none focus:border-onclickfield focus:ring-0 peer m-0 block md:h-[56px] h-[45px] md:mx-14 md:w-[77%] mx-10 rounded-[10px] w-[245px] border-[1px] border-solid border-clue-gray bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray "
                            id="name"
                            value={username}
                            onChange={handleUsernameChange}

                            placeholder="Username" />

                    </div>
                    <div className={'relative md:mb-7 mb-5  '} >
                        <input
                            type="email"
                            class="focus:outline-none focus:border-onclickfield focus:ring-0 peer m-0 block md:h-[56px] h-[45px] md:mx-14 md:w-[77%] mx-10 rounded-[10px] w-[245px] border-[1px] border-solid border-clue-gray bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray "
                            id="email"
                            value={email}
                            onChange={handleEmailChange}

                            placeholder="Email" />

                    </div>

                    <div class="relative md:mb-7 mb-5  ">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            class="focus:outline-none focus:border-onclickfield focus:ring-0 peer m-0 block md:h-[56px] h-[45px]  md:mx-14 md:w-[77%]  mx-10  w-[245px]  rounded-[10px] border-[1px] border-solid border-clue-gray bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray"
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="Password"


                        />
                        {showPassword ? (
                            <VisibilityOffOutlinedIcon
                                className="absolute md:top-4 top-3 md:right-[70px] right-12 cursor-pointer text-gray-300"
                                onClick={toggleShowPassword}
                            />
                        ) : (
                            <VisibilityOutlinedIcon
                                className="absolute md:top-4 top-3 md:right-[70px] right-12 cursor-pointer text-gray-300"
                                onClick={toggleShowPassword}
                            />
                        )}
                    </div>
                    <div class="relative md:mb-7 mb-5  ">
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            class="focus:outline-none focus:ring-0 focus:border-onclickfield peer m-0 block md:h-[56px] h-[45px]  md:mx-14 md:w-[77%]  mx-10  w-[245px]  rounded-[10px] border-[1px] border-solid border-clue-gray bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray"
                            id="password"
                            value={confirmpassword}
                            onChange={handleConfirmPasswordChange}
                            placeholder="Confirm Password"
                        />
                        {showConfirmPassword ? (
                            <VisibilityOffOutlinedIcon
                                className="absolute md:top-4 top-3 md:right-[70px] right-12  cursor-pointer text-gray-300"
                                onClick={toggleShowConfirmPassword}
                            />
                        ) : (
                            <VisibilityOutlinedIcon
                                className="absolute md:top-4 top-3 md:right-[70px] right-12  cursor-pointer text-gray-300"
                                onClick={toggleShowConfirmPassword}
                            />
                        )}
                    </div>

                    <div className="relative md:mb-4 mb-2 md:ml-6 mr-1">
                        <input
                            type="checkbox"
                            id="agreeTerms"
                            className="md:mr-5 mr-3"
                            checked={agreeTerms}
                            onChange={handleAgreeTermsChange}
                        />
                        <label htmlFor="agreeTerms" className="relative text-left text-[15px] font-normal text-filter-heading  inline-block w-[200px] md:w-[370px]" style={{ verticalAlign: 'top' }}>
                            I agree to the <u onClick={openTermsModal} className='text-clue-yellow cursor-pointer transition-opacity hover:opacity-70'>Terms and Conditions</u>
                        </label>
                    </div>

                    <div className="relative mb-4 md:ml-6 mr-1 md:mr-0">
                        <input
                            type="checkbox"
                            id="receiveUpdates"
                            className="md:mr-5 mr-3"
                            checked={receiveUpdates}
                            onChange={handleReceiveUpdatesChange}
                        />

                        <label htmlFor="receiveUpdates" className="relative text-left  text-[15px] font-normal text-filter-heading inline-block w-[200px] md:w-[370px]" style={{ verticalAlign: 'top' }}>
                            I want to receive updates about new competitions and promotions
                        </label>
                    </div>
                    <button
                        type="button"
                        className="mb-2 block md:mx-14 md:w-[77%] md:mt-11 mt-8 mx-10 md:h-[56px] h-[45px] w-[245px] pb-[3px] bg-onclickfield rounded-[10px] md:pb-1 md:pl-1 text-center text-[19px] md:text-[22px] font-[700] leading-normal text-white transition-opacity hover:opacity-70"
                        onClick={handleSignUp}>
 {showLoading ? (
              <span className="flex w-full items-center justify-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </span>
            ) : (
              <span>Sign Up</span>
            )}
                        
                    </button>

                    <div className="md:mt-7 mt-5 w-52 md:w-80 text-[17px] md:text-[20px] md:ml-[92px]  text-center ml-[60px] font-[400] text-clue-gray leading-tight text-lg ">
                        Already have an account?
                        <a href="/account/signin" className="font-[700] transition-opacity hover:opacity-70 text-clue-yellow md:pr-[0px] md:pl-[5px] pl-[6px] pr-[1px] md:text-center underline" onClick={handleSignIn}>Sign In</a>
                    </div>

                </div>
            </div>
            <div><Footer /></div>
        </div>

    );
}
export default Sign_up;