import AdminTile from "../tiles/admin_tile";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import Footer from "../../components/footer/footer.js";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import WebFreshUserManager from "../../models/website/auth/https/webfreshuserhttp.js";
import Toast from "../toasts/toast.js";
function Dashboard() {
  const [username, setUsername] = useState(localStorage.getItem('websiteUsername') || '');
  useEffect(() => {
    // Check if email or username is not found in local storage
    if ( !username) {
      // Navigate to account/signin page
      navigate('/account/signin');
    }
  }, [ username]);
  const Account = {
    name: localStorage.getItem("websiteUsername"),
  };
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [freshUserData, setFreshUserData] = useState(null);
  const currentHour = new Date().getHours();
  const [toastMessages, setToastMessages] = useState([]);
  const location = useLocation();
  const successfulPayment = location.state;
  
  useEffect(() => {
    if (successfulPayment != null && successfulPayment) {
      setToastMessages([
        ...toastMessages,
        {
          type: "success",
          title: "Success",
          body: "Purchase Successful.",
        },
      ]);
    }
  }, []);

  let greeting;
  if (currentHour >= 3 && currentHour < 12) {
    greeting = "Good Morning";
  } else if (currentHour >= 12 && currentHour < 16) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  useEffect(() => {
    const fetchFreshUserData = async () => {
      setShowLoading(true);
      try {
        const freshUserManager = new WebFreshUserManager();
        const response = await freshUserManager.get();
        if (!response.error) {
          setShowLoading(true);
          setFreshUserData(response.data);
        const isEmailSubscribed = response.data.is_email_subscribed;
        localStorage.setItem('WebisEmailSubscribed', isEmailSubscribed);

        } else {
          // Handle error appropriately
          console.error(response.error);
        }
      } catch (error) {
      } finally {
        // Set loading to false whether the request is successful or not
        setShowLoading(false);
      }
    };

    // Call the function to fetch fresh user data when the component mounts
    fetchFreshUserData();
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  const filteredBooks = (freshUserData?.competitions || [])
    .filter((book) => book !== null)
    .filter((book) => {
      return (
        !searchTerm ||
        book.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  return (
    <div
      className="flex flex-col min-h-[95vh] text-white overflow-x-hidden"
      style={{ fontFamily: "Inter" }}
    >
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex-grow">
            {toastMessages.map((toast, index) => (
              <Toast
                className="mb-0"
                key={index}
                toasts={[toast]}
                onClose={() => {
                  const updatedToasts = [...toastMessages];
                  updatedToasts.splice(index, 1);
                  setToastMessages(updatedToasts);
                }}
              />
            ))}
            <div className="flex flex-col md:flex-row md:mx-24 justify-between mt-5 md:mt-10">
              <span className="text-clue-gray font-bold text-2xl mb-4 md:mb-0">
                {greeting}, {Account.name}
              </span>

              <div className="flex md:flex-row justify-between mx-14 md:mx-10 md:ml-0 md:space-y-0 md:space-x-8 mt-4 md:mt-0">
                <Link to="/account/dashboard/my-profile">
                  <div className="text-clue-purchase flex items-center space-x-2">
                    <span className="hidden md:flex">
                      <AccountCircleIcon />
                    </span>
                    <span className="underline">My Profile</span>
                  </div>
                </Link>
                {freshUserData && freshUserData.is_contributor && (
                  <Link to="/account/dashboard/author-dashboard">
                    <div className="text-clue-purchase flex items-center space-x-2">
                      <span className="hidden md:flex">
                        <AccountCircleIcon />
                      </span>
                      <span className="underline">Author Dashboard</span>
                    </div>
                  </Link>
                )}
              </div>
            </div>
            <div className="flex mt-10 ml-6 md:ml-[10%]">
              <p className="text-clue-gray font-bold text-2xl mb-4 md:mb-0">
                Your Purchased Books
              </p>
            </div>
            <div
              className="mt-5 md:mt-10 w-full justify-center mx-7 md:ml-[10%] flex flex-col items-start relative"
              style={{ fontFamily: "Inter" }}
            >
              <div className="flex w-60 md:w-[50%] items-center space-x-4 z-10">
                <div className="flex-grow flex items-center bg-clue-black rounded-xl p-2 space-x-4">
                  <SearchIcon className="text-clue-yellow m-2" />
                  <input
                    type="text"
                    placeholder="Search Books"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-60 md:w-[100%] bg-clue-black text-clue-gray placeholder-clue-gray border-none outline-none focus:ring-0"
                  />
                </div>
              </div>
            </div>
            <div className="md:ml-[7%] md:w-[90%] flex flex-wrap mt-12 -mx-2">
              {filteredBooks.map((book, index) => (
                <div
                  key={index}
                  className="w-full lg:w-1/2 px-2 mx-5 md:mx-0 mb-10"
                >
                  <div
                    onClick={() =>
                      navigate(`/account/dashboard/book?book_id=${book._id}`)
                    }
                  >
                    <AdminTile book={book} />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-10">
            <Footer />
          </div>
        </>
      )}
    </div>
  );
}
export default Dashboard;
