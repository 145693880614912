import Toast from "../toasts/toast";
import BackButton from "../backbutton/backbutton.js";
import { useState,useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import CreateIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";
import EmailManager from "../../models/admin/misc/http/emailsubshttp.js";
import Export from "../../models/admin/export/http/exporthttp.js";
import UnsubscribeOutlinedIcon from '@mui/icons-material/UnsubscribeOutlined';

function EmailSubs() {
  const [toastMessages, setToastMessages] = useState([]);
  const navigate = useNavigate();
  const [emailData, setEmailData] = useState([]);
  const navigateToCompose = () => {
    navigate('/adboard/dashboard/miscellaneous/emailsubs/compose');
  }
  const [showLoading2, setShowLoading2] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isUnsub, setisUnsub] = useState(false);
  const emailManager = new EmailManager();
  // const emailData = [
  //   {
  //     username: "yay",
  //   },
  //   {
  //     username: "yay",
  //   },
  //   {
  //     username: "yay",
  //   },
  // ];
  const [selectedEmail, setSelectedEmail] = useState(null);
  const closeUnSub = () => {
    setisUnsub(false);
    setSelectedEmail(null);
  };
  const openUnSub = (email) => {
    setSelectedEmail(email);
    setisUnsub(true);
  };
  const handleDownload = async () => {
    setShowLoading2(true);
    try {
      const exportInstance = new Export();
      const response = await exportInstance.ExportEmailSub();

      if (response?.success) {
        // Assume the response contains the base64 data in a property called 'base64Data'
        const base64Data = response.data.replace(/^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/, '');

      // Remove whitespaces or line breaks from the base64 data
      const cleanedBase64Data = base64Data.replace(/\s/g, '');

      // Convert base64 to binary
      const binaryData = atob(cleanedBase64Data);

        // Create ArrayBuffer and uint8 array
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create Blob
        const blob = new Blob([uint8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create download link
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'subscriber_list.xlsx';

        // Append link to the document
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      } else {
        // Handle error case
        console.error(response.errorMessage);
      }
    } catch (error) {
      // Handle other errors
      console.error(error.toString());
    }
    finally{
      setShowLoading2(false);
    }
  };
  useEffect(() => {
    const fetchEmailSubscribers = async () => {
      setShowLoading(true);
      try {
        
        const response = await emailManager.get();
        if (response?.success) {
        setEmailData(response?.data || []);}
        else{
          setShowLoading(true);
        }
      } catch (error) {
        console.error("Error fetching email subscribers:", error);
      }
      finally {
        // Set loading to false whether the request is successful or not
        setShowLoading(false);
      }
    };

    fetchEmailSubscribers();
  }, []);

  const handleUnsubscribe = async () => {
    if (!selectedEmail) {
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Error",
          body: "No Email selected",
        },
      ]);
      return;
    }

    try {
      const response = await emailManager.unsubscribe(selectedEmail);


      if (response.success === true) {
        const updatedData = emailData.filter((data) => data.email !== selectedEmail);
        setEmailData(updatedData);
        closeUnSub();
        setToastMessages([
          ...toastMessages,
          {
            type: "success",
            title: "Success",
            body: response.message,
          },
        ]); // Close the popup after successful unsubscribe
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Error",
            body: "Error Occurred",
          },
        ]);
        // Display an error message or perform other actions as needed
      }
    } catch (error) {
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Error",
          body: "Error Occurred",
        },
      ]);
    }
  };
  
  return (
    <div className="w-full" style={{ fontFamily: "Inter" }}>
       {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div></div></div>
      ) : (
      <>
      {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <div className="mt-14 flex justify-between">
        <div>
          <BackButton />
        </div>
        <div className="md:px-[10%] flex md:flex-row flex-col">
          <span onClick={() => !showLoading2 && handleDownload()}  className="text-clue-yellow mr-10 underline cursor-pointer">
                {showLoading2 ? (
              <span className="flex w-full items-center justify-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="inline md:mr-20 mr-7 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </span>
            ) : (
              <>
          
            <span className="transition-opacity hover:opacity-70"><DownloadIcon fontSize="small" />
            <span className="ml-2">Export Subscriber's List</span></span>  </>  )}
          </span>
          <span className="transition-opacity hover:opacity-70 text-clue-yellow underline cursor-pointer md:mr-0 mr-24" onClick={navigateToCompose}>
            <CreateIcon fontSize="small" />
            <span className="ml-3 mr-1">Compose Email</span>
          </span>
        </div>
      </div>
      <div className="flex ml-10 md:ml-[10%] mt-10">
        <span className="text-clue-book-title text-2xl">Email Subscribers</span>
      </div>
      <div className="overflow-x-auto mt-10 mx-10 md:ml-[10%] md:w-[90%]">
        <table className="table-fixed bg-clue-black w-[800px] md:w-[80vw] mx-10 md:mx-0 rounded text-clue-book-author">
          <thead>
            <tr className="border-b border-filter-heading">
              <th className="p-0 py-5 w-16 border-r border-filter-heading">
                #
              </th>
              <th className="p-7 py-5 w-72 border-r border-filter-heading text-left">
                Emails
              </th>
              <th className="p-7 py-5 border-filter-heading text-left">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {emailData.map((emailData, index) => (
              <tr
                key={index}
                className="border-b border-filter-heading hover:bg-black"
              >
                <td className="p-0 py-5 border-r border-filter-heading">
                  {index + 1}
                </td>

                <td className="p-7 py-5 border-r hover:bg-black border-filter-heading text-left">
                  {emailData.email}
                </td>
                
                <td className="p-7 py-5 hover:bg-black border-filter-heading text-left">
                 <p onClick={() => openUnSub(emailData.email)} className="text-clue-logout cursor-pointer"><UnsubscribeOutlinedIcon/> <span className="underline">Unsubscribe Email</span></p>
                </td>
              </tr>
            ))}
            <tr className="border-b-0 ">
              <td className="md:py-10 py-10 border-r border-filter-heading"></td>
              <td className="md:py-10 py-10 border-r border-filter-heading"></td>
              
            </tr>
          </tbody>
        </table>
      </div>
      </>)}

      
      {isUnsub && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={closeUnSub}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black rounded-3xl md:w-auto w-80  p-8 px-12 relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author md:w-auto w-60 text-left mb-4">
              Confirm
            </h3>
            <p className="text-filter-heading md:w-auto w-60 text-left">
              Are you sure you want to unsubscribe this email?
            </p>
            <div className="flex md:justify-end justify-center mt-6">
              <button
                onClick={closeUnSub}
                className="text-filter-heading transition-opacity hover:opacity-70  mr-4 border-2 rounded-[9px] border-filter-heading py-0.5 px-6"
              >
                Cancel
              </button>
              <button className="bg-clue-logout transition-opacity hover:opacity-70  text-white px-7 rounded-[9px] py-0.5 "
                onClick={handleUnsubscribe}>
                Unsubscribe
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EmailSubs;
