import React, { useEffect } from "react";

function TermsOfServiceModal({ isOpen, onClose,text }) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed top-0 left-0 w-full h-full z-50 flex items-center bg-black bg-opacity-50 justify-center"
      onClick={handleBackdropClick}
    >
      <div
        className="bg-clue-black h-[500px] md:h-[70%] rounded-lg p-8 md:w-[60%] z-50"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-md md:text-xl font-bold mb-4 text-white">Terms of Service</h2>
        <p className="overflow-y-auto text-left md:text-md text-sm px-5 h-[70%]"
         dangerouslySetInnerHTML={{ __html: text }}>
       
         
        </p>
        <button
          className="md:mt-4 mt-8 px-4 py-2 md:w-[20%] font-semibold rounded-xl bg-clue-purchase text-white hover:bg-black"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default TermsOfServiceModal;
