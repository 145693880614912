import React from 'react';
import Error_detective from '../../assets/images/error_detective.png';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';

function Handle_Error() {
  return (
    <div className="h-screen bg-black text-white flex flex-col justify-between">
  
      <div className="flex items-center justify-center mt-14 md:mt-32">
        <img
          src="https://dq6y48nvbage3.cloudfront.net/assets/error-detective.png"
          alt="Error Detective"
          className="w-40 h-40 md:w-72 md:h-72"
        />
      </div>

      <p className="mt-4 md:mt-[20px] mx-12 text-xl md:text-2xl text-center font-semibold text-clue-yellow leading-7">
        You've jumped beyond the yellow tape
      </p>

      <div className="mt-4 md:mt-[50px] mx-8 md:mb-14 mb-12 text-base md:text-xl text-center font-normal text-clue-gray leading-7">
  <p>
    The page you are looking for doesn't exist or might've been<br className="md:inline hidden" />  removed.
    
    Feeling lost? There's no place like{' '}
    <a href="/home" className="font-semibold text-clue-yellow underline hover:opacity-70">
      home
    </a>
  </p>
</div>
<div className='md:mt-[29px]'> <Footer /></div>
     
    </div>
  );
}

export default Handle_Error;
