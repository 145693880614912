import { useNavigate } from "react-router-dom";
import Clue from "../../assets/images/cluebycandlelight.png";
import Sign_In from "../../components/sign_in/sign_in";
import SignInPage from "../../components/sign_in/sign_in_page";
import { useEffect, useState } from "react";

function Entry() {
  let isAdmin = true;
  const navigate = useNavigate();

  useEffect(() => {
    const isToken = localStorage.getItem("userToken");
    if (isToken) {
      navigate("/adboard/dashboard/collection");
    }
  }, []);

  return (
    <div className="w-full" style={{ fontFamily: "Inter" }}>
      <div className="flex items-center flex-col md:flex-row justify-between mt-[2%]">
        <div
          className="lg:ml-44 md:ml-24 ml-5 mr-24 w-[25vw] hidden md:block"
          style={{
            animationName: `slideFadeInUp`,
            animationDuration: `1500ms`,
            animationTimingFunction: `ease-in-out`,
            animationFillMode: `forwards`,
          }}
        >
          <img src={Clue} alt="Clue" />
        </div>
        <div className="mr-0 md:mr-24 md:mt-0 mt-28 lg:mr-20 w-80 md:w-[42%]">
          <Sign_In isAdmin={isAdmin} />
        </div>
      </div>

      <style>
        {`
          @keyframes slideFadeInUp {
            from {
              transform: translateY(10rem);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
}

export default Entry;
