import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ParamBackButton from "./../../components/backbutton/parambackbutton.js";
import Toast from "../../components/toasts/toast";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import EntriesIdManager from "../../models/admin/entries/https/listentriesidhttp.js";
import ChangeWinner from "../../models/admin/winners/https/updatewinnerhttp.js";
import RandomWinnerManager from "../../models/admin/winners/https/randomwinnerhttp.js";
import { set } from "date-fns";
import { setId } from "@material-tailwind/react/components/Tabs/TabsContext.js";
import CulpritUpdateCompManager from "../../models/admin/competitions/https/culpritupdatehttp.js";
import DeleteWinner from "../../models/admin/winners/https/deletewinnerhttp.js";

function ViewArchiveEntries() {
  const [isDeleteBook, setIsDeleteBook] = useState(false);
  const [isSelectWinConfirm, setIsSelectWinConfirm] = useState(false);
  const [isSelect3WinConfirm, setIsSelect3WinConfirm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectWinOpen, setisSelectWinOpen] = useState(false);
  const [isSelect3WinOpen, setisSelect3WinOpen] = useState(false);
  const [toastMessages, setToastMessages] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [entry, setEntry] = useState([]);
  const [entryData, setEntryData] = useState([]);
  const [randomWinners, setRandomWinners] = useState([]);
  const [idx, setIdx] = useState([]);
  const [oneidx, setOneIdx] = useState(999999999999999);
  const [entryIdx, setEntryIdx] = useState([]);
  const [isEntry, setIsEntry] = useState(false);
  const [updatedCulprit, setUpdatedCulprit] = useState("");
  const [isDeleteWinner, setIsDeleteWinner] = useState(false);
  const [isWinner, setIsWinner] = useState(false);
  const [winners, setWinners] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const forId = searchParams.get("id");

  const entriesidManager = new EntriesIdManager();
  const winnerManager = new ChangeWinner();
  const randomManager = new RandomWinnerManager();
  const updateCulprit = new CulpritUpdateCompManager();
  const deleteWinner = new DeleteWinner();

  useEffect(() => {
    setShowLoading(true);
    entriesidManager.get(forId).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          if (value.data.winners.length > 0) {
            setIsWinner(true);
            setWinners(value.data.winners);
          }
          setUpdatedCulprit(value.data.culprit);
          setEntry(value.data.entries);
          setEntryData(value.data);
          setShowLoading(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, [isWinner]);

  const handleDeleteWinner = () => {
    deleteWinner.delete(forId).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setToastMessages([
            ...toastMessages,
            {
              type: "success",
              title: "Success",
              body: value.message,
            },
          ]);
          setIsWinner(false);
          setIsDeleteWinner(false);
        } else {
          setIsWinner(false);
          setIsDeleteWinner(false);
          setToastMessages([
            ...toastMessages,
            {
              type: "error",
              title: "Error",
              body: value.message,
            },
          ]);
          setIsDeleteWinner(false);
        }
      } else {
        setIsWinner(false);
        setIsDeleteWinner(false);
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  };

  const handleNewWinner = (index) => {
    if (entryData.is_one_winner) {
      if (index == oneidx) {
        setOneIdx(999999999999999);
        setEntryIdx([]);
        setIsEntry(false);
      } else {
        setOneIdx(index);
        setEntryIdx(entry[index]._id);
        setIsEntry(true);
      }
    } else {
      const idxExists = idx.includes(index);
      const entryIdExists = entryIdx.includes(entry[index]._id);

      let updatedIdx;
      let updatedEntryIdx;
      if (entry.length < 2) {
        updatedIdx = [...idx, index];
        updatedEntryIdx = [...entryIdx, entry[index]._id];
        setIsEntry(true);
      } else {
        updatedIdx = idxExists
          ? idx.filter((i) => i !== index)
          : [...idx, index];
        updatedEntryIdx = entryIdExists
          ? entryIdx.filter((id) => id !== entry[index]._id)
          : [...entryIdx, entry[index]._id];
        if (updatedEntryIdx == null) {
          setIsEntry(false);
        }
      }
      if (updatedEntryIdx.length > 0) {
        setIsEntry(true);
      } else {
        setIsEntry(false);
      }

      setIdx(updatedIdx);
      setEntryIdx(updatedEntryIdx);
    }
  };

  const convertDateFormat = (date) => {
    const parsedDate = new Date(date);

    if (isNaN(parsedDate)) {
      console.error("Invalid date format");
      return "";
    }

    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedStartDate = parsedDate.toLocaleDateString("en-US", options);
    return formattedStartDate;
  };

  const openModal = () => {
    setisSelectWinOpen(false);
    setisSelect3WinOpen(false);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeDeleteWinner = () => {
    setIsDeleteWinner(false);
  };

  const openSelectWin = () => {
    updateCulprit.update(forId, updatedCulprit).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          randomManager.random(forId).then((value) => {
            if (!value.error) {
              const baseResponse = value.success;
              if (baseResponse == true) {
                setRandomWinners(value.data);
                setIsModalOpen(false);
                if (entryData.is_one_winner) {
                  setisSelectWinOpen(true);
                } else {
                  setisSelect3WinOpen(true);
                }
              } else {
                setIsModalOpen(false);
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "invalid",
                    title: "Error",
                    body: value.message,
                  },
                ]);
              }
            } else {
              setIsModalOpen(false);
              setToastMessages([
                ...toastMessages,
                {
                  type: "error",
                  title: "Error",
                  body: value.error,
                },
              ]);
            }
          });
        } else {
          randomManager.random(forId).then((value) => {
            if (!value.error) {
              const baseResponse = value.success;
              if (baseResponse == true) {
                setRandomWinners(value.data);
                setIsModalOpen(false);
                if (entryData.is_one_winner) {
                  setisSelectWinOpen(true);
                } else {
                  setisSelect3WinOpen(true);
                }
              } else {
                setIsModalOpen(false);
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "invalid",
                    title: "Error",
                    body: value.message,
                  },
                ]);
              }
            } else {
              setIsModalOpen(false);
              setToastMessages([
                ...toastMessages,
                {
                  type: "error",
                  title: "Error",
                  body: value.error,
                },
              ]);
            }
          });
        }
      } else {
        setIsModalOpen(false);
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  };

  const closeSelectWin = () => {
    setisSelectWinOpen(false);
  };
  const closeSelect3Win = () => {
    setisSelect3WinOpen(false);
  };

  const openSelectWinConfirm = (entryIDS) => {
    setEntryIdx(entryIDS);
    if (entryData.is_one_winner) {
      setisSelectWinOpen(false);
      setIsSelectWinConfirm(true);
    } else {
      setisSelect3WinOpen(false);
      setIsSelect3WinConfirm(true);
    }
  };

  const handleEntryIds = (entryIDS) => {
    setEntryIdx(entryIDS);
  };

  const closeSelectWinConfirm = () => {
    setIsSelectWinConfirm(false);
  };

  const closeSelectWin3Confirm = () => {
    setIsSelect3WinConfirm(false);
  };

  const handleSelectWinConfirm = (entryid) => {
    winnerManager.put(forId, entryid).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setIsWinner(true);
          setEntryIdx([]);
          setIsEntry(false);
          closeSelectWinConfirm();
          closeSelectWin3Confirm();
          setToastMessages([
            ...toastMessages,
            {
              type: "success",
              title: "Selected",
              body: "Winner has been selected",
            },
          ]);
          setShowLoading(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  };

  // const [showSuccessToast, setShowSuccessToast] = useState(false);
  // const successToast = [
  //     {
  //         type: "success",
  //         title: "Selected",
  //         body: "Winner has been selected",
  //     },
  // ];

  const filteredBookData = entry.filter((book) => {
    // Filter by search term in username
    if (
      searchTerm &&
      !book.username.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  const handleButtonClick = (index, buttonIndex) => {
    // Create a copy of entryIdx to avoid mutating the state directly
    const updatedEntryIdx = [...entryIdx];

    // Find the index of the current entry ID in the array
    const currentIndex = updatedEntryIdx.indexOf(entry[index]._id);

    // Move the current entry ID to the specified buttonIndex
    if (currentIndex !== -1) {
      updatedEntryIdx.splice(currentIndex, 1);
      updatedEntryIdx.splice(buttonIndex, 0, entry[index]._id);
    }

    // Update the state using setEntryIdx
    setEntryIdx(updatedEntryIdx);
  };

  return (
    <div
      className="w-full  xl:overflow-y-hidden"
      style={{ fontFamily: "Inter" }}
    >
      <div className=" md:mt-14 mt-14">
        <ParamBackButton />
      </div>
      <div className="ml-0 md:ml-16 xl:ml-48">
        <div class="flex md:flex-row flex-col md:items-center justify-between mt-5 md:mt-10">
          <div className="flex">
          <p class="text-clue-purchase font-bold md:ml-0 ml-10 text-[24px] md:text-[28px] text-left">
            Entries For Title
          </p>
          </div>
          <div className="flex md:flex-row flex-col md:w-[69%] items-end md:mr-0 mr-10 md:ml-0 ml-10 md:mt-0 mt-10">
          {isWinner && (
            <a
              class="cursor-pointer md:text-[16px] text-clue-logout md:ml-52 md:mr-[5%] transition-opacity hover:opacity-70 underline"
              onClick={() => setIsDeleteWinner(true)}
            >
              Remove Winners
            </a>
          )}
          {isWinner ? (
            <a class="cursor-pointer md:text-[16px] text-clue-gray md:ml-auto transition-opacity hover:opacity-70 underline">
              Select Randomly
            </a>
          ) : (
            <a
              onClick={openModal}
              class="cursor-pointer md:text-[16px] text-clue-yellow md:ml-auto transition-opacity hover:opacity-70 underline"
            >
              Select Randomly
            </a>
          )}

          <a
            href="#"
            class="md:text-[16px] text-clue-yellow lg:mr-16 md:mr-8 xl:mr-48 md:pl-12 underline transition-opacity hover:opacity-70"
          >
            Export Entries
          </a>
          </div>
        </div>

        <div className="md:mt-14 mt-10 md:w-[90%] mx-9 md:mx-0 flex flex-col items-start relative">
          <div className="flex w-full md:w-[65%] items-center">
            <div className="flex-grow flex items-center bg-clue-black rounded-xl p-2">
              <SearchIcon className="text-clue-yellow m-2" />
              <input
                type="text"
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search Users"
                className="w-full bg-clue-black text-white placeholder-white border-none outline-none focus:ring-0"
              />
            </div>
          </div>
        </div>
      </div>
      {toastMessages.map((toast, index) => (
        <Toast
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-20 left-0">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="md:overflow-x-auto xl:overflow-x-hidden overflow-x-auto  mt-10 mx-10 md:ml-16 md:mx-10 lg:mr-16 xl:mx-48 lg:w-auto md:w-auto  xl:w-auto mb-10">
          <table className="table-fixed min-w-full bg-clue-black w-[800px] md:w-[50vw] md:mx-0   rounded text-clue-book-author">
            <thead>
              <tr className="border-b border-filter-heading">
                <th className="p-0 py-5  border-r border-filter-heading">#</th>
                <th className="p-2 py-5 border-r border-filter-heading ">
                  Username
                </th>
                <th className="p-2 py-5  border-r border-filter-heading">
                  Email
                </th>
                <th className="p-2 py-5  border-r border-filter-heading">
                  Bought On
                </th>
                <th className="p-2 py-5 border-r border-filter-heading">
                  Submitted On
                </th>
                <th className="p-2 py-5 border-r border-filter-heading">
                  Guess
                </th>
                <th className="p-2 py-5 border-filter-heading">
                  Select Winner
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredBookData.map((book, index) => (
                <tr
                  key={index}
                  className="border-b border-filter-heading hover:bg-black"
                >
                  <td className="p-0 py-5 border-r border-filter-heading">
                    {index + 1}
                  </td>
             
                  <td style={{ wordWrap: 'break-word' }} className=" w-full h-full overflow-hidden overflow-ellipsis p-2 py-5 border-r hover:bg-black border-filter-heading">
                    {book.username}
                  </td>
                  <td className="p-2 py-5 whitespace-normal break-words border-r border-filter-heading">
                    {book.email}
                  </td>
                  <td className="p-2 py-5  border-r border-filter-heading">
                    {convertDateFormat(book.bought_on)}
                  </td>
                  <td className="p-2 py-5  border-r border-filter-heading">
                    {convertDateFormat(book.submitted_on)}
                  </td>

                  <td className={`p-2 py-5 border-r border-filter-heading`}>
                    {book.is_culprit_match == true ? (
                      <span className="font-[500px] text-[16px] text-clue-restore">
                        Correct
                      </span>
                    ) : (
                      <span className="font-[500px] text-[16px] text-clue-logout">
                        Wrong
                      </span>
                    )}
                  </td>
                  <td className="p-2 py-5  border-filter-heading">
                    {isWinner ? (
                      <button
                        className={`transition-opacity hover:opacity-70 text-white px-4 md:px-6 lg:px-6 xl:px-8 py-1.5 rounded-lg bg-clue-gray`}
                      >
                        Select
                      </button>
                    ) : (
                      <button
                        className={`transition-opacity hover:opacity-70 text-white px-4 md:px-6 lg:px-6 xl:px-8 py-1.5 rounded-lg ${
                          entryData.is_one_winner
                            ? oneidx == index
                              ? "bg-clue-gray"
                              : "bg-onclickfield"
                            : idx.includes(index)
                            ? "bg-clue-gray"
                            : "bg-onclickfield"
                        }`}
                        onClick={() => handleNewWinner(index)}
                      >
                        Select
                      </button>
                    )}
                  </td>
                </tr>
              ))}
              <tr className="border-b-0 ">
                <td className="md:py-24 py-10 border-r border-filter-heading"></td>
                <td className="md:py-24 py-10 border-r border-filter-heading"></td>
                <td className="md:py-24 py-10 border-r border-filter-heading"></td>
                <td className="md:py-24 py-10 border-r border-filter-heading"></td>
                <td className="md:py-24 py-10 border-r border-filter-heading"></td>
                <td className="md:py-24 py-10 border-r border-filter-heading"></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div className="md:mt-0 mt-40"></div>
      {isWinner && (
        <div className="fixed bottom-0 mt-10 bg-clue-purchase w-full italic flex flex-col md:flex-row rounded-xl py-3 px-5">
          <span className="flex-1 text-left md:mx-[5%] text-white text-sm">
            <p>
              Competition winners are:{" "}
              <span className="text-black font-bold">
                {winners.map((item) =>
                  item.user_id ===
                  entry.find((winner) => winner.user_id === item.user_id)
                    ?.user_id
                    ? entry.find((winner) => winner.user_id === item.user_id)
                        ?.username +
                      String.fromCharCode(160) +
                      String.fromCharCode(160) +
                      String.fromCharCode(160)
                    : ""
                )}
              </span>
            </p>
          </span>
        </div>
      )}
      {isEntry ? (
        <div className="fixed bottom-0 mt-10 bg-clue-purchase w-full italic flex flex-col md:flex-row rounded-xl py-3 px-5">
          <span className="flex-1 text-left md:mx-[5%] text-white text-sm">
            <p>
              You've selected{" "}
              <span className="text-black font-bold">
                {entryData.is_one_winner
                  ? entry.map((entry1) =>
                      entryIdx == entry1._id ? entry1.username : ""
                    )
                  : entry.map((entry1) =>
                      entryIdx.includes(entry1._id)
                        ? entry1.username +
                          String.fromCharCode(160) +
                          String.fromCharCode(160) +
                          String.fromCharCode(160)
                        : null
                    )}
              </span>
            </p>
            <p>
              {entryData.is_one_winner
                ? "You have selected a winner, update to choose as winner"
                : `Select ${
                    3 - entryIdx.length
                  } more to update the winners for this book.`}
            </p>
          </span>
          <button
            className="bg-clue-black font-semibold text-white rounded-xl mx-[20%] md:mx-36 md:mt-0 mt-4 md:py-0 py-2 w-52 md:w-80 text-center"
            onClick={() => handleSelectWinConfirm(entryIdx)}
          >
            Update Winners
          </button>
        </div>
      ) : (
        <div></div>
      )}

      {isModalOpen && (
        <div
          className="fixed  inset-0 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black p-8 md:w-auto w-80 px-12 rounded-[30px] relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author w-60 md:w-96   text-left text-[18px] mb-6">
              Select Winner
            </h3>
            <p className=" w-60 md:w-96 text-left  text-[16px]  font-[500] text-filter-heading">
              This dialog will give you a randomly selected name from the
              entries of the users that guessed correctly
            </p>
            <br />
            <p className="md:w-96 w-60 text-left text-[16px]  font-[500] text-filter-heading">
              This was the suspect that you marked as the culprit for this book,
              you have the option to update it before selecting a winner
            </p>
            <br />
            <select
              className="mb-4 w-60 md:w-80 py-4 pl-5 bg-clue-black text-white p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
              onChange={(e) => setUpdatedCulprit(e.target.value)}
            >
              {entryData.suspects.map((suspect, index) => (
                <option key={index} value={suspect}>
                  {suspect}
                </option>
              ))}
            </select>
            <br />
            <div className="flex justify-end mt-6">
              <button
                onClick={closeModal}
                className="transition-opacity hover:opacity-70  text-filter-heading mr-4 border-filter-heading rounded-[9px]  border-solid border px-4 md:h-8"
              >
                Cancel
              </button>
              <button
                onClick={openSelectWin}
                className="transition-opacity hover:opacity-70  bg-clue-purchase text-white py-1 md:py-[-2px] rounded-[9px]  px-6 md:h-8"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      {isSelectWinOpen && (
        <div
          className="fixed  inset-0 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black p-8 px-12 md:w-[450px] w-80 rounded-[30px] relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author w-60 md:w-auto text-left text-[18px] mb-6">
              Select Winner
            </h3>
            <p className="md:w-[360px] w-60 text-left font-[500]  text-filter-heading">
              The randomly selected winner from the entries on the basis of the
              culprit is
            </p>
            <br />
            <input
              type="text"
              className="md:w-80 w-60 mb-4 pointer-events-none select-none py-3 bg-clue-black text-white pl-4  rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
              placeholder={randomWinners[0].username}
            />
            <br />
            <div className="flex justify-end mt-6 md:w-96 w-60">
              <button
                onClick={closeSelectWin}
                className="transition-opacity hover:opacity-70  text-filter-heading md:mr-4 mr-4 rounded-[9px] border-filter-heading border-solid border px-2 md:px-4 md:h-8"
              >
                Cancel
              </button>
              <button
                onClick={openModal}
                className="transition-opacity hover:opacity-70  text-filter-heading md:mr-4 mr-4 rounded-[9px]  border-solid border border-filter-heading px-3 md:px-5 md:h-8"
              >
                Again
              </button>
              <button
                onClick={() => openSelectWinConfirm(randomWinners[0]._id)}
                className="transition-opacity hover:opacity-70 md:mr-4 bg-clue-purchase text-white  py-1 md:py-[-2px] rounded-[9px] px-4 md:px-6 md:h-8"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
      {/* For 3 winners */}
      {isSelect3WinOpen && (
        <div
          className="fixed  inset-0 flex items-center justify-center z-50"
          onClick={closeSelect3Win}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black p-8 px-12 md:w-[450px] w-80 rounded-[30px] relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author w-60 md:w-auto text-left text-[18px] mb-6">
              Select Winner
            </h3>
            <p className="md:w-[360px] w-60 text-left font-[500]  text-filter-heading">
              The randomly selected winner from the entries on the basis of the
              culprit is
            </p>
            <br />
            <input
              type="text"
              className="md:w-80 w-60 mb-4 pointer-events-none select-none py-3 bg-clue-black text-white pl-4  rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
              placeholder={randomWinners[0].username}
            />
            <input
              type="text"
              className="md:w-80 w-60 mb-4 pointer-events-none select-none py-3 bg-clue-black text-white pl-4  rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
              placeholder={randomWinners[1].username}
            />
            <input
              type="text"
              className="md:w-80 w-60 mb-4 pointer-events-none select-none py-3 bg-clue-black text-white pl-4  rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
              placeholder={randomWinners[2].username}
            />
            <br />
            <div className="flex justify-end mt-6 md:w-96 w-60">
              <button
                onClick={closeSelect3Win}
                className="transition-opacity hover:opacity-70  text-filter-heading md:mr-4 mr-4 rounded-[9px] border-filter-heading border-solid border px-2 md:px-4 md:h-8"
              >
                Cancel
              </button>
              <button
                onClick={openModal}
                className="transition-opacity hover:opacity-70  text-filter-heading md:mr-4 mr-4 rounded-[9px]  border-solid border border-filter-heading px-3 md:px-5 md:h-8"
              >
                Again
              </button>
              <button
                onClick={() =>
                  openSelectWinConfirm(
                    randomWinners.map((winner) => winner._id)
                  )
                }
                className="transition-opacity hover:opacity-70 md:mr-4 bg-clue-purchase text-white  py-1 md:py-[-2px] rounded-[9px] px-4 md:px-6 md:h-8"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      {isSelectWinConfirm && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={closeSelectWinConfirm}
        >
          <div className="bg-black opacity-50 absolute inset-0 rounded-[30px]"></div>
          <div
            className="bg-clue-black p-8 px-12 md:w-auto w-80 rounded-lg relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author w-60 md:w-auto text-left  mb-6 text-[18px]">
              Confirm
            </h3>
            <p className="text-filter-heading text-left w-60 md:w-96  ">
              This action is irreversible, are you sure you want to update{" "}
              {entry.map((entry1) => (
                <b className="text-clue-yellow">
                  {entryIdx == entry1._id ? entry1.username : ""}
                </b>
              ))}{" "}
              as the winner site-wide?{" "}
            </p>
            <div className="flex justify-end mt-6">
              <button
                onClick={closeSelectWinConfirm}
                className="transition-opacity hover:opacity-70 rounded-[9px]  border-solid border border-filter-heading px-5 md:h-8 text-filter-heading mr-4"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSelectWinConfirm(entryIdx)}
                className="transition-opacity hover:opacity-70 rounded-[9px] px-7 md:h-8  bg-clue-logout text-white  py-1 "
              >
                Select
              </button>
            </div>
          </div>
        </div>
      )}

      {/* For 3 winners */}
      {isSelect3WinConfirm && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={closeSelectWin3Confirm}
        >
          <div className="bg-black opacity-50 absolute inset-0 rounded-[30px]"></div>
          <div
            className="bg-clue-black p-8 px-12 md:w-auto w-80 rounded-lg relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author w-60 md:w-auto text-left  mb-6 text-[18px]">
              Confirm
            </h3>
            <p className="text-filter-heading text-left w-60 md:w-96 mb-4">
              This action is irreversible, are you sure you want to update
              following as the winners site-wide?{" "}
            </p>
            {entry.map((entry1, index) =>
              entryIdx.includes(entry1._id) ? (
                <p
                  key={index}
                  className="text-filter-heading text-left w-60 md:w-96"
                >
                  <button
                    onClick={() => handleButtonClick(index, 0)}
                    className={`transition-opacity hover:opacity-70 text-white rounded-lg border-2 w-14 my-1 mx-1 ${
                      entryIdx[0] == entry1._id
                        ? "bg-onclickfield"
                        : "border-clue-gray "
                    }`}
                  >
                    1st
                  </button>
                  <button
                    onClick={() => handleButtonClick(index, 1)}
                    className={`transition-opacity hover:opacity-70 text-white rounded-lg border-2 border-clue-gray w-14 my-1 mx-1 ${
                      entryIdx[1] == entry1._id
                        ? "bg-onclickfield"
                        : "border-clue-gray "
                    }`}
                  >
                    2nd
                  </button>
                  <button
                    onClick={() => handleButtonClick(index, 2)}
                    className={`transition-opacity hover:opacity-70 text-white rounded-lg border-2 border-clue-gray w-14 my-1 ml-1 mr-2 ${
                      entryIdx[2] == entry1._id
                        ? "bg-onclickfield"
                        : "border-clue-gray "
                    }`}
                  >
                    3rd
                  </button>
                  <b className="text-clue-yellow">{entry1.username}</b>{" "}
                </p>
              ) : null
            )}

            <div className="flex justify-end mt-6">
              <button
                onClick={closeSelectWin3Confirm}
                className="transition-opacity hover:opacity-70 rounded-[9px]  border-solid border border-filter-heading px-5 md:h-8 text-filter-heading mr-4"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSelectWinConfirm(entryIdx)}
                className="transition-opacity hover:opacity-70 rounded-[9px] px-7 md:h-8  bg-clue-logout text-white  py-1 "
              >
                Select
              </button>
            </div>
          </div>
        </div>
      )}
      {isDeleteWinner && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={closeDeleteWinner}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black rounded-3xl md:w-auto w-80 p-8 px-12 relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
              Delete Winners
            </h3>
            <p className="text-filter-heading text-left">
              Are you sure you want to proceed? Continuing will delete all the
              winners of this book.
            </p>
            <div className="flex justify-end mt-6">
              <button
                onClick={closeDeleteWinner}
                className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
              >
                Cancel
              </button>
              <button
                className="bg-clue-logout text-white px-4 py-2 rounded"
                onClick={handleDeleteWinner}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default ViewArchiveEntries;
