import React, { useState, useRef,useEffect } from "react";
import BackButton from "../backbutton/parambackbutton.js";
import Footer from "../footer/footer.js";
import suspect from '../../assets/images/suspect.jpg';
import { Link,useNavigate,useLocation } from "react-router-dom";
import WebFreshUserManager from "../../models/website/auth/https/webfreshuserhttp.js";
import BookByIDList from "../../models/website/competitions/https/compbookidhttp.js";
import CreateEntry from "../../models/website/entries/https/entryhttp.js";
import { select } from "@material-tailwind/react";
function SubmissionPage() {
    const bookByIDList = new BookByIDList();
    const createEntry = new CreateEntry();
    const [isSelectWinConfirm, setIsSelectWinConfirm] = useState(false);
    const [selectedSuspect, setSelectedSuspect] = useState("");
    const [suspectsArray, setSuspectsArray] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("book_id")
    
  const [freshUserData, setFreshUserData] = useState(null);
  const [bookDetails, setBookDetails] = useState(null);
  
    const openSelectWinConfirm = () => {
      
        setIsSelectWinConfirm(true);
    };
    const [showLoading, setShowLoading] = useState(false);
    const navigate = useNavigate();
    const closeSelectWinConfirm = () => {
        setIsSelectWinConfirm(false);
    };

    const handleSelectWinConfirm = async () => {
        try {
          // Assuming `selectedSuspect` and `id` are defined
          const entry = await createEntry.post(id, selectedSuspect);
      
          if (entry && entry.success) {
            // Entry submission was successful
            closeSelectWinConfirm();
            navigate(`/account/dashboard/book/submission/success?id=${id}`);
            // Add a new toast message or any other UI update
          } else {
            // Entry submission failed
            closeSelectWinConfirm();
            console.error("Entry submission failed:", entry);
            // Handle the failure, show an error message, etc.
          }
        } catch (error) {
          // Handle other errors, show an error message, etc.
          console.error("Error submitting entry:", error);
        }
      };

   
    const chunk = (arr, size) => {
        let result = [];
        for (let i = 0; i < arr.length; i += size) {
            result.push(arr.slice(i, i + size));
        }
        return result;
    };
   useEffect(() => {
    const fetchBookDetails = async () => {
      if (!id) {
        navigate("/beyond-the-yellow-tape");
        return;
      }
      setShowLoading(true);
      try {
        const bookDetailsResponse = await bookByIDList.get(id);
        const entrystepResponse=await createEntry.get(id);
        if (bookDetailsResponse.success) {
          setBookDetails(bookDetailsResponse);
          if (entrystepResponse.success) {
          const { next_step } = entrystepResponse.data;

          if (next_step === "end-date-passed") {
            navigate(`/account/dashboard/book/submission/solved?id=${id}`);
            return;
          }
          
          if (next_step === "already-entered") {
            navigate(`/account/dashboard/book/submission/closed?id=${id}`, {
              state: { selectedSuspected: selectedSuspect },
            });
            return;
          }
          if (next_step === "move-to-submission") {
               const { suspects } = bookDetailsResponse.data;

          // Set the suspects array in state
          setSuspectsArray(suspects);
            return;
          }
          // Extract suspects array directly from the received data
       
        }
        else {
          setShowLoading(true);
          navigate("/beyond-the-yellow-tape");
        }} else {
          setShowLoading(true);
          navigate("/beyond-the-yellow-tape");
        }

      } catch (error) {
        navigate("/beyond-the-yellow-tape");
      }
      finally {
        // Set loading to false whether the request is successful or not
        setShowLoading(false);
      }
    };
    
    
    
  
    fetchBookDetails();
  }, [id]);
    
    const chunkedSuspects = chunk(suspectsArray, 5);


    return (
        <div className="h-screen " style={{ fontFamily: "Inter" }}>
            {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div></div></div>
      ) : (
            <div className="flex flex-col md:flex-row md:items-center md:mt-10 xl:mt-5 lg:mt-5">
                <BackButton className="mb-0 md:mb-0" />
                <div className="text-left md:text-left space-y-1 mt-[-30px] md:mt-0 ml-20 md:ml-8">
                {bookDetails && bookDetails.data && (
              <>
                    <div className=" text-xl md:text-[30px] font-[700] text-clue-book-title">
                    {bookDetails.data.title}
                    </div>
                    <div className="text-sm md:text-lg text-clue-book-author">
                    {bookDetails.data.author}
                    </div>
                    </>
            )}
                </div>
            </div> )}
            {!showLoading && (
            <div className="xl:mt-24 md:mt-36 lg:mt-10 mt-10 text-center flex justify-center items-center">
                <p className=" text-clue-book-desc text-center font-[400] xl:w-[40%] italic lg:w-[62%] w-[80%] md:w-[80%] md:mx-14">
                    You have been reading a Clue by Candlelight mystery. The time has come to test your sleuthing skills. Select the culprit from the suspects below to go into the draw for the cash prize.
                </p>
            </div>)}
            {!showLoading && (
            <div className="flex flex-col md:flex-row xl:w-[80%] mt-10  md:mt-6 justify-center items-center">
                <img
                    src="https://dq6y48nvbage3.cloudfront.net/assets/suspect.jpg"
                    alt="Error Detective"
                    className="w-40 h-40 xl:ml-[20%] lg:ml-[4%] ml-[2%] md:w-60 md:h-60"
                />
                <div className="lg:ml-10 md:ml-10 xl:ml-16 ml-1 mt-5 md:mt-6  flex">
                    {chunkedSuspects.map((suspectChunk, idx) => (
                        <div key={idx} className="flex flex-col ">
                            {suspectChunk.map((suspect, index) => (
                                <label key={index} className=" flex-wrap my-2 text-start xl:px-5 lg:px-6  mx-1 md:px-5 xl:text-[16px] text-[14px] text-white">
                                    <input type="radio" name="selectedSuspect" value={suspect} onClick={() => setSelectedSuspect(suspect)} className=" mr-2 lg:mr-5 md:mr-5 xl:mr-8 text-clue-purchase  focus:outline-none outline-none" />

                                    {suspect}
                                </label>
                            ))}
                        </div>
                    ))}
                </div>
            </div>)}
            {!showLoading && (
            <div className="md:ml-0 md:mt-5 xl:mt-6 mt-10 text-center">
                <button className="transition-opacity hover:opacity-70 bg-clue-purchase text-white md:px-28 px-20 py-3 rounded-[10px] text-lg"  onClick={openSelectWinConfirm}>Submit</button>
            </div>)}
            {!showLoading && (
            <div className="lg:mt-10 xl:mb-28 xl:mt-6 md:mt-6 mt-10 text-center flex justify-center items-center">
                <p className="text-clue-book-desc text-center w-[80%] xl:w-[40%] font-[400] italic lg:w-[70%] md:w-[80%] md:mx-14">
                The winner and final chapter revealing the outcome will be emailed upon completion of the competition. Good luck!</p>
            </div>)}
            {isSelectWinConfirm && (
                <div
                    className="fixed inset-0 rounded-[30px] flex items-center justify-center z-50"
                    onClick={closeSelectWinConfirm}
                >
                    <div className="bg-black opacity-50 absolute inset-0 rounded-[30px]"></div>
                    <div
                        className="bg-clue-black md:w-auto w-80 p-8 px-12 rounded-lg relative z-10"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h3 className="text-clue-book-author ml-[0px] md:ml-[0px] text-left mb-6 text-[18px]">
                            Confirm Submission
                        </h3>
                        <p className="text-filter-heading text-left w-60 md:w-96 ">
                        Last chance. Once submitted this action cannot be undone. If you're really sure<b className="text-clue-yellow"> {selectedSuspect}</b> is the culprit, then go ahead and click that submit button. Good luck!   </p>
                        <div className="flex justify-end mt-6">
                            <button
                                onClick={closeSelectWinConfirm}
                                className="transition-opacity hover:opacity-70 rounded-[9px]  border-solid border border-filter-heading px-5 md:h-8 text-filter-heading mr-4"
                            >
                                Cancel
                            </button>
              
                            <button  onClick={handleSelectWinConfirm} className="transition-opacity hover:opacity-70 rounded-[9px] px-7 md:h-8  bg-onclickfield text-white  py-1 ">
                                Submit

                            </button>
                            
                        </div>
                    </div>
                </div>
            )} 
            {!showLoading && (
            <div>
            <div className='lg:mt-[29px] md:mt-[55%] xl:mt-[29px] mt-[15%] md:bottom-0'> <Footer /></div>

</div> )}
        </div>
    );
}

export default SubmissionPage;
