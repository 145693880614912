import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Vector from "../../assets/images/Vector.png";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import GradientIcon from "@mui/icons-material/Gradient";
import CloseIcon from "@mui/icons-material/Close";
import BackButton from "../../components/backbutton/backbutton";
import CollectionIdManager from "../../models/admin/competitions/https/getcollectionidhttp";
import ParamBackButton from "../../components/backbutton/parambackbutton";
import Toast from "../../components/toasts/toast";
import AllUsersManager from "../../models/admin/users/https/getallusershttp";
import AddContributionManager from "../../models/admin/contributions/https/createconthttp";
import ContributionIdManager from "../../models/admin/contributions/https/getcontbyidhttp";
import UpdateContributionManager from "../../models/admin/contributions/https/updateconthttp";
import DeleteContManager from "../../models/admin/contributions/https/deleteconthttp";
import DeleteWinner from "../../models/admin/winners/https/deletewinnerhttp";
import GetWinner from "../../models/admin/winners/https/getwinnerbyidhttp";

import PromoCode from "../../models/admin/promocode/http/promohttp";
function ArchivedCollectionCompEdit() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const forId = searchParams.get("id");
  const collectionidManager = new CollectionIdManager();
  const [showLoading, setShowLoading] = useState(false);

  const convertDateFormat = (startDate) => {
    const parsedDate = new Date(startDate);

    if (isNaN(parsedDate)) {
      console.error("Invalid date format");
      return "";
    }

    // Use toLocaleDateString to format the date as YYYY-MM-DD
    const formattedStartDate = parsedDate.toLocaleDateString("en-CA");
    return formattedStartDate;
  };

  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSusOpen, setIsSusOpen] = useState(false);
  const [isDeleteCont, setIsDeleteCont] = useState(false);
  const [isDeleteSus, setIsDeleteSus] = useState(false);
  const [isDeleteWinner, setIsDeleteWinner] = useState(false);
  const [isFile, setIsFile] = useState();
  const [fileSize, setFileSize] = useState("");
  const [imageSize, setImageSize] = useState("");
  const [isImage, setIsImage] = useState();
  const [isPromo, setIsPromo] = useState(false);
  const [is3Winner, setIs3Winner] = useState(false);
  const [is1Winner, setIs1Winner] = useState(true);
  const [suspectName, setSuspectName] = useState("");
  const [forIdx, setIdx] = useState(0);
  const [ContIdx, setContIdx] = useState(0);
  const [EditContIdx, setEditContIdx] = useState();
  const [toastMessages, setToastMessages] = useState([]);
  const [fullFile1, setFullFile] = useState();
  const [fullImage1, setFullImage] = useState();
  const [printsus, setPrintsus] = useState(false);
  const [winnerData, setWinnerData] = useState([]);

  const [codeName, setCodeName] = useState("");
  const [discount, setDiscount] = useState("");
  const [iscodenamechanged, setIscodenamechanged] = useState(false);
  const [isdiscountchanged, setIsdiscountchanged] = useState(false);
  //FORM VALUES

  const [title1, setTitle] = useState("");
  const [description1, setDescription] = useState("");
  const [startDate1, setStartDate] = useState();
  const [endDate1, setEndDate] = useState();
  const [prizeAmount1, setPrizeAmount] = useState("");
  const [culprit1, setCulprit] = useState("");
  const [isVisible1, setIsVisible] = useState("");
  const [under18, setUnder18] = useState("");
  const [suspects1, setSuspects] = useState([]);
  const [author1, setAuthor] = useState("");
  const [amount1, setAmount] = useState("");
  const [linkToAuthor1, setLinkToAuthor] = useState("");
  const [contributionsEdit, setContributionsEdit] = useState([]);
  const [contributions, setContributions] = useState([]);

  //CONTRIBUTIONS

  const [userid, setUserId] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const [percentage, setPercentage] = useState("");
  const [bookId, setBookId] = useState("");

  const allusersManager = new AllUsersManager();
  const [users, setUsers] = useState([]);

  const contIdManager = new ContributionIdManager();
  const addcontManager = new AddContributionManager();
  const updatecontManager = new UpdateContributionManager();
  const deletecontManager = new DeleteContManager();
  const deleteWinner = new DeleteWinner();
  const getWinner = new GetWinner();
  useEffect(() => {
    const fetchPromoInfo = async () => {
      try {
        const promoCode = new PromoCode();
        const response = await promoCode.posttoget(forId);

        if (response.success === true) {
          setIsPromo(true); // Set isPromo to true if promo is active
          setCodeName(response.data.text || "");
          setDiscount(response.data.discount || "");
        }
      } catch (error) {
        console.error("Error getting promo code information:", error);
      }
    };

    fetchPromoInfo();
  }, [forId]);
  const handleCodeNameChange = (e) => {
    setCodeName(e.target.value);
    setIscodenamechanged(true);
  };

  const handleDiscountChange = (e) => {
    setDiscount(e.target.value);
    setIsdiscountchanged(true);
  };

  useEffect(() => {
    setShowLoading(true);
    allusersManager.get(true).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setUsers(value.data);
          setShowLoading(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    setShowLoading(true);
    contIdManager.get(forId).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setContributionsEdit(value.data);
          setShowLoading(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, [contributions]);

  useEffect(() => {
    setShowLoading(true);
    getWinner.get(forId).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setWinnerData(value.data);
          setShowLoading(false);
        } else {
        }
      } else {
      }
    });
  }, [contributions]);

  const handleAddContribution = () => {
    const newContribution = {
      user_id: userid,
      percentage: percentage,
      is_owner: isOwner,
      book_id: bookId,
    };

    setContributions((prevContributions) => [
      ...prevContributions,
      newContribution,
    ]);

    setUserId("");
    setIsOwner(false);
    setPercentage("");
    setBookId("");
  };

  const onUserIdChange = (e) => {
    setUserId(e.target.value);
  };

  const onContributorChange = (e) => {
    setIsOwner(e.target.value === "true");
  };

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
  };

  const addContributor = () => {
    handleAddContribution();
    closeModal();
  };

  const handleContDelete = () => {
    //WORK HERE
    const id = contributionsEdit[EditContIdx]._id;
    deletecontManager.delete(id).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setShowLoading(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
    contributionsEdit.splice(EditContIdx, 1);
    closeDeleteCont();
  };

  useEffect(() => {
    setIs1Winner(!is3Winner);
  }, [is3Winner]);

  const navigate = useNavigate();
  useEffect(() => {
    setShowLoading(true);
    collectionidManager.get(forId).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setFullFile(value.data.book);
          setFullImage(value.data.cover);
          setTitle(value.data.title);
          setDescription(value.data.description);
          setStartDate(convertDateFormat(value.data.start_date));
          setEndDate(convertDateFormat(value.data.end_date));
          setPrizeAmount(value.data.prize_amount);
          setCulprit(value.data.culprit);
          setIsVisible(value.data.is_visible_on_product_page);
          setUnder18(value.data.under_18_appropriate);
          setSuspects(value.data.suspects);
          setAuthor(value.data.author);
          setAmount(value.data.amount);
          setLinkToAuthor(value.data.link_to_author);
          setPrintsus(true);
          const maxFileNameLength = 30;
          if (value.data.book) {
            if (value.data.book.length > maxFileNameLength) {
              const truncatedFileName = value.data.book.substring(
                0,
                maxFileNameLength - 1
              );
              setIsFile(truncatedFileName + "...");
            }
          } else {
            setIsFile(value.data.book);
          }
          if (value.data.cover) {
            if (value.data.cover.length > maxFileNameLength) {
              const truncatedImageName = value.data.cover.substring(
                0,
                maxFileNameLength - 1
              );
              setIsImage(truncatedImageName + "...");
            }
          } else {
            setIsImage(value.data.book);
          }
          setIs3Winner(!value.data.is_one_winner);
          setShowLoading(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    const updatedSusData = suspects1.map((suspectName) => ({
      name: suspectName,
      culprit: suspectName == culprit1 ? true : false,
    }));

    setSusData((prevSusData) => {
      const newSusData = [...prevSusData, ...updatedSusData];
      setData(newSusData);
      return newSusData;
    });
  }, [printsus]);

  const addContribution = () => {
    setShowLoading(true);
    addcontManager.add(userid, percentage, isOwner, forId).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setShowLoading(false);
          addContributor();
          setToastMessages([
            ...toastMessages,
            {
              type: "success",
              title: "Success",
              body: value.message,
            },
          ]);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  };

  const updateContributor = () => {
    contributionsEdit[EditContIdx].user_id = userid;
    contributionsEdit[EditContIdx].percentage = percentage;
    contributionsEdit[EditContIdx].is_owner = isOwner;

    setUserId("");
    setIsOwner(false);
    setPercentage("");
    setBookId("");
  };

  const updateContribution = () => {
    const id = contributionsEdit[EditContIdx]._id;
    setShowLoading(true);
    updatecontManager
      .update(id, userid, percentage, isOwner, forId)
      .then((value) => {
        if (!value.error) {
          const baseResponse = value.success;
          if (baseResponse == true) {
            setShowLoading(false);
            setToastMessages([
              ...toastMessages,
              {
                type: "success",
                title: "Success",
                body: value.message,
              },
            ]);
            updateContributor();
            closeEditModal();
          } else {
            setToastMessages([
              ...toastMessages,
              {
                type: "invalid",
                title: "Error",
                body: value.message,
              },
            ]);
          }
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "error",
              title: "Error",
              body: value.error,
            },
          ]);
        }
      });
  };

  const navigateToPreview = () => {
    if (validateInfo() == true) {
      navigate(`/adboard/dashboard/collection/info/edit/preview?id=${forId}`, {
        state: {
          fullFile1,
          title1,
          description1,
          startDate1,
          endDate1,
          prizeAmount1,
          culprit1,
          isVisible1,
          under18,
          suspects1,
          fullImage1,
          author1,
          amount1,
          linkToAuthor1,
          is1Winner,
          contributionsEdit,
          codeName,
          discount,
          isPromo,
          iscodenamechanged,
          isdiscountchanged,
        },
      });
    } else {
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Missing Values",
          body: "Please enter all the values",
        },
      ]);
    }
  };

  const openDeleteWinner = () => {
    setIsDeleteWinner(true);
  };

  const closeDeleteWinner = () => {
    setIsDeleteWinner(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openDeleteCont = (index) => {
    setEditContIdx(index);
    setIsDeleteCont(true);
  };

  const closeDeleteCont = () => {
    setIsDeleteCont(false);
  };

  const openDeleteSus = (idx) => {
    setIdx(idx);
    setIsDeleteSus(true);
  };

  const openEditModal = (idx) => {
    setEditContIdx(idx);
    setPercentage(contributionsEdit[idx].percentage);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const closeDeletesus = () => {
    setIsDeleteSus(false);
  };
  const openSus = () => {
    setIsSusOpen(true);
  };

  const closeSus = () => {
    setIsSusOpen(false);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDeleteWinner = () => {
    deleteWinner.delete(forId).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setIs3Winner(!is3Winner);
          setIsDeleteWinner(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "error",
              title: "Error",
              body: value.message,
            },
          ]);
          if (value.message == "No winners found for the specified bookid") {
            setIs3Winner(!is3Winner);
          }
          setIsDeleteWinner(false);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
        setIsDeleteWinner(false);
      }
    });
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleStartChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndChange = (e) => {
    setEndDate(e.target.value);
  };

  const handlePrizeChange = (e) => {
    setPrizeAmount(e.target.value);
  };

  const handleCulpritChange = (e) => {
    setCulprit(e.target.value);
  };

  const handleVisibleChange = (e) => {
    setIsVisible(!isVisible1);
  };

  const handleUnder18Change = (e) => {
    setUnder18(!under18);
  };

  const handleSuspectChange = (e) => {
    setSuspects(e.target.value);
  };

  const handleAuthorChange = (e) => {
    setAuthor(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleAuthorLink = (e) => {
    setLinkToAuthor(e.target.value);
  };

  const handleFileChange = (event) => {
    const maxFileNameLength = 20;
    const file = event.target.files[0];
    setFullFile(file);
    if (file) {
      const fileName = file.name;

      if (fileName.length > maxFileNameLength) {
        const fileExtension = fileName.split(".").pop();
        const truncatedFileName = fileName.substring(
          0,
          maxFileNameLength - fileExtension.length - 1
        );
        const displayedFileName = truncatedFileName + "..." + fileExtension;

        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsFile(displayedFileName);
        setFileSize(fileSizeInMB);
      } else {
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsFile(fileName);
        setFileSize(fileSizeInMB);
      }
    }
  };

  const removeFileChange = (e) => {
    e.stopPropagation();
    setIsFile("");
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const imageNameLength = () => {
    const maxFileNameLength = 30;
    if (isImage) {
      if (isImage.length > maxFileNameLength) {
        const truncatedFileName = isImage.substring(0, maxFileNameLength - 1);
        return truncatedFileName + "...";
      }
    } else {
      return isImage;
    }
  };

  const fileNameLength = () => {
    const maxFileNameLength = 30;
    if (isFile) {
      if (isFile.length > maxFileNameLength) {
        const truncatedFileName = isFile.substring(0, maxFileNameLength - 1);
        return truncatedFileName + "...";
      }
    } else {
      return isFile;
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const maxFileNameLength = 20;
    const file = event.target.files[0];
    setFullFile(file);
    if (file) {
      const fileName = file.name;

      if (fileName.length > maxFileNameLength) {
        const fileExtension = fileName.split(".").pop();
        const truncatedFileName = fileName.substring(
          0,
          maxFileNameLength - fileExtension.length - 1
        );
        const displayedFileName = truncatedFileName + "..." + fileExtension;

        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsFile(displayedFileName);
        setFileSize(fileSizeInMB);
      } else {
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsFile(fileName);
        setFileSize(fileSizeInMB);
      }
    }
  };
  const handleImageChange = (event) => {
    const maxFileNameLength = 20;
    const file = event.target.files[0];
    setFullImage(file);
    if (file) {
      const fileName = file.name;

      if (fileName.length > maxFileNameLength) {
        const fileExtension = fileName.split(".").pop();
        const truncatedFileName = fileName.substring(
          0,
          maxFileNameLength - fileExtension.length - 1
        );
        const displayedFileName = truncatedFileName + "..." + fileExtension;

        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsImage(displayedFileName);
        setImageSize(fileSizeInMB);
      } else {
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsImage(fileName);
        setImageSize(fileSizeInMB);
      }
    }
  };

  const handleImageDragOver = (event) => {
    event.preventDefault();
  };

  const removeImageChange = (e) => {
    e.stopPropagation();
    setIsImage("");
  };

  const handleImageDrop = (event) => {
    event.preventDefault();
    const maxFileNameLength = 20;
    const file = event.target.files[0];
    setFullImage(file);
    if (file) {
      const fileName = file.name;

      if (fileName.length > maxFileNameLength) {
        const fileExtension = fileName.split(".").pop();
        const truncatedFileName = fileName.substring(
          0,
          maxFileNameLength - fileExtension.length - 1
        );
        const displayedFileName = truncatedFileName + "..." + fileExtension;

        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsImage(displayedFileName);
        setImageSize(fileSizeInMB);
      } else {
        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        setIsImage(fileName);
        setImageSize(fileSizeInMB);
      }
    }
  };

  const validateInfo = () => {
    if (title1 == "") {
      return false;
    }
    if (description1 == "") {
      return false;
    }
    if (startDate1 == null) {
      return false;
    }
    if (endDate1 == null) {
      return false;
    }
    if (prizeAmount1 == "") {
      return false;
    }
    if (culprit1 == "") {
      return false;
    }
    if (suspects1 == "") {
      return false;
    }
    if (author1 == "") {
      return false;
    }
    if (amount1 == "") {
      return false;
    }
    if (linkToAuthor1 == "") {
      return false;
    }
    if (fullFile1 == "") {
      return false;
    }
    if (fullImage1 == "") {
      return false;
    }
    return true;
  };

  const updateSuspects = () => {
    if (suspectName.trim() === "") {
      console.error("Invalid suspect name");
      return;
    }

    const newSuspect = {
      name: suspectName,
      culprit: false,
    };

    setSusData((prevSusData) => {
      const updatedSusData = [...prevSusData, newSuspect];
      setData(updatedSusData);
      return updatedSusData;
    });

    setSuspects((prevSuspects) => [...prevSuspects, suspectName]);

    closeSus();
    setSuspectName("");
  };

  const [susData, setSusData] = useState([]);
  const [data, setData] = useState(susData);

  const handleCulpritClick = (index) => {
    const newData = data.map((suspect, idx) => {
      if (idx === index) {
        return { ...suspect, culprit: true };
      } else {
        return { ...suspect, culprit: false };
      }
    });
    setData(newData);
  };

  useEffect(() => {
    const newCulprit = data.find((suspect) => suspect.culprit)?.name || "";
    setCulprit(newCulprit);
  }, [data]);

  const deleteSus = () => {
    if (forIdx >= 0 && forIdx <= susData.length) {
      const updatedSusData = susData.filter((_, idx) => idx !== forIdx);
      setSusData(updatedSusData);
      if (culprit1 && susData[forIdx].culprit) {
        setCulprit("");
      }
      setTimeout(() => {
        setSuspects(updatedSusData.map((suspect) => suspect.name));
        setData(updatedSusData);
      }, 10);
      closeDeletesus();
    } else {
      console.error("Invalid index provided for deletion");
    }
  };

  const openFile = (e) => {
    e.stopPropagation();
    if (fullFile1.startsWith("http://")) {
      window.location.href = fullFile1;
    }
  };

  const openImage = (e) => {
    e.stopPropagation();
    if (fullImage1.startsWith("http://")) {
      window.location.href = fullImage1;
    }
  };
  const sanitizedHTML = { __html: description1 };
  return (
    <div className="w-full" style={{ fontFamily: "Inter" }}>
      {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <div className="mt-12">
        <ParamBackButton />
      </div>
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="md:ml-32 md:mr-28">
          <div className="flex justify-start md:justify-start mt-24 md:mt-16">
            <p className="text-clue-purchase font-bold md:ml-[0px] ml-[20px] text-[24px] md:text-2xl">
              New Competitions
            </p>
          </div>
          <div className="border-b-2 border-filter-heading md:mr-24 mx-10">
            <div>
              <div className="flex justify-center md:justify-start my-8">
                <p className="text-clue-yellow font-bold text-xl">EPUB Book</p>
              </div>
              <div
                className={`flex  ${
                  isFile == ""
                    ? "border-filter-heading"
                    : "border-clue-purchase"
                } flex-col cursor-pointer justify-center items-center rounded-xl border-2 border-dashed h-40 w-full mb-10`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current.click()}
              >
                <div className="flex w-full justify-end">
                  {isFile !== "" && (
                    <div
                      className="flex rounded-3xl border-2 bg-clue-ellipse justify-end cursor-pointer mr-3 md:mr-7"
                      onClick={removeFileChange}
                    >
                      <CloseIcon fontSize="small" />
                    </div>
                  )}
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".epub"
                  className="hidden"
                  onChange={handleFileChange}
                />
                {isFile == "" ? (
                  <img src={Vector} alt="Vector Image" className="mr-2.5" />
                ) : (
                  <AutoStoriesOutlinedIcon
                    className="mr-2.5 text-clue-purchase"
                    fontSize="large"
                  />
                )}
                {isFile == "" ? (
                  <span className="text-clue-gray mt-5">
                    <span className="underline text-white">Choose a file</span>{" "}
                    or drag it here
                  </span>
                ) : (
                  <div className="flex flex-col">
                    <span className="text-clue-gray mt-2" onClick={openFile}>
                      {isFile}
                    </span>
                    <span className="text-clue-gray mt-2">
                      {fileSize ? fileSize + "MB" : ""}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="border-b-2 border-filter-heading md:mr-24 mx-10">
            <div>
              <div className="flex justify-center md:justify-start my-8">
                <p className="text-clue-yellow font-bold text-xl">
                  Information
                </p>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-col order-2 md:order-1 md:mr-24 w-full">
                  <div className="mb-4">
                    <label
                      className="block text-left mb-2 text-sm text-filter-heading"
                      htmlFor="cardNumber1"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      id="title"
                      value={title1}
                      onChange={handleTitleChange}
                      className="w-full bg-clue-black p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase text-clue-gray"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-left mb-2 text-sm text-filter-heading"
                      htmlFor="desc"
                    >
                      Author's Name
                    </label>
                    <input
                      id="name"
                      value={author1}
                      onChange={handleAuthorChange}
                      rows="4"
                      className="w-full text-clue-gray bg-clue-black p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase resize-none"
                    />
                  </div>
                </div>
                <div
                  className={`order-1 md:order-2 ${
                    isImage == ""
                      ? "border-filter-heading"
                      : "border-clue-purchase"
                  } flex flex-col cursor-pointer justify-center items-center rounded-xl border-2 border-dashed h-40 w-full mb-5`}
                  onDragOver={handleImageDragOver}
                  onDrop={handleImageDrop}
                  onClick={() => imageInputRef.current.click()}
                >
                  <div className="flex w-full justify-end">
                    {isImage !== "" && (
                      <div
                        className="flex rounded-3xl border-2 bg-clue-ellipse justify-end cursor-pointer mr-3 md:mr-7"
                        onClick={removeImageChange}
                      >
                        <CloseIcon fontSize="small" />
                      </div>
                    )}
                  </div>
                  <input
                    ref={imageInputRef}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    className="hidden"
                    onChange={handleImageChange}
                  />
                  {isImage == "" ? (
                    <AddPhotoAlternateIcon
                      className="mr-2.5 text-clue-yellow"
                      fontSize="large"
                    />
                  ) : (
                    <GradientIcon
                      className="mr-2.5 text-clue-purchase"
                      fontSize="large"
                    />
                  )}
                  {isImage == "" ? (
                    <span className="text-clue-gray mt-5">
                      <span className="underline text-white">
                        Choose a file
                      </span>{" "}
                      or drag it here
                    </span>
                  ) : (
                    <div className="flex flex-col">
                      <span className="text-clue-gray mt-2" onClick={openImage}>
                        {isImage}
                      </span>
                      <span className="text-clue-gray mt-2">
                        {imageSize ? imageSize + "MB" : ""}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-left text-sm mb-2 text-filter-heading"
                  htmlFor="link"
                >
                  Link to Author’s Site
                </label>
                <input
                  type="text"
                  id="cardNumber1"
                  onChange={handleAuthorLink}
                  value={linkToAuthor1}
                  className="w-full text-clue-gray bg-clue-black p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
                />
              </div>
              <div className="mb-10">
                <label
                  className="block text-left mb-2 text-sm text-filter-heading"
                  htmlFor="desc"
                >
                  Description
                </label>
                <textarea
                  id="desc"
                  dangerouslySetInnerHTML={sanitizedHTML}
                  onChange={handleDescriptionChange}
                  rows="4"
                  className="w-full h-44 resize-none text-clue-gray bg-clue-black p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-filter-heading md:mr-24 mx-10">
            <div>
              <div className="flex justify-center md:justify-start my-8">
                <p className="text-clue-yellow font-bold text-xl">
                  Competition Details
                </p>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full mb-4 md:mr-[2%]">
                  <label
                    className="block text-left mb-2 text-sm text-filter-heading"
                    htmlFor="cardNumber1"
                  >
                    Start Date
                  </label>
                  <div className="flex">
                    <input
                      type="date"
                      value={startDate1}
                      onChange={handleStartChange}
                      id="startDate"
                      className="w-full text-filter-heading bg-clue-black p-2 rounded-l-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
                    />
                  </div>
                </div>
                <div className="w-full mb-4 md:mx-[2%]">
                  <label
                    className="block text-left mb-2 text-sm text-filter-heading"
                    htmlFor="cardNumber1"
                  >
                    End Date
                  </label>
                  <div className="flex">
                    <input
                      type="date"
                      value={endDate1}
                      onChange={handleEndChange}
                      id="endDate"
                      className="w-full text-filter-heading bg-clue-black p-2 rounded-l-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
                    />
                  </div>
                </div>
                <div className="w-full mb-4 md:ml-[2%]">
                  <label
                    className="block text-left mb-2 text-sm text-filter-heading"
                    htmlFor="cardNumber1"
                  >
                    Prize Amount
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={prizeAmount1}
                    onChange={handlePrizeChange}
                    className="w-full text-clue-gray bg-clue-black p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
                  />
                </div>
              </div>

              <div className="md:w-72 mb-4">
                <label
                  className="block text-left mb-2 text-sm text-filter-heading"
                  htmlFor="cardNumber1"
                >
                  Amount
                </label>
                <input
                  type="text"
                  id="title"
                  value={amount1}
                  onChange={handleAmountChange}
                  className="w-full text-clue-gray bg-clue-black p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
                />
              </div>
              <div className="flex mb-2 mt-5">
                <span className="text-clue-gray font-semibold">
                  Include Promo Code
                </span>
                <div className="ml-5 mt-1">
                  <label class="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      checked={isPromo}
                      id="promo"
                      class="sr-only peer"
                      onClick={() => setIsPromo(!isPromo)}
                    />
                    <div class="w-11 h-6  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-clue-purchase  rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-clue-purchase"></div>
                  </label>
                </div>
              </div>

              {isPromo ? (
                <div className="md:w-72 mb-4">
                  <label
                    className="block text-left mb-1 mt-3 text-sm text-filter-heading"
                    htmlFor="codename"
                  >
                    Code Name
                  </label>
                  <input
                    type="text"
                    id="codename"
                    className="w-full text-clue-gray bg-clue-black p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
                    defaultValue={codeName}
                    onChange={handleCodeNameChange}
                  />
                  <label
                    className="block text-left mb-1 mt-3 text-sm text-filter-heading"
                    htmlFor="discount"
                  >
                    Discount Percentage
                  </label>
                  <input
                    type="text"
                    id="discount"
                    className="w-full text-clue-gray bg-clue-black p-2 rounded-xl outline-none border-0 focus:outline-none focus:ring-0 focus:border focus:border-clue-purchase"
                    defaultValue={discount}
                    onChange={handleDiscountChange}
                  />
                </div>
              ) : (
                <div></div>
              )}
              <div className="flex mt-3 mb-10">
                <span className="text-clue-gray font-semibold">3 Winners</span>
                <div className="ml-5 mt-1">
                  <label class="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={is3Winner}
                      id="winners"
                      class="sr-only peer"
                      onClick={openDeleteWinner}
                    />
                    <div class="w-11 h-6  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-clue-purchase  rounded-full peerbg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-clue-purchase"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-filter-heading md:mr-24 mx-10">
            <div>
              <div className="flex justify-center md:justify-start my-8">
                <p className="text-clue-yellow font-bold text-xl">Visibility</p>
              </div>
              <div className="flex mb-5">
                <span className="text-clue-gray font-bold">
                  Visiblity on Product Page
                </span>
                <div className="ml-10">
                  <label class="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={isVisible1}
                      onChange={handleVisibleChange}
                      id="visibility"
                      class="sr-only peer"
                    />
                    <div class="w-11 h-6  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-clue-purchase  rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-clue-purchase"></div>
                  </label>
                </div>
              </div>
              <div className="mb-10">
              <p className={`italic text-sm text-filter-heading text-left `}>
  {isVisible1
    ? "Turn this toggle off if you don’t want the competition to be live yet" 
    : "Turn this toggle on when you are ready to launch the competition."}
</p>
              </div>
            </div>
          </div>
          <div className="border-b-2 border-filter-heading md:mr-24 mx-10">
            <div>
              <div className="flex justify-center md:justify-start my-8">
                <p className="text-clue-yellow font-bold text-xl">
                  Reader's Age
                </p>
              </div>
              <div className="flex mb-5">
                <span className="text-clue-gray font-bold">
                  Under 18 appropriate
                </span>
                <div className="ml-16">
                  <label class="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={under18}
                      onChange={handleUnder18Change}
                      id="under18"
                      class="sr-only peer"
                    />
                    <div class="w-11 h-6  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-clue-purchase  rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-clue-purchase"></div>
                  </label>
                </div>
              </div>
              <div className="mb-10">
      <p className="italic text-sm text-filter-heading text-left">
        {under18
          ? "Turn this toggle off if you don’t want the competition to be live yet"
          : "Turn this toggle on when you are ready to launch the competition."}
      </p>
    </div>
            </div>
          </div>
          <div className="border-b-2 border-filter-heading lg:mr-24 mx-10">
            <div>
              <div className="flex flex-col md:flex-row justify-center md:justify-between my-8">
                <span className="text-clue-yellow font-bold text-xl">
                  Company & Contributors
                </span>
                <span
                  className="text-clue-yellow underline md:mt-0 mt-5 cursor-pointer"
                  onClick={openModal}
                >
                  Add Contributor
                </span>
              </div>
              <div className="w-full mb-10">
                {contributionsEdit.map((item, index) => (
                  <div
                    key={index}
                    className="md:text-base rounded-xl text-sm flex justify-between bg-clue-black h-16 mb-5 w-full py-5 px-2 md:px-5"
                  >
                    <span className="text-filter-heading">
                      {item.user_id ===
                      users.find((user) => user.user_id === item.user_id)
                        ?.user_id
                        ? users.find((user) => user.user_id === item.user_id)
                            ?.username
                        : ""}
                    </span>
                    <span className="text-filter-heading">
                      {item.is_owner ? "Company" : "Contributor"}
                    </span>
                    <span className="text-filter-heading">
                      {item.percentage}
                    </span>
                    <div className="flex space-x-2 md:space-x-10">
                      <span
                        className="text-filter-heading cursor-pointer"
                        onClick={() => openEditModal(index)}
                      >
                        <CreateIcon
                          className="text-clue-purchase"
                          fontSize="small"
                        />
                      </span>
                      <div onClick={() => openDeleteCont(index)}>
                        <span className="text-filter-heading cursor-pointer">
                          <DeleteIcon
                            className="text-clue-logout"
                            fontSize="small"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="lg:mr-24 mx-10">
            <div>
              <div className="flex flex-col md:flex-row justify-center md:justify-between my-8">
                <span className="text-clue-yellow font-bold text-xl">
                  Suspects
                </span>
                <span
                  className="text-clue-yellow underline md:mt-0 mt-5 cursor-pointer"
                  onClick={openSus}
                >
                  Add Suspect
                </span>
              </div>

              <div className="w-full mb-10">
                {data.map((item, idx) => (
                  <div
                    key={idx}
                    className="md:text-base text-sm rounded-xl flex justify-between bg-clue-black h-16 mb-5 w-full py-5 px-2 md:px-5"
                  >
                    <span className="text-filter-heading">{item.name}</span>
                    <div className="flex space-x-2 md:space-x-10">
                      <div
                        className={`h-7 rounded-lg cursor-pointer ${
                          item.culprit
                            ? "bg-clue-purchase border-2 border-clue-purchase"
                            : "border-2 border-filter-heading"
                        }`}
                        onClick={() => handleCulpritClick(idx)}
                      >
                        <span
                          className={`m-3 ${
                            item.culprit ? "text-white" : "text-filter-heading"
                          }`}
                        >
                          is the culprit
                        </span>
                      </div>
                      <span className="text-filter-heading m-0.5 md:mt-0 cursor-pointer">
                        <CreateIcon
                          className="text-clue-purchase"
                          fontSize="small"
                        />
                      </span>
                      <div onClick={() => openDeleteSus(idx)}>
                        <span className="text-filter-heading m-0.5 md:mt-0 cursor-pointer">
                          <DeleteIcon
                            className="text-clue-logout"
                            fontSize="small"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {isDeleteWinner && (
                <div
                  className="fixed inset-0 flex items-center justify-center z-50"
                  onClick={closeDeleteWinner}
                >
                  <div className="bg-black opacity-50 absolute inset-0"></div>
                  <div
                    className="bg-clue-black rounded-3xl md:w-auto w-80 p-8 px-12 relative z-10"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                      Delete Winners
                    </h3>
                    <p className="text-filter-heading text-left">
                      Are you sure you want to proceed? Continuing will delete
                      all the winners of this book.
                    </p>
                    <div className="flex justify-end mt-6">
                      <button
                        onClick={closeDeleteWinner}
                        className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-clue-logout text-white px-4 py-2 rounded"
                        onClick={handleDeleteWinner}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isDeleteCont && (
                <div
                  className="fixed inset-0 flex items-center justify-center z-50"
                  onClick={closeDeleteCont}
                >
                  <div className="bg-black opacity-50 absolute inset-0"></div>
                  <div
                    className="bg-clue-black rounded-3xl md:w-auto w-80 p-8 px-12 relative z-10"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                      Delete Contributor
                    </h3>
                    <p className="text-filter-heading text-left">
                      Are you sure you want to delete this contributor from this
                      book?
                    </p>
                    <div className="flex justify-end mt-6">
                      <button
                        onClick={closeDeleteCont}
                        className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-clue-logout text-white px-4 py-2 rounded"
                        onClick={handleContDelete}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isModalOpen && (
                <div
                  className="fixed inset-0 flex items-center justify-center z-50"
                  onClick={closeModal}
                >
                  <div className="bg-black opacity-50 absolute inset-0"></div>
                  <div
                    className="bg-clue-black p-8 px-12 md:w-auto w-80  rounded-3xl relative z-10"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                      Add Contributor
                    </h3>
                    <select
                      className="mb-4 w-60 md:w-80 bg-clue-black text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                      onChange={onUserIdChange}
                    >
                      <option value="" disabled selected>
                        {contributionsEdit[EditContIdx]
                          ? users.map((user) =>
                              contributionsEdit[EditContIdx].user_id ==
                              user.user_id
                                ? user.username
                                : ""
                            )
                          : "Username"}
                      </option>
                      {users.map((user) => (
                        <option key={user.user_id} value={user.user_id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                    <br />
                    <input
                      type="text"
                      value={percentage}
                      onChange={handlePercentageChange}
                      className="mb-4 w-60 md:w-80 bg-clue-black placeholder:text-clue-gray text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                      placeholder="Percentage"
                    />
                    <br />
                    <select
                      className="mb-4 w-60 md:w-80 bg-clue-black text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                      onChange={onContributorChange}
                    >
                      <option value="" disabled selected>
                        Contributor
                      </option>
                      <option value="false">Yes</option>
                      <option value="true">No</option>
                    </select>

                    <br />
                    <div className="flex justify-end mt-6">
                      <button
                        onClick={closeModal}
                        className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-clue-purchase text-white px-4 py-2 rounded"
                        onClick={addContribution}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isEditModalOpen && (
                <div
                  className="fixed inset-0 flex items-center justify-center z-50"
                  onClick={closeEditModal}
                >
                  <div className="bg-black opacity-50 absolute inset-0"></div>
                  <div
                    className="bg-clue-black p-8 px-12 md:w-auto w-80  rounded-3xl relative z-10"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                      Update Contributor
                    </h3>
                    <select
                      className="mb-4 w-60 md:w-80 bg-clue-black text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                      onChange={onUserIdChange}
                    >
                      <option value="" disabled selected>
                        {users.map((user) =>
                          contributionsEdit[EditContIdx].user_id == user.user_id
                            ? user.username
                            : ""
                        )}
                      </option>
                      {users.map((user) => (
                        <option key={user.user_id} value={user.user_id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                    <br />
                    <input
                      type="text"
                      value={percentage}
                      onChange={handlePercentageChange}
                      className="mb-4 w-60 md:w-80 bg-clue-black placeholder:text-clue-gray text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                      placeholder="Percentage"
                    />
                    <br />
                    <select
                      className="mb-4 w-60 md:w-80 bg-clue-black text-clue-gray p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                      onChange={onContributorChange}
                    >
                      <option value="" disabled selected>
                        {contributionsEdit[EditContIdx].is_owner ? "No" : "Yes"}
                      </option>
                      <option value="false">Yes</option>
                      <option value="true">No</option>
                    </select>

                    <br />
                    <div className="flex justify-end mt-6">
                      <button
                        onClick={closeEditModal}
                        className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-clue-purchase text-white px-4 py-2 rounded"
                        onClick={updateContribution}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isSusOpen && (
                <div
                  className="fixed rounded-xl inset-0 flex items-center justify-center z-50"
                  onClick={closeSus}
                >
                  <div className="bg-black opacity-50 absolute inset-0"></div>
                  <div
                    className="bg-clue-black p-8 px-12 md:w-auto w-80  rounded-3xl relative z-10"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                      Add Suspect
                    </h3>
                    <input
                      type="text"
                      className="mb-4 w-60 md:w-80 bg-clue-black text-white p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                      placeholder="Name"
                      value={suspectName}
                      onChange={(e) => {
                        setSuspectName(e.target.value);
                      }}
                    />
                    <div className="flex justify-end mt-6">
                      <button
                        onClick={closeSus}
                        className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-clue-purchase text-white px-4 py-2 rounded"
                        onClick={updateSuspects}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isDeleteSus && (
                <div
                  className="fixed inset-0 flex items-center justify-center z-50"
                  onClick={closeDeletesus}
                >
                  <div className="bg-black opacity-50 absolute inset-0"></div>
                  <div
                    className="bg-clue-black rounded-3xl md:w-auto w-80  p-8 px-12 relative z-10"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                      Delete Suspect
                    </h3>
                    <p className="text-filter-heading text-left">
                      Are you sure you want to delete this suspect from this
                      book?
                    </p>
                    <div className="flex justify-end mt-6">
                      <button
                        onClick={closeDeletesus}
                        className="text-filter-heading mr-4 border-2 rounded-md border-filter-heading py-2 px-4"
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-clue-logout text-white px-4 py-2 rounded"
                        onClick={deleteSus}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <button
              type="button"
              onClick={navigateToPreview}
              className="mb-24 mx-24 md:w-60 md:mx-96 md:mt-10 inline-block rounded-md bg-clue-purchase px-6 py-2 font-semibold text-white"
            >
              Go to Preview
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default ArchivedCollectionCompEdit;
