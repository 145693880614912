import React, { useState,useEffect } from 'react';
import '@fontsource/inter';
import OtpInput from 'react-otp-input';
import WestIcon from "@mui/icons-material/West";
import Navbar from '../navbar/navbar';
import Toast from "../../components/toasts/toast";

import { useNavigate,useLocation } from "react-router-dom";
import VerifyMailManager from '../../models/website/auth/https/verifymailhttp';
import Footer from '../footer/footer';
import WebResetPass from "../../models/website/auth/https/webresetpasshttp";
const resetManager = new WebResetPass();
function Verify_Mail() {
    const location = useLocation();
    const emailFromState = location?.state?.email || '';
    const is_from_signup2 = location?.state?.is_from_signup2 || '';
    const is_from_signup = location?.state?.is_from_signup || '';
    const unauthorizfromState = location?.state?.unauthorized||false;
    const isFromSignupPage = is_from_signup || is_from_signup2;

   
    const [toastMessages, setToastMessages] = useState([]);

    const navigate = useNavigate();

    const [otp, setOtp] = useState('');

    const [isOtpValid, setIsOtpValid] = useState(false);
   
    useEffect(() => {
      if (unauthorizfromState) {
        handleReSendCode();
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Unverified Mail",
            body: "To sign in, please verify the mail",
          },
        ]);
      }
      const websiteToken = localStorage.getItem('websiteToken');
     
        if (websiteToken) {
          // Use React Router's useHistory hook to navigate
          navigate('/account/dashboard');
        } 
      
       

    }, []);
    const handleSendCode = async () => {
        try {
          const verifyMailManager = new VerifyMailManager();
          const response = await verifyMailManager.add(emailFromState, otp);
          
          if (!response.error) {
            const baseResponse = response.success;
      
            if (baseResponse == true) {
              setIsOtpValid(true);
              navigate("/account/signin", { state: { showtoast: true } });
            } else {
              setToastMessages([
                ...toastMessages,
                {
                  type: "invalid",
                  title: "OTP Code",
                  body: response.message,
                },
              ]);
            }
          } else {
            setToastMessages([
              ...toastMessages,
              {
                type: "invalid",
                title: "OTP Code",
                body: response.error,
              },
            ]);
          }
        } catch (error) {
          console.error("Error sending code:", error);
        }
      };
    // const handleSendCode = () => {
    //     const isVerificationSuccessful = otp === '1234'; // Replace '1234' with the actual valid OTP
    //     if (isVerificationSuccessful) {
    //       setIsOtpValid(true);
    //        navigate('/account/signin');
          
    //     }
    //     else{
    //         setToastMessages([
    //             ...toastMessages,
    //             {
    //                 type: "invalid",
    //                 title: "OTP Code",
    //                 body: "The code is incorrect",
    //             },
    //         ]);
    //     }
    // };
  

    const handleReSendCode = () => {
      resetManager.reset1(emailFromState,isFromSignupPage).then((value) => {
        if (!value.error) {
          const baseResponse = value.success;
          if (baseResponse == true) {
            setToastMessages([
              ...toastMessages,
              {
                type: "success",
                title: "OTP Resend",
                body: "OTP has been resend.",
              },
            ]);
          } else {
            setToastMessages([
              ...toastMessages,
              {
                type: "invalid",
                title: "Error",
                body: value.message,
              },
            ]);
          }
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "error",
              title: "Error",
              body: value.error,
            },
          ]);
        }
      });
    };

    const handleBackClick = () => {
        const segments = location.pathname.split('/').filter(Boolean);
        if (segments.length > 1) {
          segments.pop();
          navigate('/' + segments.join('/'));
        } else {
          navigate('/');
        }
       
      };
    return (
        <div className=' bg-black '  >
          
           {toastMessages.map((toast, index) => (
                <Toast className="mb-0"
                    key={index}
                    toasts={[toast]}
                    onClose={() => {
                        // Remove the toast message when it's closed
                        const updatedToasts = [...toastMessages];
                        updatedToasts.splice(index, 1);
                        setToastMessages(updatedToasts);
                    }}
                />
            ))}
        <div className="flex justify-center items-center ">
        
            <div
                className="  mx-2 md:w-[460px] w-[320px]  md:h-[450px] h-[390px] md:mb-[15vh] md:mt-[15vh] mt-24   block rounded-[30px] bg-clue-black '
              ">

                <p
                    className="md:mr-[42%] md:text-[24px] text-[22px] mr-[25%]  md:mt-12 mt-8 md:mb-8 mb-5 font-[700] leading-tighttext-clue-yellow">
                    <WestIcon className="text-clue-yellow mr-3 mb-1 cursor-pointer transition-opacity hover:opacity-70" onClick={handleBackClick} />Verification
                </p>

                <p className="md:mb-9 md:mt-7 mt-7 w-[247px] md:w-96 text-[16px] md:text-[20px] md:ml-[55px] text-left ml-[47px] font-[400] text-clue-gray leading-[1.2] md:leading-[1.2]">
                    A verification code has been sent
                    <span className='md:ml-[0.25%]'> to <span className="text-clue-yellow"> {emailFromState} </span> </span>
                </p>


                <div className={'relative md:mb-7 md:mt-0 mb-5 mt-8 ml-12 md:ml-20'}>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderInput={(props, index) => (
                            <input
                                {...props}
                                className="focus:ring-0
                            md:mr-5 w-12 h-12 mr-3 md:h-14 md:w-16 border  border-clue-gray focus:outline-none focus:border-onclickfield rounded-[10px] text-center text-clue-gray
                            text-xl md:text-xl
                        "
                                style={{
                                    background: 'transparent',
                                    fontSize: '1.5rem',
                                }}
                            />
                        )}
                    />

                </div>


                <button
                    type="button"
                    className="mb-2 block md:mx-14 md:w-[370px] md:mt-9 mt-8 mx-10 md:h-[56px] h-[50px] w-[245px] bg-onclickfield rounded-[10px] md:pb-1 md:pl-1 text-center text-[19px] md:text-[22px] font-[700] leading-normal text-white transition-opacity hover:opacity-70"
                    onClick={handleSendCode}>

                    Verify Code
                </button>

                <div className="md:mt-7 mt-5 w-52 md:w-80 text-[17px] md:text-[20px] md:ml-[80px]  text-center ml-[60px] font-[400] text-clue-gray leading-tight ">
                    Did not receive a code?
                    <a href="#" className="font-[700] text-clue-yellow pl-2 md:text-center underline transition-opacity hover:opacity-70" onClick={handleReSendCode}>Resend</a>
                </div>


            </div>
            <div></div>
        </div>
        
        <div className='md:mt-[2%] mt-[30%] bottom-0 '>
            <Footer/>
        </div>
        </div>
    );
}
export default Verify_Mail;