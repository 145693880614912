import React, { useEffect, useState } from "react";
import "@fontsource/inter";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Verify_Code from "../../components/reset_pw/verify_code";
import AddAdminManager from "../../models/admin/auth/https/addadminhttp.js";
import ChangeUsernameManager from "../../models/admin/auth/https/changeusernamehttp.js";
import ListManager from "../../models/admin/auth/https/getadminshttp.js";
import ResetPass from "../../models/admin/auth/https/resetpasshttp.js";
import DeleteAdminManager from "../../models/admin/auth/https/deleteadminhttp.js";
import Toast from "../../components/toasts/toast.js";
import { Oval } from "react-loader-spinner";
import ContributionManager from "../../models/admin/contributions/https/listconthttp.js";
import DeleteContManager from "../../models/admin/contributions/https/deleteconthttp.js";
import ListCompNamesManager from "../../models/admin/competitions/https/listcompnameshttp.js";
import AllUsersManager from "../../models/admin/users/https/getallusershttp.js";
import AddContributionManager from "../../models/admin/contributions/https/createconthttp.js";

function Accounts() {
  const prevemail = localStorage.getItem("email");
  const prevusername = localStorage.getItem("username");
  const [email, setEmail] = useState(prevemail);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteSus, setIsDeleteSus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [percentage, setPercentage] = useState("");
  const [password, setPassword] = useState("");
  const [isVerifyCodeOpen, setIsVerifyCodeOpen] = useState(false);
  const adminManager = new AddAdminManager();
  const changeManager = new ChangeUsernameManager();
  const resetManager = new ResetPass();
  const listManager = new ListManager();
  const deleteManager = new DeleteAdminManager();
  const listcontManager = new ContributionManager();
  const deletecontManager = new DeleteContManager();
  const compnamesManager = new ListCompNamesManager();
  const addcontManager = new AddContributionManager();
  const [toastMessages, setToastMessages] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showLoading1, setShowLoading1] = useState(false);
  const [showLoading2, setShowLoading2] = useState(false);
  const [showLoading3, setShowLoading3] = useState(false);
  const [showLoading4, setShowLoading4] = useState(false);
  const [forIdx, setIdx] = useState(0);
  const [forContIdx, setContIdx] = useState(0);
  const [data, setData] = useState([
    // Default admin added here
    {
      username: "Default Admin",
      email: "default@gmail.com",
    },
  ]);
  const allusersManager = new AllUsersManager();
  const [users, setUsers] = useState([]);
  const [Contdata, setContData] = useState([]);
  const [compNames, setCompNames] = useState([]);
  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const [username, setUsername] = useState(prevusername);
  const [adminUsername, setAdminUsername] = useState("");
  const [ContUsername, setContUsername] = useState("");
  const [ContEmail, setContEmail] = useState("");
  const [bookId, setBookId] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [isDeleteCont, setIsDeleteCont] = useState(false);
  const [isContModalOpen, setIsContModalOpen] = useState(false);

  const handlePercentageChange = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      setPercentage(inputValue);
    }
  };

  const [isOwner, setIsOwner] = useState(false);

  const onContributorChange = (e) => {
    setIsOwner(e.target.value === "true");
  };

  const handleUserIDChange = (e) => {
    setContEmail(e.target.value);
  };

  const handleBookIDChange = (e) => {
    setBookId(e.target.value);
  };

  useEffect(() => {
    setShowLoading4(true);
    listManager.get().then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setData(value.data);
          setShowLoading4(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    setShowLoading4(true);
    listcontManager.get().then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setContData(value.data);
          setShowLoading4(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    setShowLoading4(true);
    allusersManager.get(false).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setUsers(value.data);
          setShowLoading4(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    setShowLoading4(true);
    compnamesManager.get().then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setCompNames(value.data);
          setShowLoading4(false);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const openVerifyCodeModal = () => {
    try {
      resetManager.reset1().then((value) => {
        if (value == null) {
        } else if (!value.error) {
          const baseResponse = value.success;
          if (baseResponse == true) {
            setIsVerifyCodeOpen(true);
          } else {
            setToastMessages([
              ...toastMessages,
              {
                type: "invalid",
                title: "Error",
                body: value.message,
              },
            ]);
          }
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "error",
              title: "Error",
              body: value.error,
            },
          ]);
        }
      });
    } catch {
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Error",
          body: "Error Occurred",
        },
      ]);
    }
  };
  const back = () => {
    setIsVerifyCodeOpen(false);
  };
  const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleAddAdmin = () => {
    //ADD AN ADMIN HEREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE
    if (!isValidEmail(adminEmail)) {
      // Show toast message for invalid email
      closeModal();
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Email",
          body: "Enter a valid email",
        },
      ]);

      // Return early if email is not valid
      return;
    }
    if (password.length < 8) {
      // Show toast message for invalid password length
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Error",
          body: "Password length should be at least 8 characters",
        },
      ]);
      // Return early if password length is less than 8
      return;
    }
    setShowLoading1(true);
    adminManager.add(adminEmail, password, adminUsername).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setToastMessages([
            ...toastMessages,
            {
              type: "success",
              title: "Success",
              body: value.message,
            },
          ]);
          setAdminEmail("");
          setPassword("");
          setAdminUsername("");
          setShowLoading1(false);
          listManager.get().then((value) => {
            if (!value.error) {
              const baseResponse = value.success;
              if (baseResponse == true) {
                setData(value.data);
              } else {
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "invalid",
                    title: "Error",
                    body: value.message,
                  },
                ]);
              }
            } else {
              setToastMessages([
                ...toastMessages,
                {
                  type: "error",
                  title: "Error",
                  body: value.error,
                },
              ]);
            }
          });
        } else {
          setShowLoading1(false);
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setShowLoading1(false);
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
    closeModal(); // Close the add admin modal
  };
  const handleDeleteAdmin = () => {
    const useremail = data[forIdx].email;
    deleteManager.delete(useremail).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse === true) {
          listManager.get().then((value) => {
            if (!value.error) {
              const baseResponse = value.success;
              if (baseResponse == true) {
                setData(value.data);
              } else {
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "invalid",
                    title: "Error",
                    body: value.message,
                  },
                ]);
              }
            } else {
              setToastMessages([
                ...toastMessages,
                {
                  type: "error",
                  title: "Error",
                  body: value.error,
                },
              ]);
            }
          });
        } else {
          setShowLoading1(false);
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setShowLoading1(false);
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
    closeDeletesus();
  };

  const addContribution = () => {
    setShowLoading4(true);
    addcontManager.addFromAccount(ContEmail).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setShowLoading4(false);
          closeContModal();
          closeModal();
          setShowLoading(true);
          listcontManager.get().then((value) => {
            if (!value.error) {
              const baseResponse = value.success;
              if (baseResponse == true) {
                setContData(value.data);
                setShowLoading(false);
              } else {
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "invalid",
                    title: "Error",
                    body: value.message,
                  },
                ]);
              }
            } else {
              setToastMessages([
                ...toastMessages,
                {
                  type: "error",
                  title: "Error",
                  body: value.error,
                },
              ]);
            }
          });
          setToastMessages([
            ...toastMessages,
            {
              type: "success",
              title: "Success",
              body: value.message,
            },
          ]);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  };

  const handleAddCont = () => {
    const newCont = {
      name: ContUsername, // Use the username input field for the admin's name
    };

    // Update the state using setData
    setContData((prevData) => [...prevData, newCont]);

    closeContModal(); // Close the add admin modal
  };
  const handleDeleteCont = () => {
    closeDeleteCont(); // Close the delete admin modal
  };
  {
    data.map((item, idx) => (
      <div
        key={idx}
        className="md:text-base text-sm flex justify-between bg-clue-black h-16 mb-5 ml-[13%]  rounded-[13px] md:mx-24 py-5 px-2 md:px-5"
      >
        <span className="text-filter-heading">{item.username}</span>
        <div className="flex space-x-2 md:space-x-10">
          <div onClick={() => handleDeleteAdmin(idx)}>
            <span className="text-filter-heading m-0.5 md:mt-0 cursor-pointer">
              <DeleteIcon className="text-clue-logout" fontSize="small" />
            </span>
          </div>
        </div>
      </div>
    ));
  }
  {
    Contdata.map((item, idx) => (
      <div
        key={idx}
        className="md:text-base text-sm flex justify-between bg-clue-black h-16 mb-5 ml-[13%]  rounded-[13px] md:mx-24 py-5 px-2 md:px-5"
      >
        <span className="text-filter-heading">{item.name}</span>
        <div className="flex space-x-2 md:space-x-10">
          <div onClick={() => handleDeleteCont(idx)}>
            <span className="text-filter-heading m-0.5 md:mt-0 cursor-pointer">
              <DeleteIcon className="text-clue-logout" fontSize="small" />
            </span>
          </div>
        </div>
      </div>
    ));
  }

  const openContModal = () => {
    setIsContModalOpen(true);
  };

  const closeContModal = () => {
    setIsContModalOpen(false);
  };

  const openDeleteCont = (idx) => {
    setContIdx(idx);
    setIsDeleteCont(true);
  };

  const closeDeleteCont = () => {
    setIsDeleteCont(false);
  };

  const closeVerifyCodePopup = () => {
    setIsVerifyCodeOpen(false);
  };
  const handleVerifyCodeSuccess = () => {
    // Handle the successful verification here
    // After verification, close the popup
    closeVerifyCodePopup();
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const openDeleteSus = (idx) => {
    setIdx(idx);
    setIsDeleteSus(true);
  };

  const closeDeletesus = () => {
    setIsDeleteSus(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSaveChanges = () => {
    setShowLoading(true);
    changeManager.put(username).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setToastMessages([
            ...toastMessages,
            {
              type: "success",
              title: "Success",
              body: value.message,
            },
          ]);
          localStorage.setItem('username', username);
          setShowLoading(false);
        } else {
          setShowLoading(false);
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setShowLoading(false);
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  };

  const handleCancel = () => {
    setAdminUsername("");
    setAdminEmail("");
    setPassword("");
    closeContModal();
    closeModal(); // Close the add admin modal
  };
  const handleResetPw = () => {
    // Implement your password reset logic here
  };

  const [isDivClicked, setIsDivClicked] = useState(false);

  const handleDivClick = () => {
    setIsDivClicked(true);
  };

  const handleDivBlur = () => {
    setIsDivClicked(false);
  };
  const handleAdminUsernameChange = (e) => {
    const newAdminUsername = e.target.value;

    // Check if the new admin username length is less than 50
    if (newAdminUsername.length >= 50) {
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Invalid Admin Username",
          body: "Admin username should be less than 50 characters.",
        },
      ]);
      // Optionally, you may choose to prevent setting the new admin username
      // Uncomment the line below if you want to prevent setting the new admin username
      return;
    }

    setAdminUsername(newAdminUsername);
  };
  const handleContDelete = () => {
    const id = Contdata[forContIdx].email;

    deletecontManager.removeFromAccount(id).then((value) => {
      if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          listcontManager.get().then((value) => {
            if (!value.error) {
              const baseResponse = value.success;
              if (baseResponse == true) {
                setContData(value.data);
                setShowLoading(false);
              } else {
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "invalid",
                    title: "Error",
                    body: value.message,
                  },
                ]);
              }
            } else {
              setToastMessages([
                ...toastMessages,
                {
                  type: "error",
                  title: "Error",
                  body: value.error,
                },
              ]);
            }
          });
          setShowLoading(false);
          setToastMessages([
            ...toastMessages,
            {
              type: "success",
              title: "Success",
              body: value.message,
            },
          ]);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
    closeDeleteCont();
  };

  return (
    <div className=" bg-black text-white flex flex-col  justify-between ">
      {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      {showLoading4 ? (
        <span className="flex w-full h-[95vh] items-center justify-center">
          <div role="status">
            <svg
              aria-hidden="true"
              class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </span>
      ) : (
        <div className="flex justify-center items-center ">
          <div className=" md:ml-[3%] mx-2 md:w-[100%]  w-[360px] md:mt-10 mt-20 block rounded-[30px] bg-transparent  ">
            <p className=" text-left md:mt-5 md:mx-0 mx-2 cursor-pointer transition-opacity hover:opacity-70 text-[24px] md:text-[28px] md:ml-[0px]  md:mb-12 font-[700] leading-tight text-onclickfield">
              <p className="text-onclickfield " /> Account
            </p>
            <p className="md:mr-[70%] text-[20px] md:text-[24px] md:ml-[0px]  mr-[105px] md:mt-12 mt-10 mb-6 md:mb-12 font-[700] leading-tight text-clue-yellow">
              Your Information
            </p>
            <div className="mb-4 flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
              {" "}
              {/* Use flex-column for mobile view */}
              <input
                type="text"
                className="focus:outline-none focus:ring-0 focus:border-onclickfield peer m-0 block md:h-[56px] h-[45px] ml-[50px] md:ml-[14%] md:mr-[3%] md:w-[27%] rounded-[10px] w-[270px]  bg-clue-black bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray"
                id="username"
                value={username}
                onChange={handleUsernameChange}
                placeholder="Name"
              />
              <input
                readOnly
                type="email"
                className="focus:outline-none focus:ring-0 focus:border-onclickfield peer m-0 block md:h-[56px] h-[45px] ml-[50px]  md:ml-[0px]  md:w-[27%] rounded-[10px] w-[270px] bg-clue-black bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
              />
            </div>

            <button
              type="button"
              className="mb-2 block  md:mx-[3%] md:w-[23%] md:ml-[32%] ml-[95px] mx-10 md:h-[56px] flex-wrap h-[45px] w-[180px] bg-onclickfield rounded-[10px] md:pb-1 md:pl-1 text-center text-[16px] md:text-[19px] font-medium leading-normal text-white transition-opacity hover:opacity-70 md:mt-[50px] mt-[40px]"
              onClick={handleSaveChanges}
            >
              {showLoading ? (
                <span className="flex w-full items-center justify-center">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </span>
              ) : (
                <span>Save Changes</span>
              )}
            </button>

            <p className="md:mt-12 md:ml-[0%] mt-[45px] flex-row items-center  justify-center w-[270px] md:w-[86%] text-[17px] md:text-[20px] text-center ml-[50px] font-[700] text-clue-gray leading-[1.30]">
              If you want to reset your password, you can reset it by <br></br>
              <a
                className="font-[400] text-clue-yellow pl-[2px] md:text-center underline transition-opacity hover:opacity-70  cursor-pointer"
                onClick={openVerifyCodeModal}
              >
                clicking here
              </a>
            </p>

            <div className="flex md:w-[70%] w-[80%] mx-10 justify-between items-center md:mt-10 mt-10 md:mx-[6%]  mb-6 md:mb-6 ">
              <p className="md:mr-[0px] md:text-[22px]  text-[18px] ml-0 md:ml-[0%]    font-[700] leading-tight text-clue-yellow">
                Other Admins
              </p>
              <span
                onClick={openModal}
                className="cursor-pointer underline  md:mr-0 text-[15px] transition-opacity hover:opacity-70  md:text-[18px]  ml-0 md:ml-0   font-[600] leading-tight text-clue-yellow"
              >
                Add Admin
              </span>
            </div>
            {showLoading2 ? (
              <span className="flex w-[80%] items-center justify-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </span>
            ) : (
              <div className="w-[92%] md:w-[84%]  ">
                {data.map((item, idx) => (
                  <div
                    key={idx}
                    className={` md:text-base text-sm flex justify-between bg-clue-black h-16 mb-5 ml-[13%] rounded-[13px] md:mx-24 py-5 px-2 md:px-5 ${
                      idx === data.length - 1 ? "mb-32" : ""
                    }`}
                  >
                    <span className="text-filter-heading ">
                      {item.username}
                    </span>
                    <div className="flex space-x-2 md:space-x-10 ">
                      <div onClick={() => openDeleteSus(idx)}>
                        <span className="text-filter-heading m-0.5 md:mt-0 cursor-pointer">
                          <DeleteIcon
                            className="text-clue-logout transition-opacity hover:opacity-70 "
                            fontSize="small"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="flex md:w-[70%] w-[84%] mx-10 justify-between items-center md:mt-10 mt-10 md:mx-[6%]  mb-6 md:mb-6 ">
              <p className="md:mr-[0px] md:text-[22px]  text-left text-[18px] ml-0 md:ml-[0%]    font-[700] leading-tighttext-clue-yellow">
                Other Contributors
              </p>
              <span
                onClick={openContModal}
                className="cursor-pointer underline transition-opacity hover:opacity-70  text-left md:mr-0 text-[15px] md:text-[18px]  ml-9 md:ml-0   font-[600] leading-tight text-clue-yellow"
              >
                Add Contributors
              </span>
            </div>
            {showLoading3 ? (
              <span className="flex w-[80%] items-center justify-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </span>
            ) : (
              <div className="w-[92%] md:w-[84%]  ">
                {Contdata.map((item, idx) => (
                  <div
                    key={idx}
                    className={` md:text-base text-sm flex justify-between bg-clue-black h-16 mb-5 ml-[13%] rounded-[13px] md:mx-24 py-5 px-2 md:px-5 ${
                      idx === Contdata.length - 1 ? "mb-[6%]" : ""
                    }`}
                  >
                    <span className="text-filter-heading ">
                      {item.username}
                    </span>
                    <div className="flex space-x-2 md:space-x-10 ">
                      <div onClick={() => openDeleteCont(idx)}>
                        <span className="text-filter-heading m-0.5 md:mt-0 cursor-pointer">
                          <DeleteIcon
                            className="text-clue-logout transition-opacity hover:opacity-70 "
                            fontSize="small"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {isDeleteCont && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeDeleteCont}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black rounded-3xl md:w-auto w-80 p-8 px-12 relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                    Delete Contributor
                  </h3>
                  <p className="text-filter-heading">
                    Are you sure you want to remove this user as a contributor?
                    This action will remove all their contributions across all
                    books.
                  </p>
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeDeleteCont}
                      className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-clue-logout text-white px-4 py-2 rounded"
                      onClick={handleContDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isContModalOpen && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeContModal}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black p-8 px-12 md:w-auto w-80  rounded-3xl relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
                    Add Contributor
                  </h3>
                  <select
                    className="mb-4 px-5 w-60 md:w-80 bg-clue-black text-white p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    onChange={handleUserIDChange}
                  >
                    <option value={ContEmail} disabled selected >
                      Select Username
                    </option>
                    {users.map((user) => (
                      <option key={user.username} value={user.email}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                  <br />
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={handleCancel}
                      className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-2 px-4"
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-clue-purchase text-white px-4 py-2 rounded"
                      onClick={addContribution}
                    >
                      {showLoading1 ? (
                        <span className="flex w-full items-center justify-center">
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        </span>
                      ) : (
                        <span>Add</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="md:mb-4  flex flex-row items-center">
          <input
            type="text"
            className="md:pl-8 focus:outline-none focus:border-onclickfield peer md:mb-0 mb-28 m-0 block md:h-[56px] h-[45px] ml-[50px] md:ml-[9%] md:mr-[-50px] mr-[-40px] md:w-[69%] rounded-[10px] w-[270px] bg-clue-black bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray shadow-white" // Added shadow-white class
            id="username"
            value={username}
            onChange={handleUsernameChange}
            placeholder="Name"
          />
          <DeleteIcon className="cursor-pointer text-clue-logout md:mb-0 mb-28" onClick={openDeleteSus} />
        </div> */}
            {isVerifyCodeOpen && (
              <div
                className="fixed inset-0 flex items-center my-4 h-screen justify-center z-50"
                onClick={closeVerifyCodePopup}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                {/*
    Pass the onClose function as a prop to the Verify_Code component to close the modal when needed
    */}
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="z-10 mb-28 my-4"
                >
                  <div>
                    <Verify_Code
                      email={email}
                      onBack={back}
                      isPopupOpen={isPopupOpen}
                      closePopup={handleVerifyCodeSuccess}
                      onClose={() => setIsVerifyCodeOpen(false)}
                    />
                  </div>
                </div>
              </div>
            )}
            {isModalOpen && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeModal}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black p-8 px-12 md:w-auto w-80  rounded-3xl relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author  text-left mb-6">
                    Add Admin
                  </h3>
                  <p className="text-filter-heading  w-60 md:w-96  text-left mb-6">
                    You are about to create a privileged user that will have all
                    the read and write authorities to the data
                  </p>
                  <input
                    type="text"
                    className="mb-4 w-60 md:w-80 my-2 py-3 bg-clue-black text-filter-heading p-2 rounded-[10px] border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    id="username"
                    value={adminUsername}
                    onChange={handleAdminUsernameChange}
                    placeholder="Username"
                  />
                  <br />
                  <input
                    type="text"
                    className="mb-6 w-60 md:w-80 my-2 py-3 bg-clue-black text-filter-heading p-2 rounded-[10px] border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                    id="email"
                    value={adminEmail}
                    onChange={(e) => setAdminEmail(e.target.value)}
                    placeholder="Email"
                  />
                  <br />

                  <div
                    className={`  rounded-[10px] h-[3.3rem] pt-1 md:mx-10 w-[240px] border  md:w-80  ${
                      isDivClicked ? "border-clue-purchase" : "border-zinc-500"
                    } focus:ring-0 focus:outline-none`}
                    onClick={handleDivClick}
                    onBlur={handleDivBlur}
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      className="mb-4 w-52 md:w-72  border-none  bg-clue-black text-filter-heading p-2 rounded-[10px] border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Password"
                    />
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon
                        className=" md:top-[50%] top-[12px] right-[50px] md:right-[80px] mr-1 cursor-pointer text-gray-300"
                        onClick={toggleShowPassword}
                      />
                    ) : (
                      <VisibilityOutlinedIcon
                        className=" md:top-[18px] top-[12px] right-[50px] md:right-[80px] mr-1 cursor-pointer text-gray-300"
                        onClick={toggleShowPassword}
                      />
                    )}
                  </div>
                  <br />
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={handleCancel}
                      className="text-filter-heading transition-opacity hover:opacity-70 mr-4 rounded-md border-2 border-filter-heading py-0.5 px-6"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleAddAdmin}
                      className="bg-clue-logout transition-opacity hover:opacity-70 border-filter-heading text-clue-book-author rounded-[9px] px-7 py-0.5 "
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            )}

            {isDeleteSus && (
              <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={closeDeletesus}
              >
                <div className="bg-black opacity-50 absolute inset-0"></div>
                <div
                  className="bg-clue-black rounded-3xl md:w-auto w-80  p-8 px-12 relative z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3 className="text-clue-book-author font-[600]  text-left mb-4">
                    Delete Admin
                  </h3>
                  <p className="text-filter-heading flex items-start justify-start text-left w-60  md:w-full">
                    Are you sure you want to delete this admin profile?
                  </p>
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={closeDeletesus}
                      className="text-filter-heading mr-4 transition-opacity hover:opacity-70 border-2 rounded-md border-filter-heading py-0.5 px-6"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleDeleteAdmin}
                      className="bg-clue-logout transition-opacity hover:opacity-70 text-white px-7 py-0.5 rounded"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default Accounts;
