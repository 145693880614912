import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import myImage from "../../assets/images/cover.png";
import "@fontsource/inter";
import BackButton from "../backbutton/backbutton";
import Footer from "../../components/footer/footer.js";
import ContributionsList from "../../models/website/author dasboard/http/authordashhttp.js";
import Export from "../../models/website/export/webexporthttp/webexporthttp.js";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

function ContributionDetails() {
  const contributionlist = new ContributionsList();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("book_id");
  const [contributionData, setContributionData] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showLoading2, setShowLoading2] = useState(false);

  const handleDownload = async (bookId) => {
    setShowLoading2(true);
    try {
      const exportInstance = new Export();
      const response = await exportInstance.ExportContByBookID(bookId);

      if (response?.success) {
        // Assume the response contains the base64 data in a property called 'base64Data'
        const base64Data = response.data.replace(
          /^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/,
          ""
        );

        // Remove whitespaces or line breaks from the base64 data
        const cleanedBase64Data = base64Data.replace(/\s/g, "");

        // Convert base64 to binary
        const binaryData = atob(cleanedBase64Data);

        // Create ArrayBuffer and uint8 array
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create Blob
        const blob = new Blob([uint8Array], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Sales_Report_List.xlsx";

        // Append link to the document
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      } else {
        // Handle error case
        console.error(response.errorMessage);
      }
    } catch (error) {
      // Handle other errors
      console.error(error.toString());
    } finally {
      setShowLoading2(false);
    }
  };

  const navigateToEntries = () => {
    navigate(
      "/account/dashboard/author-dashboard/competition/view-entries?book_id=" +
      id
    );
  };

  const navigateToSales = () => {
    navigate(
      "/account/dashboard/author-dashboard/competition/view-sales?book_id=" + id
    );
  };

  const convertDateFormat = (startDate) => {
    const parsedDate = new Date(startDate);

    if (isNaN(parsedDate)) {
      console.error("Invalid date format");
      return "";
    }

    // Use toLocaleDateString to format the date as "Nov 05, 2023"
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedStartDate = parsedDate.toLocaleDateString("en-US", options);
    return formattedStartDate;
  };
  useEffect(() => {
    const fetchData = async () => {
      setShowLoading(true);
      try {
        const contributionsData = await contributionlist.getbyid(id);
        if (contributionsData?.success) {
          setContributionData(contributionsData.data);
        } else {
          setShowLoading(true);
        }
      } catch (error) {
        // Handle error - show user-friendly message
        console.error("Error fetching contribution details:", error);
      } finally {
        setShowLoading(false);
      }
    };

    fetchData();
  }, [id, setContributionData, setShowLoading]);

  const book = contributionData?.[0]?.book;
  const contribution = contributionData?.[0]?.contribution;
  const entrants = contributionData?.[0]?.entrants;
  const contributor_earnings = contributionData?.[0]?.contributor_earnings;
  const total_earnings = contributionData?.[0]?.total_earnings;
  const other_contributors = contributionData?.[0]?.other_contributors;
  const winners = contributionData?.[0]?.winners;

  return (
    <div style={{ fontFamily: "Inter" }}>
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-10 flex md:flex-row flex-col justify-between">
            <div>
              <BackButton />
            </div>
            <div className="flex md:flex-row flex-col md:my-0 mt-5 md:py-0 md:space-x-10 mr-10 md:mr-24">
              <span
                className="underline text-clue-yellow transition-opacity hover:opacity-70 cursor-pointer md:my-0 my-2"
                onClick={navigateToEntries}
              >
                <PeopleAltIcon className="mx-2" fontSize="small" />
                View Entries
              </span>
              <span
                className="underline transition-opacity hover:opacity-70 text-clue-yellow cursor-pointer md:my-0 my-2"
                onClick={navigateToSales}
              >
                <AttachMoneyIcon className="mx-2" fontSize="small" />
                View Sales
              </span>
            </div>
          </div>
          <div className="ml-[8.5%] md:ml-[10%]">
            <div className="flex">
              <span className="text-clue-yellow font-bold text-[28px] mt-10 ">
                Information
              </span>
            </div>
            <div className="flex-1 max-w-5xl m-4 lg:ml-16 lg:mt-7 p-6 rounded-md relative">
              <div className="flex flex-col md:flex-row flex-wrap justify-center md:justify-start items-center">
                <div className="flex-none mb-4 md:mb-0 w-full md:w-52">
                  <img
                    src={book?.cover}
                    alt="Cover"
                    className="h-72 w-60 object-cover mx-auto"
                  />
                </div>

                <div className="px-6 ml-0 md:ml-10 flex-1 text-center md:text-left">
                  <span className="md:ml-0 ml-28"></span>
                  <h2 className="text-clue-book-title font-semibold text-2xl mb-2">
                    {book?.title}
                  </h2>
                  <p className="text-clue-book-author text-base transition-opacity hover:opacity-70 font-medium mb-10">
                    {book?.link_to_author ? (
                      <a
                        href={book.link_to_author}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {book.author}
                      </a>
                    ) : (
                      book?.author
                    )}
                  </p>
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Visible on Product Page
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {book?.is_visible_on_product_page ? "Yes" : "No"}
                  </p>
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Competition Open Date
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {convertDateFormat(book?.start_date)}
                  </p>
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Number of Entrants
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {entrants}
                  </p>
                </div>
                <div className="px-6 flex-1 text-center md:text-left">
                  <p className="text-clue-draw-amount mt-2 md:mt-[105px] mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Ages
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {book?.under_18_appropriate ? "14+" : "18+"}
                  </p>
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Competition Close Date
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {convertDateFormat(book?.end_date)}
                  </p>
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    {winners && winners.length > 1 ? "Winners" : "Winner"}
                  </p>

                  {winners ? (
                    <div className="flex flex-wrap">
                      <div className="flex p-0 px-0">
                        <p className="text-clue-book-author font-bold mb-2 text-base">
                          {winners.length > 0
                            ? winners.map((winner, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && ", "}
                                {winner.username}
                              </React.Fragment>
                            ))
                            : "Pending"}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <hr className="mx-10 md:mx-24 bg-filter-heading" />
          <div className=" md:flex-row flex">
            <span className="text-clue-yellow font-bold text-[28px] mt-10 ml-[8.5%] md:ml-[10%]">
              Finances
            </span>
          </div>
          <div class="m-4 lg:mt-8 lg:mr-8 ml-[10%] w-[75%] md:w-[55%] md:mx-[18%] block rounded-xl bg-black text-center">
            <div class="flex justify-between border-b-2 border-filter-heading mt-8 p-2 px-4">
              <div>
                <p class="text-account-color">Total Earnings</p>
              </div>
              <div>
                <p class="text-account-color">${total_earnings}</p>
              </div>
            </div>
            <div class="flex justify-between border-b-2 border-filter-heading px-4 p-2">
              <div>
                <p class="text-account-color">Prize Amount</p>
              </div>
              <div>
                <p class="text-account-color">
                  {/* ${book?.prize_amount} */}
                  {book?.prize_amount && book?.prize_amount.length > 0
                    ? book?.prize_amount.map((amount, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && ", "}${amount}
                      </React.Fragment>
                    ))
                    : "TBD"}
                </p>
              </div>
            </div>
            <div class="flex justify-between p-2 px-4">
              <div>
                <p class="text-account-color">Contributor Earnings</p>
              </div>
              <div>
                <p class="text-account-color">
                  $
                  {contributor_earnings !== undefined &&
                    contributor_earnings !== null
                    ? contributor_earnings.toFixed(2)
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>
          <hr className="mx-10 md:mx-24 mt-12 md:mt-24 border-filter-heading" />
          <div className="flex">
            <span className="text-clue-yellow font-bold text-[28px] mt-10 ml-[8.5%] md:ml-[10%]">
              Other Contributors
            </span>
          </div>
          <div className="m-4 lg:mt-8 lg:mr-8 ml-[10%] w-[75%] md:w-[55%] md:mx-[18%] block rounded-xl bg-black text-center">
            {other_contributors ? (
              other_contributors.map((contributor, index) => (
                <React.Fragment key={contributor.id}>
                  <div
                    className={`flex ${index !== other_contributors.length - 1
                        ? "border-b-2 border-filter-heading"
                        : ""
                      } justify-between p-2 px-4`}
                  >
                    <div>
                      <p className="text-account-color">
                        {`Contributor ${index + 1}`}
                      </p>
                    </div>
                    <div>
                      <p className="text-account-color">
                        {contributor.username}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <p>No contributors available.</p>
            )}
          </div>
          <hr className="mx-10 md:mx-24 mt-12 md:mt-24 border-filter-heading" />
          <div className="flex ">
            <span className="text-clue-yellow font-bold text-[28px] mt-10 ml-[8.5%] md:ml-[10%]">
              Suspects
            </span>
          </div>
          <div className="m-4 lg:mt-8 lg:mr-8 ml-[10%] w-[75%] md:w-[55%] md:mx-[18%] block rounded-xl bg-black text-center">
            {Array.isArray(book?.suspects) &&
              book?.suspects.map((suspect, index) => (
                <div
                  key={index}
                  className={`flex ${index !== book?.suspects.length - 1
                      ? "border-b-2 border-filter-heading"
                      : ""
                    } justify-between p-2 px-4`}
                >
                  <div>
                    <p className="text-account-color font-bold">{suspect}</p>
                  </div>
                </div>
              ))}{" "}
            <div className="mb-20"></div>
          </div>
          <div className="mt-10">
            <Footer />
          </div>
        </>
      )}
    </div>
  );
}
export default ContributionDetails;
