import React, { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import AllUsersManager from "../../models/admin/users/https/getallusershttp";
import Export from "../../models/admin/export/http/exporthttp.js";

function Users() {
  const [isDeleteBook, setIsDeleteBook] = useState(false);
  const [isRestoreBook, setIsRestoreBook] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const filterRef = useRef(null);
  const buttonRef = useRef(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showLoading2, setShowLoading2] = useState(false);

  const [filters, setFilters] = useState({
    ageUnder18: false,
    filterBy: "",
    sortBy: "",
    orderBy: "",
    deletedUsers: false,
  });

  const openDeleteBook = () => {
    setIsDeleteBook(true);
  };

  const closeDeleteBook = () => {
    setIsDeleteBook(false);
  };
  const openRestoreBook = () => {
    setIsRestoreBook(true);
  };

  const closeRestoreBook = () => {
    setIsRestoreBook(false);
  };
  const navigate = useNavigate();
  const allUsersManager = new AllUsersManager();
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    // Call the API when the component mounts
    const fetchData = async () => {
      setShowLoading(true);
      try {
        const response = await allUsersManager.getuserlist();

        setUserList(response.data);
         // Assuming the API returns user data in the "data" property
      } catch (error) {
        console.error("Error fetching user list:", error.message);
      } finally {
        // Set loading to false whether the request is successful or not
        setShowLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleUserClick = (userId) => {
    // Use navigate to programmatically navigate to the user's page
    navigate(`/adboard/dashboard/users/user?userId=${userId}`);
  };

  function handleFilterChange(event) {
    const { name, value, type, checked } = event.target;
  
    if (type === "radio" && name === "sortBy") {
      // Handle radio buttons for "Sort By"
      if (value === "DeletedUsers") {
        setFilters((prevFilters) => ({
          ...prevFilters,
          sortBy: "DeletedUsers",
          deletedUsers: true,
        }));
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          sortBy: value,
          deletedUsers: false, // Uncheck deletedUsers if another radio button is selected
        }));
      }
    } else {
      // Handle other filters
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  }
  

  // Inside your component function
// Inside your component function
// Inside your component function
const deletedUsers = userList.filter((book) => book.is_deleted);
const nonDeletedUsers = userList.filter((book) => !book.is_deleted);

// By default, show non-deleted users first, followed by deleted users
let allUsers = [...nonDeletedUsers, ...deletedUsers];

// If Deleted Users filter is selected, move deleted users to the top
if (filters.deletedUsers) {
  allUsers = [...deletedUsers, ...nonDeletedUsers];
}

const filteredBooks = allUsers.filter((book) => {
  // Filter by search term
  if (
    searchTerm &&
    !book.username.toLowerCase().includes(searchTerm.toLowerCase())
  ) {
    return false;
  }

  return true;
});


let sortedBooks = [...filteredBooks];
if (filters.sortBy) {
  sortedBooks.sort((a, b) => {
    if (filters.sortBy === "DeletedUsers") {
      // When sorting by "Deleted Users," move deleted users based on ascending/descending order
      const deletedUserComparison = a.is_deleted - b.is_deleted;
      if (filters.orderBy === "Ascending") {
        return deletedUserComparison || a.username.localeCompare(b.username);
      } else {
        return -deletedUserComparison || a.username.localeCompare(b.username);
      }
    } else {
      // Handle non-DeletedUsers sorting
      const deletedUserComparison = a.is_deleted - b.is_deleted;
      if (deletedUserComparison !== 0) {
        return deletedUserComparison;
      }
    }
    if (filters.sortBy === "TotalSpent") {
      const prizeA = Number(a.total_spent);
      const prizeB = Number(b.total_spent);
      return filters.orderBy === "Ascending" ? prizeA - prizeB : prizeB - prizeA;
    } else if (filters.sortBy === "TotalWinnings") {
      const prizeA = Number(a.total_winnings);
      const prizeB = Number(b.total_winnings);
      return filters.orderBy === "Ascending" ? prizeA - prizeB : prizeB - prizeA;
    } else if (filters.sortBy === "TotalCompetitions") {
      const prizeA = Number(a.total_competitions);
      const prizeB = Number(b.total_competitions);
      return filters.orderBy === "Ascending" ? prizeA - prizeB : prizeB - prizeA;
    } else if (filters.sortBy === "ActiveCompetitions") {
      const prizeA = Number(a.active_competitions);
      const prizeB = Number(b.active_competitions);
      return filters.orderBy === "Ascending" ? prizeA - prizeB : prizeB - prizeA;
    } else if (filters.sortBy === "DeletedUsers") {
      if (a.is_deleted && !b.is_deleted) {
        return 1; // Move deleted user down
      } else if (!a.is_deleted && b.is_deleted) {
        return -1; // Move deleted user up
      }
    }
    return 0;
  });
}

  function resetFilters() {
    setFilters({
      ageUnder18: false,
      filterBy: "",
      sortBy: "",
      orderBy: "",
    });
    setSearchTerm(""); // Reset the search term as well
  }
  const handleDownload = async () => {
    setShowLoading2(true);
    try {
      const exportInstance = new Export();
      const response = await exportInstance.Export_USERS();

      if (response?.success) {
        // Assume the response contains the base64 data in a property called 'base64Data'
        const base64Data = response.data.replace(
          /^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/,
          ""
        );

        // Remove whitespaces or line breaks from the base64 data
        const cleanedBase64Data = base64Data.replace(/\s/g, "");

        // Convert base64 to binary
        const binaryData = atob(cleanedBase64Data);

        // Create ArrayBuffer and uint8 array
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create Blob
        const blob = new Blob([uint8Array], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "User_Details_list.xlsx";

        // Append link to the document
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      } else {
        // Handle error case
        console.error(response.errorMessage);
      }
    } catch (error) {
      // Handle other errors
      console.error(error.toString());
    } finally {
      setShowLoading2(false);
    }
  };

  return (
    <div className="w-full md:h-screen" style={{ fontFamily: "Inter" }}>
      <div className="ml-0 md:ml-10">
        <div className="flex justify-start md:justify-start mt-24 md:mt-16 mx-4 md:mx-2">
          <p className="text-clue-purchase font-bold md:ml-[0px] ml-[10px] text-[24px] md:text-2xl w-[96%] text-left md:w-[83%]">
            Users
          </p>
          <p
            onClick={() => !showLoading2 && handleDownload()}
            class="flex cursor-pointer  md:w-48 w-60 md:text-[16px] text-clue-yellow  transition-opacity hover:opacity-70  underline"
          >
            <span>
              {showLoading2 ? (
                <span className="flex w-full items-center justify-center">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline md:ml-14 md:mr-0 mr-10 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </span>
              ) : (
                <span className="">Export User Details</span>
              )}
            </span>
          </p>
        </div>
        <div className="md:mt-16 mt-10 md:w-[90%] mx-5 md:mx-0 flex flex-col items-start relative">
          <div className="flex w-full items-center">
            <div className="flex-grow flex items-center bg-clue-black rounded-xl p-2">
              <SearchIcon className="text-clue-yellow m-2" />
              <input
                type="text"
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search Users"
                className="w-full bg-clue-black text-white placeholder-white border-none outline-none focus:ring-0"
              />
            </div>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center h-14 bg-clue-black text-white ml-5 px-5 p-2 rounded-xl"
              ref={buttonRef}
            >
              <FilterListIcon className="text-clue-yellow" />
              <span className="ml-2 hidden md:block">Filters</span>
            </button>
          </div>
          {showFilters && (
            <>
              <div
                className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-40"
                onClick={() => setShowFilters(false)}
              ></div>
              <div
                className="bg-clue-black md:h-[360px] mt-4 p-4 rounded-xl absolute top-12 right-0 z-50"
                style={{ minWidth: "290px" }}
                ref={filterRef}
              >
                <div className="w-full text-right mt-4 mb-4">
                  <span
                    className="text-clue-purchase mr-5 underline cursor-pointer"
                    onClick={resetFilters}
                  >
                    Reset Filters
                  </span>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 text-clue-book-author">
                  <div className="text-left  md:col-span-4">
                    <div className="mb-4 pb-2 border-b border-sidebar-hover-color">
                      <p className="font-bold mb-2 text-filter-heading md:mx-5">
                        Order By
                      </p>
                      <label className="block ml-5 mb-1 md:ml-0">
                        <input
                          type="radio"
                          name="orderBy"
                          value="Ascending"
                          checked={filters.orderBy === "Ascending"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Ascending</span>
                      </label>
                      <label className="block ml-5 md:ml-0">
                        <input
                          type="radio"
                          name="orderBy"
                          value="Descending"
                          checked={filters.orderBy === "Descending"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Descending</span>
                      </label>
                    </div>
                    <div className="mb-4 pb-2  border-sidebar-hover-color">
                      <p className="font-bold mb-2 text-filter-heading md:mx-5">
                        Sort By
                      </p>
                      <label className="block ml-5 md:ml-0 mb-1">
                        <input
                          type="radio"
                          name="sortBy"
                          value="TotalSpent"
                          checked={filters.sortBy === "TotalSpent"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Total Spent</span>
                      </label>
                      <label className="block ml-5 md:ml-0 mb-1">
                        <input
                          type="radio"
                          name="sortBy"
                          value="TotalWinnings"
                          checked={filters.sortBy === "TotalWinnings"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Total Winnings</span>
                      </label>
                      <label className="block ml-5 md:ml-0 mb-1">
                        <input
                          type="radio"
                          name="sortBy"
                          value="TotalCompetitions"
                          checked={filters.sortBy === "TotalCompetitions"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Total Competitions</span>
                      </label>
                      <label className="block ml-5 md:ml-0 mb-1">
                        <input
                          type="radio"
                          name="sortBy"
                          value="ActiveCompetitions"
                          checked={filters.sortBy === "ActiveCompetitions"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Active Competitions</span>
                      </label>
                      <label className="block ml-5 md:ml-0 mb-1">
  <input
    type="radio"
    name="sortBy"
    value="DeletedUsers"
    checked={filters.sortBy === "DeletedUsers"}
    onChange={handleFilterChange}
    className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
  />
  <span className="ml-2">Deleted Users</span>
</label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-20">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="overflow-x-auto   mt-10 mx-10 md:w-[90%]">
          <table className="table-fixed mb-14 min-w-full bg-clue-black w-[800px] md:w-[50vw] rounded text-clue-book-author">
            <thead>
              <tr className="border-b border-filter-heading">
                <th className="p-0 py-5  border-r border-filter-heading">#</th>
                <th
                  className="p-2 overflow-hidden overflow-ellipsis py-5  border-r border-filter-heading"
                  style={{ wordWrap: "break-word" }}
                >
                  Username
                </th>
                <th
                  className="p-2 overflow-hidden overflow-ellipsis py-5  border-r border-filter-heading"
                  style={{ wordWrap: "break-word" }}
                >
                  Email
                </th>
                <th
                  className="p-2 overflow-hidden overflow-ellipsis py-5  border-r border-filter-heading"
                  style={{ wordWrap: "break-word" }}
                >
                  Contributor
                </th>
                <th
                  className="p-2 overflow-hidden overflow-ellipsis py-5  border-r border-filter-heading"
                  style={{ wordWrap: "break-word" }}
                >
                  Active Competitions
                </th>
                <th
                  className="p-2 overflow-hidden overflow-ellipsis py-5  border-r border-filter-heading"
                  style={{ wordWrap: "break-word" }}
                >
                  Total Competitions
                </th>
                <th className="p-2 py-5  border-filter-heading">Total Spent</th>
              </tr>
            </thead>
            <tbody>
              {sortedBooks.map((book, index) => (
                <tr
                  key={index}
                  className={`border-b border-filter-heading hover:bg-black ${
                    book.is_deleted ? 'bg-isdeleted' : '' // Add your desired background color class here
                  }`}
                >
                  <td className="p-0 py-5 border-r  cursor-pointer border-filter-heading"      
                    onClick={() => handleUserClick(book.user_id)}>
                    <a
                      className="block w-full h-full"
                    >
                      {index + 1}
                    </a>
                  </td>

                  <td className="p-2 py-5 cursor-pointer border-r  border-filter-heading"
                        onClick={() => handleUserClick(book.user_id)}>
                    <a
                      className="block w-full h-full overflow-hidden overflow-ellipsis"
                      style={{ wordWrap: "break-word" }}
                    >
                      {book.username}
                    </a>
                  </td>

                  <td className="p-2 py-5 cursor-pointer border-r  border-filter-heading"
                        onClick={() => handleUserClick(book.user_id)}>
                    <a
                      className="block w-full h-full overflow-hidden overflow-ellipsis"
                      style={{ wordWrap: "break-word" }}
                    >
                      {book.email}
                    </a>
                  </td>
                  <td className="p-2 py-5 cursor-pointer border-r  border-filter-heading"
                        onClick={() => handleUserClick(book.user_id)}>
                    <a
                      className="block w-full h-full overflow-hidden overflow-ellipsis"
                      style={{ wordWrap: "break-word" }}
                    >
                       {book.is_contributor ? "Yes" : "No"}
                    </a>
                  </td>
                  <td className="p-2 py-5 cursor-pointer border-r  border-filter-heading"
                        onClick={() => handleUserClick(book.user_id)}>
                    <a
                      className="block w-full h-full"
                    >
                      {book.active_competitions}
                    </a>
                  </td>
                  <td className="p-2 py-5 border-r cursor-pointer border-filter-heading"
                        onClick={() => handleUserClick(book.user_id)}>
                    <a
                      className="block w-full h-full"
                    >
                      {book.total_competitions}
                    </a>
                  </td>
                  <td className="p-2 py-5 cursor-pointer  border-filter-heading "
                        onClick={() => handleUserClick(book.user_id)}>
                    <a
                      className="block w-full h-full"
                    >
                      {book.total_spent}
                    </a>
                  </td>
                </tr>
              ))}
              <tr className="border-b-0">
                <td className="py-10 border-r border-filter-heading"></td>
                <td className="py-10 border-r border-filter-heading"></td>
                <td className="py-10 border-r border-filter-heading"></td>
                <td className="py-10 border-r border-filter-heading"></td>
                <td className="py-10 border-r border-filter-heading"></td>
                <td className="py-10 border-r border-filter-heading"></td>
                <td className="py-10  border-filter-heading"></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {isDeleteBook && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={closeDeleteBook}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black p-8 px-12 rounded-lg relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
              Delete Book
            </h3>
            <p className="text-filter-heading">
              Are you sure you want to delete this book?
            </p>
            <div className="flex justify-end mt-6">
              <button
                onClick={closeDeleteBook}
                className="text-filter-heading mr-4"
              >
                Cancel
              </button>
              <button className="bg-clue-logout text-white px-4 py-2 rounded">
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {isRestoreBook && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={closeRestoreBook}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black p-8 px-12 rounded-lg relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6">
              Restore Book
            </h3>
            <p className="text-filter-heading">
              Are you sure you want to restore this book?
            </p>
            <div className="flex justify-end mt-6">
              <button
                onClick={closeRestoreBook}
                className="text-filter-heading mr-4"
              >
                Cancel
              </button>
              <button className="bg-onclickfield text-white px-4 py-2 rounded">
                Restore
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Users;
