import React, { useState,useEffect } from 'react';
import '@fontsource/inter';
import WestIcon from "@mui/icons-material/West";
import Verify_Code from './verify_code'; // Import the Verify_Code component
import Footer from '../footer/footer';
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../components/toasts/toast";
import WebResetPass from '../../models/website/auth/https/webresetpasshttp';
const resetPassInstance = new WebResetPass();
function Reset_mail() {
    const navigate = useNavigate();
    useEffect(() => {
     
        const websiteToken = localStorage.getItem('websiteToken');
    
        if (websiteToken) {
          // Use React Router's useHistory hook to navigate
          navigate('/account/dashboard');
        } 
       
       
      }, );
    const [email, setEmail] = useState('');
    const [step, setStep] = useState(1);
    
    const location = useLocation();
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const type = "user";
    const [toastMessages, setToastMessages] = useState([]);

      const handleSendCode = async () => {
        // Perform any email verification logic here
        // If the email is valid, proceed to the verification step
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailRegex.test(email)) {
            // Invalid email format, show an error message or take appropriate action
            
                setToastMessages([
                    ...toastMessages,
                    {
                        type: "invalid",
                        title: "Email",
                        body: "The Email is incorrect",
                    },
                ]);
            
            return; // Do not proceed to the verification page
        }
        try {
            // Call the reset1 method from ResetPass class
        
            const baseResponse = await resetPassInstance.reset1(email);
            setStep(2);
        } catch (error) {
            // Handle error if the reset1 method fails
            console.error(error);
        }
    };
  
    const handleBackClick = () => {
      const segments = location.pathname.split('/').filter(Boolean);
      if (segments.length > 1) {
        segments.pop();
        navigate('/' + segments.join('/'));
      } else {
        navigate('/');
      }
      setStep(1);
    };
    const back= () => {setStep(1);}
    

    return (
        <div className=" bg-black text-white flex flex-col justify-between">
            {toastMessages.map((toast, index) => (
                <Toast
                    key={index}
                    toasts={[toast]}
                    onClose={() => {
                        // Remove the toast message when it's closed
                        const updatedToasts = [...toastMessages];
                        updatedToasts.splice(index, 1);
                        setToastMessages(updatedToasts);
                    }}
                />
            ))}
        <div className="flex  justify-center items-center ">
            
            {step === 1 && (
                <div className="mx-2 md:w-[460px] w-[320px] md:h-[400px] h-[350px] md:mb-[15vh] md:mt-[15vh] mt-8 block rounded-[30px] bg-clue-black">
                    <p className="md:mr-[117px] md:text-[24px] text-[22px] mr-[250px] md:w-[320px] w-[280px] md:mt-12 mt-10 md:mb-8 mb-5 font-[700] leading-tight text-clue-yellow">
                        <WestIcon className="text-clue-yellow mr-3 mb-1 cursor-pointer transition-opacity hover:opacity-70" onClick={handleBackClick} />Reset Password
                    </p>

                    <div>
                        <p className="md:mb-8 md:mt-5 mt-7 w-60 md:w-72 text-[17px] md:text-[20px] md:ml-[55px] text-left ml-[40px] font-[400] text-clue-gray leading-[1.30] text-lg">
                            Please enter your email that you used for your account
                        </p>
                        <div className={'relative md:mb-7 mb-5 md:mt-0 mt-7'}>
                            <input
                                type="email"
                                class="focus:outline-none focus:ring-0 focus:border-onclickfield peer m-0 block md:h-[56px] h-[45px] md:mx-14 md:w-[370px] mx-10 rounded-[10px] w-[245px] border-[1px] border-solid border-field-clue-gray bg-clue-black bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-field-clue-gray"
                                id="email"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="Email"
                            />

                        </div>


                        <button
                            type="button"
                            className="mb-2 block md:mx-14 md:w-[370px] md:mt-9 mt-8 mx-10 md:h-[56px] h-[50px] w-[245px] bg-onclickfield rounded-[10px] md:pb-1 md:pl-1 text-center text-[19px] md:text-[22px] font-[700] leading-normal text-white transition-opacity hover:opacity-70"
                            onClick={handleSendCode}
                        >
                            Send Code
                        </button>
                    </div>


                </div>
            )}
            {step === 2 && (
                <Verify_Code email={email} type={type} onBack={back} />
            )}
          
        </div>
        
        <div className='md:mt-[2%] mt-[30%] bottom-0 '>
            <Footer/>
        </div>
    </div>
    );
}

export default Reset_mail;
