import React from "react";
import WestIcon from "@mui/icons-material/West";
import { useNavigate, useLocation } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const forId = searchParams.get("bookID");
  const handleBackClick = () => {
    const segments = location.pathname.split('/').filter(Boolean);
    if (location.state && location.state.fromHome) {
      // If coming from home, navigate back to home
      navigate('/home');
    } else if (location.state && location.state.fromSales) {
      // If coming from an entry page, navigate back to the entry page
      navigate(`/adboard/dashboard/collection/view-sales?id=${forId}`);
    }
    else {
      // Navigate back normally
      if (segments.length > 1) {
        segments.pop();
        navigate('/' + segments.join('/'));
      } else {
        navigate('/');
      }
    }
  };

  return (
    <div className="flex ml-10 md:ml-24 cursor-pointer transition-opacity hover:opacity-70" onClick={handleBackClick}>
      <WestIcon className="text-clue-yellow" />
      <span className="text-clue-yellow ml-2 hidden md:inline ">Back</span>
    </div>
  );
}

export default BackButton;
