import React, { useState, useEffect } from "react";
import fbImage from "../../assets/images/icons8-facebook-100.png";
import InstaImage from "../../assets/images/icons8-instagram-100.png";
import twitterImage from "../../assets/images/icons8-twitterx-100.png";
import TermsOfServiceModal from "./termsofservice";
import PrivacyPolicyModal from "./privacypolicy";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import ContactModal from "./contactus";
import Input from "@mui/material/Input";
import AllCompList from "../../models/website/public/https/comphttp";
import CookiePolicyModal from "./cookiepolicy";
import { Contrast } from "@mui/icons-material";
import Toast from "../toasts/toast";
function Footer() {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isCookieModalOpen, setIsCookieModalOpen] = useState(false);
  const [isDivClicked, setIsDivClicked] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [cookiePolicy, setCookiePolicy] = useState("");
  const [contact_info, setContact_Info] = useState("");
  const handleDivClick = () => {
    setIsDivClicked(true);
  };

  const handleDivBlur = () => {
    setIsDivClicked(false);
  };
  const openTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  const [isPPModalOpen, setIsPPModalOpen] = useState(false);

  const openPPModal = () => {
    setIsPPModalOpen(true);
  };

  const closePPModal = () => {
    setIsPPModalOpen(false);
  };
  const openContactModal = () => {
    setIsContactModalOpen(true);
  };

  const closeContactModal = () => {
    setIsContactModalOpen(false);
  };
  const openCookieModal = () => {
    setIsCookieModalOpen(true);
  };

  const closeCookieModal = () => {
    setIsCookieModalOpen(false);
  };
  const [email, setEmail] = useState("");
  const handleChange = (e) => {
    // Update the email state when the input value changes
    setEmail(e.target.value);
  };
  const [toastMessages, setToastMessages] = useState([]);
  // function convertHtmlToText(html) {
  //   const doc = new DOMParser().parseFromString(html, "text/html");
  //   return doc.body.textContent || "";
  // }
  // function convertHtmlToStyledText(html) {
  //   return { __html: html };
  // }]
  const api = new AllCompList();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const footerResponse = await api.getfooter();
        if(footerResponse.success){
        // Filter data based on type
        const termsData = footerResponse.data.find(
          (item) => item.type === "terms-and-conditions"
        );
        const privacyData = footerResponse.data.find(
          (item) => item.type === "privacy-policy"
        );
        const cookieData = footerResponse.data.find(
          (item) => item.type === "cookies-policy"
        );
        const contact_info = footerResponse.data.find(
          (item) => item.type === "contact-information"
        );
        setTermsAndConditions(termsData?.text || "");
        setPrivacyPolicy(privacyData?.text || "");
        setCookiePolicy(cookieData?.text || "");
        setContact_Info(contact_info?.text || "");
      }
      else{
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Error",
            body: "Some Error Occurred",
          },
        ]);
      }
    } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleIconClick = async () => {
    // Call the add API function when the icon is clicked
    try {
      if (!email) {
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Missing Information",
            body: "Email is required.",
          },
        ]);
        return; // Do not proceed with sign-up
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Invalid Email",
            body: "Please enter a valid email address.",
          },
        ]);
        return; // Do not proceed with sign-in
      }
      const baseResponse = await api.add(email);

      if (baseResponse.success === true) {
        setEmail("");
        setToastMessages([
          ...toastMessages,
          {
            type: "success",
            title: "Email Subscription",
            body: "Email is subscribed successfully.",
          },
        ]);
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "invalid",
            title: "Error",
            body: "Some error occurred.",
          },
        ]);
      }
    } catch (error) {
      // Handle other errors, e.g., network issues or JSON parsing errors
      console.error("Error:", error);
    }
  };
  return (
    <footer className=" text-center  bg-clue-black text-neutral-200">
      <PrivacyPolicyModal
        isOpen={isPPModalOpen}
        onClose={closePPModal}
        text={privacyPolicy}
      />
      <TermsOfServiceModal
        isOpen={isTermsModalOpen}
        onClose={closeTermsModal}
        text={termsAndConditions}
      />
      <ContactModal
        isOpen={isContactModalOpen}
        onClose={closeContactModal}
        text={contact_info}
      />
      <CookiePolicyModal
        isOpen={isCookieModalOpen}
        onClose={closeCookieModal}
        text={cookiePolicy}
      />
      <div className="py-6">
        {toastMessages.map((toast, index) => (
          <Toast
            key={index}
            toasts={[toast]}
            onClose={() => {
              // Remove the toast message when it's closed
              const updatedToasts = [...toastMessages];
              updatedToasts.splice(index, 1);
              setToastMessages(updatedToasts);
            }}
          />
        ))}
        <div className="flex w-full justify-center text-clue-book-desc mb-5">
          <span>
            Powered By | <a className="underline transition-opacity hover:opacity-70" href="https://www.dijinx.com">D I J I N X</a>
          </span>
        </div>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-2">
          <div>
            <h6 className="mb-4 flex items-center justify-center md:items-left md:justify-between md:w-[62%]  md:mx-auto font-semibold text-clue-book-desc">
              Information
            </h6>
            <p className="flex flex-wrap md:mt-7 items-center justify-center md:items-left md:justify-start  md:mx-auto mx-[5%] md:ml-[19%] md:w-[95%]">
              <a
                onClick={openTermsModal}
                className="font-inter flex items-left cursor-pointer  font-weight-400 transition-opacity hover:opacity-70 text-clue-book-desc text-base leading-6  pr-4 "
              >
                Terms of Service
              </a>
              <a
                onClick={openCookieModal}
                className="font-inter flex md:flex-col flex-row   cursor-pointer font-weight-400 text-clue-book-desc text-base leading-6  transition-opacity hover:opacity-70 pr-4"
              >
                Cookie Policy
              </a>
              <a
                onClick={openPPModal}
                className="font-inter flex md:flex-col flex-row cursor-pointer font-weight-400text-clue-book-desc text-base leading-6 transition-opacity hover:opacity-70 pr-4"
              >
                Privacy Policy
              </a>
              <a
                onClick={openContactModal}
                className="font-inter cursor-pointer font-weight-400 text-clue-book-desc text-base leading-6 inline-block transition-opacity hover:opacity-70 pr-4"
              >
                Contact Us
              </a>
            </p>
          </div>

          <div className="flex-col items-center justify-start">
            <h6 className="mb-4 font-semibold md:mr-20 text-clue-book-desc">
              Stay update on new competitions
            </h6>
            <div
              className={`relative mx-auto  h-[50%]  text-filter-heading rounded-[10px] border
                ${
                  isDivClicked
                    ? "border-clue-purchase"
                    : "border-filter-heading"
                } focus:ring-0 focus:outline-none`}
              style={{ maxWidth: "345px" }}
              onClick={handleDivClick}
              onBlur={handleDivBlur}
            >
              <Input
                disableUnderline
                classes={{
                  root: "border-none my-2 w-80 pl-0 text-filter-heading ",
                  input:
                    "pl-4 text-filter-heading mt-2 focus:ring-0 focus:outline-none",
                }}
                value={email}
                onChange={handleChange}
                type="email"
                id="email1"
                placeholder="Email"
                style={{
                  color: "var(--text-filter-heading-color)",
                  paddingBottom: "10px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <span
                      onClick={handleIconClick}
                      className=" bg-clue-purchase w-12 rounded-[5px] h-7 cursor-pointer transition-opacity  hover:opacity-70"
                    >
                      <ForwardToInboxIcon className="cursor-pointer text-white" />
                    </span>
                  </InputAdornment>
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className=" p-6 text-center bg-clue-black border-t-2 border-clue-book-desc">
        <a
          className="font-inter font-weight-400 text-clue-book-desc text-base leading-6 inline-block mb-2"
          style={{ transition: "opacity 0.2s" }}
        >
          © Clue by Candlelight 2023
          <span className="hidden md:inline px-3"> | </span>
          <br className="md:hidden" />
          All Rights Reserved
        </a>
      </div>
    </footer>
  );
}

export default Footer;
