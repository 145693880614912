import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import ListArchiveManager from "../../models/admin/competitions/https/listarchivehttp";
import RestoreArchiveManager from "../../models/admin/competitions/https/restorearchivehttp";
import DeleteArchiveManager from "../../models/admin/competitions/https/deletearchivehttp";
import Toast from "../../components/toasts/toast";

function Archives() {
  const [isDeleteBook, setIsDeleteBook] = useState(false);
  const [isRestoreBook, setIsRestoreBook] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [toastMessages, setToastMessages] = useState([]);
  const [allArchives, setAllArchives] = useState({ data: [] });
  const [showLoading, setShowLoading] = useState(false);
  const [forIdx, setIdx] = useState(0);
  const archiveManager = new ListArchiveManager();
  const restoreManager = new RestoreArchiveManager();
  const deleteManager = new DeleteArchiveManager();
  const navigate = useNavigate();
  const filterRef = useRef(null);
  const buttonRef = useRef(null);
  const [filters, setFilters] = useState({
    ageUnder18: false,
    filterBy: "",
    sortBy: "",
    orderBy: "",
  });

  useEffect(() => {
    setShowLoading(true);
    archiveManager.get().then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setShowLoading(false);
          setAllArchives(value);
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  }, []);

  const navigatetoArchiveDetails = (index) => {
    const forId = sortedBooks[index].id;
    console.log(sortedBooks[index]);
    navigate(`/adboard/dashboard/archive/details?id=${forId}`);
  };

  const openDeleteBook = (index) => {
    setIdx(index);
    setIsDeleteBook(true);
  };

  const closeDeleteBook = () => {
    setIsDeleteBook(false);
  };
  const openRestoreBook = (index) => {
    setIdx(index);
    setIsRestoreBook(true);
  };

  const closeRestoreBook = () => {
    setIsRestoreBook(false);
  };

  function handleFilterChange(event) {
    const { name, value, type, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowFilters(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredBooks = allArchives.data.filter((book) => {
    // Filter by search term
    if (
      searchTerm &&
      !book.title.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }

    if (filters.ageUnder18 && !book.under_18_appropriate) return false;
    if (filters.filterBy === "Active" && book.status !== "Active") return false;
    if (filters.filterBy === "Ended" && book.status !== "Ended") return false;

    return true;
  });

  let sortedBooks = [...filteredBooks];
  if (filters.sortBy) {
    sortedBooks.sort((a, b) => {
      if (filters.sortBy === "Prize") {
        const prizeA = Number(a.prize_amount);
        const prizeB = Number(b.prize_amount);
        return filters.orderBy === "Ascending"
          ? prizeA - prizeB
          : prizeB - prizeA;
      } else if (filters.sortBy === "ArchivedOn") {
        const dateA = new Date(a.archived_on);
        const dateB = new Date(b.archived_on);
        return filters.orderBy === "Ascending" ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
  }

  function resetFilters() {
    setFilters({
      ageUnder18: false,
      filterBy: "",
      sortBy: "",
      orderBy: "",
    });
    setSearchTerm(""); // Reset the search term as well
  }

  const deleteArchive = () => {
    setShowLoading(true);
    const forId = sortedBooks[forIdx].id;
    deleteManager.deletefromarchive(forId).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setShowLoading(false);
          archiveManager.get().then((value) => {
            if (!value.error) {
              const baseResponse = value.success;
              if (baseResponse == true) {
                setAllArchives(value);
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "success",
                    title: "Success",
                    body: "Successfully Deleted.",
                  },
                ]);
              } else {
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "invalid",
                    title: "Error",
                    body: value.message,
                  },
                ]);
              }
            } else {
              setToastMessages([
                ...toastMessages,
                {
                  type: "error",
                  title: "Error",
                  body: value.error,
                },
              ]);
            }
          });
          closeDeleteBook();
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  };

  const restore = () => {
    setShowLoading(true);
    const forId = sortedBooks[forIdx].id;
    restoreManager.restorefromarchive(forId).then((value) => {
      if (value == null) {
      } else if (!value.error) {
        const baseResponse = value.success;
        if (baseResponse == true) {
          setShowLoading(false);
          archiveManager.get().then((value) => {
            if (!value.error) {
              const baseResponse = value.success;
              if (baseResponse == true) {
                setAllArchives(value);
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "success",
                    title: "Success",
                    body: "Successfully Restored.",
                  },
                ]);
              } else {
                setToastMessages([
                  ...toastMessages,
                  {
                    type: "invalid",
                    title: "Error",
                    body: value.message,
                  },
                ]);
              }
            } else {
              setToastMessages([
                ...toastMessages,
                {
                  type: "error",
                  title: "Error",
                  body: value.error,
                },
              ]);
            }
          });
          closeRestoreBook();
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "invalid",
              title: "Error",
              body: value.message,
            },
          ]);
        }
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: "error",
            title: "Error",
            body: value.error,
          },
        ]);
      }
    });
  };

  return (
    <div className="w-full md:h-screen" style={{ fontFamily: "Inter" }}>
      {toastMessages.map((toast, index) => (
        <Toast
          className="mb-0 z-20 fixed"
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <div className="ml-0 md:ml-10">
        <div className="flex justify-start md:justify-start mt-24 md:mt-16">
          <p className="text-clue-purchase font-bold md:ml-[0px] ml-[20px] text-[24px] md:text-2xl">
            Archive
          </p>
        </div>
        <div className="md:mt-16 mt-10 md:w-[90%] mx-5 md:mx-0 flex flex-col items-start relative">
          <div className="flex w-full items-center">
            <div className="flex-grow flex items-center bg-clue-black rounded-xl p-2">
              <SearchIcon className="text-clue-yellow m-2" />
              <input
                type="text"
                value={searchTerm} // Make sure you use the state value for input value
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search Books"
                className="w-full bg-clue-black text-white placeholder-white border-none outline-none focus:ring-0"
              />
            </div>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center h-14 bg-clue-black text-white ml-5 px-5 p-2 rounded-xl"
              ref={buttonRef}
            >
              <FilterListIcon className="text-clue-yellow" />
              <span className="ml-2 hidden md:block">Filters</span>
            </button>
          </div>
          {showFilters && (
            <>
              <div
                className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-40"
                onClick={() => setShowFilters(false)}
              ></div>
              <div
                className="bg-clue-black md:h-[480px] mt-4 p-4 rounded-xl absolute top-12 right-0 z-50"
                style={{ minWidth: "290px" }}
                ref={filterRef}
              >
                <div className="w-full text-right mt-4 mb-4">
                  <span
                    className="text-clue-purchase mr-5 underline cursor-pointer"
                    onClick={resetFilters}
                  >
                    Reset Filters
                  </span>
                </div>
                <div className="grid grid-rows-1 md:grid-cols-1 gap-x-4 text-clue-book-author mb-5">
                  <div className="text-left pb-2 border-b border-sidebar-hover-color ">
                    <p className="font-bold mb-2 text-filter-heading md:mx-5">
                      Age
                    </p>
                    <label className="block ml-5 md:ml-0 ">
                      <input
                        type="checkbox"
                        name="ageUnder18"
                        checked={filters.ageUnder18}
                        onChange={handleFilterChange}
                        className="form-checkbox text-clue-purchase h-3 w-3 md:ml-10"
                      />
                      <span className="ml-2 ">Suitable for under 18</span>
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 text-clue-book-author">
                  <div className="text-left  md:col-span-4">
                    <div className="mb-4 pb-2 border-b border-sidebar-hover-color">
                      <p className="font-bold mb-2 text-filter-heading md:mx-5">
                        Status
                      </p>
                      <label className="block ml-5 md:ml-0">
                        <input
                          type="radio"
                          name="filterBy"
                          value="Active"
                          checked={filters.filterBy === "Active"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Active</span>
                      </label>
                      <label className="block ml-5 md:ml-0">
                        <input
                          type="radio"
                          name="filterBy"
                          value="Ended"
                          checked={filters.filterBy === "Ended"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Ended</span>
                      </label>
                    </div>
                    <div className="mb-4 pb-2 border-b border-sidebar-hover-color">
                      <p className="font-bold mb-2 text-filter-heading md:mx-5">
                        Sort By
                      </p>
                      <label className="block ml-5 md:ml-0">
                        <input
                          type="radio"
                          name="sortBy"
                          value="Prize"
                          checked={filters.sortBy === "Prize"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Prize</span>
                      </label>
                      <label className="block ml-5 md:ml-0">
                        <input
                          type="radio"
                          name="sortBy"
                          value="ArchivedOn"
                          checked={filters.sortBy === "ArchivedOn"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Archived On</span>
                      </label>
                    </div>
                    <div className="">
                      <p className="font-bold mb-2 text-filter-heading md:mx-5">
                        Order By
                      </p>
                      <label className="block ml-5 md:ml-0">
                        <input
                          type="radio"
                          name="orderBy"
                          value="Ascending"
                          checked={filters.orderBy === "Ascending"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Ascending</span>
                      </label>
                      <label className="block ml-5 md:ml-0">
                        <input
                          type="radio"
                          name="orderBy"
                          value="Descending"
                          checked={filters.orderBy === "Descending"}
                          onChange={handleFilterChange}
                          className="form-radio text-clue-purchase h-3 w-3 md:ml-10"
                        />
                        <span className="ml-2">Descending</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-20">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="overflow-x-auto  mt-10 mx-10 md:w-[90%]">
          <table className="table-fixed min-w-full bg-clue-black w-[800px] md:w-[50vw] rounded text-clue-book-author">
            <thead>
              <tr className="border-b border-filter-heading">
                <th className="p-0 py-5  border-r border-filter-heading">#</th>
                <th className="p-2 py-5 border-r border-filter-heading ">
                  Title
                </th>
                <th className="p-2 py-5  border-r border-filter-heading">
                  Archived On
                </th>
                <th className="p-2 py-5  border-r border-filter-heading">
                  Restore
                </th>
                <th className="p-2 py-5  border-filter-heading">Delete</th>
              </tr>
            </thead>
            <tbody>
              {sortedBooks.map((book, index) => (
                <tr
                  key={index}
                  className="border-b border-filter-heading hover:bg-black"
                >
                  <td className="p-0 py-5 border-r border-filter-heading">
                    <a
                      href="/adboard/dashboard/archive/details"
                      className="block w-full h-full"
                    >
                      {index + 1}
                    </a>
                  </td>

                  <td className="p-2 py-5 cursor-pointer border-r  border-filter-heading">
                    <a
                      onClick={() => navigatetoArchiveDetails(index)}
                      className="block w-full h-full overflow-hidden overflow-ellipsis"
                      style={{ wordWrap: "break-word" }}
                    >
                      {book.title}
                    </a>
                  </td>
                  <td className="p-2 py-5  border-r border-filter-heading">
                    <a
                      onClick={() => navigatetoArchiveDetails(index)}
                      className="block w-full h-full"
                    >
                      {book.archived_on}
                    </a>
                  </td>
                  <td className="p-2 py-5 border-r  border-filter-heading text-clue-restore">
                    <div className=" transition-opacity hover:opacity-70 ">
                      <RestoreIcon
                        onClick={() => openRestoreBook(index)}
                        className="cursor-pointer"
                      />{" "}
                      <span
                        onClick={() => openRestoreBook(index)}
                        className="  cursor-pointer underline"
                      >
                        Restore
                      </span>
                    </div>
                  </td>
                  <td className="p-2 py-5 border-filter-heading text-clue-logout">
                    <div className=" transition-opacity hover:opacity-70 ">
                      <DeleteIcon
                        onClick={() => openDeleteBook(index)}
                        className="cursor-pointer"
                      />{" "}
                      <span
                        onClick={() => openDeleteBook(index)}
                        className=" cursor-pointer underline "
                      >
                        Delete
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
              <tr className="border-b-0">
                <td className="md:py-32 py-16 border-r border-filter-heading"></td>
                <td className="md:py-32 py-16 border-r border-filter-heading"></td>
                <td className="md:py-32 py-16 border-r border-filter-heading"></td>
                <td className="md:py-32 py-16 border-r border-filter-heading"></td>
                <td className="md:py-32 py-16  border-filter-heading"></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {isDeleteBook && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={closeDeleteBook}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black p-8 md:w-auto w-80 px-12 rounded-lg relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author w-60 md:w-auto  text-left mb-6">
              Delete Book
            </h3>
            <p className="text-filter-heading w-60 md:w-auto text-left">
              <span>Are you sure you want to delete this book?</span>
            </p>
            <div className="flex justify-end mt-6 w-60 md:w-auto">
              <button
                onClick={closeDeleteBook}
                className="transition-opacity hover:opacity-70 mr-4 rounded-[9px] border-filter-heading border-solid border px-2 md:px-4 md:h-8 text-filter-heading "
              >
                Cancel
              </button>
              <button
                className="transition-opacity hover:opacity-70 rounded-[9px] bg-clue-logout text-white px-5 md:h-8py-2 "
                onClick={deleteArchive}
              >
                {showLoading ? (
                  <span className="flex w-full items-center justify-center">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </span>
                ) : (
                  <span>Delete</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {isRestoreBook && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={closeRestoreBook}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black p-8 px-12 md:w-auto w-80 rounded-lg relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author w-60 md:w-80  text-left mb-6">
              Restore Book
            </h3>
            <p className="text-filter-heading w-60 md:w-auto text-left flex items-center">
              <span>Are you sure you want to restore this book?</span>
            </p>
            <div className="flex justify-end mt-6">
              <button
                onClick={closeRestoreBook}
                className="text-filter-heading mr-4 border-filter-heading rounded-[9px]  border-solid border px-4 md:h-8"
              >
                Cancel
              </button>
              <button
                className="bg-onclickfield text-white  py-[-2px] rounded-[9px]  px-6 md:h-8"
                onClick={restore}
              >
                {showLoading ? (
                  <span className="flex w-full items-center justify-center">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </span>
                ) : (
                  <span>Restore</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Archives;
