import { NavLink, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import "@fontsource/inter";

function Navbar() {
  const isAuthenticated = localStorage.getItem("websiteToken");

  let links = [
    { name: "Home", link: "/home" },
    { name: "Competitions By Author", link: "/competitions" },
    { name: "Account", link: isAuthenticated ? "/account/dashboard" : "/account/signin" },
  ];

  const location = useLocation();
  const currentPath = location.pathname;

  let [open, setOpen] = useState(false);
  return (
    <div
      className="shadow-md w-full top-0 left-0 z-50"
      style={{ fontFamily: "Inter" }}
    >
      <div className="md:flex items-center justify-between bg-black mt-1 py-3 md:px-10 px-0">
        <NavLink
          className="transition-opacity hover:opacity-70 text-clue-gray text-sm mx-7 my-2 border-b-2 border-transparent font-bold cursor-pointer flex items-center"
          to={"/"}
        >
          Clue by Candlelight
        </NavLink>
        <div
          onClick={() => setOpen(!open)}
          className="text-clue-gray text-3xl absolute right-8 top-3 cursor-pointer md:hidden"
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </div>
        <ul
          className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static  bg-black z-30 left-0
      w-full md:w-auto md:pl-0 transition-all duration-500 ease-in ${open ? "top-15 " : "top-[-490px]"
            }`}
        >
          {links.map((link) => (
            <li
              onClick={() => setOpen(false)}
              key={link.name}
              className={`md:ml-8 text-sm md:my-0 my-7 pl-1 pr-1  transition-opacity hover:opacity-70
                 ${(currentPath === link.link ||
                  (currentPath === "/" && link.name === "Account" && isAuthenticated) ||
                  (currentPath === "/" && link.name === "Home" && !isAuthenticated))
                  ? "text-clue-yellow md:border-b-2 border-b-0 md:pb-2 pb-0 border-clue-yellow"
                  : "text-clue-gray md:border-b-2 md:pb-2 pb-0 border-transparent"
                }`}
            >
              <NavLink to={link.link}>{link.name}</NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
