import React, { useState, useEffect, useRef } from "react";
import Candle from "../../assets/images/candle_hero.png";
import Flare from "../../assets/images/flare_hero.png";
import Footer from "../../components/footer/footer.js";
import MainTile from "../../components/tiles/main_tile";
import SubTile from "../../components/tiles/sub_tile";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import AllCompList from "../../models/website/public/https/comphttp.js";

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const animateRef = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);
  const navigate = useNavigate();
  const [parallaxStyles, setParallaxStyles] = useState({
    candle: { transform: "translate(0, 0)" },
    flare: { transform: "translate(-50%, -50%) translate(0, 0)", opacity: 0 },
  });

  const [allComps, setAllComps] = useState({ data: [] });

  useEffect(() => {
    async function fetchData() {
      try {
        const allCompsResponse = await new AllCompList().getRecent();

        setAllComps(allCompsResponse);
      } catch (error) {
        console.error("Error fetching competition data:", error);
      }
    }

    fetchData();
  }, []);

  const handleMouseMove = (e) => {
    // Check if the screen width is greater than or equal to 768px
    if (!isMobile) {
      const { clientX, clientY } = e || { clientX: 0, clientY: 0 };

      // Calculate parallax effect based on mouse position
      const parallaxX = (clientX / window.innerWidth - 0.5) * 40;
      const parallaxY = (clientY / window.innerHeight - 0.5) * 40;

      // Update styles for parallax effect
      setParallaxStyles({
        candle: { transform: `translate(${-parallaxX}px, ${parallaxY}px)` },
        flare: {
          transform: `translate(-50%, -50%) translate(${-parallaxX * 0.3}px, ${
            parallaxY * 0.3
          }px)`,
          opacity: 1,
        },
      });
    } else {
      setParallaxStyles({
        candle: { transform: "translate(0, 0)" },
        flare: {
          transform: "translate(-50%, -50%) scale(0.7, 0.7) translate(0, 0)",
          opacity: 0,
        },
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 768;

      // Check if isMobile state has changed
      if (newIsMobile !== isMobile) {
        setIsMobile(newIsMobile);

        // Call handleMouseMove without waiting for hover
        handleMouseMove();
      }
      handleMouseMove();
    };

    window.addEventListener("resize", handleResize);

    // Initial call to handleResize to set the initial isMobile state
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      if (entries[0].isIntersecting) {
        setIsAnimated(true);
        observer.unobserve(animateRef.current);
      }
    };

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (animateRef.current) {
      observer.observe(animateRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const fadeInAnimation = {
    animationName: "fadeIn",
    animationTimingFunction: "ease-in",
    animationDuration: "1.5s",
    animationIterationCount: 1,
    animationFillMode: "forwards",
    opacity: 0,
  };

  const handleIntersection = (entries, observer) => {
    if (entries[0].isIntersecting) {
      setIsAnimated(true);
      observer.unobserve(animateRef.current);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (animateRef.current) {
      observer.observe(animateRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="w-full" style={{ fontFamily: "Inter" }}>
      <div
        className="flex h-[65vh] md:h-[45vh] justify-center mt-24 relative"
        onMouseMove={handleMouseMove}
      >
        <img
          src="https://dq6y48nvbage3.cloudfront.net/assets/candle-hero.png"
          style={parallaxStyles.candle}
          className="z-20"
        />
        <img
          src="https://dq6y48nvbage3.cloudfront.net/assets/flare-hero.png"
          style={{ ...parallaxStyles.flare, ...fadeInAnimation }}
          className="absolute h-full top-5 md:top-9 left-1/2 transform -translate-x-1/2 z-10"
        />
      </div>

      <div
        id="animate"
        ref={animateRef}
        className={`md:w-[70%] mt-32  mb-20 md:mb-0 flex flex-col md:flex-row md:pb-6 justify-between items-start md:items-center ml-16 md:mx-[15%] relative ${
          isAnimated ? "animated" : ""
        }`}
      >
        <div
          className={`${
            isAnimated ? "animated1" : "hidden"
          } flex w-52 flex-row md:flex-col justify-normal mx-5 md:mx-0 items-center md:text-left`}
        >
          <div
            className={`${
              isAnimated ? "animated1" : "hidden"
            } flex md:hidden bg-clue-yellow rounded-full md:mx-[4%] w-8 py-1 px-2 flex-col text-center mt-2`}
          >
            <span className="font-bold text-center text-md">1</span>
          </div>
          <span className="text-xl text-clue-book-title mx-4 md:mx-0 md:mt-0 mt-3 mb-1">
            <LocalLibraryOutlinedIcon />
          </span>
          <span className="text-xl text-clue-book-title mx-1 md:mx-0 md:mt-0 mt-4 mb-1">
            Read
          </span>
          <div
            className={`${
              isAnimated ? "animated1" : "hidden"
            } md:flex hidden bg-clue-yellow rounded-full mx-[4%] w-8 py-1 px-2 flex-col text-center mt-2`}
          >
            <span className="font-bold text-center text-md">1</span>
          </div>
        </div>
        <div
          className={`${
            isAnimated ? "animated2" : "hidden"
          } flex w-52 flex-row md:flex-col items-center md:text-left mx-5 md:mx-0 md:mt-0 mt-5`}
        >
          <div
            className={`${
              isAnimated ? "animated2" : "hidden"
            } flex md:hidden bg-clue-yellow rounded-full md:mx-[5%] w-8 py-1 px-2 flex-col text-center mt-2`}
          >
            <span className="font-bold text-md">2</span>
          </div>
          <span className="text-xl text-clue-book-title mx-4 md:mx-0 md:mt-0 mt-3 mb-1">
            <SearchIcon />
          </span>
          <span className="text-xl text-clue-book-title mx-1 md:mx-0 md:mt-0 mt-4 mb-2">
            Solve
          </span>
          <div
            className={`${
              isAnimated ? "animated2" : "hidden"
            } md:flex hidden bg-clue-yellow rounded-full mx-[5%] w-8 py-1 px-2 flex-col text-center mt-2`}
          >
            <span className="font-bold text-md">2</span>
          </div>
        </div>
        <div
          className={`${
            isAnimated ? "animated3" : "hidden"
          } flex w-52 items-center flex-row md:flex-col mx-5 md:mx-0 md:mt-0 mt-4 md:text-left`}
        >
          <div
            className={`${
              isAnimated ? "animated3" : "hidden"
            } flex md:hidden bg-clue-yellow rounded-full md:mx-[2%] w-8 py-1 px-2 flex-col text-center mt-2`}
          >
            <span className="font-bold text-md">3</span>
          </div>
          <span className="text-xl text-clue-book-title mx-4 md:mx-0 md:mt-0 mt-3 mb-1">
            <PaidOutlinedIcon />
          </span>
          <span className="text-xl text-clue-book-title mx-1 md:mx-0 md:mt-0 mt-4 mb-2">
            Win
          </span>
          <div
            className={`${
              isAnimated ? "animated3" : "hidden"
            } md:flex hidden bg-clue-yellow rounded-full mx-[2%] w-8 py-1 px-2 flex-col text-center mt-2`}
          >
            <span className="font-bold text-md">3</span>
          </div>
        </div>
        {!isMobile && (
          <style jsx>
            {`
              .animated {
                position: relative;
              }

              .animated1 {
                animation-name: fadeIn;
                animation-timing-function: ease-in;
                animation-duration: 1s;
                animation-delay: 0s; /* Adjust the delay as needed */
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                opacity: 0;
              }

              .animated2 {
                animation-name: fadeIn;
                animation-timing-function: ease-in;
                animation-duration: 1s;
                animation-delay: 1s; /* Adjust the delay as needed */
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                opacity: 0;
              }

              .animated3 {
                animation-name: fadeIn;
                animation-timing-function: ease-in;
                animation-duration: 1s;
                animation-delay: 2s; /* Adjust the delay as needed */
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                opacity: 0;
              }

              .animated::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                height: 4px;
                background: #edd13d;
                animation: borderExpand 6s forwards;
              }

              @keyframes borderExpand {
                100% {
                  width: 100%;
                }
              }
            `}
          </style>
        )}
        {isMobile && (
          <style jsx>
            {`
              .animated {
                position: relative;
              }

              .animated1 {
                animation-name: fadeIn;
                animation-timing-function: ease-in;
                animation-duration: 1s;
                animation-delay: 0s; /* Adjust the delay as needed */
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                opacity: 0;
              }

              .animated2 {
                animation-name: fadeIn;
                animation-timing-function: ease-in;
                animation-duration: 1s;
                animation-delay: 1s; /* Adjust the delay as needed */
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                opacity: 0;
              }

              .animated3 {
                animation-name: fadeIn;
                animation-timing-function: ease-in;
                animation-duration: 1s;
                animation-delay: 2s; /* Adjust the delay as needed */
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                opacity: 0;
              }

              .animated::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 0%;
                width: 4px;
                background: #edd13d;
                animation: borderExpandMobile 6s forwards;
              }

              @keyframes borderExpandMobile {
                100% {
                  height: 100%;
                }
              }
            `}
          </style>
        )}
      </div>

      <div className="my-7 md:my-14">
        <span className="text-filter-heading text-xl md:text-xl">
          Explore our competitions
        </span>
      </div>
      {!isMobile && (
        <style jsx>
          {`
            .animated {
              position: relative;
            }

            .animated1 {
              animation-name: fadeIn;
              animation-timing-function: ease-in;
              animation-duration: 1s;
              animation-delay: 0s; /* Adjust the delay as needed */
              animation-iteration-count: 1;
              animation-fill-mode: forwards;
              opacity: 0;
            }

            .animated2 {
              animation-name: fadeIn;
              animation-timing-function: ease-in;
              animation-duration: 1s;
              animation-delay: 1s; /* Adjust the delay as needed */
              animation-iteration-count: 1;
              animation-fill-mode: forwards;
              opacity: 0;
            }

            .animated3 {
              animation-name: fadeIn;
              animation-timing-function: ease-in;
              animation-duration: 1s;
              animation-delay: 2s; /* Adjust the delay as needed */
              animation-iteration-count: 1;
              animation-fill-mode: forwards;
              opacity: 0;
            }

            @keyframes borderExpand {
              100% {
                width: 100%;
              }
            }
          `}
        </style>
      )}
      <div>
        {allComps.data.map((book, index) => (
          <div key={index} className="mx-5 md:w-[80%] md:mx-[10%]">
            <div
              className="cursor-pointer"
              onClick={() => {
                // Update the state when navigating to /competitions
                navigate(`/competitions/product?book_id=${book.id}`, {
                  state: { fromHome: true },
                });
              }}
            >
              {book.status === "Active" ? (
                // Render MainTile if status is "active"
                <MainTile bookDetails={book} fromHome={true} />
              ) : (
                // Render SubTile for other cases
                <SubTile bookDetails={book} />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="my-16">
        <Link to="/competitions">
          <span className="underline text-clue-yellow text-lg cursor-pointer transition-opacity hover:opacity-70">
            See more...
          </span>
        </Link>
      </div>
      <div>
        <Footer />
      </div>
      <style jsx>
        {`
          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
}

export default Home;
