import React, { useState, useEffect } from 'react';
import '@fontsource/inter';
import Reset_Success from './reset_success';
import ResetPass from '../../models/admin/auth/https/resetpasshttp';
import Toast from '../../components/toasts/toast';
import WebResetPass from '../../models/website/auth/https/webresetpasshttp';
import { useNavigate, useLocation } from "react-router-dom";

function Reset_pw({ isPopupOpen, closePopup, type, email }) {

  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [toastMessages, setToastMessages] = useState([]);
  const resetManager = new ResetPass();
  const navigate = useNavigate();
  const [step, setStep] = useState(3);
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const resetInstance = type === "user" ? new WebResetPass() : new ResetPass();
  const Resetbtn = () => {
    if (password.length < 8) {
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Password Length",
          body: "Password should be at least 8 characters.",
        },
      ]);
      return; // Do not proceed with sign-up
    }
    if (password === confirmpassword && password.trim() !== '') {
      
      if (type === 'user') {
        
       
        resetInstance.reset3(email, password).then((value) => {
          handleResetResponse(value);
        });
      } else {
        
        resetInstance.reset3(password).then((value) => {
          handleResetResponse(value);
        });
      }
    } else {
      setToastMessages([
        ...toastMessages,
        {
          type: 'invalid',
          title: 'Password',
          body: 'The password is incorrect',
        },
      ]);
    }
  };

  const handleResetResponse = (value) => {
    if (!value.error) {
      const baseResponse = value.success;
      if (baseResponse === true) {
        setStep((prevStep) => prevStep + 1);
      } else {
        setToastMessages([
          ...toastMessages,
          {
            type: 'invalid',
            title: 'Reset Password',
            body: value.message,
          },
        ]);
      }
    } else {
      setToastMessages([
        ...toastMessages,
        {
          type: 'invalid',
          title: 'Reset Password',
          body: value.error,
        },
      ]);
    }
  };

  return (
    <div>{toastMessages.map((toast, index) => (
      <Toast
        key={index}
        toasts={[toast]}
        onClose={() => {
          // Remove the toast message when it's closed
          const updatedToasts = [...toastMessages];
          updatedToasts.splice(index, 1);
          setToastMessages(updatedToasts);
        }}
      />
    ))}
      <div className="flex justify-center items-center ">

        {step === 3 && (
          <div
            className="mx-2 md:w-[460px] w-[320px] md:h-[460px] h-[370px] md:mb-[15vh] md:mt-[15vh] mt-8 block rounded-[30px] bg-clue-black '
              ">
            <p
              className="md:mr-[146px] md:text-[26px] text-[23px] mr-[57px]  md:mt-12 mt-8 md:mb-8 mb-5 font-[700] leading-tight text-clue-yellow">
              Reset Password
            </p>

            <p className=" md:mb-7 md:mt-9  mt-7 w-52 md:w-72 text-[17px] md:text-[20px] md:ml-[57px]  text-left ml-[45px] font-[400] text-clue-gray leading-[1.30] text-lg ">
              Enter your new password

            </p>

            <div className={'relative md:mb-7 mb-5 md:mt-0 mt-5  '} >
              <input
                type="password"
                class="focus:outline-none focus:border-onclickfield focus:ring-0 peer m-0 block md:h-[56px] h-[45px] md:mx-14 md:w-[370px] mx-10 rounded-[10px] w-[245px] border-[1px] border-solid border-clue-gray bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray "
                id="password"
                value={password}
                onChange={handlePasswordChange}

                placeholder="Password" />

            </div>
            <div className={'relative md:mb-7 mb-5  '} >
              <input
                type="password"
                class="focus:outline-none focus:border-onclickfield focus:ring-0 peer m-0 block md:h-[56px] h-[45px] md:mx-14 md:w-[370px] mx-10 rounded-[10px] w-[245px] border-[1px] border-solid border-clue-gray bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray "
                id="confirmpassword"
                value={confirmpassword}
                onChange={handleConfirmPasswordChange}

                placeholder="Confirm password" />

            </div>



            <button
              type="button"
              className="mb-2 block md:mx-14 md:w-[370px] md:mt-6 mt-6 mx-10 md:h-[56px] h-[50px] w-[245px] bg-onclickfield rounded-[10px] md:pb-1 md:pl-1 text-center text-[19px] md:text-[24px] font-[700] leading-normal text-white transition-opacity hover:opacity-70"
              onClick={Resetbtn}>

              Reset
            </button>



          </div>
        )}  {step === 4 && <Reset_Success isPopupOpen={isPopupOpen} closePopup={closePopup} />}

      </div></div>

  );
}
export default Reset_pw;