import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import BackButton from "./../../components/backbutton/backbutton";
import Toast from "../../components/toasts/toast";
import { useLocation, useNavigate } from "react-router-dom";
import SalesList from "../../models/admin/sales/http/saleshttp";
import ContributionIdManager from "../../models/admin/contributions/https/getcontbyidhttp";
import Export from "../../models/admin/export/http/exporthttp.js";

function ViewSales() {
  const contIdManager = new ContributionIdManager();
  const [isSusOpen, setIsSusOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedContributor, setSelectedContributor] = useState("");
  const [isDeleteBook, setIsDeleteBook] = useState(false);
  const [isSelectWinConfirm, setIsSelectWinConfirm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectWinOpen, setisSelectWinOpen] = useState(false);
  const [toastMessages, setToastMessages] = useState([]);
  const [contributions, setContributions] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const forId = searchParams.get("id");
  const [salesData, setSalesData] = useState(null);
  const [finances, setFinances] = useState(null);
  const openModal = () => {
    setisSelectWinOpen(false);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeSus = () => {
    setIsSusOpen(false);
    setSelectedContributor("");
  };
  const [showLoading2, setShowLoading2] = useState(false);
  const [showLoading3, setShowLoading3] = useState(false);
  const [showLoading4, setShowLoading4] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  const openSelectWin = () => {
    setIsModalOpen(false);
    setisSelectWinOpen(true);
  };

  const closeSelectWin = () => {
    setisSelectWinOpen(false);
  };
  const openSelectWinConfirm = () => {
    setisSelectWinOpen(false);
    setIsSelectWinConfirm(true);
  };

  const closeSelectWinConfirm = () => {
    setIsSelectWinConfirm(false);
  };
  const handleSelectWinConfirm = () => {
    closeSelectWinConfirm();
    // Add a new toast message
    setToastMessages([
      ...toastMessages,
      {
        type: "success",
        title: "Selected",
        body: "Winner has been selected",
      },
    ]);
  };
  const convertDateFormat = (startDate) => {
    const parsedDate = new Date(startDate);

    if (isNaN(parsedDate)) {
      console.error("Invalid date format");
      return "";
    }

    // Use toLocaleDateString to format the date as "Nov 05, 2023"
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedStartDate = parsedDate.toLocaleDateString("en-US", options);
    return formattedStartDate;
  };
  useEffect(() => {
    const fetchData = async () => {
      setShowLoading(true);
      try {
        const salesList = new SalesList();
        const response = await salesList.getbyid(forId);

        // Check if response.details is present
        if (response.details) {
          const financialData = response.details;
          // Set the financial data to the state
          setFinances(financialData);
          setSalesData(response.sales || []);
        } else {
          // Handle the case where response.details is undefined
          console.error("Error: Response does not contain details.");
        }
      } catch (error) {
        console.error("Error fetching sales data:", error.message);
      } finally {
        // Set loading to false whether the request is successful or not
        setShowLoading(false);
      }
    };

    fetchData();
  }, [forId]);

  const openSus = async () => {
    // Fetch the list of emails when the dropdown is opened
    //setShowLoading(true);
    setShowLoading4(true);
    try {
      const response = await contIdManager.get(forId);
      if (response?.success) {
        setContributions(response?.data || []);
      } else {
        //setShowLoading(true);
      }
    } catch (error) {
      console.error("Error fetching email subscribers:", error);
    } finally {
      // Set loading to false whether the request is successful or not

      setIsSusOpen(true);
      setShowLoading4(false);
    }
  };
  const handleDownloadAdmin = async () => {
    setShowLoading4(true);
    try {
      const exportInstance = new Export();
      const response = await exportInstance.ExportSalesListByID(forId);
      if (response?.success) {
        // Assume the response contains the base64 data in a property called 'base64Data'
        const base64Data = response.data.replace(
          /^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/,
          ""
        );

        // Remove whitespaces or line breaks from the base64 data
        const cleanedBase64Data = base64Data.replace(/\s/g, "");

        // Convert base64 to binary
        const binaryData = atob(cleanedBase64Data);

        // Create ArrayBuffer and uint8 array
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create Blob
        const blob = new Blob([uint8Array], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Sales_List.xlsx";

        // Append link to the document
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      } else {
        // Handle error case
        console.error(response.errorMessage);
      }
    } catch (error) {
      // Handle other errors
      console.error(error.toString());
    } finally {
      setShowLoading4(false);
    }
  };
  const handleDownloadCont = async (selectedContributor) => {
    setShowLoading3(true);
    try {
      const exportInstance = new Export();
      const response = await exportInstance.Export_CONT(
        forId,
        selectedContributor
      );

      if (response?.success) {
        // Assume the response contains the base64 data in a property called 'base64Data'
        const base64Data = response.data.replace(
          /^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/,
          ""
        );

        // Remove whitespaces or line breaks from the base64 data
        const cleanedBase64Data = base64Data.replace(/\s/g, "");

        // Convert base64 to binary
        const binaryData = atob(cleanedBase64Data);

        // Create ArrayBuffer and uint8 array
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create Blob
        const blob = new Blob([uint8Array], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Sales_As_Contributor.xlsx";

        // Append link to the document
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      } else {
        // Handle error case
        console.error(response.errorMessage);
      }
    } catch (error) {
      // Handle other errors
      console.error(error.toString());
    } finally {
      setShowLoading3(false);
      setSelectedContributor("");
      closeSus();
    }
  };
  const bookData = salesData || [];
  const filteredBookData = bookData.filter((book) => {
    // Filter by search term in username
    if (
      searchTerm &&
      !book.username.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });
  // const finances = {
  //     total: "50",
  //     prize: "$80.00",
  //     owner:"$30.00",
  //     contributor: "$30.00",
  // };

  // const book = [
  //     {
  //         username: "User 1",
  //         email: "user@gmail.com",
  //         boughton: "12/01/2023",
  //         method: "PayPal",

  //     },
  // ];
  // const [showSuccessToast, setShowSuccessToast] = useState(false);
  // const successToast = [
  //     {
  //         type: "success",
  //         title: "Selected",
  //         body: "Winner has been selected",
  //     },
  // ];
  const navigateToComposeEmail = () => {
    navigate(
      `/adboard/dashboard/miscellaneous/emailsubs/compose?bookID=${forId}`,
      {
        state: { fromSales: true },
      }
    );
  };
  return (
    <div
      className="w-full  xl:overflow-y-hidden mb-16"
      style={{ fontFamily: "Inter" }}
    >
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="md:ml-12 md:mt-14 mt-14">
            <BackButton />
          </div>
          <div className="ml-0 md:ml-[12%]">
            <div class="flex items-center justify-between mt-5 md:mt-10 mb-12">
              <p class="text-clue-purchase md:mr-0 mr-3 font-bold md:ml-0 ml-10 text-[24px] md:text-[28px] text-left ">
                Sales For Title
              </p>
              <a
                onClick={navigateToComposeEmail}
                class="text-center md:text-left cursor-pointer md:text-[16px] text-clue-yellow ml-auto transition-opacity hover:opacity-70 underline"
              >
                {showLoading2 ? (
                  <span className="flex w-full items-center justify-center">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="inline md:mr-12  mr-7 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </span>
                ) : (
                  <span className="md:mr-0 "> Mail Entrants</span>
                )}
              </a>

              <a
                onClick={handleDownloadAdmin}
                class="md:text-[16px] text-center md:text-left cursor-pointer text-clue-yellow lg:mr-16 md:mr-8 xl:mr-48 md:pl-12 underline transition-opacity hover:opacity-70"
              >
                {showLoading4 ? (
                  <span className="flex w-full items-center justify-center">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="inline md:mr-12 mr-14 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </span>
                ) : (
                  <span className="md:ml-0 md:pl-0 pl-5  ">Export Sales</span>
                )}
              </a>

              <p className="mr-8"></p>
            </div>
            <div className="w-full md:flex md:justify-center md:items-center md:mx-[-5%]">
              {finances && (
                <div class="m-4 lg:mt-8 lg:mr-8  md:max-w-3xl block rounded-xl  bg-black text-center">
                  <div class="flex justify-between mt-8 p-2 px-4">
                    <div>
                      <p class="text-account-color">Total Earnings</p>
                    </div>
                    <div>
                      <p class="text-account-color">
                        ${finances.total_earnings.toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <hr class="mx-5 md:mx-0 w-auto lg:w-[780px] md:w-[500px] border-t border-filter-heading" />
                  <div class="flex justify-between px-4 p-2">
                    <div>
                      <p class="text-account-color">Prize Amount</p>
                    </div>
                    <div>
                      <p class=" text-account-color">
                        {/* ${finances.prize_amount} */}
                        {finances.prize_amount &&
                        Array.isArray(finances.prize_amount) &&
                        finances.prize_amount.length > 0
                          ? `$${finances.prize_amount[0].toLocaleString()}${
                              finances.prize_amount.length > 1
                                ? `, $${finances.prize_amount[1].toLocaleString()}`
                                : ""
                            }${
                              finances.prize_amount.length > 2
                                ? `, $${finances.prize_amount[2].toLocaleString()}`
                                : ""
                            }`
                          : "No prize amount available"}
                      </p>
                    </div>
                  </div>
                  <hr class="mx-5 md:mx-0 w-auto lg:w-[780px] md:w-[500px] border-t border-filter-heading" />
                  {finances.contributors.map((contribution, index, array) => (
                    <div
                      key={index}
                      class={`flex justify-between p-2 px-4 ${
                        index !== array.length - 1
                          ? "border-b-2 border-filter-heading"
                          : ""
                      }`}
                    >
                      <div>
                        <p class="text-account-color">
                          {contribution.is_owner ? "Company" : "Contributor"}{" "}
                          <span className="italic">({contribution.name})</span>
                          <span className="italic ml-2">
                            {contribution.percentage}%
                          </span>
                        </p>
                      </div>
                      <div>
                        <p class="text-account-color font-semibold">
                          $
                          {contribution.contributor_earnings &&
                            contribution.contributor_earnings.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="md:mt-14 mt-10 md:w-[90%] mx-9 md:mx-0 flex flex-col items-start relative">
              <div className="flex w-[65%] items-center">
                <div className="flex-grow flex items-center bg-clue-black rounded-xl p-2">
                  <SearchIcon className="text-clue-yellow m-2" />
                  <input
                    type="text"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search Users"
                    className="w-full bg-clue-black text-white placeholder-white border-none outline-none focus:ring-0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md:overflow-x-auto xl:overflow-x-hidden overflow-x-auto  mt-10 mx-10 md:ml-16 md:mx-10 lg:mr-16 xl:mx-48 lg:w-auto md:w-auto  xl:w-auto">
            <table className="table-fixed min-w-full bg-clue-black w-[800px] md:w-[50vw] md:mx-0   rounded text-clue-book-author">
              <thead>
                <tr className="border-b border-filter-heading">
                  <th className="p-0 py-5  border-r border-filter-heading">
                    #
                  </th>
                  <th className="p-2 py-5 border-r border-filter-heading ">
                    Username
                  </th>
                  <th className="p-2 py-5  border-r border-filter-heading">
                    Email
                  </th>
                  <th className="p-2 py-5  border-r border-filter-heading">
                    Bought On
                  </th>
                  <th className="p-2 py-5 border-r border-filter-heading ">
                    Method
                  </th>
                  <th className="p-2 py-5 border-r border-filter-heading ">
                    Promo Code
                  </th>
                  <th className="p-2 py-5 border-r border-filter-heading ">
                    Order ID
                  </th>
                  <th className="p-2 py-5  border-filter-heading ">
                    Bought For
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredBookData.map((book, index) => (
                  <tr
                    key={index}
                    className="border-b border-filter-heading hover:bg-black"
                  >
                    <td className="p-0 py-5 border-r border-filter-heading">
                      {index + 1}
                    </td>
                    <td className="p-2 py-5 border-r hover:bg-black border-filter-heading">
                      {book.username}
                    </td>
                    <td className="p-2 py-5 whitespace-normal break-words border-r border-filter-heading">
                      {book.user_email}
                    </td>
                    <td className="p-2 py-5 border-r border-filter-heading">
                      {convertDateFormat(book.bought_on)}
                    </td>
                    <td className="p-2 py-5 border-r border-filter-heading">
                      {book.payment_type}
                    </td>
                    <td className="p-2 py-5 border-r border-filter-heading">
                      {book.promocode || "N/A"}
                    </td>
                    <td className="p-2 py-5 whitespace-normal break-words border-r border-filter-heading">
                      {book.paypal_order_id}
                    </td>
                    <td className="p-2 py-5 border-filter-heading">
                      {`\$${book.gross_amount}`}
                    </td>
                  </tr>
                ))}
                <tr className="border-b-0 ">
                  <td className="md:py-20 py-10 border-r border-filter-heading"></td>
                  <td className="md:py-20 py-10 border-r border-filter-heading"></td>
                  <td className="md:py-20 py-10 border-r border-filter-heading"></td>
                  <td className="md:py-20 py-10 border-r  border-filter-heading"></td>
                  <td className="md:py-20 py-10 border-r  border-filter-heading"></td>
                  <td className="md:py-20 py-10 border-r  border-filter-heading"></td>
                  <td className="md:py-20 py-10 border-r  border-filter-heading"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="md:mt-0 mt-40"></div>
          {isModalOpen && (
            <div
              className="fixed  inset-0 flex items-center justify-center z-50"
              onClick={closeModal}
            >
              <div className="bg-black opacity-50 absolute inset-0"></div>
              <div
                className="bg-clue-black p-8 md:w-auto w-80 px-12 rounded-[30px] relative z-10"
                onClick={(e) => e.stopPropagation()}
              >
                <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left text-[18px] mb-6">
                  Select Winner
                </h3>
                <p className=" w-60 md:w-96 md:mx-[-20px] text-left text-[16px] md:ml-[-20px] font-[500] text-filter-heading">
                  This dialog will give you a randomly selected name from the
                  entries of the users that guessed correctly
                </p>
                <br />
                <p className="w-60 md:w-96 md:mx-[-20px] text-left text-[16px] md:ml-[-20px] font-[500] text-filter-heading">
                  This was the suspect that you marked as the culprit for this
                  book, you have the option to update it before selecting a
                  winner
                </p>
                <br />
                <select className="mb-4 w-60 md:w-80 py-4 pl-5 bg-clue-black text-white p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase">
                  <option value="" disabled selected>
                    Suspect 1
                  </option>
                </select>
                <br />
                <div className="flex justify-end mt-6">
                  <button
                    onClick={closeModal}
                    className="transition-opacity hover:opacity-70  text-filter-heading mr-4 border-filter-heading rounded-[9px]  border-solid border px-4 md:h-8"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={openSelectWin}
                    className="transition-opacity hover:opacity-70  bg-clue-purchase text-white  py-[-2px] rounded-[9px]  px-6 md:h-8"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
          {isSelectWinOpen && (
            <div
              className="fixed  inset-0 flex items-center justify-center z-50"
              onClick={closeModal}
            >
              <div className="bg-black opacity-50 absolute inset-0"></div>
              <div
                className="bg-clue-black p-8 px-12 md:w-auto w-80 rounded-[30px] relative z-10"
                onClick={(e) => e.stopPropagation()}
              >
                <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left text-[18px] mb-6">
                  Select Winner
                </h3>
                <p className="w-60 md:w-96 md:mx-[-25px] text-left text-[16px] md:ml-[-20px] font-[500]  text-filter-heading">
                  The randomly selected winner from the entries on the basis of
                  the culprit is
                </p>
                <br />
                <input
                  type="text"
                  className="mb-4 pointer-events-none select-none py-3 bg-clue-black text-white pl-4 w-60 md:w-80 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                  placeholder=" Moasfar Javed"
                />
                <br />
                <div className="flex justify-end mt-6 md:w-80 w-60">
                  <button
                    onClick={closeSelectWin}
                    className="transition-opacity hover:opacity-70  text-filter-heading md:mr-4 mr-4 rounded-[9px] border-filter-heading border-solid border px-2 md:px-4 md:h-8"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={openModal}
                    className="transition-opacity hover:opacity-70  text-filter-heading md:mr-4 mr-4 rounded-[9px]  border-solid border border-filter-heading px-3 md:px-5 md:h-8"
                  >
                    Again
                  </button>
                  <button
                    onClick={openSelectWinConfirm}
                    className="transition-opacity hover:opacity-70  bg-clue-purchase text-white  py-[-2px] rounded-[9px] px-4 md:px-6 md:h-8"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
          {isSusOpen && (
            <div
              className="fixed rounded-xl inset-0 flex items-center justify-center z-50"
              onClick={closeSus}
            >
              <div className="bg-black opacity-50 absolute inset-0"></div>
              <div
                className="bg-clue-black p-5 px-10 md:w-[480px] w-80  rounded-3xl relative z-10"
                onClick={(e) => e.stopPropagation()}
              >
                <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-3">
                  Select User
                </h3>
                <p className="text-clue-gray text-left text-sm mb-4 ml-[-10px] md:ml-[-20px]">
                  Select a contributor to download the file that contributor
                  will see in their dashboard.
                </p>
                <select
                  value={selectedContributor}
                  onChange={(e) => setSelectedContributor(e.target.value)}
                  className="mb-4 px-5 w-60 md:w-80 bg-clue-black text-white p-2 rounded-xl border focus:outline-none focus:ring-0 focus:border-clue-purchase"
                >
                  <option value="" disabled>
                    Select Username
                  </option>
                  {contributions.map((contribution) => (
                    <option key={contribution._id} value={contribution.user_id}>
                      {contribution.username}
                    </option>
                  ))}
                </select>
                <div className="flex justify-end mt-2">
                  <button
                    onClick={closeSus}
                    className="text-filter-heading mr-4 rounded-md border-2 border-filter-heading py-1 px-4"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() =>
                      selectedContributor &&
                      handleDownloadCont(selectedContributor)
                    }
                    disabled={!selectedContributor}
                    className={`bg-clue-purchase text-white px-4 py-1 rounded ${
                      !selectedContributor
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  >
                    {showLoading3 ? (
                      <span className="flex w-full items-center justify-center">
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            class="inline  w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </span>
                    ) : (
                      <span>Export</span>
                    )}{" "}
                  </button>
                </div>
              </div>
            </div>
          )}
          {isSelectWinConfirm && (
            <div
              className="fixed inset-0 flex items-center justify-center z-50"
              onClick={closeSelectWinConfirm}
            >
              <div className="bg-black opacity-50 absolute inset-0 rounded-[30px]"></div>
              <div
                className="bg-clue-black p-8 px-12 md:w-auto w-80 rounded-lg relative z-10"
                onClick={(e) => e.stopPropagation()}
              >
                <h3 className="text-clue-book-author ml-[-10px] md:ml-[-20px] text-left mb-6 text-[18px]">
                  Confirm
                </h3>
                <p className="text-filter-heading text-left w-60 md:w-80 ">
                  This action is irreversible, are you sure you want to update{" "}
                  <b className="text-clue-yellow">Moasfar Javed</b> as the
                  winner site-wide?{" "}
                </p>
                <div className="flex justify-end mt-6">
                  <button
                    onClick={closeSelectWinConfirm}
                    className="transition-opacity hover:opacity-70 rounded-[9px]  border-solid border border-filter-heading px-5 md:h-8 text-filter-heading mr-4"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSelectWinConfirm}
                    className="transition-opacity hover:opacity-70 rounded-[9px] px-7 md:h-8  bg-clue-logout text-white  py-1 "
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          )}{" "}
          {toastMessages.map((toast, index) => (
            <Toast
              key={index}
              toasts={[toast]}
              onClose={() => {
                // Remove the toast message when it's closed
                const updatedToasts = [...toastMessages];
                updatedToasts.splice(index, 1);
                setToastMessages(updatedToasts);
              }}
            />
          ))}
        </>
      )}
    </div>
  );
}
export default ViewSales;
