import React from "react";
import Error_detective from "../../assets/images/detective.png";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import WestIcon from "@mui/icons-material/West";
import { Link, useNavigate, useLocation } from "react-router-dom";

function BookOwned() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const navigate = useNavigate();
  return (
    <div className="min-h-[95vh] bg-black text-white flex flex-col">
      <div className="flex-grow">
        <div className="flex justify-center items-center mt-28 lg:mt-28 xl:mt-32">
          <img
            src="https://dq6y48nvbage3.cloudfront.net/assets/detective.png"
            alt="Error Detective"
            className="w-40 h-40 md:w-60 md:h-60 md:ml-[-40px]"
          />
        </div>
        <p className="mt-4 md:mt-[20px] text-xl md:text-2xl text-center font-semibold text-clue-yellow leading-7">
          You’ve already purchased this book
        </p>
        <div className="md:ml-0 md:mt-10 mt-3 text-center">
          <Link to="/competitions">
            <button className="bg-clue-purchase text-white font-[700] md:px-20 px-4 py-3 rounded-[10px] text-[16px] md:text-lg transition-opacity hover:opacity-70">
              Explore more competitions
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-24 md:mt-20">
        <Footer />
      </div>
    </div>
  );
}

export default BookOwned;
