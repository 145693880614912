import React from "react";
import myImage from "../../assets/images/cover.png";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "@fontsource/inter";
import BackButton from "../backbutton/backbutton";
import Footer from "../../components/footer/footer.js";
import BookByIDList from "../../models/website/competitions/https/compbookidhttp.js";
import AllCompList from "../../models/website/public/https/comphttp.js";
import Countdown from "react-countdown";

const allcompList = new AllCompList();
function InfoPage() {
  const location = useLocation();
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  const forHome = ["/home/product"].includes(location.pathname);
  const forCompetitions = ["/competitions/product"].includes(location.pathname);
  const [bookDetails, setBookDetails] = useState({ data: [] });
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("book_id");
  const [allComps, setAllComps] = useState({ data: [] });

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return "Timer Expired!";
    } else if (days > 0) {
      return days + " days";
    } else {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (!id) {
        navigate("/beyond-the-yellow-tape");
        return;
      }
      setShowLoading(true);

      try {
        const allCompsResponse = await allcompList.getbyid(id);

        if (allCompsResponse.success) {
          setAllComps(allCompsResponse);
        } else {
          setShowLoading(true);
          navigate("/beyond-the-yellow-tape");
        }
      } catch (error) {
        navigate("/beyond-the-yellow-tape");
      } finally {
        // Set loading to false whether the request is successful or not
        setShowLoading(false);
      }
    }

    fetchData();
  }, []);

  const convertDateFormat = (startDate) => {
    const parsedDate = new Date(startDate);

    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedStartDate = parsedDate.toLocaleDateString("en-US", options);
    return formattedStartDate;
  };

  const navigateToPayment = () => {
    navigate("/competitions/product/payment?book_id=" + id);
  };
  const handleVisitAuthorClick = (e) => {
    e.stopPropagation();

    if (allComps && allComps.data.link_to_author) {
      // Navigate to the author details page
      window.location.href = allComps.data.link_to_author;
    }
  };
  return (
    <div
      className="flex flex-col min-h-[95vh] text-white"
      style={{ fontFamily: "Inter" }}
    >
      {showLoading ? (
        <div className="flex w-full h-full items-center justify-center fixed top-0 left-0 ">
          <div className="flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5 flex-grow ">
          <div className="md:w-40 w-3">
            <BackButton />
          </div>
          <div className="md:flex justify-between items-start flex-col md:flex-row">
            <div className="flex-1 max-w-xl m-4 md:ml-[5%] md:mt-7 p-6 rounded-md relative">
              <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-between">
                <div className="flex-none mb-4 md:mb-0 w-full md:w-auto">
                  <img
                    src={allComps.data.cover}
                    alt="Cover"
                    className="w-3/4 md:w-52 md:h-72 mx-auto object-cover"
                  />
                </div>
                <div className="px-6 flex-1 text-center md:text-left mt-4 md:mt-0">
                  <h2 className="text-clue-book-title font-semibold text-2xl mt-2 mb-2">
                    {allComps.data.title}
                  </h2>
                  <p className="text-clue-book-author text-base font-medium mb-8">
                    {allComps.data.author}
                  </p>
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Start Date
                  </p>
                  <p className="text-clue-book-author font-bold mb-4 text-base">
                    {convertDateFormat(allComps.data.start_date)}
                  </p>
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Time Left
                  </p>
                  <p className="text-clue-book-author font-bold mb-4 text-base">
                    <Countdown
                      date={new Date(allComps.data.end_date)}
                      renderer={renderer}
                    />
                  </p>
                  <p className="text-clue-draw-amount mb-2 font-medium group-hover:text-clue-hover-draw text-sm">
                    Ages
                  </p>
                  <p className="text-clue-book-author font-bold mb-2 text-base">
                    {allComps.data.under_18_appropriate
                      ? "Appropriate for ages of 14 and above"
                      : "Not suitable for readers under the age of 18"}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex-1 m-4 md:mt-16 md:mr-[5%] md:max-w-[40%] block rounded-xl px-10  text-center bg-clue-black">
              <div class="flex justify-between mt-8 p-4 border-b-2 border-neutral-600">
                <div>
                  <p class="text-neutral-50">Prize Amount</p>
                </div>
                <div className="text-white">
                  {allComps.data.prize_amount &&
                  allComps.data.prize_amount.length > 0
                    ? allComps.data.prize_amount.map((amount, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && ", "}${amount.toLocaleString()}
                        </React.Fragment>
                      ))
                    : "TBD"}
                </div>
              </div>
              <div class="flex justify-between p-4 border-b-2 border-neutral-600">
                <div>
                  <p class="text-neutral-50">Draw Date</p>
                </div>
                <div className="text-white">
                  {convertDateFormat(allComps.data.end_date)}
                </div>
              </div>
              <div class="flex justify-between p-4">
                <div>
                  <p class="text-neutral-50">Entry Price</p>
                </div>
                <div className="text-white">${allComps.data.amount}</div>
              </div>
              <div class="flex justify-center py-6">
                {allComps.data.is_purchased ? (
                  <button
                    className="bg-gray-500 font-semibold text-clue-yellow w-44 px-4 py-2 mt-6 rounded-xl text-center cursor-not-allowed hover:opacity-80 transition-transform transform hover:scale-110"
                    // Add any additional styles for the "Purchased" button
                  >
                    Purchased
                  </button>
                ) : allComps.data.status === "Active" ? (
                  <div>
                    {forHome && (
                      <Link to="/home/payment">
                        <button className="bg-clue-purchase font-semibold text-white w-44 px-4 py-2 mt-6 rounded-xl text-center md:group-hover:bg-black">
                          Purchase
                        </button>
                      </Link>
                    )}
                    {forCompetitions && (
                      <button
                        className="hover:opacity-80 transition-transform transform hover:scale-110 bg-clue-purchase font-semibold text-white w-44 px-4 py-2 mt-6 rounded-xl text-center"
                        onClick={navigateToPayment}
                      >
                        Purchase
                      </button>
                    )}
                  </div>
                ) : (
                  allComps.data.link_to_author && (
                    <button
                      className="hover:opacity-80 transition-transform transform hover:scale-110 border-2 border-clue-purchase font-semibold text-white w-44 px-4 py-2 mt-6 rounded-xl text-center"
                      onClick={handleVisitAuthorClick}
                    >
                      Visit The Author
                    </button>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="px-4 md:px-24 py-10">
            <p className="text-clue-draw-amount font-medium text-left">
              Description
            </p>
            <p
              className="text-clue-book-author text-left mt-3"
              dangerouslySetInnerHTML={{ __html: allComps.data.description }}
            ></p>
          </div>
        </div>
      )}
      {!showLoading && (
        <div className="mt-36">
          <Footer />
        </div>
      )}
    </div>
  );
}

export default InfoPage;
