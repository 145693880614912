import Celebration from "../../assets/images/Celebration.png";
import BackButton from "../../components/backbutton/backbutton.js";
import { useNavigate } from "react-router-dom";

function ArchiveUpdated() {
  const navigate = useNavigate();
  const navigateToCollection = () => {
    navigate('/adboard/dashboard/archive');
  };
  return (
    <div className="w-full h-screen flex items-center overflow-y-hidden" style={{ fontFamily: "Inter" }}>
      <div className="w-full">
        <div className="flex justify-center items-center mb-16">
          <img src="https://dq6y48nvbage3.cloudfront.net/assets/celebration.png" />
        </div>
        <div className="mx-10 my-5">
          <p className="text-clue-gray text-xl font-bold">
            Competition Updated In Archive
          </p>
        </div>
        <div className="mx-10">
          <p className="text-filter-heading text-md">
            Competition has been updated in your collection of competitions, you<br/>
            can view your competitions{" "}
            <span className="text-clue-yellow underline cursor-pointer" onClick={navigateToCollection}>here</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ArchiveUpdated;
