import React, { useState, useEffect } from 'react';
import '@fontsource/inter';
import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';
import WestIcon from "@mui/icons-material/West";
import LogoutIcon from '@mui/icons-material/Logout';
import Verify_Code from '../reset_pw/verify_code';
import { useNavigate, useLocation } from "react-router-dom";
import WebSignoutManager from '../../models/website/auth/https/websignouthttp';
import WebChangeUsernameManager from '../../models/website/auth/https/webchangeusernamehttp';
import Toast from "../../components/toasts/toast";
import WebResetPass from '../../models/website/auth/https/webresetpasshttp';
import AddSubMails from '../../models/website/subscribe email/http/subsmailhttp';
const resetManager = new WebResetPass();
function Profile() {
  const location = useLocation();
  const [email, setEmail] = useState(localStorage.getItem('websiteEmail') || '');
  const [username, setUsername] = useState(localStorage.getItem('websiteUsername') || '');
  const [isSignout, setIsSignout] = useState(false);
  const [isVerifyCodeOpen, setIsVerifyCodeOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if email or username is not found in local storage
    if (!email || !username) {
      // Navigate to account/signin page
      navigate('/account/signin');
    }
  }, [email, username]);
  const closeSignOut = () => {
    setIsSignout(false);
  };
  const openSignOut = () => {
    setIsSignout(true);
  };
  const openVerifyCodeModal = () => {
    try {
      resetManager.reset1(email).then((value) => {
        if (!value.error) {
          const baseResponse = value.success;
          if (baseResponse == true) {
            setIsVerifyCodeOpen(true);
          } else {
            setToastMessages([
              ...toastMessages,
              {
                type: "invalid",
                title: "Error",
                body: value.message,
              },
            ]);
          }
        } else {
          setToastMessages([
            ...toastMessages,
            {
              type: "error",
              title: "Error",
              body: value.error,
            },
          ]);
        }
      });
    }
    catch {
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Error",
          body: "Error Occurred",
        },
      ]);

    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const [dataLoaded, setDataLoaded] = useState(false);
 

  
  const handleBackClick = () => {
    const segments = location.pathname.split('/').filter(Boolean);
    if (segments.length > 1) {
      segments.pop();
      navigate('/' + segments.join('/'));
    } else {
      navigate('/');
    }
  };

  //const [receiveUpdates, setReceiveUpdates] = useState(localStorage.getItem('WebisEmailSubscribed'));

  // useEffect(() => {
  //   // Retrieve the value from localStorage
  //   const storedValue = localStorage.getItem('WebisEmailSubscribed');
  //   if(storedValue===true)
  //   {  setReceiveUpdates(true);}
  //   if(storedValue===false)
  //   {  setReceiveUpdates(false);}
  //   // Set the initial state based on the retrieved value

  // }, []);
  const [receiveUpdates, setReceiveUpdates] = useState(
    localStorage.getItem('WebisEmailSubscribed') === 'true'
  );

  const handleReceiveUpdatesChange = () => {
    setReceiveUpdates(!receiveUpdates);
    localStorage.setItem('WebisEmailSubscribed', receiveUpdates.toString());
  };
  // const handleReceiveUpdatesChange = () => {
  //   setReceiveUpdates(!receiveUpdates);

  // };
  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;

    // Check if the new username length is less than 50
    if (newUsername.length >= 50) {
      setToastMessages([
        ...toastMessages,
        {
          type: "invalid",
          title: "Invalid Username",
          body: "Username should be less than 50 characters.",
        },
      ]);
      // Optionally, you may choose to prevent setting the new username
      // Uncomment the line below if you want to prevent setting the new username
      return;
    }

    setUsername(newUsername);
  };

  const back = () => {
    setIsVerifyCodeOpen(false);
  };
  const [toastMessages, setToastMessages] = useState([]);




  const SubMails = new AddSubMails();
  const isEmailSubscribed = receiveUpdates;


  const handleSaveChanges = async () => {
    try {
      let isApiCalled = false;
      // Check if the username is not null or empty
      if (!username) {
        setToastMessages([
          ...toastMessages,
          {
            type: 'invalid',
            title: 'Username',
            body: 'Username cannot be empty',
          },
        ]);
        return;
      }



      const storedUsername = localStorage.getItem('websiteUsername') || '';
      const isUsernameChanged = storedUsername !== username;

      if (isUsernameChanged) {
        const changeUsernameManager = new WebChangeUsernameManager();
        const response = await changeUsernameManager.put(username, isEmailSubscribed);

        if (!response.error) {
          isApiCalled = true;
          setToastMessages([
            ...toastMessages,
            {
              type: 'success',
              title: 'Username Changed',
              body: 'Your username has been changed successfully.',
            },
          ]);

          // Store the new username in local storage
          localStorage.setItem('websiteUsername', username);
        } else {
          // Handle username change error, display an error message or take appropriate action
          console.error(response.error);

          // Show a toast for the error
          setToastMessages([
            ...toastMessages,
            {
              type: 'invalid',
              title: 'Error',
              body: `Failed to change username. ${response.error}`,
            },
          ]);

          return; // Stop execution if changing the username fails
        }
      }
      // If receiveUpdates was initially true and is now false, call the delete API
      if (localStorage.getItem('WebisEmailSubscribed') === 'true' && !receiveUpdates) {
        try {
          const deleteResponse = await SubMails.delete();
          if (deleteResponse.success) {
            isApiCalled = true;
            setToastMessages([
              ...toastMessages,
              {
                type: 'success',
                title: 'Email Subscription',
                body: 'You will not receive updates about new competitions and promotions',
              },
            ]);
            localStorage.setItem('WebisEmailSubscribed', receiveUpdates.toString());
          }
        } catch (error) {
          console.error("Error calling DeleteSubMails API:", error);
          setToastMessages([
            ...toastMessages,
            {
              type: 'invalid',
              title: 'Error',
              body: 'Failed to delete subscription.',
            },
          ]);
        }
      }

      // If receiveUpdates was initially false and is now true, call the add API
      if (localStorage.getItem('WebisEmailSubscribed') === 'false' && receiveUpdates) {
        try {
          const subMailResponse = await SubMails.add(email);
          if (subMailResponse.success) {
            isApiCalled = true;
            setToastMessages([
              ...toastMessages,
              {
                type: 'success',
                title: 'Email Subscription',
                body: 'You will now receive updates about new competitions and promotions',
              },
            ]);
            localStorage.setItem('WebisEmailSubscribed', receiveUpdates.toString());
          }
        } catch (error) {
          console.error("Error calling AddSubMails API:", error);
          setToastMessages([
            ...toastMessages,
            {
              type: 'invalid',
              title: 'Error',
              body: 'Failed to subscribe to emails.',
            },
          ]);
        }
      }
      // Update the local storage with the current state of receiveUpdates
      localStorage.setItem('WebisEmailSubscribed', receiveUpdates);
      if (!isApiCalled) {
        setToastMessages([
          ...toastMessages,
          {
            type: 'success',
            title: 'Changes Update',
            body: 'No changes were made.',
          },
        ]);
      }
      // Continue with the rest of your logic (e.g., changing the username)
    } catch (error) {
      console.error(error);
    }
  };



  const closeVerifyCodePopup = () => {
    setIsVerifyCodeOpen(false);
  };

  const handleVerifyCodeSuccess = () => {
    // Handle the successful verification here
    // After verification, close the popup
    closeVerifyCodePopup();
  };


  const HandleSignout = async () => {
    try {
      const signoutManager = new WebSignoutManager();
      const response = await signoutManager.signout();

      if (!response.error) {
        // Handle successful sign-out, e.g., navigate to the sign-in page
        navigate('/account/signin');

      } else {
        // Handle sign-out error, display an error message or take appropriate action
        console.error(response.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Define the openModal function here if required
  const openModal = () => {
    // Implement your open modal logic here
  };
  const type = "user";

  return (
    <div className="bg-black text-white flex flex-col min-h-[95vh] justify-between">
      {toastMessages.map((toast, index) => (
        <Toast
          key={index}
          toasts={[toast]}
          onClose={() => {
            // Remove the toast message when it's closed
            const updatedToasts = [...toastMessages];
            updatedToasts.splice(index, 1);
            setToastMessages(updatedToasts);
          }}
        />
      ))}
      <div className="flex justify-center  items-center ">
        <div className="md:ml-[13%] mx-2 md:w-[100%] w-[360px] md:mt-[4%] lg:mt-[4%] xl:mt-10 mt-20 block rounded-[30px]  bg-transparent">
          <div className='flex mx-8 md:mx-12 md:text-[18px] text-[14px]'>
            <div className='flex items-start w-[100%] md:w-[76%] mb-1 justify-start text-clue-yellow' >
              <div onClick={handleBackClick} className=' transition-opacity hover:opacity-70 md:w-20 mr-5 cursor-pointer'><WestIcon /><span className='ml-2 lg:inline hidden'>Back</span> </div></div>
            <div className='flex transition-opacity hover:opacity-70 items-end justify-end cursor-pointer text-clue-logout ' onClick={openSignOut}><LogoutIcon /><span className='ml-2 underline lg:inline hidden'>Sign Out</span></div>
          </div>
          <p className="md:mr-[60%] text-[20px] md:text-[24px] md:ml-[0px] mr-[105px] md:mt-10 mt-7 mb-6 md:mb-12 font-[700] leading-tight text-clue-yellow">
            Your Information
          </p>
          <div className="mb-4 flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
            {/* Use flex-column for mobile view */}
            <input
              type="text"
              className="focus:outline-none focus:ring-0 focus:border-onclickfield peer m-0 block md:h-[56px] h-[45px] ml-[50px] md:ml-[14%] md:mr-[3%] md:w-[27%] rounded-[10px] w-[270px] bg-clue-black bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Name"
            />

            <input
              type="email"
              className="focus:outline-none   focus:ring-0 focus:border-onclickfield peer m-0 block md:h-[56px] h-[45px] ml-[50px] md:ml-[0px] md:w-[27%] rounded-[10px] w-[270px] bg-clue-black bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-clue-gray"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Email"
              readOnly
            />
          </div>

          <button
            type="button"
            className="mb-2 block md:mx-[3%] md:w-[23%] md:ml-[32%] ml-[95px] mx-10 md:h-[56px] flex-wrap h-[45px] w-[180px] bg-onclickfield rounded-[10px] md:pb-1 md:pl-1 text-center text-[16px] md:text-[19px] font-medium leading-normal text-white transition-opacity hover:opacity-70 md:mt-[50px] mt-[40px]"
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
          <p className="md:mt-16 md:ml-[0%] mt-[45px] flex-row items-center justify-center w-[270px] md:w-[86%] text-[17px] md:text-[20px] text-center ml-[50px] font-[500] text-filter-heading leading-[1.30]">
            <input type="checkbox" checked={receiveUpdates} onChange={handleReceiveUpdatesChange} className="md:mr-5 mr-3 md:mb-1" />
            I want to receive updates about new competitions and promotions
          </p>
          <p className="md:mt-12 md:ml-[0%] mt-[45px] flex-row items-center justify-center w-[270px] md:w-[86%] text-[17px] md:text-[20px] text-center ml-[50px] font-[500] text-filter-heading leading-[1.30]">
            If you want to reset your password, you can reset it by{' '}

            <a className="font-[400] text-clue-yellow pl-[2px] md:text-center transition-opacity hover:opacity-70  underline cursor-pointer" onClick={openVerifyCodeModal}>
              clicking here
            </a>
          </p>


        </div>
      </div>
      <div className='md:mt-[5%]  mt-[24%] bottom-0 '>
        <Footer />
      </div>

      {isVerifyCodeOpen && (
        <div className="fixed inset-0 flex items-center my-4 h-screen justify-center z-50" onClick={closeVerifyCodePopup}>
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div onClick={(e) => e.stopPropagation()} className='z-10 mb-28 my-4'>
            <div>
              <Verify_Code email={email}  type={type} onBack={back} isPopupOpen={isPopupOpen} closePopup={handleVerifyCodeSuccess} onClose={() => setIsVerifyCodeOpen(false)} />
            </div>
          </div>
        </div>
      )}



      {isSignout && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          onClick={closeSignOut}
        >
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div
            className="bg-clue-black rounded-3xl md:w-auto w-80  p-8 px-12 relative z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-clue-book-author md:w-auto w-60 text-left mb-4">
              Confirm
            </h3>
            <p className="text-filter-heading md:w-auto w-60 text-left">
              Are you sure you want to sign out of your account?
            </p>
            <div className="flex justify-end mt-6">
              <button
                onClick={closeSignOut}
                className="text-filter-heading transition-opacity hover:opacity-70 mr-4 border-2 rounded-[9px] border-filter-heading py-0.5 px-6"
              >
                Cancel
              </button>
              <button className="bg-clue-logout transition-opacity hover:opacity-70 text-white px-7 rounded-[9px] py-0.5 "
                onClick={HandleSignout}>
                Sign Out
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
