import Celebration from "../../assets/images/Celebration.png";
import Footer from "../footer/footer";
import WestIcon from "@mui/icons-material/West";
import { Link,useNavigate,useLocation  } from "react-router-dom";

function SuccessfulSubmit() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id")
    const navigate = useNavigate();
    return (
        <div className="w-full min-h-[96vh] flex flex-col" style={{ fontFamily: "Inter" }}>
             <div className="flex-grow">
            <div className="w-full md:mt-24">
                <div className="flex justify-center items-center mb-12 xl:mb-8 md:mt-28 mt-[7%]">
                    <img src={Celebration} />
                </div>
                <div className="mx-10 my-5">
                    <p className="text-clue-gray md:text-[28px] text-[20px] font-bold">
                        Successfully Submitted
                    </p>
                </div>
                <div className="mx-10 text-center flex-wrap flex justify-center items-center ">
                    <p className="text-filter-heading text-md text-center justify-center items-center ">
                        Your answer is submitted and the winner and final chapter revealing the outcome will be emailed upon completion of the competition.<br />Good luck!

                    </p>
                </div>
                <div className="md:ml-0 md:mt-7 lg:mt-10 xl:mt-10 mt-12 text-center">
                  <Link to="/competitions">
                    <button className="bg-clue-purchase text-white md:px-20 px-3 py-3 rounded-[10px] text-[17px] md:text-lg transition-opacity hover:opacity-70"  >
                        Explore more competitions</button>
                </Link></div>
                <div className="md:ml-0 md:mt-7 lg:mt-10  xl:mt-8 mt-10 text-center">
                 
                    <button
                     className="md:mb-[3.75%] bg-transparent border-solid border-filter-heading border text-white md:px-20 px-5 py-3 rounded-[10px] text-[17px] md:text-lg transition-opacity hover:opacity-70"  
                     onClick={() => navigate(`/account/dashboard/book?book_id=${id}`)}
                    >
                        <WestIcon className="mr-3" />Back To Book</button>
             </div>
            </div>
            </div>
            <div className="mt-24 md:mt-20"> <Footer /></div>

        </div>
    );
}

export default SuccessfulSubmit;
